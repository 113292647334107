$profile-assistance-body-width: 450px;
$profile-assistance-col-width: 450px;

.profile-assistance-heading {
  @extend %hero__title;
}

.profile-assistance-body {
  max-width: $profile-assistance-body-width;

  .btn {
    text-transform: uppercase;
  }

  .res-id-type-row,
  .expiry-date-row {
    background: #fff;
    border: 1px solid #dadada;
    border-radius: $input-border-radius;
    margin: 0 auto 8px;
    position: relative;
    overflow: hidden;

    select {
      background: transparent;
      border: none;
      position: relative;
      padding-right: 30px;
      text-overflow: ellipsis;
      -webkit-appearance: none;
      -moz-appearance:    none;
      z-index: 2;
    }

    // Hide dotted outline on selects in Firefox
    select:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 $primary-text-color;
    }

    &.in-focus {
      @include input-focus;
    }

    .icon {
      font-size: 24px;
      line-height: 26px !important;
      pointer-events: none;
      position: absolute;
      text-align: center;
      width: 34px;
      z-index: 2;

      top: 1px;
      right: 1px;
      bottom: 1px;

      &:before {
        height: 24px;
        margin-top: -12px;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
      }
    }
  }

  .expiry-date-row {
    border: none;
    background: transparent;

    .col-xs-6 {
      border: 1px solid #dadada;
      background: #fff;
      width: 49%;
      padding-left: 0px;
      padding-right: 0px;
    }    

    .col-xs-6.rightcol {
      margin-left: 7px
    }
  }
}

.profile-assistance-unavailable,
.profile-assistance-complete,
.profile-assistance-buttons,
.profile-assistance-form,
.profile-assistance-form-col,
.profile-assistance-form-submit-button {
  max-width: $profile-assistance-col-width;
  margin-right: auto;
  margin-left: auto;
}

.profile-assistance-form-title {
  padding-bottom: 30px;
}

.profile-assistance-form-submit {
  text-align: center;
}


@media only screen and (max-width : $screen-xs) {
  .profile-assistance-body {
    .expiry-date-row {
      .col-xs-6.rightcol {
        margin-left: 3px
      }
    }
  }
}


@media only screen and (min-width : $screen-sm) {
  .profile-assistance-heading {
    font-size: 42px;
  }
}
