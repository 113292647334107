$left-content: "\3c";
$right-content: "\3e";

.back-btn {
  width: $header-height-mobile;
  height: $header-height-mobile;
  background-color: $header-bg-color;
  border: none;
  position: fixed;
  font-size: 18px;
  top: 0;
  left: 0;
  color: $white;
  z-index: $z_modalBackButton;

  &:before {
    content: $left-content;
  }

  .rtl & {
    right: 0;
    left: inherit;
  }

  .is-mobile-app & {
    top: auto;
    bottom: 0;

    &.app-hide-title {
      display: none;
    }
  }
}

.back-btn--modal {
  position: absolute;
  top: -($header-height-mobile + 1);
}

@media only screen and (min-width: $screen-sm) {
  .back-btn {
    display: none;
  }
}
