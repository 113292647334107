$hero-padding: 36px;
$hero-height: 425px;
$hero-high-cut: 200px;

.hero {
  background: $panel-background;
  padding-top: $hero-padding;
  // 1px padding to prevent margins from overflowing
  padding-bottom: 1px;
  display: block;

  h1, h2, h3, h4 {
    font-family: $primary-font;
    font-weight: $primary-font-weight;
    margin-bottom: 0;
    margin-top: 0;
  }
}

%hero__title--base,
%hero__title,
.hero__title {
  display: block;
  margin-left: auto;
  margin-right: auto;

  text-align: center;
  text-transform: uppercase;

  letter-spacing: 0.1em;
  line-height: 1;
}

.hero__logo {
  display: block;
  max-width: 50%;
  margin: 38px auto;
}

.fs-hero {
  .best_rate_guaranteed_outer_box {
    display: none;
  }
}

// mobile
@media screen and (max-width: $screen-sm-max) {
  .fs-hero {
    body {
      background: $panel-background;
    }
  }

  .hero--hide--mob {
    visibility: hidden;
    display: none;
  }
}

// logo shouldn't block CTAs on small screens like iPhone 5, Nexus 5, etc
@media screen and (max-height: 640px) {
  .hero__logo {
    max-width: 40%;
    margin: 10px auto;
  }
}

// desktop
@media screen and (min-width: $screen-sm) {
  .fs-hero {
    // dirty header overrides
    header {
      position: absolute;

      .breadcrumbs,
      .brg_help,
      .best_rate_guaranteed_wrapper {
        display: none;
      }

      .header-background {
        border-bottom: none;
        background: none !important;
        height: 0;
      }
    }

    // move the error container
    .main-inner > .fs-errors-container {
      position: relative;
      top: 84px;
    }
  }

  .hero {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $white;
    }

    &.hero {
      color: $white;
      letter-spacing: 0.1em;
    }

    line-height: $hero-height;
    height: $hero-height;

    background-color: $header-bg-color;
    background-image: url('/images/hero-bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    border: none;

    text-shadow: rgba(#333, 0.5) 0 0 .4em;
    text-align: center;
  }

  %hero--high-cut,
  .hero--high-cut {
    line-height: ($hero-height - $hero-high-cut);

    .hero__content {
      margin-top: 0;
    }
  }

  .hero__content {
    display: inline-block;
    line-height: normal;
    vertical-align: middle;

    margin-top: -$hero-padding;
  }

  %hero__title,
  .hero__title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 33px;
  }

  .hero--profile {
    background-image: url('/images/profile-hero.jpg');
  }

  .hero--register {
    background-image: url('/images/register-hero.jpg');
  }

  .hero--sign-in {
    background-image: url('/images/sign-in-hero.jpg');
  }
}

