$register-body-width: 450px;

.register-heading {
  @extend %hero__title;
}

.register-form-col--short {
  min-width: 100px;
  max-width: 120px;
  width: 20%;

  float: left;

  .rtl & {
    float: right;
  }

  .pt &{
    max-width: 140px;
    width: 140px;
  }

  .fr & {
    min-width: 150px;
    max-width: 150px;
  }
}
.register-form-col--long {
  display: block;
  overflow: hidden;

  .fr & {
    width: 240px;
    float: left;
  }
}

@media only screen and (min-width : $screen-sm) {

  .register-body {
    max-width: $register-body-width;
    margin-top: -165px;
  }

  .register-heading {
    font-size: 42px;
  }
}
