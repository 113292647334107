.campaign-banner {
  border: 1px solid #ccc;
  margin: 20px auto;
  position: relative;
  overflow: hidden;
  background-color: #FFFFFF;

  .campaign-banner-text {
    position: relative;
    z-index: 1;
    padding: 0px 16px 12px 16px;
    font-size: 14px;
  }

  .campaign-banner-large-text{
    font-size:16px;
  }

  .campaign-banner-image {
    img {
      display: block;
      width: 100%;
    }
  }

  .banner-text-offer {
    font-size: 16px;
    margin-top: 0;
    color: black;
    font-weight:bolder;
    padding-left: 0px;
  }

  .banner-profile-text {
    color: $theme-black;
    padding-bottom: 10px;
  }


  p.small-print {
    font-size: 11px;
  }


    &.affix {
      position: fixed;
      top: 67px;
      left:0;
      box-shadow: $calendar-shadow;
      z-index: 9999;
      width:100%;
    }

  h4 {
		padding-top: 12px;
  }

  h4.banner-text-offer.with-image {
		padding-top: 12px;
		padding-left: 26px;
		background-image: url(/images/Exclusive_Offer_Icon.svg);
		background-repeat: no-repeat;
		background-size: 20px 20px;
		background-position-y: 12px;
  }
}

.view-booking-flow__plan-your-stay .campaign-banner {
  margin-bottom: 0;
}

.campaign-banner-terms-link,
.campaign-banner-name {
  font-size: 14px;
}
.campaign-banner-toggle{
  border: none;
  background: none;
  max-width: 50%;
  min-width: 10% !important;
  width: 20%;
  float: right;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}



@media screen and (min-width: $screen-sm) {
  .campaign-banner {
    margin: 0 auto 20px;

    .campaign-banner-text,
    .campaign-banner-image {
      width: auto;
    }
      .banner-profile-text {
        padding-bottom: 0px;
      }

    .campaign-banner-image {
      // background: url("//cdn.optimizely.com/img/152450673/5e63da41b9e9457195858a5ff0e42dca.jpg") 50% 50% no-repeat;
      background-size: cover;
      background-position: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;

      img {
        display: none;
      }
    }
  }

  .view-booking-flow__plan-your-stay .campaign-banner {
    margin-bottom: 20px;
  }

  .rtl {
    .campaign-banner {
      .campaign-banner-image {
        left: 0;
        right: auto;
      }
    }
  }
}
