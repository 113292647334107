.fs-profile-email-address-form {
  .verify-button {
    display: flex;
    float: left;
    margin-bottom: 40px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 0;
    }

    button {
      height: 39px;
    }
  }

  &__checkboxes {
    &::after {
      content: "";
      clear: both;
      display: table;
    }

    @media screen and (min-width: $breakpoints-md) {
      float: left;
    }
  }

  &__checkbox {
    float: left;
    clear: left;

    margin-right: 20px;
    padding-right: 22px;
    position: relative;
    display: flex;

    @media screen and (min-width: $breakpoints-md) {
      clear: none;
    }

    .fsp-input__checkbox,
    .fs-input__checkbox {
      input.checkbox {
        top: 0;
      }
    }
  }

  .profiles--tooltip__container {
    right: 0;
    top: 0;

    .profiles--tooltip {
      display: none;
    }

    &:hover {
      .profiles--tooltip {
        display: block;
      }
    }
  }

  .fs-toggle-block__actions {
    float: right;

    margin-left: -10px;
    margin-right: -10px;

    > * {
      margin: 0 10px;
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 1;

      @media screen and (min-width: $breakpoints-md) {
        flex-basis: auto;
      }
    }

    button {
      width: 100%;

      @media screen and (min-width: $breakpoints-md) {
        width: auto;
      }
    }
  }
}
