.select-new-dates-warning-modal {
  .modal-dialog__content {
    background: #fff;
  }

  &__content {
    background: #fff;
    padding: 65px;
    text-align: center;

    .warning-icon {
      border: 1px solid #000000;
      border-radius: 100%;
      width: 42px;
      height: 42px;
      margin: 0 auto 28px;
      position: relative;

      &:after {
        content: "!";
        position: absolute;
        top: 7px;
        left: 0;
        width: 100%;

        font-family: Garamond MT Std;
        font-size: 24px;
        line-height: 1.12;
        text-align: center;
        letter-spacing: 0.5px;
      }
    }

    p {
      margin: 0 0 20px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;

    button {
      margin: 0 8px;
      min-width: auto !important;
      max-width: none !important;
      width: auto !important;
    }
  }
}
