$column-paddings: 15px;

ul.search-results {

  font-size: 0;
  list-style: none;
  margin: 12px (-$column-paddings);
  padding: 0;
}

.info-row {
  margin: 0 -15px;
  padding: $column-paddings $column-paddings 0;
}

@media only screen and (min-width: $screen-sm) {
  ul.search-results {
    margin-left: auto;
    margin-right: auto;

    li.search-result {
      margin: 20px 0;
    }
  }

  .info-row {
    border-top: 2px solid $light-grey;
    margin: 0;
  }

  ul.search-results.layout-list {
    .modal {
      background: none;
      position: static;
      transition: none;

      .modal-dialog {
        background: none;
        border-radius: 0;
        border: none;
        padding: 0;
        position: static;
        width: auto;
      }

      .modal-dialog-content,
      .modal-content-body {
        overflow-y: hidden;
      }
    }
    .modal-overlay {
      background: none;
    }
  }
}
