.form-gray {
  .TextInput {
    margin-bottom: 0;
    padding-bottom: 27px;

    .formElement-field {
      padding: 10px 0 10px 16px;
      background-color: $gray6;

      @include rtl {
        padding: 10px 16px 10px 0;
      }
    }
  }

  .TextArea {
    .formElement-field {
      resize: none;
      height: 120px;
      min-height: 120px;
    }

    .formElement-message {
      top: 120px;
    }
  }

  .Dropdown {
    margin-bottom: 0;
    .formElement-field {
      padding: 10px 16px;
      background-color: $gray6;
    }

    @include rtl {
      &:after {
        right: unset;
        left: 16px;
      }
    }
    &:after {
      right: 16px;
    }
  }

  .fsp-input--phone-input__country-code {
    margin-bottom: 27px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 0;
    }
    .fsp-input--phone-input__country-code__label {
      background-color: $gray6;
      padding: 10px 16px;
    }
  }

  .fsp-input--phone-input .error-message {
    line-height: 15px;
  }

  .formElement-message {
    font-size: 12px;
    line-height: 15px;
    top: 47px;
  }

  .arrival-time {
    padding-bottom: 27px;

    &-error {
      padding-bottom: 12px;
    }
    .arrival-time-selects {
      .Dropdown {
        padding-bottom: 0;
      }
    }

    .error-message {
      line-height: 15px;
      color: $error-red;
      .formElement-message {
        position: static;
      }
    }
  }

  .autocomplete-input-wrapper input,
  .datepicker input {
    background-color: $gray6;
    border: none;
    border-bottom: 1px solid currentColor;
    font-size: 18px;
    line-height: 27px;
    color: $gray1;
    height: 47px;
    margin-bottom: 30px;
  }

  .destination {
    .icon-search {
      background-image: url("/images/profiles_2018/search.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: transparent;
      top: 13px;
      left: 15px;
      width: 19px;
      height: 20px;

      @include rtl {
        right: 15px;
        left: unset;
      }
    }
    .icon-search:before {
      content: "";
    }

    .autocomplete-input {
      padding: 0 50px;
    }
    .btn-close {
      top: 16px;
      right: 20px;
      height: 16px;
      width: 16px;

      @include rtl {
        left: 20px;
        right: unset;
      }
    }

    .autocomplete-list {
      position: absolute;
      z-index: 55;
      width: 100%;
      margin-top: -30px;

      .region-title {
        padding: 10px 20px;
        line-height: 27px;
        height: auto;
        border: none;
        font-style: italic;
        font-family: $garamond;
        font-size: 18px;
      }

      .property {
        padding: 10px 20px;
        line-height: 27px;
        height: auto;
        border: none;

        &.is-selected {
          background: $black;
        }
      }
    }
  }

  .display-field {
    label {
      display: block;
      margin-top: 15px;
      margin-bottom: 20px;
    }

    span {
      display: block;
      margin-bottom: 30px;
    }
  }
}
