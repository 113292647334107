$fs-btn-select-shadow-color: rgba($new-darker-grey,0.5);
$fs-btn-select-shadow-blur: 10px;
$fs-btn-select-shadow: 0 0 $fs-btn-select-shadow-blur $fs-btn-select-shadow-color;

$fs-btn-select-icon-width: 34px;

$fs-btn-select-font-size: 14px;
$fs-btn-select-label-padding: 15px;
$fs-btn-select-min-height: 46px;


.fsclickable {
  cursor: pointer;
}

.fs-btn-select {
  position: relative;
  display: block;
  min-height: 40px;
  font-size: $fs-btn-select-font-size;

  .fs-btn-select-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $z-fsBtnSelect;

    &.is-open {
      z-index: ($z-fsBtnSelect + 2);
    }

    .label {
      width: auto;
      padding: $fs-btn-select-label-padding;
    }

    .toggle {
      padding-right: 10px;
      .icon {
        float: right;
        display: inline-block;
        font-size: 16px;
        margin-left: 4px;
        cursor: pointer;
      }
    }

    .options {
      clear: both;
      background-color: white;
      padding: 20px;
      text-transform: uppercase;

      -webkit-box-shadow: $fs-btn-select-shadow;
      box-shadow: $fs-btn-select-shadow;

      width: auto;
      height: auto;

      z-index: ($z-fsBtnSelect + 1);
      overflow: auto;

      fieldset {
        margin: 0;
      }

      h4, legend {
        font-weight: $primary-font-weight;
        font-family: $primary-font;
        margin: 0 auto;
        padding: 0 0 10px;
        color: #5b5a56;
        font-size: 15px;
        line-height: 20px;
        text-align: left;
      }

      legend {
        background-color: white;
        letter-spacing: $letter-spacing-large;
      }

      a.btn, button.btn {
        display: block;
        margin-bottom: 4px;
      }
    }
  }
}

.rtl {
  .fs-btn-select {
    .fs-btn-select-container {
      .options {
        h4, legend {
          text-align: right;
        }
      }

      .toggle {
        padding-left: 10px;
        padding-right: 15px;
        .icon {
          float: left;
          margin-left: 0;
          margin-right: 4px;
        }
      }
    }
  }
}

@media only screen and (min-width : $screen-sm) {
  .fs-btn-select {
    .fs-btn-select-container {
      .options {
        legend {
          letter-spacing: 0.1em;
        }
      }
    }
  }
}
