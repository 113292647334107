.fs-currency-select {
  margin: 12px auto;

  .fs-select-mask {
    border-right: none;
    border-top-right-radius: 0;
    border-top-left-radius: $input-border-radius;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: $input-border-radius;
    width: 30%;

    select {
        padding-right: 0px;
        padding-left: 10px;
    }
    .icon {
      font-size: 14px;
      width: 24px;
    }
  }

  .fs-currency-select-inner {
    border: solid 1px $input-border-color;
    border-top-right-radius: $input-border-radius;
    border-top-left-radius: 0;
    border-bottom-right-radius: $input-border-radius;
    border-bottom-left-radius: 0;
    height: $input-height-with-border;
    line-height: $input-height-with-border;
    margin: 0;
    padding: 0 5px;
    float: right;
    width: 70%;
    background-color: $theme-gray4;
    text-align: right;
  }

  .fs-currency-select-label {
    display: none;
    float: left;
  }

  .fs-currency-select-total {
    font-size: 26px;
    float: right;
    font-family: $bold-font;
    padding: 0;
    font-weight: 700;
  }
}

.rtl{
  .fs-currency-select {
    .fs-select-mask {
      border: solid 1px $input-border-color;
      border-top-left-radius: 0;
      border-top-right-radius: $input-border-radius;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: $input-border-radius;
      border-left: none;

      select {
          padding-right: 10px;
          padding-left: 0px;
      }
    }

    .fs-currency-select-inner {
      border: solid 1px $input-border-color;
      border-top-left-radius: $input-border-radius;
      border-top-right-radius: 0;
      border-bottom-left-radius: $input-border-radius;
      border-bottom-right-radius: 0;
      height: $input-height-with-border;
      line-height: $input-height-with-border;
      margin: 0;
      padding: 0 5px;
      float: left;
      text-align: left;
    }

    .fs-currency-select-label {
      float: right;
    }

    .fs-currency-select-total {
      float: left;
    }
  }
}


@media screen and (min-width: $screen-sm) {
  .fs-currency-select {
    .fs-currency-select-label {
      display: none;
    }
    .fs-currency-select-total {
      float: none;
    }
    .fs-select-mask {
      border: solid 1px $theme-gray3;
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-right-radius: $input-border-radius;
      border-bottom-left-radius: $input-border-radius;
      width: 100%;
    }

    .fs-currency-select-inner {
      border-top-right-radius: $input-border-radius;
      border-top-left-radius: $input-border-radius;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      width: 100%;
    }
  }

  .rtl{
    .fs-currency-select {
      .fs-currency-select-total {
        float: none;
      }
      .fs-select-mask {
        border: solid 1px $theme-gray3;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $input-border-radius;
        border-bottom-right-radius: $input-border-radius;
      }

      .fs-currency-select-inner {
        border-top-right-radius: $input-border-radius;
        border-top-left-radius: $input-border-radius;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .fs-currency-select {
    .fs-currency-select-label {
      display: none;
    }
  }
}

@media screen and (max-width: 555px) {
  .ru {
    .fs-currency-select {
      .fs-currency-select-label {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $screen-lg) {
  .fs-currency-select {
    .fs-select-mask {
      select {
        padding-left: 10px;
      }
    }
  }
  .rtl{
    .fs-currency-select {
      .fs-select-mask {
        select {
          padding-right: 10px;
        }
      }
    }
  }
}
