.is-mobile-app {

  header {
    display: none;
    bottom: 0;
    top: auto;

    h1,
    .logo {
      display: none;
    }
  }

  footer {
    display: none;
  }

  main .main-inner {
    padding-bottom: $header-height-mobile;
  }

  // hero layout differs slightly
  &.fs-hero {
    header {
      display: block;
    }
  }
}

.ru {
  font-weight: 200 !important;
}

.ru h1,h2,h3,h4,h5,h6 {
  letter-spacing: 0;
}
