%icon,
.icon,
[class*="icon-"] {
  font-family: "fs-rbf-font";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right:before {
  content: "\3e";
}
.icon-arrow-left:before {
  content: "\3c";
}
%icon-arrow-up,
.icon-arrow-up:before {
  content: "\5e";
}
%icon-arrow-down,
.icon-arrow-down:before {
  content: "\56";
}
.icon-hamburger:before {
  content: "\68";
}
.icon-fs-icon:before {
  content: "\41";
}
.icon-plus:before {
  content: "\2b";
}
.icon-twitter:before {
  content: "\74";
}
.icon-facebook:before {
  content: "\66";
}
.icon-googleplus:before {
  content: "\67";
}
.icon-minus:before {
  content: "\2d";
}
.icon-adult:before {
  content: "\61";
}
.icon-phone:before {
  content: "\70";
}
.icon-child:before {
  content: "\63";
}
.icon-email-thin:before {
  content: "\65";
}
.icon-location-thin:before {
  content: "\6c";
}
.icon-tick-neg:before {
  content: "\31";
}
.icon-tick-with-box:before {
  content: "\32";
}
.icon-tick:before {
  content: "\33";
}
.icon-tick-box:before {
  content: "\34";
}
.icon-close:before {
  content: "\78";
}
.icon-error:before {
  content: "\58";
}
.icon-tile:before {
  content: "\7c";
}
.icon-list:before {
  content: "\3d";
}
.icon-location:before {
  content: "\3f";
}
.icon-fs-logo-neg:before {
  content: "\4f";
}
.icon-fs-logo:before {
  content: "\6f";
}
.icon-phone-thin:before {
  content: "\50";
}
.icon-print:before {
  content: "\48";
}
.icon-trash:before {
  content: "\55";
}
.icon-calender:before {
  content: "\4a";
}
.icon-cancel:before {
  content: "\6a";
}
.icon-search:before {
  content: "\73";
}
.icon-user:before {
    content: "\75";
}
.icon-visible-shown:before {
    content: "\69";
}
.icon-visible-hidden:before {
    content: "\49";
}
.icon-edit:before {
  content: "\45";
}
.icon-delete:before {
  content: "\44";
}
