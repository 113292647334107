.check-in-title {
  @extend %hero__title;
  max-width: 660px;
}

.check-in-subtitle {
  @extend %hero__title--base;

  max-width: 250px;
  padding-bottom: 6px;

  font-size: 12px;
  letter-spacing: .1em;

  color: $panel-color;
}

.check-in__title {
  margin-top: 0;
  margin-bottom: 0;

  font-weight: 300;
  line-height: 38px;
}

.check-in-summary {
  display: block;

  font-size: 1.1em;
}


.check-in-summary-line {
  margin: -($hero-body-padding);
  margin-top: $hero-body-padding;
  margin-bottom: $hero-body-padding;
  display: block;
  height: 1px;
  border: 0;
  border-top: $panel-border;
}

.check-in-registration-card {
  font-size: .9em;
}

.check-in-marketing-opt-out {
  margin-top: 2em;
}

.form-input--arrivalTime {
  overflow: hidden;

  .fs-select-mask {
    float: left;
    width: 33.3%;
    border-right-width: 0px;
  }

  .fs-select-mask:last-of-type {
    border-right-width: 1px;
  }
}

.check-in-complete-callout {
  padding-top: $hero-body-padding;
  text-align: center;

  ul {
    text-align: left;
  }
}

.check-in-complete-callout-title {
  padding-top: 2em;

  color: $new-darker-grey;
  letter-spacing: .05em;
}

.property-name {
  display: block;
  padding-bottom: 7px;
}

.check-in-btn {
  width: 90%;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: $hero-body-font-size;

  &.form-input--btn {
    display: block;
  }
}

.check-in-btn--profile {
  margin-top: $hero-body-padding;
}

.check-in-early-body {
  background: $panel-background;
  color: $panel-color;

  padding: $hero-body-padding;
  margin-right: auto;
  margin-left: auto;

  font-family: $primary-font;
  font-size: $hero-body-font-size;
}

.property-name {
  display: block;
}

// mobile only
@media screen and (max-width: $screen-sm) {
  .check-in-title {
    display: none;
  }
  .check-in-body {
    .form-input--btn {
      height: auto;
      min-height: $form-input-height;
      font-size: 0.75em;
      line-height: 1.5;
    }
  }
}


// desktop
@media screen and (min-width: $screen-sm) {
  .check-in-body {
    max-width: 450px;
  }

  .check-in-body--complete {
    max-width: 1150px;
  }

  .check-in-summary-line {
    margin: -($hero-body-padding--desk);
    margin-top: $hero-body-padding--desk;
    margin-bottom: $hero-body-padding;
  }

  .check-in-email-notification {
    padding-top: $hero-body-padding;
  }

  .check-in-complete-callout {
    max-width: 845px;
    margin: auto;

    text-align: left;
  }

  .check-in-complete-callout-title {
    margin-top: 0;
  }

  .check-in-subtitle {
    display: none;
  }

  .check-in-summary {
    font-size: 1em;
  }

  .check-in-registration-card {
    font-size: 1em;
  }
}

// China
.china-app-qr,
.china-app-qr + .hero-body__list {
  float: left;
  max-width: calc(50% - 20px);
}
.china-app-qr + .hero-body__list {
  margin-left: 20px;
}

// shameful temp styling
// this will be redundant when
// 'new' button styles are updated
.check-in-body {
  .btn {
    text-transform: uppercase;
  }
}

body[class~="view-check-in.contest-landing"], body.view-check-in-contest {
  position: relative;
  margin: 8px;
  height: auto;
  width: auto;

  .centred-logo {
    display: block;
    text-align: center;

    img {
      width: 76px;
      height: 43px;
      position: absolute;
      top: 0;
      display: inline-block;
      z-index: 1;
      transform: translateX(-50%);
    }
  }

  header {
    display: none;
  }

  main {
    .main-inner {
      padding: 0;

      .back-btn {
        display: none;
      }
    }
  }

  footer {
    display: none;
  }

  .esg_header_holder {
    position: relative;
    overflow: hidden;
  }

  .esg_header_image {
    width: 140%;
  }

  .esg_header_text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 50px 10%;
    background: linear-gradient(to bottom, rgba(255,255,255,0.5), transparent);
    color: #000;
  }

  .esg_enter_holder {
    margin: 10px auto 0;

    .esg_enter_form {
      width: 85%;
      padding: 25px;
      margin: auto;
      letter-spacing: .2px;

      .esg_link_color {
        color: #0000ff;
        text-decoration: none;
      }

      .esg_error_holder {
        margin: 35px auto 0;
        text-align: center;
        color: #e63a2e;
        font-weight: 700;
      }

      .esg_enter_button {
        width: 175px;
        height: auto;
        padding: 10px;
        font-size: 18px;
        background-color: #008494;
        color: #ffffff;
        text-align: center;
        margin: auto;
        margin-top: 30px;
        cursor: pointer;
        text-transform: uppercase;
        letter-spacing: 1.6px;
      }
    }
  }

  .esg_disclaimer_holder {
    font-size: 10px;
    padding: 25px;
    text-align: justify;
    background-color: #f1e6da;
    margin-top: 20px;
    letter-spacing: 0.2px;

    .esg_disclaimer_link_holder {
      width: 180px;
      margin: auto;
      margin-bottom: 25px;
      font-size: 10px;

      .esg_disclaimer_links {
        width: 180px;
        text-align: center;

        .esg_disclaimer_link {
          float: left;
          width: 90px;
          text-align: center;

          a {
            color: $primary-text-color;
          }
        }

        .esg_disclaimer_break {
          clear: both;
        }
      }
    }

    .esg_disclaimer_text {
      font-weight: bold;
    }
  }

  .esg_thankyou_holder {
     margin: 40px auto 46px;

    .esg_thankyou_form {
      width: 75%;
      padding: 0 25px 25px;
      margin: auto;
      text-align: center;
      letter-spacing: 0.7px;
    }

    .esg_home_button {
      width: 325px;
      margin: auto;
      font-size: 14px;
    }
  }
}

.hero-error--simple {
  &.early-checkin-error {
    background-color: #fff;
    color: #72716e;
  }
}

.add-to-calendar {
  padding-top: 15px;

  .add-to-calendar-btn {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    overflow: visible;
    margin: 0;
    padding: 0;
    width: 100%;

    .btn {
      padding-top: 10px;
    }
  }

  .select-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: -1px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    border: 1px solid #72716e;
    width: 100%;

    >li {
      display: list-item;
      text-align: -webkit-match-parent;

      >a {
        display: block;
        padding: 5px 20px;
        clear: both;
        line-height: 1.42857143;
        color: #5b5a56;
        white-space: nowrap;
        text-decoration: none;
      }
    }
  }

  .open {
    >.select-menu {
      display: block;
    }

    .btn--thin {
      border-color: #72716e;
      color: #5b5a56;
    }
  }
}

.contest-entry-modal-body {
  background: $panel-background;
  color: $panel-color;

  padding: $hero-body-padding;
  margin-right: auto;
  margin-left: auto;

  font-family: $primary-font;
  font-size: $hero-body-font-size;

  p {
    margin: 8px 0 35px;
  }

  .official-rules-link {
    margin-top: 20px;
    text-align: center;
  }
}

.check-in-facebook-messenger {
  padding: 10px;
  margin: 20px auto;
  width: 100%;

  .check-in-facebook-messenger-text {
    max-width: 80%;
    display: inline-block;
  }
  .fb-messengermessageus {
    float: none;
    margin-left: 10px;
    min-width: 10%;
    width: auto !important;
    vertical-align: bottom;

    .rtl & {
      float: none;
      margin-left: 0px;
      margin-right: 10px;
    }
  }
}

.check-in-facebook-messenger.bottom {
  .check-in-facebook-messenger-text {
    max-width: 100%;
    text-align: center;
    display: block;
  }
  .fb-messengermessageus {
      width: 100% !important;
      text-align: center;
      margin: 10px 0 0 0;

    .rtl & {
      float: left;
    }
  }
}

// mobile only styles
@media screen and (max-width: $screen-sm) {
  .check-in-facebook-messenger {
    .fb-messengermessageus {
      vertical-align: super;
    }

    .check-in-facebook-messenger-text {
      max-width: 50%;
      font-size: 0.875em;
    }
  }
}
@media only screen and (min-width: $screen-sm) {
  body[class~="view-check-in.contest-landing"], body.view-check-in-contest {
    .esg_enter_form {
      width: 75%;
      padding: 25px;
    }

    .esg_header_image {
      width: 100%;
    }

    .esg_header_text {
       padding: 100px 20%;
    }

    .centred-logo img {
      width: 152px;
      height: 87px;
    }
  }
}
