.web-checkin {
  main {
    scroll-behavior: smooth;
  }
  .banner {
    width: 100%;
    z-index: 55;
    padding: 24px 60px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-shrink: 0;

    p {
      margin: 0 0 30px 0;
    }

    @media screen and (min-width: $breakpoints-lg) {
      padding: 24px 160px;
      flex-direction: row;
      p {
        padding: 0;
        margin: 0 30px 0 0;

        @include rtl {
          margin: 0 0 0 30px;
        }
      }
    }

    @include rtl {
      left: unset;
      right: 0;
      text-align: right;
    }
  }

  .error-banner {
    color: $white;
    background-color: $error-red;

    button {
      background-color: $error-red;
      border-color: $white;

      &:hover {
        background-color: $white;
        border-color: $white;
      }
    }
  }

  .early-check-in-banner {
    background-color: $gray4;
    box-shadow: 0 4px 20px 3px rgba(0, 0, 0, 0.1);

    p {
      margin: 0 0 30px 0;
      flex: 1 1 0;
      @media screen and (min-width: $breakpoints-lg) {
        margin: 0 50px 0 0;

        @include rtl {
          margin: 0 0 0 50px;
        }
      }
    }

    button.add-to-calendar {
      margin: 0 0 30px 0;
      @media screen and (min-width: $breakpoints-lg) {
        margin: 0 20px 0 0;
        @include rtl {
          margin: 0 0 0 20px;
        }
      }
    }

    button {
      width: 100%;

      @media screen and (min-width: $breakpoints-lg) {
        width: auto;
      }
    }
  }
  .inputs {
    margin-bottom: 37px;
  }
  .view.step-1 {
    padding-top: 68px;
    padding-bottom: 80px;
    text-align: left;

    @include rtl {
      text-align: right;
    }

    h1,
    h2 {
      text-align: left;

      @include rtl {
        text-align: right;
      }
    }
    h1 {
      line-height: 48px;
      margin-bottom: 27px;
    }

    p {
      font-size: 20px;
      margin: 0 0 42px;
    }

    .provided-details {
      padding-bottom: 36px;
      margin-bottom: 35px;
      border-bottom: 1px solid $gray3;
    }

    h2 {
      color: $gray2;
      margin-bottom: 50px;
    }
  }

  .step-2 {
    position: relative;
    background-color: $white;
    margin: 0 auto;
    max-width: 740px;

    @media screen and (min-width: $breakpoints-md) {
      margin-top: 100px;
      margin-bottom: 100px;
    }

    &__inner {
      padding: 50px 20px 30px;

      @media screen and (min-width: $breakpoints-md) {
        padding: 50px 60px 30px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        padding: 50px 90px 30px;
      }
    }

    h1 {
      padding-bottom: 31px;
      margin-bottom: 30px;
      border-bottom: 1px solid $gray3;
    }

    &__content {
      border-bottom: 1px solid $gray3;
    }

    .property-disabled {
      h2,
      p {
        margin: 0 0 20px 0;
      }
    }

    .booking-info {
      p {
        margin: 0 0 20px 0;
      }
      margin-bottom: 50px;
    }

    .additional-details {
      margin-bottom: 10px;
    }

    .email-notification-label {
      margin-top: 3px;
      margin-bottom: 15px;
    }

    .reg-card-paragraphs {
      p {
        margin: 0 0 30px;
      }

      padding-bottom: 20px;
    }

    .reg-card-signature {
      border-top: 1px solid #d8d8d8;

      .prompt {
        margin: 0;
        padding: 20px 0 20px 0;
        @media screen and (min-width: $breakpoints-md) {
          padding-top: 30px;
        }
      }
    }

    .FormSignature {
      text-align: center;
      margin-bottom: 40px;

      .label-and-field {
        margin: 0 auto;
        text-align: left;

        @include rtl {
          text-align: right;
        }
      }

      &-typed {
        display: none;

        @media screen and (min-width: $breakpoints-md) {
          display: block;
        }

        float: right;

        @include rtl {
          float: left;
        }
      }
      &-canvas {
        border: 1px solid black;
      }
    }

    .controls {
      text-align: center;
      margin-bottom: 50px;
    }

    .webcheckin-terms-and-conditions {
      border-top: solid 1px #d8d8d8;
      padding: 30px 0 0;
      margin: 0 0 30px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        margin: 0;
      }
    }

    .subscription-disclaimer {
      margin-bottom: 50px;
      font-size: 15px;
      line-height: 25px;

      p {
        margin: 0 0 16px 0;
      }
    }

    &.confirmation {
      border: none;
      text-align: center;
      margin-bottom: 80px;

      p {
        margin: 0 0 30px 0;
      }
    }

    .persistent-email {
      .email-notification-label {
        margin-bottom: 10px;
      }
      .DisplayOnlyInput {
        &-text {
          margin-top: 0;
        }
        &-field {
          padding-top: 0;
        }
      }
    }
  }

  .form-gray .display-field label {
    margin-bottom: 5px;
  }


  .mobile-app-promotion {
    position: relative;
    color: $white;
    .desktop {
      display: flex;
      justify-content: center;
      margin: 0 120px 50px;

      .text {
        max-width: 539px;
        text-align: center;
        margin-right: 30px;
        margin-top: 120px;

        @include rtl {
          margin-right: 0;
          margin-left: 30px;
        }
        h1 {
          font-size: 51px;
          line-height: 64px;
          margin-bottom: 30px;
          letter-spacing: 1.85px;
          text-transform: none;
        }

        p {
          margin: 0 0 30px 0;
          padding: 0 50px;
        }
      }

      .image {
        img {
          max-width: 563px;
        }
      }
    }

    .mobile {
      max-width: 740px;
      margin: 0 auto 50px;

      h1 {
        text-transform: none;
        line-height: 39px;
        letter-spacing: 1.16px;
        font-size: 32px;
        margin-bottom: 50px;
        padding: 0 20px;
      }

      .image {
        width: 100%;
        margin-bottom: 50px;
      }

      p {
        margin: 0 0 30px 0;
        padding: 0 20px;
        text-align: center;
      }
    }

    .mobile-downloads {
      display: flex;
      justify-content: space-evenly;
      .app-button {
        max-height: 43px;

        @media screen and (min-width: $breakpoints-md) {
          max-height: 62px;
        }
      }
    }
    .welcome {
      margin-bottom: 200px;

      h2 {
        text-transform: none;
        letter-spacing: 1.02px;
        padding: 0 20px;
      }
    }
  }

  .full-screen {
    .background-image {
      background-size: cover;
      background-repeat: no-repeat;
      display: none;
      position: absolute;
      top: 50px;
      height: 715px;
      width: 100%;
      @media screen and (min-width: $breakpoints-md) {
        display: block;
      }
    }

    .background-image-gradient {
      position: absolute;
      display: none;
      top: 481px;
      height: 284px;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      @media screen and (min-width: $breakpoints-md) {
        display: block;
      }
    }
  }
}

.fs-modal-container.employ-portal-modal-container {
  .modal--friends-and-family.view--profile-folio.modal--add-to-calendar {
    max-width: 820px;
    @media screen and (min-width: $breakpoints-md) {
      padding: 50px 50px;
    }

    .links {
      a {
        font-size: 12px;
      }

      margin-bottom: 20px;
    }

    @media screen and (min-width: $breakpoints-md) {
      h3 {
        margin-bottom: 60px;
      }
    }
  }
}

.quick-links--web-check-in {
  padding: 0 20px 30px;

  @media screen and (min-width: $breakpoints-md) {
    padding: 0 30px 60px;
  }

  .quick-links__links {
    @media screen and (min-width: $breakpoints-md) {
      justify-content: center;
    }
  }

  .quick-link {
    @media screen and (min-width: $breakpoints-md) {
      width: 50%;
      max-width: none;
      flex-direction: row;
      padding: 20px 20px 20px 0;

      &__icon {
        width: 72px;
        flex-basis: 72px;
        align-items: center;
        justify-content: center;
        margin: 0;
      }
    }
  }
}
