// Responsive Utilities
//
// Defines responsive breakpoints to be used in all templates. These will only be available to use in your project if you have included the style kit SCSS files, if you're just using the compiled CSS, please use the existing grid system classes.
//
// | Targeted Device | Width    |
// |------------------|----------|
// | `$mobile`          | 480px  |
// | `$tablet`          | 768px  |
// | `$desktop`         | 992px  |
// | `$large-desktop`   | 1200px |
// | `$extra-large-desktop`   | 1920px |
//
// If you have included the Style Kit SCSS in your project, you can use the `media()` mixin in combination with the responsive variables to generate media queries. For example:
//
// `@include media($mobile) { ... }`
//
// will compile to:
//
// `@media only screen and (max-width: 480px) { ... }`
//
// Styleguide 1.2.0

$mobile: 480px;
$tablet: 768px;
$desktop: 992px;
$large-desktop: 1200px;
$extra-large-desktop: 1920px;

$responsive-property: min-width;

@mixin media ($device: $desktop) { //desktop for default
  @media only screen and ($responsive-property: $device) {
    @content;
  }
}
