//New style buttons, extended from styleguide
$btn-inline-spacing: 8px;
$btn-inline-padding: ($btn-inline-spacing - 1) $btn-inline-spacing
  $btn-inline-spacing;
$btn-thin-line-height: 22px;
$btn-icon-size: 22px;
$btn-icon-margin: ($btn-inline-spacing + 2);

%btn--default {
  border-color: $new-dark-grey;
  color: $accent-color;
  background: $white;

  padding-right: 0;
  padding-left: 0;

  letter-spacing: 0.1em;
  -webkit-appearance: none;

  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;

  &:hover,
  &:focus {
    border-color: $new-dark-grey;
    color: $new-darker-grey;

    &:before {
      border-color: $new-dark-grey;
    }
  }

  &:active {
    border-color: $accent-color;
    color: $accent-color;

    &:before {
      border-color: $accent-color;
    }
  }

  &.is-disabled,
  &[disabled] {
    border-color: $disabled-accent-color;
    color: $disabled-accent-color;
    background: $white;

    &:before {
      border-color: $disabled-accent-color;
    }
  }

  &:before {
    border-color: $accent-color;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }
}

.btn-primary,
.btn--primary {
  @extend .btn;
  @extend .btn, .btn-primary;
  width: 100%;

  &:hover,
  &:active {
    color: $white;
  }
}

.btn--secondary {
  @extend .btn;
  @extend .btn, .btn-secondary;
  width: 100%;
}

%btn--warning,
.btn--warning {
  @extend %btn--default;

  border-color: $new-error-color;
  color: $new-error-color;

  &:before {
    border-color: $new-error-color;
  }

  &:hover,
  &:active {
    border-color: $new-error-color;
    background-color: $new-error-color;
    color: $white;
    cursor: pointer;
  }
}

.btn--dark {
  @extend .btn;
  @extend .btn, .btn-overlay;
  width: 100%;
}

%btn--inline,
.btn--inline {
  @extend %btn--default;

  padding: $btn-inline-padding;
  display: inline-block;
  width: auto;
}

.btn--icon {
  @extend .btn;
  @extend .btn, .btn-secondary;

  line-height: $btn-thin-line-height;
  width: 100%;

  &.btn--primary {
    @extend .btn, .btn-primary;
  }
  &.btn--secondary {
    @extend .btn, .btn-secondary;
  }
}

.btn__icon {
  width: $btn-icon-size;
  height: $btn-icon-size;
  line-height: $btn-icon-size;
  margin-right: $btn-icon-margin;
  font-size: $btn-icon-size;
  float: left;

  .rtl & {
    margin-left: $btn-icon-margin;
    margin-left: auto;
    float: right;
  }
}

.btn__icon--inline {
  position: relative;

  width: $btn-icon-size;
  height: $btn-icon-size;
  line-height: $btn-icon-size;
  margin-right: ($btn-icon-margin * 0.5);

  font-size: $btn-icon-size;
  vertical-align: middle;
  top: -1px; // minor adjustment

  .rtl & {
    margin-left: ($btn-icon-margin * 0.5);
    margin-right: auto;
  }
}
