.layout--profile-auth {
  .view--register {
    h1 {
      margin-bottom: 30px;
    }

    .view__actions {
      margin-top: 40px;
    }

    &__actions {
      margin: 0 auto 30px;
      max-width: 300px;
      width: 100%;

      #social-login .bricks,
      #social-login .bricks ul {
        margin: 0;
        padding: 0;
      }

      .gplus-provider,
      .linkedin-provider {
        margin: 0 0 20px;
      }

      .divider {
        font-family: $garamond;
        font-style: italic;
        margin: 0 0 18px;
      }

      .CTA--secondary--light,
      .CTA--secondary--dark {
        width: 100%;
      }
    }
  }
}
