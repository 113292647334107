.fs-profile-payment-information {
  h3 {
    margin: 0 0 40px;
  }
}

.payment-method {
  max-width: 550px;
  padding: 0 0 0 60px;
  position: relative;

  @media screen and (min-width: $breakpoints-lg) {
    padding-left: 96px;
  }

  &__card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;

    @media screen and (min-width: $breakpoints-lg) {
      width: 48px;
    }
  }

  &__card-number {
    font-family: $hn;
    font-weight: 250;
    font-size: rem(22px, 16px);
    color: #7f7f7f;
    letter-spacing: 0.88px;
    line-height: 40px;
    margin-bottom: 24px;

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(32px, 16px);
    }
  }

  p {
    color: #808080;
    letter-spacing: 0;
    line-height: 1.75;
    margin: 0;

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(18px, 16px);
    }
  }
}
