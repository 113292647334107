.unauthenticated-upcoming-trip-sign-in {
  text-align: left;

  h1 {
    text-align: inherit;
    margin: 0 0 40px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 80px;
    }
  }

  h3,
  p {
    text-align: inherit;
    margin: 0 0 40px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 60px;
    }
  }

  .actions {
    margin-top: 40px;
    margin-bottom: 50px;
    text-align: center;

    @media screen and (min-width: $breakpoints-md) {
      margin-top: 80px;
      text-align: inherit;
    }

    button {
        width: 100%;
    }
  }

  .sign-in-container {
    border-top: 1px solid rgba(0, 0, 0, .21);
    margin-bottom: 100px;
    line-height: 45px;

    .or_text {
      padding: 0% 3%;
      margin: 0% 45%;
      top: -22px;
      position: relative;
      background-color: white;
    }

    .signin-button,
    .register-button {
      width: 49%;
      float: left;

      a {
        width: 100%;
      }
    }
    .register-button {
      float: right;
    }
   }
}

.rtl {
  .unauthenticated-upcoming-trip-sign-in {
    text-align: right;
  }
}
