.fs-otp-sign-in {
  height: 100%;
  text-align: center;
  color: #000;
  font-family: $garamond;
  font-size: 18px;
  line-height: 1.5;

  h1 {
    color: #000;
    font-family: $hn;
    font-weight: 250;
    font-size: 32px;
    line-height: 1.4;
    text-transform: uppercase;
    margin-top: 0;
    letter-spacing: 3.76px;

    margin-bottom: 30px;
  }

  .intro {
    margin: 0 0 52px;
    color: #000;
    font-family: $garamond;
    font-size: 18px;
    line-height: 1.5;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 80px;
    }
  }

  .view__actions {
    max-width: none;
    display: flex;
    flex-direction: row;
    justify-content: center;

    > fs-button {
      display: inline-block;
      margin: 0 10px;
      padding: 0;
      width: 200px;

      button {
        width: 100%;
      }
    }

  label.fsp-input__checkbox {
    font-family: $garamond;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.5px;
    text-align: center;
  }
  }

  .persistent-login {
    align-items: baseline;
    margin-bottom: 50px !important;

    &-details {
      &-button {
        position: relative;
        padding-right: 20px;

        @include rtl {
          padding-right: 0;
          padding-left: 20px;

          &::after {
            right: unset;
            left: 4px;
          }
        }

        button {
          font-family: $hn-neue;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 1px;
          text-align: center;
          color: #666666;
          padding-bottom: 0;
          border-color: #666666;
          margin-left: 7px;
          text-transform: none;

          @include rtl {
            margin-right: 7px;
            margin-left: 0;
          }
        }
        &::after {
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #666666;
          content: "";
          height: 0;
          position: absolute;
          right: 4px;
          top: 7px;
          width: 0;
        }
      }
  
      &-text {

        &-container {
          display: flex;
          justify-content: center;
          margin-bottom: 50px;
        }

        max-width: 435px;
        background-color: #f4f4f4;
        border: 1px solid #d8d8d8;
        font-family: $hn-neue;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 1px;
        text-align: left;

        @include rtl {
          text-align: right;
        }
        p {
          margin:0;
          padding: 10px 15px;
        }
      }
    }

    &-verification-code {
      margin-bottom: 30px !important;
    }

    &-next {
      margin-top: 30px !important;
      margin-bottom: 50px !important;
    }
    &-footer {
      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 10px !important;
        }
    }

    &.showDetails {
      margin-bottom: 10px !important;
      & .persistent-login-details-button::after {
        border-top: none;
        border-bottom: 5px solid #666666;
      }
    }
  }

  .CTA--secondary--dark {
    font-size: 10px;
  }

  .CTA--underlined--light {
    font-size: 10px;
  }

  &__request-otp,
  &__submit-otp,
  &__invalid-otp {
    background: #fff;
    padding: 80px 20px 20px;
    color: #000;
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    text-align: center;

    @media screen and (min-width: $breakpoints-md) {
      padding: 40px;
      overflow: visible;
    }
  }

  &__request-otp {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
    }

    .intro {
      margin-bottom: 40px;
    }

    .fs-radio-inputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      justify-content: center;

      @media screen and (min-width: $breakpoints-md) {
        flex-direction: row;
      }
    }

    .fs-radio {
      margin: 10px auto;
      width: 160px;
      text-align: left;

      @media screen and (min-width: $breakpoints-md) {
        width: auto;
        margin: 0 10px;
      }
    }

    .rates-may-apply {
      margin: 0 0 30px;
      text-align: left;
    }

    .dont-have-account {
      margin: 0;
      text-align: left;

      &.exclusives {
        margin-top: 30px;
      }
    }

    .problem-please-call {
      margin: 0 0 40px;
      text-align: left;

      p {
        font-family: "Monotype Garamond W05", Garamond, serif;
        line-height: 1.3em;
        margin: 0;

        .other-toll-free {
          margin-left: 20px;
          margin-right: 0;
        }
      }
    }
  }

  &__submit-otp {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__content {
      margin-bottom: 0;
    }

    .verification-code {
      margin: 0 -5px 60px;

      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .fsp-input--verification-code {
      background: #fff;
      margin: 0 5px;
      appearance: none;
    }

    .view__footer {
      display: block;
      height: auto;
      margin-top: auto;
    }
  }
}

.rtl {
  .fs-otp-sign-in {
    &__request-otp {
      .rates-may-apply {
        text-align: right;
      }
      .dont-have-account {
        text-align: right;
      }
      .problem-please-call {
        text-align: right;

        .other-toll-free {
          margin-left: 0;
          margin-right: 20px;
        }
      }
    }
  }
}
