.view--profile-folio {
  margin: 0 auto;
  max-width: 836px;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 80px 0;
  position: relative;
  min-height: 100%;
  text-align: center;

  @media screen and (min-width: $breakpoints-md) {
    height: auto;
    min-height: auto;
    padding: 110px 105px;
  }

  @media screen and (min-width: $breakpoints-lg) {
    padding: 100px 98px;
  }


  h1 {
    font-size: rem(34px);
    letter-spacing: 4px;
    line-height: 1.4;
    margin: 0 0 80px;
  }

  .TextInput {
    margin-bottom: 20px;
  }

  .intro {
    margin: 0 0 60px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 60px;

    .employee-activate {
      margin: 0 10px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      margin-top: 80px;
    }
  }


  &--success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    h1 {
      margin: 0 0 80px;

      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 30px;
      }
    }

    p {
      margin: 0 0 30px;
    }
  }
}
