$modal-background: $white;

$modal-title-icon-offset-v: 21px;
$modal-title-icon-offset-h: 20px;
$modal-content-padding: $form-padding;
$modal-content-background: $panel-background;
$modal-overlay-background: rgba(50, 50, 50, 0.66);

.modal {
  position: fixed;
  top: ($header-height-mobile + 1);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z_modalOverlay;
  transition: opacity 0.3s;

  /*desktop hide show animations */
  &.ng-hide {opacity: 0; left: 0;}       /* starting animations for enter */
  &.ng-hide.ng-hide-active {opacity: 1;} /* terminal animations for enter */


  /*MODAL CONTENT STYLES */
  .floorplan {
    text-align: center;
    margin: 36px auto;
    img {
      max-width: 100%;
    }
  }
}

.modal-icon {
  display: none;
  &,&:hover {
    background-color: transparent;
    top: $modal-title-icon-offset-v;
    right: $modal-title-icon-offset-h;

    &:before {
      color: $new-dark-grey;
      font-weight: 300;
    }
  }

  &.modal-dialog__close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: $modal-title-icon-offset-v;
    right: $modal-title-icon-offset-h;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    @include rtl {
      right: auto;
      left: $modal-title-icon-offset-h;
    }
    @media only screen and (max-width: 767px) {
        right: 15px;
        top: 12px;

        @include rtl {
          right: auto;
          left: 15px;
        }
    }
    svg {
      width: 21px;
      height: 21px;
    }
  }
}

.modal-dialog {
  background-color: $modal-background;
  margin: auto;
  z-index: $z_modalDialog;
  padding: 0;
}

.modal-dialog-content {
  background-color: $modal-content-background;
}

.modal-title {
  background-color: $white;
  h1 {
    display: none;
    padding: 0;
    text-align: left;
    font-family: $primary-font;
    font-weight: 300;
    color: $new-dark-grey;
    letter-spacing: 0.1em;
  }
}

.modal-title.modal-title--centered {
  h1 {
    text-align: center;
  }
}

.modal-content-body {
  background-color: $modal-content-background;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.modal-content-body-padding {
  background-color: $modal-content-background;
  padding: $modal-content-padding;

  .alert,
  .modal-message {
    margin-top: (-$modal-content-padding);
    margin-left: (-$modal-content-padding);
    margin-right: (-$modal-content-padding);
  }
  .alert {
    text-align: center;
  }
}

.modal-message--simple {
  @extend %panel-message;

  border-bottom: $panel-border;
}

.modal-error {
  @extend %hero-error--simple;
}

.modal-error.outage-error {
  background-color: transparent;
}

// Mobile App overrides
// Extra specificity on purpose
.is-mobile-app {
  .modal {
    top: 0;

    .modal-title {
      background: $header-bg-color;
      position: fixed;
      bottom: 0;
      top: auto;
      left: 0;
      right: 0;
      height: ($header-height-mobile + 1);
      z-index: $z_mobileHeader;
      text-transform: uppercase;

      h1 {
        display: none;
      }
    }
  }

  .modal-content-body-padding {
    padding-bottom: ($modal-content-padding + $header-height-mobile);

    padding-bottom: ($page-padding + $header-height-mobile);
  }
}

.offer-details {
  padding: 20px;

  a {
    display: inline-block;
    margin-top: 16px;
  }

  h2, h3,
  .inclusions dt {
    font-family: $primary-font;
    font-weight: $primary-font-weight;
    color: $dark-grey;
    font-size: 18px;
    text-align: left;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  .inclusions dt {
      margin: 0 0 6px;
  }

  h2 {
    font-size: 20px;
    margin: 8px 0 6px;
  }

  h3 {
    margin: 16px  0 10px 0;
    font-size: 14px;
  }

  p {
    font-size: 15px;
    margin: 0;
    color: $theme-black;
  }

  .price {
    font-weight: 700;

    span.night {
      display: inline-block;
      font-weight: $primary-font-weight;
    }
  }

  .book-now {
    margin-bottom: 12px;
    word-wrap: break-word;
  }

  .book-direct {
    word-wrap: break-word;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    border: solid 1px $medium-grey;
    padding: 10px;
    text-align: center;

        .phone a {
          margin-top: 0px;
        }
  }

  .inclusions {
    padding: 0;
    margin-top: 20px;
    margin-bottom: 0;
    color: $theme-black;

    dt {
      font-family: $primary-font;
      margin-bottom: 10px;

      &.inclusion-title {
        font-size: 16px;
        letter-spacing: 1px;
      }
    }

    dd {
      display: list-item;
      list-style-type: disc;
      list-style-position: outside;
      margin: 0 0 10px 16px;
    }
  }

  .long-description {
    margin-top: 16px;
  }

  .included {
    margin-top: 16px;
  }

  .terms {
    margin: 16px 0;
  }

  .terms-button{
    border: none;
    background: none;
    text-decoration: underline;
    text-align: left;
    padding-left: 0px;
    padding-bottom: 0;
    padding-top: 15px;
    letter-spacing: 0.432px;
  }
  .pp-amenities {
    .included {
      .inclusions {
        border: 1px solid $new-light-grey;
        padding: 0 20px;
        background: $white;
        margin-top: 0;

        dt {
          padding: 0;
          margin: 16px 0 10px;
        }
      }
    }
  }

  .resort-fee {
    margin-top: 6px;
    line-height: 1.2;
  }
}

.rtl {
  .modal-title {
    h1 {
      text-align: right;
    }
  }
}

@media only screen and (max-width: $screen-sm-max) {
  // Do not allow custom width/height for mobile
  .modal .modal-dialog {
    width: auto !important;
    height: auto !important;
  }

  // Mobile App overrides
  .is-mobile-app {
    .app-hide-title {
      .modal-title {
        display: none;
      }

      .modal-content-body {
        padding-top: 0;
      }
    }
  }

  .offer-details .pp-amenities {
    padding: 0;
    .included {
      .inclusions {
        border-right: none;
        border-left: none;
        padding: 15px 30px;

        dt {
          margin-left: 0;
        }
      }
    }
  }

  .offer-details .btn--book-now {
    margin-top: 16px;
    width: auto;
    padding: 15px 30px;
  }
}


/* Desktops and laptops ----------- */
@media only screen  and (min-width : $screen-sm) {
  .modal {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z_modalOverlayDesktop;
    overflow: visible;
    overflow-y: auto;
    background-color: $modal-overlay-background;
  }

  .modal-icon {
    position: absolute;
    display: block;
    top: $modal-title-icon-offset-v;
    right: $modal-title-icon-offset-h;
    width: 24px;
    height: 24px;
    background-color: transparent;
    color: $white;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        width: 21px;
        height: 21px;
    }

  }

  .modal-dialog {
    background-color: #323232;
    margin: 10% auto;
    padding: 0;
    -webkit-overflow-scrolling: touch;
    z-index: 10;
  }

  .modal-dialog-content {
    position: relative;
    height: 100%;
  }

  .modal-content-body {
    position: static;
    overflow: visible;

    .signin-form .btn {
      margin: 0 5px;
      width: auto;
    }
  }

  .modal-content-body-padding {
    .alert {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }

  .modal-title {
    padding: 20px 45px 20px 20px;
    overflow: hidden;
    position: relative;

    h1 {
      display: block;
      margin: 0;
      font-size: 20px;
      text-transform: uppercase;
      min-height: 28px;
    }
  }

  .rtl {
    .modal-icon {
      right: auto;
      left: $modal-title-icon-offset-h;
    }

    .modal-title {
      padding: 20px 20px 20px 45px;
    }
  }

  .modal--inline {
    .modal-dialog {
      width: auto;
      max-width: none;
      margin: 0;
    }

    .modal-title {
      display: none;
    }

    .modal-icon {
      &,&:hover {
        background-color: #dcdcdc;
        top: 0;
        right: 0;

        &:before {
          color: #fff;
          font-weight: 300;
        }
      }
    }
  }

  .modal--vertical-center {
    height: 100%;
    width: 100%;
    text-align: center;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
    }

    .modal-dialog {
      margin: auto;
      display: inline-block;
      vertical-align: middle;
      margin: auto;
      padding: 5% 0;
      background: none;
    }
  }
}
