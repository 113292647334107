.signin-form {
  text-align: center;

  fieldset {
    margin: $page-padding;
    text-align: left;

    .form-row:first-child {
      border-radius: $input-border-radius $input-border-radius 0 0;
      label {
        border-radius: $input-border-radius 0 0 0;
      }
    }

    .form-row:last-child {
      border-radius: 0 0 $input-border-radius $input-border-radius;
      label {
        border-radius: 0 0 0 $input-border-radius;
      }
    }
  }

  .form-actions {
    margin-top: $page-padding;
    padding: $page-padding;
  }

  .btn {
    margin-bottom: 10px;
  }
}

.rtl{
  .signin-form{
    fieldset{
      text-align: right;

      .form-row:first-child {
        border-radius: $input-border-radius $input-border-radius 0 0;
        label {
          border-radius: 0 $input-border-radius 0 0;
        }
      }

      .form-row:last-child {
        border-radius: 0 0 $input-border-radius $input-border-radius;
        label {
          border-radius: 0 0 $input-border-radius 0;
        }
      }
    }
  }
}



@media only screen and (min-width: $screen-sm) {
  .es .modal-wrap .signin-form {
    $label-width: 250px;

    .form-row {
      padding-left: $label-width;
      label {
        width: $label-width;
      }
    }
  }
  .br .modal-overlay .signin-form {
    $label-width: 160px;

    .form-row {
      padding-left: $label-width;
      label {
        width: $label-width;
      }
    }
  }
  .signin-form {
    $label-width: 128px;

    .form-row {
      padding-left: $label-width;
      label {
        width: $label-width;
      }
    }

    .btn {
      margin: 0 5px;
      width: auto;
    }
  }

  .signin-form {
    input[type="text"].empty,
    input[type="email"].empty,
    input[type="password"].empty,
    input[type="tel"].empty,
    input[type="number"].empty,
    input[type="search"].empty,
    input[type="range"].empty,
    textarea.empty              { color: transparent; }
    ::-webkit-input-placeholder { color: transparent; }
    :-moz-placeholder           { color: transparent; }
    ::-moz-placeholder          { color: transparent; }
    :-ms-input-placeholder      { color: transparent; }

    input[disabled],
    select[disabled],
    textarea[disabled] {
      &.empty                      { color: transparent; }
      &::-webkit-input-placeholder { color: transparent; }
      &:-moz-placeholder           { color: transparent; }
      &::-moz-placeholder          { color: transparent; }
      &:-ms-input-placeholder      { color: transparent; }
    }
  }
  .rtl{
    .signin-form {
      $label-width: 140px;

      .form-row {
        padding-right: $label-width;
        padding-left: 0px;
        label {
          width: $label-width;
        }
      }
    }
  }
}



.signin-form-inline {
  margin: 20px auto;
  text-align: left;

  p {
    margin-top: 0;
    @extend h5;
  }

  fieldset {
    margin: 0;
  }

  .form-row {
    border-radius: 0 !important;
  }

  .form-actions {
    padding: 0;
  }
}


@media only screen and (min-width: $screen-sm) {
  .signin-form-inline {
    margin-top: 0;

    form {
      position: relative;
      padding: $page-padding;
      background: $theme-gray4;
    }

    label {
        position: inherit;
        display: inline-block;
        width:  100% !important;
    }

    fieldset {
      margin-right: 120px;
    }

    .form-actions {
      margin: 0;
      position: absolute;
      top: 14px;
      right: $page-padding;
      width: 120px;

      .btn-primary {
        height: 80px;
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }

    .form-row {
      border: none;
      clear: none;
      float: left;
      padding-left: 0;
      padding-right: 15px;
      width: 50%;

      input {
        border: solid 1px $input-border-color;
        width: 100%;
      }
    }

    input[type="text"].empty,
    input[type="email"].empty,
    input[type="password"].empty,
    ::-webkit-input-placeholder { color: $primary-text-color !important; }
    :-moz-placeholder           { color: $primary-text-color !important; }
    ::-moz-placeholder          { color: $primary-text-color !important; }
    :-ms-input-placeholder      { color: $primary-text-color !important; }

    input[disabled] {
      &.empty                      { color: $primary-text-color !important; }
      &::-webkit-input-placeholder { color: $primary-text-color !important; }
      &:-moz-placeholder           { color: $primary-text-color !important; }
      &::-moz-placeholder          { color: $primary-text-color !important; }
      &:-ms-input-placeholder      { color: $primary-text-color !important; }
    }
  }
  .rtl{
    .signin-form-inline {
      fieldset {
        margin-right: 0px;
        margin-left: 120px;
      }
      .form-actions {
        right:auto;
        top: 0;
        left: 0;
      }
      .form-row {
        float: right;
        padding-right: 0;
        padding-left: 15px;
        width: 50%;
      }
    }
  }
}
