// TextInput
.TextInput {
  position: relative;
  margin: 15px 0 10px;
  padding-bottom: 30px;

  .icon-close {
    fill: $black;
  }

  &--dark {
    color: $white;

    .icon-close {
      fill: $white;
    }
  }

  .formElement-field {
    background: transparent;
    color: inherit;

  &.DisplayOnlyInput-field {
    background: $gray4;
    padding-left: 10px;
    padding-right: 10px;
    color: $black;
  }
  }

  &__show-password,
  &__hide-password {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 30px 20px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjQ3IiB2aWV3Qm94PSIwIDAgNzEgNDciIHdpZHRoPSI3MSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjN2Q3ZDdkIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0zNS40MTguNTgyYy0yMC44MzIgMC0zNS40MTggMjIuOTE4LTM1LjQxOCAyMi45MThzMTAuNjI1IDIyLjkxOCAzNS40MTggMjIuOTE4YzI1IDAgMzUuNDE4LTIyLjkxOCAzNS40MTgtMjIuOTE4cy0xNC4zNzUtMjIuOTE4LTM1LjQxOC0yMi45MThtMCAzNy41Yy04LjEyNSAwLTE0LjU4Mi02LjQ1Ny0xNC41ODItMTQuNTgyczYuNDU3LTE0LjU4MiAxNC41ODItMTQuNTgyIDE0LjU4MiA2LjQ1NyAxNC41ODIgMTQuNTgyLTYuNDU3IDE0LjU4Mi0xNC41ODIgMTQuNTgyIi8+PHBhdGggZD0ibTQxLjY2OCAyMy41YzAgMy40NTMtMi43OTcgNi4yNS02LjI1IDYuMjVzLTYuMjUtMi43OTctNi4yNS02LjI1IDIuNzk3LTYuMjUgNi4yNS02LjI1IDYuMjUgMi43OTcgNi4yNSA2LjI1Ii8+PC9nPjwvc3ZnPg==);
    cursor: pointer;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 0;
    right: 0;
  }

  &__hide-password {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjU1IiB2aWV3Qm94PSIwIDAgNzIgNTUiIHdpZHRoPSI3MiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjN2Q3ZDdkIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Im0yMS4zMzYgMjcuMjVjMC04LjEyNSA2LjQ1Ny0xNC41ODIgMTQuNTgyLTE0LjU4MiAxLjgyNCAwIDMuNTU3LjM0MSA1LjE1OS45MzdsNi43OTktNi43OThjLTMuNjY4LTEuNTE0LTcuNjcyLTIuNDc1LTExLjk1OC0yLjQ3NS0yMC44MzIgMC0zNS40MTggMjIuOTE4LTM1LjQxOCAyMi45MThzMy44MDIgOC4xOSAxMi4xNTUgMTQuNzc4bDkuNjE5LTkuNjE5Yy0uNTk3LTEuNjAyLS45MzgtMy4zMzUtLjkzOC01LjE1OSIvPjxwYXRoIGQ9Im01OC40NzA3IDEzLjE4MjYtOC45MDkgOC45MDhjLjU5NyAxLjYwMi45MzggMy4zMzUuOTM4IDUuMTU5IDAgOC4xMjUtNi40NTcgMTQuNTgyLTE0LjU4MiAxNC41ODItMS44MjQgMC0zLjU1Ny0uMzQtNS4xNTktLjkzOGwtNy4xOTcgNy4xOThjMy42NDcgMS4yODUgNy43NTcgMi4wNzYgMTIuMzU2IDIuMDc2IDI1IDAgMzUuNDE4LTIyLjkxOCAzNS40MTgtMjIuOTE4cy00Ljc4NS03LjYxNS0xMi44NjUtMTQuMDY3Ii8+PHBhdGggZD0ibTEwLjY2OCA1NC41Yy0uNTEyIDAtMS4wMjQtLjE5NS0xLjQxNC0uNTg2LS43ODEtLjc4MS0uNzgxLTIuMDQ3IDAtMi44MjhsNTAuNS01MC41Yy43OC0uNzgxIDIuMDQ4LS43ODEgMi44MjggMCAuNzgxLjc4MS43ODEgMi4wNDcgMCAyLjgyOGwtNTAuNSA1MC41Yy0uMzkuMzkxLS45MDIuNTg2LTEuNDE0LjU4NiIvPjwvZz48L3N2Zz4=);
  }
}

.formElement-message {
  position: absolute;
  bottom: 0;
  left: 0;
  font-family: $hn;
  font-size: rem(12, 16);
  font-weight: 250;
  letter-spacing: em(1.2, 12);
  line-height: em(24, 12);

  @include rtl {
    left: auto;
    right: 0;
  }
}

// field
.formElement-field {
  width: 100%;
  height: 46px;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid currentColor;
  padding: 0.5em 0;
  color: $black;
  background: $white;
  cursor: pointer;
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  // field when in focus
  &:focus {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    //   outline: none;
  }

  // field disabled
  &--disabled,
  &[disabled],
  &[readonly] {
    cursor: not-allowed;
  }
}

.TextInput-field {
  &--readOnly {
    border-bottom: 0;
    padding-bottom: 0;
  }

  // field when in focus
  &:focus {
    outline: none;
  }

  &:invalid {
    box-shadow: none;
  }

  // field when in focus and valid - filled in
  &.formElement-field--valid {
    padding-left: 0;
    padding-right: 25px;

    @include rtl {
      padding-left: 25px;
      padding-right: 0;
    }
  }
}

// label
.formElement-label {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.5s ease;
  white-space: nowrap;
  z-index: 50;

  @include ie {
    transition: none;
  }

  @include rtl {
    left: auto;
    right: 0;
  }
}

// label when in focus
.TextInput-field:focus + .formElement-label,
.TextInput-field.formElement-field--filled + .formElement-label,
.TextInput-field--readOnly + .formElement-label:not(.formElement-label--hidden),
.formElement--focusAlways {
  opacity: 1;
  transform: translateY(-15px);
}

.TextInput-field {
  // placeholder
  &::placeholder {
    opacity: 1;
    color: $black;
    transition: 1s ease;
  }

  // placeholder - become hidden when in focus
  &:focus::placeholder {
    opacity: 0;
    transition: 300ms ease;
  }

  // placeholder disabled
  &--disabled::placeholder,
  &--disabled:hover::placeholder {
    color: $gray2;
  }
}

.TextInput--dark {
  .TextInput-field {
    &::placeholder {
      color: $white;
    }
  }
}

.Dropdown {
  position: relative;
  margin: 15px 0 10px;
  padding-bottom: 30px;
  color: inherit;

  // arrow
  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    position: absolute;
    top: 20px;
    right: 4px;
    z-index: 20;
    color: inherit;
  }

  &--dark {
    color: $white;
  }

  option {
    color: $black;
  }

  @include rtl {
    &::after {
      right: auto;
      left: 0;
    }
  }

  .formElement-field {
    background: transparent;
    color: inherit;
    display: block;
    padding-left: 0;
    padding-right: 0;
  }

  .mask {
    background: $white;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 45px;
    width: 100%;
    z-index: 10;
    pointer-events: none;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  &--dark .mask {
    background-color: $black;
  }
}

.rtl .Dropdown {
  .mask {
    left: auto;
    right: 0;
    text-align: right;
    padding-right: 0;
    padding-left: 20px;
  }
}

.Dropdown--error::after {
  color: $error-red;
}
.TextInput--error .TextInput-field,
.Dropdown--error .formElement-field {
  color: $error-red;
  border-color: $error-red;
}

.TextareaField {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
  padding-top: 15px;

  .formElement-label {
    position: relative;
    opacity: 1;
    top: -15px;
  }

  &-field {
    border-radius: 0;
    border: 1px solid $black;
    display: block;
    min-height: 420px;
    width: 100%;
    padding: 25px 20px;

    @include viewport(medium) {
      min-height: 200px;
    }
  }
}

.fsp-input--is-invalid .formElement-field,
.TextInput--error .formElement-field,
.formElement-field--invalid {
  &,
  &::placeholder,
  & ~ .formElement-message,
  & ~ .formElement-label {
    color: $error-red;
  }
}

.formElement-submit {
  font-size: rem(10, 16);
  letter-spacing: 0.3em;
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  min-width: 130px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $hn;
  font-weight: 250;
  margin: rem(30, 16) 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form-error {
  background-image: url("/images/profiles_2018/error-icon.svg");
  background-position: 0 0;
  background-size: 20px 20px;
  background-repeat: no-repeat;
  padding-left: 32px;
  padding-right: 0;
  margin: 0 0 40px;
  text-align: left;

  &.not-found {
    font-family: "Neue Helvetica W04", Neue Helvetica, Helvetica, sans-serif;
    color: $error-red;
    margin-bottom: 0;
    font-size: 14px;
    letter-spacing: 0.2em;
    padding-bottom: 30px;
  }

  &.try-another {
    background-image: url("/images/profiles_2018/back-arrow--black.svg");
    font-family: "Neue Helvetica W04", Neue Helvetica, Helvetica, sans-serif;
    margin-bottom: 40px;
    margin-top: 10px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.2em;
  }

  @media screen and (min-width: $breakpoints-md) {
    margin-bottom: 60px;
  }

  @media screen and (min-width: $breakpoints-lg) {
    margin-bottom: 70px;
  }
}

.form-error-new {
  text-align: center;
  color: #EA0000;
  display: flex;
  justify-content: center;
  padding: 0 10px;

  @media screen and (min-width: $breakpoints-md) {
    padding: 0;
  }

  &__alert {
    svg {
      width: 18px;
      height: 18px;
    }
    vertical-align: text-top;
    margin-right: 10px;
  }
}

.rtl {
  .form-error {
    background-position: right 0;
    padding-left: 0;
    padding-right: 32px;
    text-align: right;

    &.try-another {
      background-image: url("/images/profiles_2018/rtl-back-arrow--black.svg");
    }
  }
}

.CounterInput {
  position: relative;
  margin-top: 15px;
  display: inline-block;

  label {
    font-weight: bold;
  }

  .controls {
    padding-top: 10px;

    input {
      width: 45px;
      text-align: center;
      border: none;
      height: 40px;
      padding: 0;
      margin: 0;
      font-size: 14px;
    }
    button {
      top: -5px;
      height: 40px;
      background-color: #fff;
      width: 40px;
      border-radius: 40px;
      border: solid 1px #000;
      position: relative;

      &.increment {
        &:after,
        &:before {
          content: "";
          display: block;
          background-color: #000;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:before {
          height: 10px;
          width: 1px;
        }

        &:after {
          height: 1px;
          width: 10px;
        }

        &:disabled {
          &:after,
          &:before {
            background-color: $gray3;
          }
        }
      }

      &.decrement {
        &:after {
          content: "";
          display: block;
          background-color: #000;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &:after {
          height: 1px;
          width: 15px;
        }

        &:disabled {
          &:after {
            background-color: $gray3;
          }
        }
      }

      &:disabled {
        color: $gray3;
        border-color: $gray3;
      }
    }
  }
}

.btn-close {
  background-image: url("/images/profiles_2018/black-cross.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: 14px;
  width: 14px;
  position: absolute;
  top: 40px;
  right: 40px;
  border: none;
  background-color: transparent;

  span {
    display: block;
    font-size: 0;
    height: 1px;
    overflow: hidden;
  }
}
