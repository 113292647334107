/* Base module */
@mixin content-block {
/* mixin instead of @extend because of nested selectors */

  > .row {
    padding: 0 15px;
  }

  h2, h3 {
    margin: 0 -15px;
    padding: 5px;
    background-color: #999;
    color: #fff;
    text-align: center;
    font-family: $primary-font;
    font-weight: $secondary-font-weight;
		box-sizing: initial;
		font-size: .875em;
  }

  .costs {
    margin: 0;
    padding: 0;

    li,
    dd,
    button {
      overflow: hidden;
      list-style-type: none;
      margin: 0;

      .label {
        float: left;
      }

      .amount {
        float: right;
      }
    }
  }

  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : $screen-sm) {

    h3 {
      display: none;
    }
  }
}

/* Your booking - mobile only*/
.your-booking {
  @include content-block;

  .row {
    border-bottom: 1px solid #cacaca;
  }

  dt {
    font-size: $primary-font-size;
    font-family: $primary-font;
  }

  dd {
    margin: 0;
    text-indent: 5px;
    font-size: $secondary-font-size;
  }

  dd:before {
    content: '-';
    position: relative;
    left: -5px;
  }

  button {
    background-color: transparent;
    border: none;
    text-align: left;
    position: relative;
    margin-top: 10px;
    padding: 5px;

    span {
      font-size: $primary-font-size;

      &.icon {
        font-size: 25px;
        position: absolute;
        top: 10px;
        right: -5px;
      }
    }
  }
}


.your-trip {
  @include content-block;

  clear: both;

  .package {
    background-color: $theme-gray4;
    padding: 10px 10px 0;
    margin: 10px 0;
    border: 1px solid $theme-gray3;

    .upgraded {
      color: $theme-black;
      display: block;
    }
  }

  table {
    width: 100%;
  }

  .perk {
    background-color: $lighter-grey;

    p {
      margin: 0;
      padding: 10px;
      display: block;
      font-size: 14px;
    }

    img {
      width: 100%;
    }

    #exclusive-offer-icon{
      width:20px;
    }
  }

  .costs {
    margin: 10px 0 0;

    dt {
      font-family: $primary-font;
      background-color: $white;
      margin: 0 -10px;
      text-transform: capitalize;
      cursor: pointer;

      .icon {
        text-decoration: none;
        position: absolute;
        bottom: calc(50% - 7px);
        right: 10px;
      }

      button {
        position: relative;
        background-color: transparent;
        border: none;
        border-top: 1px solid $theme-gray3;
        padding: 5px 10px;
        text-align: left;
        letter-spacing: 0.027em;

        span {
          @include theme-link;
          display: block;
        }
      }
    }

    dd {
      padding: 5px 11px;
      margin: 0 -11px;
      max-height: 100px;
      transition: max-height 0.3s;
      border-top: 1px solid $theme-gray3;

      &.daily-fee {
        border: none;
        padding-top: 0;
        font-size: 1em;
      }
    }

    dd.ng-hide,
    dd.ng-hide.ng-hide-active {
      max-height: 0;
    }

    .feesDisclaimer {
      margin-top: 5px;
      line-height: 1;
      font-size: 14px;
      font-weight: bold !important;
      text-align: right;
    }
  }
}
.rtl{
  .your-trip{
    .costs{
      dd,
      li{
        .label{
          float: right;
        }

        .amount{
          float: left;
        }
      }

      dt{
        .icon{
          float: left;
        }

        button{
          text-align: right;
        }
      }

      .feesDisclaimer {
        text-align: left;
      }
    }
  }
}

.reservation-total {
  @include content-block;

  .costs {
    margin: 0 -15px;

    li {
      border-bottom: 1px solid $theme-gray3;
      padding: 10px 15px;
    }

    .amount{
      padding-left: 5px;
    }
  }

  button {
    margin-bottom: 10px;
  }
}


/* Best Rate Guaranteed styles */
.reservation-total-brg {
  .best_rate_guaranteed_wrapper {
    background-image: url(/images/best-rate-checkmark-white.png);
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: 26px 22px;
    background-position: 3px 3px;
    position: relative;
    color: black;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 1px 1px 1px 1px;
    width: 198px;
    height: 28px;
  }

  .best_rate_guaranteed_title {
    font-family: $primary-font;
    font-size: 9pt;
    background-color: #FFFFFF;
    float: right;
    padding: 5px 7px 2px 7px;
    height: 26px;
    margin: 0px;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: $screen-md - 1) {
  .reservation-total-brg {
    .best_rate_guaranteed_wrapper {
      display: none;
    }
  }
}


.rtl{
  .reservation-total{
    .costs{
      li{
        .label{
          float: right;
        }

        .amount{
          float: left;
          padding-left: 0px;
          padding-right: 5px;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .your-booking {
    display: none;
  }

  .summary {
    border-bottom: 2px solid $grey;
    margin-bottom: 20px;
  }

  .estimated-total,
  .reservation-total {

    > .row {
      border-bottom: none;
    }

    .costs {
      border-bottom: 2px solid #cacaca;
      margin: 0;

      li {
        border-bottom: none;
        padding-left: 0;
        padding-right: 0;
      }
    }

    button {
      display: none;
    }

    .currency-select {

      strong {
        border-left: 1px solid $theme-gray3;
        border-radius: 3px 0 0 3px;
        text-align: left;
      }
    }
  }
  .rtl{
    .your-trip{
        .costs{
          dt{
            button{
              span{
                display: block;
              }
            }
          }
        }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .rtl{
    .your-trip{
        .costs{
          dt{
            button{
              span{
                display: inline-block;
              }
            }
          }
        }
    }
  }
}

.disclaimer p:before {
  content: '* ';
}
