.fs-your-preferences {
  &__preference {
    padding: 0 0 40px;

    &--reason {
      padding: 40px 0 40px;
    }

    &--special-requests {
      padding: 40px 0 40px;
      border-top: 1px solid rgba(0, 0, 0, 0.21);
    }

    .fs-toggle-block {
      margin-bottom: 0;

      &__row .fs-toggle-button {
        margin-top: 0;
      }

      &__container__inner {
        margin-top: 0;
        margin-bottom: 30px;
        padding-top: 75px;

        @media screen and (min-width: $breakpoints-md) {
          padding-top: 70px;
          padding-left: 90px;
        }

        @media screen and (min-width: $breakpoints-lg) {
          padding-top: 60px;
        }
      }
    }

    &__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 40px;
      position: relative;
      padding: 0;

      h3 {
        text-transform: uppercase;
        color: inherit;
        font-family: $hn;
        font-weight: 700;
        font-size: 1rem;
      }

      .icon {
        background: none;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        height: 40px;
        width: 40px;
        margin-right: 26px;

        @media screen and (min-width: $breakpoints-lg) {
          margin-right: (74px - 40px);
        }
      }
    }

    &__details {
      font-size: rem(15px);
      line-height: 1.66;
      margin: 0 0 38px;

      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 58px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        margin-bottom: 0;
      }
    }

    &__title,
    &__detail {
      @media screen and (min-width: $breakpoints-md) {
        max-width: 450px;
      }
    }

    &--reason {
      .icon {
        background-image: url("/images/profiles_2018/reason.svg");
      }
      .icon--dark {
        background-image: url("/images/profiles_2018/reason--black.svg");
      }
    }

    &--sleep {
      .icon {
        background-image: url("/images/profiles_2018/sleep.svg");
      }
      .icon--dark {
        background-image: url("/images/profiles_2018/sleep--black.svg");
      }
    }

    &--room {
      .icon {
        background-image: url("/images/profiles_2018/room.svg");
      }
      .icon--dark {
        background-image: url("/images/profiles_2018/room--black.svg");
      }
    }

    &--special-requests {
      .icon {
        background-image: url("/images/profiles_2018/room.svg");
      }
      .icon--dark {
        background-image: url("/images/profiles_2018/room--black.svg");
      }
    }

    &--most-important {
      .icon {
        background-image: url("/images/profiles_2018/room.svg");
      }
      .icon--dark {
        background-image: url("/images/profiles_2018/room--black.svg");
      }
    }

    &__value {
      font-size: rem(18px);
    }

    &__hint {
      border-top: 1px solid white;
      margin-top: 10px;
      padding-top: 30px;
      color: #b4b4b4;
      font-size: rem(15px);
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 35px;
    }

    &__selected-preferences {
      margin: 0;
      padding: 0;
      list-style: none;

      @media screen and (min-width: $breakpoints-md) {
        padding-left: 66px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        padding-left: 74px;
      }

      li {
        font-size: rem(18px);
        line-height: 1.5;
        margin: 0 0 10px;

        &:first-child {
          margin: 10px 0 10px;
        }

        &:last-child {
          margin-bottom: 0px;
        }

        &:before {
          content: "-";
          margin-right: 4px;
        }
      }
    }
  }
}

.rtl {
  .fs-your-preferences__preference__title {
    .icon {
      margin-right: 0;
      margin-left: 26px;
    }
  }
}
