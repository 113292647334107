.find-reservations {
  h1,
  h2,
  h3,
  h4,
  p,
  .actions,
  .fsp-input--checkbox {
    text-align: initial;
  }

  h1.ty-h1 {
    margin-bottom: 30px;
  }

  p.ty-b2 {
    margin-bottom: 30px;
  }

  &__get-in-touch {
    margin: 80px 0 40px;

    &__title {
      margin: 0 0 20px !important;
    }

    &__copy {
      margin: 0 0 20px !important;
      padding: 0;
      white-space: nowrap;
    }

    &__border {
      border-top: solid 1px #d8d8d8;
    }

    &__row {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px 0;
      margin: 0 -10px;

      @media screen and (min-width: $breakpoints-md) {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    &__column {
      padding: 0 10px;
    }

    &__actions {
      padding: 0 10px;
      margin: 0 -10px;

      a {
        margin: 0 10px;
        text-align: center;
        white-space: nowrap;
      }
    }
  }
}

.rtl .find-reservations {
  .fsp-input__checkbox {
    padding-right: 30px;
    padding-left: 0;

    input.checkbox {
      left: unset;
      right: 0;
    }
  }
}
