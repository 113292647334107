// Geometric Variables
//
// If you have included the Style Kit SCSS in your project, you can use measurement variables to keep components consistent and in line with other Four Seasons digital content.
//
// #### Mobile measurements
// | SCSS variable | Size |
// |----------------------------|------|
// | `$measure-mobile-xxsmall`  | 6px  |
// | `$measure-mobile-xsmall`   | 9px  |
// | `$measure-mobile-small`    | 12px |
// | `$measure-mobile-normal`   | 15px |
// | `$measure-mobile-large`    | 18px |
// | `$measure-mobile-xlarge`   | 20px |
// | `$measure-mobile-xxlarge`  | 36px |
//
// #### Desktop measurements
// These should also be used for the screen widths of tablet devices and greater.
//
// | SCSS variable | Size |
// |-----------------------------|------|
// | `$measure-desktop-xxsmall`  | 6px  |
// | `$measure-desktop-xsmall`   | 9px  |
// | `$measure-desktop-small`    | 12px |
// | `$measure-desktop-normal`   | 16px |
// | `$measure-desktop-large`    | 18px |
// | `$measure-desktop-xlarge`   | 24px |
// | `$measure-desktop-xxlarge`  | 36px |
//
// ### Uses
// Measurements are used as a base to create consistent padding and margin spacing for views and components. Example:
//
//`$geom-mobile-margin: $measure-mobile-normal;`
//
// markup:
// <div class="styleguide-note">
//   <h5>Styleguide note</h5>
//   <p>Colored squares show actual measurement dimensions, for reference only.</p>
// </div>
// <div class="measure-grid">
//   <div class="measure measure-mobile-xxsmall"></div>
//   <div class="measure measure-mobile-xsmall"></div>
//   <div class="measure measure-mobile-small"></div>
//   <div class="measure measure-mobile-normal"></div>
//   <div class="measure measure-mobile-large"></div>
//   <div class="measure measure-mobile-xlarge"></div>
//   <div class="measure measure-mobile-xxlarge"></div>
// </div>
// <div class="measure-grid">
//   <div class="measure measure-desktop-xxsmall"></div>
//   <div class="measure measure-desktop-xsmall"></div>
//   <div class="measure measure-desktop-small"></div>
//   <div class="measure measure-desktop-normal"></div>
//   <div class="measure measure-desktop-large"></div>
//   <div class="measure measure-desktop-xlarge"></div>
//   <div class="measure measure-desktop-xxlarge"></div>
// </div>
//
// Styleguide 1.4.0

//Specifies that all elements should have padding and border included in the element's total width and height
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

//Mobile measurements
$measure-mobile-xxsmall: em(6px);
$measure-mobile-xsmall: em(9px);
$measure-mobile-small: em(12px);
$measure-mobile-normal: em(15px);
$measure-mobile-large: em(18px);
$measure-mobile-xlarge: em(20px);
$measure-mobile-xxlarge: em(36px);

//Desktop measurements
$measure-desktop-xxsmall: em(6px);
$measure-desktop-xsmall: em(9px);
$measure-desktop-small: em(12px);
$measure-desktop-normal: em(16px);
$measure-desktop-large: em(18px);
$measure-desktop-xlarge: em(24px);
$measure-desktop-xxlarge: em(36px);
