.form-row .fs-occupancy-input input {
  border-left: none;
  border-radius: 0;
  border-right: none;
  height: 45px;
  text-align: center;
  display: block;
}
.form-row .fs-occupancy-input .btn {
  padding: 0;
}
.child-occupancy .fs-occupancy-input {
  margin-bottom: 0;
}

.fs-occupancy-input {
  overflow: auto;
  margin-bottom: 10px;
  padding: 0 42px;

  span {
    display: block;
    font-size: 14px;
    padding: 5px 0;
    text-align: center;
  }

  .btn {
    @include theme-button-primary;
    height: 45px;
    width: 42px;

    &:before {
      display: none;
    }
  }

  .btn[disabled] {
    background: $input-border-color;
    cursor: not-allowed;
  }

  .btn .icon {
    font-size: 30px;
  }

  .btn.btn-decrement {
    border-radius: $input-border-radius 0 0 $input-border-radius;
    float: left;
    margin-left: -42px;
  }

  .btn.btn-increment {
    border-radius: 0 $input-border-radius $input-border-radius 0;
    float: right;
    margin-right: -42px;
  }
}
.rtl{
  .fs-occupancy-input {
    .btn.btn-increment {
      border-radius: $input-border-radius 0 0 $input-border-radius;
      float: left;
      margin-left: -42px;
    }
    .btn.btn-decrement {
      border-radius: 0 $input-border-radius $input-border-radius 0;
      float: right;
      margin-right: -42px;
    }
  }
}
