.transportation-modal {
  z-index: 50000;
  padding-top: 9%;
  padding-left: 4%;
  padding-right: 4%;

  @media screen and (min-width: $breakpoints-xl) {
    padding-top: 180px;
  }

  &__container {
    background: #fff;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;
    color: #000;
    text-align: center;
    position: relative;
    width: fit-content;

    @media screen and (min-width: $breakpoints-sm) {
      padding-left: 75px;
      padding-right: 75px;
    }

    @media screen and (min-width: $breakpoints-md) {
      padding-left: 158px;
      padding-right: 158px;
      max-width: 930px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 40px;

      h3 {
        font-family: $hn-neue;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 4px;
        text-transform: uppercase;
      }

      p {
        margin: 0;
        font-family: $garamond;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        letter-spacing: 0.5px;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20px;
        padding-right: 20px;
        gap: 30px;

        @media screen and (min-width: $breakpoints-sm) {
          flex-direction: row;
          padding: 0;
          gap: 40px;
        }
      }
    }

    &__close-btn {
      background: none;
      border: none;
      position: absolute;
      top: 37px;
      right: 37px;
      width: 20px;
      height: 20px;
      margin: 0;
      padding: 0;
      font-size: 36px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      @media screen and (min-width: $breakpoints-sm) {
        right: 49px;
      }
    }
  }
}
