.quantity-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  line-height: 1;

  .ty-h3 {
    letter-spacing: 0;
    line-height: 1;
  }

  &__toggle-button {
    background: none;
    padding: 0;
    border: none;
    width: 30px;
    height: 30px;

    path {
      fill: #000;
    }

    &[disabled] {
      color: #d8d8d8;
      cursor: not-allowed;

      path {
        fill: #d8d8d8;
      }
    }
  }

  &__toggle-icon {
    span {
      font-size: 30px;
      height: 30px;
      width: 30px;
      display: block;
    }
  }
}
