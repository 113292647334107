@use "sass:math";

.image-feature-list {
  border-bottom: 1px solid $grey;
  overflow: auto;
  padding: 15px;

  &.has-image {
    padding: 5px;
    .features {
      margin-left: 38%;
    }
  }

  .img-container {
    display: inline-block;
    float: left;
    max-width: 35%;
  }

  img {
    display: block;
    max-width: 100%;
  }

  .features {
    h3,
    h5 {
      color: $theme-black;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 1.5;
      margin: 0 0 5px;
    }

    ul {
      list-style-type: disc;
      margin-bottom: 10px;
      padding-left: 15px;
      li {
        font-size: 12px;
        line-height: 1.4;
      }
    }

    p {
      margin: 0 0 5px;
    }
  }

  &:last-child {
    border-bottom: none;
  }
}

.rtl .image-feature-list {
  float: right;

  .img-container {
    float: right;
  }

  &.has-image {
    .features {
      margin-left: 0%;
      margin-right: 38%;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .image-feature-list {
    border: none;
    float: left;
    padding: 15px;
    width: math.div(100%, 3);

    &.has-image {
      padding: 15px;

      .features {
        margin-left: 40%;
      }
    }
  }

  .rtl .image-feature-list {
    float: right;
    &.has-image {
      .features {
        margin-right: 40%;
        margin-left: 0%;
      }
    }
  }
}
