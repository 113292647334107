//
// Profile Detail Items
//

$detail-item-background: $white;
$detail-item-border-color: $black;
$detail-item-border: 1px solid $detail-item-border-color;
$detail-item-primary-border-color: $accent-color;
$detail-item-primary-border-width: 10px;
$detail-item-collapse-border-width: $detail-item-primary-border-width;
$detail-item-line-height: 1.2;
$detail-item-v-padding: 15px;
$detail-item-h-padding: 25px;
$detail-item-v-padding--desk: 20px;
$detail-item-h-padding--desk: $detail-item-h-padding;
$detail-item-h-padding--desk-large: 75px;
$detail-item-padding--small: $detail-item-v-padding;
$detail-item-padding--collapse: ($detail-item-h-padding + $detail-item-collapse-border-width);
$detail-item-max-width: 100%;

$detail-item-icon-size: 20px;
$detail-item-icon-height: 32px;
$detail-item-icon-width: $detail-item-icon-height;
$detail-item-icon-color: $new-medium-grey;
$detail-item-icon-hover-color: $new-dark-grey;

$detail-item-title-size: 15px;
$detail-item-title-color: $black;
$detail-item-title-line-height: 22px;
$detail-item-subtitle-size: 14px;
$detail-item-subtitle-color: $new-medium-grey;
$detail-item-subtitle-line-height: 22px;

$detail-item-body-max-width: 375px;
$detail-item-body-max-width--desk: 500px;
$detail-item-body-max-width--small: 272px;

$detail-item-delete-button-width: 80px;
$detail-item-delete-button-padding: 0 0;

%detail-items,
.detail-items {
  display: block;
  padding: 0;
  margin: 0;

  list-style: none;
}

%detail-item,
.detail-item {
  box-sizing: border-box;
  position: relative;
  display: block;
  max-width: $detail-item-max-width;
  padding: $detail-item-v-padding $detail-item-h-padding;
  margin: 0;
  border-top: $detail-item-border;

  list-style: none;
  overflow: hidden; // clearfix

  &:before {
    content: "";
    display: none;
    position: absolute;
    background-color: $detail-item-primary-border-color;
    width: $detail-item-primary-border-width;
    height: 100%;
    bottom: 0;
    left: 0;
    top: 0;
  }

  .detail-items--small & {
    padding: $detail-item-padding--small;
  }

  .detail-items--collapse-height & {
    padding-bottom: $detail-item-collapse-border-width;
    padding-top: $detail-item-collapse-border-width;
  }
}

.detail-item--primary {
  &:before {
    display: block;
  }
}

.detail-item__extra {
  float: left;

  .rtl & {
    float: right;
  }
}

.detail-item__body {
  display: block;
  overflow: hidden;
  max-width: $detail-item-body-max-width;
  padding-right: $detail-item-icon-width;

  overflow-wrap: break-word;
  word-wrap: break-word;

  .rtl & {
    padding-left: $detail-item-icon-width;
    padding-right: 0;
  }

  .fr & {
    max-width: 275px;
  }
}

%detail-item__title,
.detail-item__title {
  display: block;

  font-size: $detail-item-title-size;
  color: $detail-item-title-color;

  line-height: $detail-item-title-line-height;
}

%detail-item__subtitle,
.detail-item__subtitle {
  display: block;

  font-size: $detail-item-subtitle-size;
  color: $detail-item-subtitle-color;

  line-height:$detail-item-subtitle-line-height
}

.detail-item__icons {
  display: block;
  margin: auto;
  padding: 0 ($detail-item-h-padding - ($detail-item-icon-width - $detail-item-icon-size));
  line-height: $detail-item-icon-height;
  height: $detail-item-icon-height;
  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;

  z-index: 1;

  .rtl & {
    right: auto;
    left: 0;
  }
}

%detail-item__icon,
.detail-item__icon {
  @extend %icon;

  display: inline-block;
  width:  $detail-item-icon-width;
  height: $detail-item-icon-height;
  line-height: $detail-item-icon-height;

  text-align: center;
  font-size: $detail-item-icon-size;
  color: $detail-item-icon-color;

  cursor: pointer;

  &:hover {
    color: $detail-item-icon-hover-color;
  }
}

.detail-item__icon--type {
  float: left;
  margin-right: $detail-item-h-padding;
}

.detail-item__icon--delete {
  @extend %detail-item__icon;

  display: none;
}

.detail-item__btn--delete {
  @extend %btn--warning;

  width: $detail-item-delete-button-width;
  padding: $detail-item-delete-button-padding;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  display: none;
}

%detail-item__overlay,
.detail-item__overlay {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 2;
}

.detail-item--estimated-total {
  .detail-item__body {
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.detail-item--estimated-total__price {
  font-family: "AdobeCaslonW01-Regular";
}

// collapseable list - mobile only
.detail-items__collapse,
.detail-items__collapse--xs-only {
  border-right: $detail-item-border;
  border-left: $detail-item-border;
  position: relative;
  display: none;
  padding: 0;
  margin: 0;

  list-style: none;

  &.is-open {
    display: block;
  }

  &:before {
    // left padding
    // for sub-items
    content: "";
    display: block;
    position: absolute;
    background: $panel-background;
    width: $detail-item-collapse-border-width;
    height: 100%;
    bottom: 0;
    left: 0;
    top: 0;
  }

  .rtl & {
    &:before {
      left: auto;
      right: 0;
    }
  }
}

.detail-items__collapse {
  .detail-item {
    padding-right: $detail-item-padding--collapse;
    padding-left: $detail-item-padding--collapse;
  }
}

[class*="profile"] {
  .detail-item,
  .reservation-item {
    background: $white;
    border-top: none;
    border-bottom: 1px solid $panel-border-color;
  }
  .reservation-item__overlay {
    border-left: 1px solid $panel-border-color;
    border-right: 1px solid $panel-border-color;
  }
  .reservation-items {
    border-top: 1px solid $panel-border-color;
  }
  .reservation-item__title,
  .detail-item__title {
    color: $new-dark-grey;
  }
}

// hacky fixed-width junk that should be addressed differently.
// currently, we're limited by the page layout being dependent
// on 'table' styling.  This requires a pretty signifigant refactor.
//
// In the meantime, this prevents long strings with no linebreaks
// from breaking the page layout on small devices.
@media screen and (max-width: $screen-xs-max) {
  .detail-item__body {
    max-width: $detail-item-body-max-width--small;
  }
}


@media screen and (max-width: $screen-sm-max) {
  // toggle icon display in delete mode on mobile only
  .detail-items--deleting {
    .detail-item__icon--delete {
      display: none;
    }
    .detail-item__btn--delete {
      display: inline-block;
    }

    // compensate for the width of the button
    .detail-item__body {
      padding-right: $detail-item-delete-button-width;

      .rtl & {
        padding-left: $detail-item-delete-button-width;
        padding-right: 0;
      }
    }
  }

  .detail-items__collapse--xs-only {
    .detail-item {
      padding-right: $detail-item-padding--collapse;
      padding-left: $detail-item-padding--collapse;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .detail-item {
    padding: $detail-item-v-padding--desk $detail-item-h-padding--desk;

    .detail-items--large & {
      padding-right: $detail-item-h-padding--desk-large;
      padding-left: $detail-item-h-padding--desk-large;
    }

    .detail-items--small & {
      padding: $detail-item-padding--small;
    }
  }

  .detail-item__body {
    max-width: $detail-item-body-max-width--desk;
  }

  .detail-item__icon--delete {
    display: inline-block;
  }

  .detail-items__collapse--xs-only {
    display: block;

    &:before {
      display: none;
    }
  }

  [class*="profile"] {
    .reservation-item {
      background: $black;
    }
    .reservation-item__title {
      color: $white;
    }
  }
}

.panel-message-cc {
	color: black;
	font-weight: bold;
	padding-left: 20px;
}
