@font-face {
  font-family: "fs-rbf-font";
  src:url('/fonts/fs-rbf-font.eot?y1r6jb');
  src:url('/fonts/fs-rbf-font.eot?#iefixy1r6jb') format('embedded-opentype'),
    url('/fonts/fs-rbf-font.woff?y1r6jb') format('woff'),
    url('/fonts/fs-rbf-font.ttf?y1r6jb') format('truetype'),
    url('/fonts/fs-rbf-font.svg?y1r6jb#fs-rbf-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

/*!
This CSS resource incorporates links to font software which is the valuable copyrighted
property of Monotype Imaging and/or is suppliers. You may not attempt to copy, install,
redistribute, convert, modify or reverse engineer this font software. Please contact Monotype
Imaging with any questions regarding Web Fonts:  http://www.fonts.com
*/

@font-face{
    font-family:"HelveticaNeue-Light";
    src:url("/fonts/helvetica-neue/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix");
    src:url("/fonts/helvetica-neue/1b950d2a-907a-4888-8056-0d44e8e86338.eot?#iefix") format("eot"),
    url("/fonts/helvetica-neue/3a46542f-f429-4821-9a88-62e3be54a640.woff2") format("woff2"),
    url("/fonts/helvetica-neue/50ac1699-f3d2-47b6-878f-67a368a17c41.woff") format("woff"),
    url("/fonts/helvetica-neue/0be5590f-8353-4ef3-ada1-43ac380859f8.ttf") format("truetype"),
    url("/fonts/helvetica-neue/82a4e1c2-7ffd-4c58-86fa-4010a6723c8e.svg#82a4e1c2-7ffd-4c58-86fa-4010a6723c8e") format("svg");
}
