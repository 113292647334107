label.checkbox {
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  overflow: hidden;
  padding: 0 0 0 22px;
  position: relative;
  text-overflow: ellipsis;
  width: 50%;
  white-space: nowrap;

  input {
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 5px;
    left: 0;
    z-index: $z_reset;
    width: auto;
  }
}


.form-row.checkbox {
  padding-left: 12px;
  padding-right: 12px;

  label {
    cursor: pointer;
    display: inline-block;
    margin: 0;
    padding: 10px 12px 10px 22px;
    position: relative;
    text-transform: none;
  }

  input[type="checkbox"] {
    cursor: pointer;
    position: absolute;
    top: 14px;
    left: 0;
  }

  .prompt-message {
    margin: -10px 0 10px;
    padding-left: 34px;
  }
}

.rtl{
  .form-row.checkbox {
    label {
      padding: 10px 22px 10px 12px;
    }

    input[type="checkbox"] {
      left: auto;
      right: 0px;
    }

    .prompt-message {
      padding-left: 0px;
      padding-right: 34px;
    }
  }
}


.emp-terms-fieldset {
  .form-row.checkbox {
    input[type="checkbox"] {
      top: 6px;
      left: 0;
    }
  }
}

.rtl{
  .emp-terms-fieldset {
    .form-row.checkbox {
      input[type="checkbox"] {
        left: auto;
        right: 0;
      }
    }
  }
}


@media screen and (min-width: $screen-sm) {
  .form-row.checkbox {
    padding-left: 0;
    padding-right: 0;

    label {
      background: none;
      border: none;
      display: block;
      padding-left: 34px;
      width: auto;
    }

    input[type="checkbox"] {
      top: 22px;
      left: 12px;
    }
  }
  .rtl{
    .form-row.checkbox {
      label {
        padding-left: 0px;
        padding-right: 34px;
      }

      input[type="checkbox"] {
        left: auto;
        right: 12px;
      }
    }
  }
}


.checkbox-moreinfo {
  p {
    border-bottom: solid 1px $light-grey;
    margin-bottom: 0;
    padding-bottom: 12px;
  }

  input[type="checkbox"] {
    top: 14px;
  }
}

@media screen and (min-width: $screen-sm) {
  .checkbox-moreinfo {
    p {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}

.campaign-info {
  padding-left: 12px;
  padding-right: 12px;
  p {
    margin-bottom: 0;
    margin-top: 6px;
    padding-bottom: 12px;
    &.create-account-prompt {
      font-size: $secondary-font-size;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .campaign-info {
    padding-left: 0;
    padding-right: 0;
    p {
      margin-left: 12px;
      margin-right: 12px;
    }
  }
}
