.layout--profile-auth {
  .view--register-details {
    h1 {
      margin-bottom: 60px;

      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 80px;
      }
    }

    &__inline-selects {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $breakpoints-md) {
        flex-direction: row;
        margin-left: -15px;
        margin-right: -15px;

        > * {
          flex-grow: 1;
          flex-shrink: 1;
          margin-left: 15px;
          margin-right: 15px;

          &:first-child {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 150px;

          }
        }
      }
    }

    .fsp-input__checkbox {
      font-size: rem(15px);
    }

    .legal-text {
      font-size: rem(15px);
      text-align: left;
      @include rtl {
        text-align: right;
      }
    }

    .view__actions {
      margin-top: 22px;

      @media screen and (min-width: $breakpoints-md) {
        margin-top: 42px;
      }
    }
  }

  .grecaptcha-badge {
    z-index: 9;
  }
}
