$input-color: $black;
$input-border-color: $black;
$input-font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,sans-serif;

$number-stepper-btn-color: $black;
$number-stepper-disabled-btn-color: #D8D8D8;

.my-stay--activity-request-form {
  .fs-calendar {
    z-index: 100;
  }

  label {
    letter-spacing: 2px;
    font-size: 10px;
    text-transform: uppercase;
    color: $black;
  }

  input {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: $input-border-color;
    color: $input-color;
    background-color: transparent;
    font-family: $input-font-family;
    font-size: 18px;
    padding: 0;
    text-indent: 0;
    height: auto;
    height: 40px;
    line-height: 40px;

    &:focus {
      background-color: transparent;
      box-shadow: none;
      border-color: $input-border-color;
    }
  }

  .form-input {
    margin-bottom: 30px;

    textarea:focus {
      font-family: $input-font-family;
      box-shadow: none;
    }

    &.has-error {
      .form-input__error {
        background-color: transparent;
        font-size: 10px;
        color: $error-color;
        padding: 0;
      }
    }

    .fs-select-mask {
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: $input-border-color;
      background-color: transparent;
      line-height: 40px;

      &.in-focus {
        box-shadow: none;
      }

      .icon {
        margin-top: 14px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5.5px 0 5.5px;
        border-color: $input-border-color transparent transparent transparent;

        &:before {
          display: none;
        }
      }

      select {
        font-family: $input-font-family;
        background-color: transparent;
        padding: 0;
        height: auto;
        line-height: 38px;
        vertical-align: sub;
        font-size: 18px;
        color: $input-color;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

  }


  .activity-request-cost {
    margin: 0;
    text-align: center;
    text-transform: uppercase;
  }

  .inline-inputs.inline-steppers {
    clear: both;
    overflow: auto;
  }

  .form-input__number-stepper {
    position: relative;
    .form-input__wrap {
      overflow: hidden;
    }

    .form-input__number-stepper--btn,
    .form-input__number-stepper--input {
      float: left;

      .icon {
        font-size: 15px;
        position: relative;
        top: -14px;
      }
    }

    .form-input__number-stepper--btn {
      border: none;
      font-size: 22px;
      line-height: 52px;
      padding: 0;
      width: 27px;
      height: 27px;
      text-indent: 3px;
      background-color: $number-stepper-btn-color;
      position: absolute;
      z-index: 51;

      &.form-input__number-stepper--btn-plus {
        right: 0;
      }

      &[disabled] {
        background-color: $number-stepper-disabled-btn-color;
      }
    }

    .form-input__number-stepper--input {
      text-align: center;
      font-family: $primary-font;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 2px;
      border: none;
      position: absolute;
      top: 0;
      z-index: 50;
      height: 27px;
      line-height: normal;
    }

    input[type="text"][disabled] {
      background-color: #fff;
      pointer-events: none;
    }
  }

  .my-stay--activity-request-form__special-requests {
    p {
      font-size: $font-size-small;
      margin: 0 0 5px;
    }
  }

  .form-input--btn {
    margin-top: 0;
    font-family: $my-stay-header-font;
    background-color: $mystay-primary-button-bg-color;
    color: $mystay-primary-button-color;
    border: 1px solid $mystay-primary-button-border-color;
    font-size: 10px;
    letter-spacing: 2px;
    padding: 13px 30px;
    width: 200px;

    &:hover {
      background-color: $mystay-primary-button-hover-bg-color;
      color: $mystay-primary-button-hover-color;
    }
    & + .form-input--btn {
      margin-top: 20px;
    }
    &.btn--primary {
      width: auto;
    }
  }

  .toggle-terms {
    cursor: pointer;
    color: $input-color;
    font-family: $my-stay-header-font;
    font-size: 12px;
    letter-spacing: 2px;
    text-align: center;
    text-decoration: underline;
  }

  // chrome autofill overrides
  :-webkit-autofill {
    &,
    &:focus {
      box-shadow: 0 0 0 40px #f2f2f2 inset;
    }
  }
}

@media only screen and (min-width: $screen-xs) {
  .my-stay--activity-request-form {
    .inline-inputs {
      /*@extend .clearfix;*/
      margin: 0 -5px;

      .form-input {
        clear: none;
        float: left;
        width: 50%;
        padding: 0 5px;
      }
    }
  }
}
