.default-feature-list {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      border-bottom: 1px solid $grey;
      padding: 5px 15px 5px 95px;
      line-height: 18px;

      span {
        position: absolute;
        left: 15px;
        font-size: 14px;
      }
    }

    li:last-child {
      border-bottom: none;
    }

    li.double-row {
      overflow: auto;
      padding: 0;

      div {
        float: left;
        line-height: 18px;
        padding: 5px 0;
        padding-left: 95px;
        position: relative;
        width: 50%;
        &:first-child {
          border-right: 1px solid $grey;
        }
      }
    }
  }
}
@media only screen and (max-width: $screen-xs) {
  .ru {
    .default-feature-list {
      ul {
        li {
          span {
            max-width: 75px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
}
.rtl .default-feature-list ul {
  li {
    padding-left: 15px;
    padding-right: 95px;
    &.double-row {
      padding-right: 0px;
      div {
        float: right;
        padding-right: 95px;
        padding-left: 0px;
        &:first-child {
          border-right: none;
          border-left: 1px solid $grey;
        }
      }
    }
  }

  span {
    left: 0px;
    right: 15px;
  }
}

.room-feature-list {
  padding: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 0 (-$column-paddings * 0.5);

  .col {
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: $column-paddings * 0.5;
    padding-right: $column-paddings * 0.5;
    width: 50%;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        font-size: 12px;
        margin-bottom: 10px;
      }

      .label {
        color: black;
        display: block;
        letter-spacing: inherit;
        line-height: 1.5;
        font-size: 14px;
        font-weight: 200;
        text-transform: uppercase;
        padding-bottom: 2px;
      }
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .room-feature-list {
    flex-direction: row;
    .col {
      width: 50%;
    }
  }

  .room-feature-list.col3 {
    .col {
      width: 33.3%;
    }
  }
}

//Default room item small mobile
@media only screen and (max-width: ($screen-xs - 1px)) {
  .room-feature-list {
    .col {
      width: 100%;
      padding-right: 0;

      ul {
        li {
          @include clearfix;
        }
        .label {
          float: left;
          width: 30%;
          font-size: 12px;
          margin-right: 8px;
        }
        .details {
          float: left;
          width: 66%;
          font-size: 14px;

          a {
            display: block;
          }
        }
      }
    }
  }
}
