@mixin rtl {
  // Used to fix node-sass version issue
  #{'&'}:lang(ar) {
    @content;
  }
}

@mixin mediaRange($min-width, $max-width) {
  @media only screen and (min-width: $min-width) and (max-width: $max-width) {
    @content;
  }
}

@mixin ie() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }
}

@mixin media($min-width) {
  @media only screen and (min-width: $min-width) {
    @content;
  }
}

@mixin viewport($viewport) {
  @if $viewport == small {
    @content;
  }

  @if $viewport == medium {
    @include media($breakpoints-md) {
      @content;
    }
  }

  @if $viewport == large {
    @include media($breakpoints-lg) {
      @content;
    }
  }

  @if $viewport == xlarge {
    @include media($breakpoints-xl) {
      @content;
    }
  }
}

@mixin clearfix() {
  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

@mixin grayHover($theme, $position: '') {
  $hoverColor: if($theme == 'light', $whover, $bhover);

  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;

  @if $position != '' {
    &:focus::#{$position},
    &:hover::#{$position} {
      color: $hoverColor;
      transition: background-color 0.3s ease-out, color 0.3s ease-out,
        border-color 0.3s ease-out;
    }
  }

  &:focus,
  &:hover {
    border-color: $hoverColor;
    color: $hoverColor;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  font-size: 0;
}
