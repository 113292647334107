$verify-max-width: 600px;

.view-verify-email footer {
  display: none;
}

.verify-email-confirm__paragraph,
.verify-email-confirm__btn {
  display: block;
  margin: 0 auto;
}

.verify-email-confirm__paragraph {
  padding: 20px 40px 0;

  color: $new-dark-grey;
}

.verify-email-confirm-body {
  max-width: $verify-max-width;
}

// min-width media query
@media only screen and (min-width: $screen-sm) {
  .verify-email-confirm__paragraph {
    padding: 0 0 10px;
  }

  .verify-email-confirm__btn-wrap {
    margin-top: 10px;
  }

  .verify-email-confirm__heading {
    max-width: $verify-max-width;
    margin: 0 auto;

    font-family: $primary-font;
    font-size: 42px;
    font-weight: $primary-font-weight;
    color: $white;
    text-align: center;
    text-transform: uppercase;
  }
  .fr{
    .verify-email-confirm__heading{
      padding-bottom: 40px;
    }
  }
  .it{
    .verify-email-confirm__heading{
      padding-bottom: 40px;
    }
  }
}

// max-width media query
@media only screen and (max-width: $screen-sm-max) {
  .verify-email-confirm-body {
    margin-top: 20px;
  }
  .view-verify-email h1 {
    text-transform: none;
  }
  .verify-email-confirm__paragraph {
    padding: 20px 5px 0;
  }
  .verify-email-confirm__btn {
    margin: 80px auto 20px;
  }
  .view-verify-email .main-inner {
    padding-top: 0;
  }
  .verify-email-confirm__heading {
    display: none;
  }
}
