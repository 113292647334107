$navigationBreakpoint: 768px;

.fs-header {
  background: #fff;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
  color: #000;
  display: block;
  height: auto;
  z-index: $z_mobileHeader;
  width: 100%;
  position: sticky;
  top: 0;
  right: 0;

  @include media($navigationBreakpoint) {
    width: auto;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  &__top-bar {
    height: 50px;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

  &--menu-is-open {
    justify-content: flex-end;
    padding-left: 22px;
    width:100%;

    @include rtl {
      padding-left: 16px;
      padding-right: 22px;
    }

    .fs-header__top-bar__nav {
      width: 100%;
      justify-content: space-between;

      &__inner {
        width:100%;
        justify-content: space-between;
      }
    }
  }

    @include media($navigationBreakpoint) {
      padding-left: 36px;
      padding-right: 36px;
    }

    &__nav {
      color: #767676;
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;

      font-family: "HelveticaNeue-Light", "Helvetica Neue Light",
        "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-family: $hn;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 3px;
      line-height: 13px;
      min-width: 40px;

      &__inner {
        background: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding: 0;

        @include media($navigationBreakpoint) {
          position: static;
          padding: 0;
        }

        > * + * {
          margin-left: 10px !important;
          margin-right: 20px !important;

          @include rtl {
            margin-right: 10px !important;
            margin-left: 20px !important;
          }
        }
      }

      a {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        background: none;
        border: solid 0 transparent;
        border-bottom-width: 1px;
        cursor: pointer;
        color: inherit !important;
        padding: 0 0 1px;
        text-decoration: none;
        transition: background-color 0.3s ease-out, color 0.3s ease-out,
          border-color 0.3s ease-out;

        @include media($navigationBreakpoint) {
          height: 100%;
          padding-bottom: 0;
          border-top-width: 3px;
          border-bottom-width: 3px;
        }

        &:hover,
        &:focus {
          color: inherit !important;
          font-weight: inherit !important;
        }

        &.is-active {
          color: #000 !important;
        }
      }
    }
  }

  &__logo-container {
    display: flex;
    flex-direction: row;
  }

  &__logo {
    display: block;
    position: relative;
    height: 30px;
    width: 20px;
    margin: 0 20px 0 0;

    &::before {
      content: url("/images/profiles_2018/fs-profiles-header/tree.svg");
      display: block;
    }

    &.preferred-partner {
      height: 32px;
      width: 144px;

      &::before {
        content: url("/images/Horizontal-PP-Black-Trans-AG-RGB_NoSpace.svg");
        display: block;
      }
    }
  }

  &__best-rate-guaranteed {
    border-left: solid 1px #979797;
    padding: 0 0 0 20px;
    color: #7d7d7d;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, sans-serif;
    font-size: 10px;
    letter-spacing: 0.5px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__title {
      background-image: url(/images/best-rate-checkmark.png);
      background-repeat: no-repeat;
      background-size: 14px;
      background-position: 0 0;
      color: #000;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      line-height: 1.3;
      margin: 0 0 3px;
      padding: 0 0 0 20px;
    }

    &__text {
      color: inherit;
      margin: 0;
      color: #4d4d4d;
    }
  }

  &__title {
    color: inherit;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, sans-serif;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 1.3;
  }

  &__menu-button {
    background: none;
    border: none;
    position: relative;
    height: 18px;
    width: 30px;
    margin: 0 0 0 10px;
    padding: 0 !important;
    min-width: auto !important;
    min-height: auto !important;

    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      height: 18px;
      width: 30px;
      content: url("/images/profiles_2018/fs-profiles-header/menu.svg");
      display: block;
    }

  &-container {
    min-width: 40px;
  }

  &--is-open {
    justify-content: flex-start;
      &::before {
        height: 16px;
        width: 16px;
        content: url("/images/profiles_2018/fs-profiles-header/close.svg");
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    pointer-events: none;
    z-index: 10;
    overflow-y: scroll;
    width: 100%;
    height: 100%;

    @include media($navigationBreakpoint) {
      width: 100%;
      height: auto;
      top: 50px;
      left: 0;
      bottom: 0;
    }

    &__content {
      background: #fff;
      overflow: hidden;
      position: relative;
      z-index: 20;
      width: calc(100% - 20px);
      height: 100%;
      transform: translateX(100%);
      transition: transform .3s ease-in-out;
      margin-left: auto;

      @include rtl {
        transform: translateX(-100%);
      }

      @include media($navigationBreakpoint) {
        width: 100%;
        height: auto;
      }

      @include rtl {
        margin-left: 0;
        margin-right: auto;
      }

      .fs-header__top-bar {
        height: 85px;
        padding: 0 20px 0 35px;
        .fs-header__menu-button-container {
          min-width: unset;
        }
      }

      &:before {
        content: "";
        display: block;
        left: 0;
        top: -50px;
        right: 0;
        height: 50px;
        position: absolute;
        @include media($navigationBreakpoint) {
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        }
      }
    }

    &__background {
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;
    }

    &--is-open {
      pointer-events: initial;
      pointer-events: initial;

      .fs-header__overlay__content {
        transform: translateX(0);
      }

      .fs-header__overlay__background {
        visibility: visible;
        opacity: 1;
      }
    }

    &--is-loggedin {
      .fs-header__profile-flyout {
        &__columns {
          padding-top: 0;
        }

        &__column {
          padding: 0 35px 40px;
          @include media($navigationBreakpoint) {
            padding: 70px 114px;
          }
        }
      }
    }
  }

  &__bottom-bar {
    background: #2d2d2d;
    overflow-x: auto;
  }

  &__breadcrumbs__container {
    margin: 0;
  }

  &__breadcrumbs {
    float: left;
    color: #d8d8d8;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, sans-serif;
    font-size: 10px;
    letter-spacing: 3px;
    line-height: 1.3;
    height: 40px;
    list-style: none;
    margin: 0;
    padding: 0 36px;
    display: flex;
    flex-direction: row;
    text-transform: uppercase;

    @include media(992px) {
      margin: 0 62px;
    }

    &__number {
      border: solid 1px;
      border-radius: 1000px;
      width: 19px;
      height: 19px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 0 10px 0 0;
      text-indent: 3px;
    }

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;

      &.is-active {
        color: #fff;
        font-weight: bold;

        .fs-header__breadcrumbs__number {
          background: #fff;
          border-color: #fff;
          color: #2d2d2d;
        }
      }
    }

    li + li {
      margin-left: 24px;
    }

    h1 {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      letter-spacing: inherit;
    }
  }

  .CTA--secondary--dark:hover {
    font-weight: bold;
  }
}

.fs-header__profile-flyout__columns {
  margin: 0 auto;
  max-width: 1240px;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;

  @include media($navigationBreakpoint) {
    flex-direction: row;
    justify-content: center;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.fs-header__profile-flyout__column {
  padding: 30px 46px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @include media($navigationBreakpoint) {
    justify-content: space-between;
    text-align: center;
    width: 50%;
    padding: 70px 114px;
  }

  h2 {
    font-family: $base-font-family-sans;
    font-weight: 200;
    font-size: 32px;
    line-height: 1.4;
    text-transform: uppercase;
    margin: 0 0 20px;
    letter-spacing: 3.76px;
  }

  p {
    font-family: $base-font-family-serif;
    font-size: 18px;
    line-height: 1.5;
    letter-spacing: 0.4px;
    margin: 0 0 30px;
    text-align: center;
  }

  .fs-header__profile-flyout--link {
    line-height: 18px;
    padding-bottom: 20px;

    &Button {
      &.ty-c2 {
        font-family: $hn-neue;
        line-height: 18px;
        background: transparent;
        border: none;
        text-align: left;
        text-decoration: none;

        @include rtl {
          text-align: right;
        }
      }
    }

    &SignOut {
      margin-top: 5px;
      padding-top: 25px;
      border-top: 1px solid #D8D8D8;
    }

    &__container {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
}

.fs-header__profile-flyout__column + .fs-header__profile-flyout__column {
  border-top: solid 1px #ccc;

  @include media($navigationBreakpoint) {
    border-top: none;
    border-left: solid 1px #979797;
  }
}

.fs-header__profile-flyout__columns--sign-out {
  padding-top: 40px;
  padding-bottom: 40px;

  @include media($navigationBreakpoint) {
    padding-top: 0;
    padding-bottom: 0;
  }

  h2 {
    margin-bottom: 40px;
  }

  .CTA--secondary--dark {
    width: 236px;
  }
}

html body .fs-header__top-bar__nav .LanguageToggle-select {
  appearance: none;
  border: none;
  color: inherit;
  text-indent: unset;
  height: auto;
  padding: 0 18px 0 0;
  width: auto;

  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 13px;
  max-width: 110px;
  text-align: right;
  text-align-last: right;

  @include rtl {
    text-align-last: left;
    padding: 0 0 0 18px;
  }

  @include media($navigationBreakpoint) {
    margin-bottom: 0;
  }
}

[dir="rtl"] .fs-header {
  &__logo {
    margin-right: 0;
    margin-left: 20px;
  }

  &__breadcrumbs {
    float: right;

    li + li {
      margin-left: 0;
      margin-right: 24px;
    }

    &__number {
      margin-right: 0;
      margin-left: 10px;
      text-indent: -3px;
    }
  }
}

.view-booking-flow__choose-rooms .fs-header {
  box-shadow: none;
}

.profile-status {
  &-icon {
    margin-right: 5px;

    @include rtl {
      margin-right: 0;
      margin-left: 5px;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }
  &-name {
    font-family: 'Helvetica Neue', helvetica, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: none;
    color: $black;

    @include rtl {
      text-align: right;
    }
  }
}
