.fs-search-panel.bl-external-1 {

	input.fs-select-date {
    display: block;
    width: 48%;
    height: 30px;
    line-height: 30px;
    border-color: gray;
    background: url(/images/btn-calendar.png) no-repeat right;
	}

	input.fs-select-date {
   	font-size: 3.75vw;
	}

	input.fs-select-date.check-in {
   	float: left;
	}

	input.fs-select-date.check-out {
   	float: right;
	}
}

.fs-search-panel.bl-external-1.mobile {
	input.fs-select-date {
   	font-size: 16px;
	}
}

@media screen and (min-width: 426px) {
	.fs-search-panel.bl-external-1 {
		input.fs-select-date {
	   	font-size: 16px;
		}
	}
}

@media screen and (max-width: 267px) {
	.fs-search-panel.bl-external-1 {
		input.fs-select-date {
	   	font-size: 10px;
		}
	}
}

div.fs-select-date {

  .date-trigger {
    background-color: $white;
    border: none;
    padding: 0;
    > div {
      border-bottom: 4px solid $medium-grey;
    }
    > div span {
      border-left: solid 1px $light-grey;
      border-right: none;
    }
  }

  &.check-out .date-trigger {
    border-left: solid 1px $light-grey;
    > div span {
      border-left: none;
      border-right: solid 1px $light-grey;
    }
  }

  .date-trigger-stage {
    padding: 8px 0;
  }

  .date-trigger-day {
    display: block;
    background-color: $medium-grey;
    color: $white;
    padding: 5px 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .date-trigger__date-month {
    display: flex !important;
    flex-direction: column;
  }

  .date-trigger-date {
    display: block;
    font-size: 40px;
    line-height: 50px;
  }

  .date-trigger-month {
    display: block;
    padding: 0 0 5px;
  }

  .date-trigger-month.reverse {
    padding: 5px 0 0;
  }

  .date-trigger.is-selected {
    div {
      border-bottom-color: $calendar-highlight-color;
    }

    .date-trigger-day {
      background-color: $calendar-highlight-color;
    }
  }
}

.zh,
.zh_hant,
.jp {
  div.fs-select-date {
    .date-trigger__date-month {
      flex-direction: column-reverse;
    }
    .date-trigger-month {
      padding: 5px 0 0;
    }
  }
}
