$default-row-spacing: ($page-padding * 2);
$tight-row-spacing: $secondary-padding;
$wide-row-spacing: ($default-row-spacing * 2);

.row {
  margin-left: -($default-row-spacing * 0.5);
  margin-right: -($default-row-spacing * 0.5);

  .col {
    padding-left: ($default-row-spacing * 0.5);
    padding-right: ($default-row-spacing * 0.5);
  }
}

.row--wide {
  margin-left: -($wide-row-spacing * 0.5);
  margin-right: -($wide-row-spacing * 0.5);

  .col {
    padding-left: ($wide-row-spacing * 0.5);
    padding-right: ($wide-row-spacing * 0.5);
  }
}

.row--tight {
  margin-left: -($tight-row-spacing * 0.5);
  margin-right: -($tight-row-spacing * 0.5);

  .col {
    padding-left: ($tight-row-spacing * 0.5);
    padding-right: ($tight-row-spacing * 0.5);
  }
}

.row--wide,
.row--tight {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.col--divider {
  .rtl & {
    right: 0;
    left: auto;
  }
}

@media only screen and (min-width: $screen-sm) {
  .col--divider {
    &:before {
      content: "";
      display: block;
      position: absolute;
      background: $new-light-grey;
      height: 100%;
      width: 1px;
      left: 0;
      top: 0;
    }
  }
}
