.promoted-property {
  padding: 10px 0 0 0;
  text-align: center;
  background: #f2f2f2;
  .btn {
    border: solid 1px #fff;
    min-width: 180px;
    background-color: rgba(29,29,27,0.6);
    color: #fff;
  }
}

.promoted-property-discount-description {
  text-align: left;
}

.promoted-property-heading {
  text-align: left;
  font-size: 1em;
}

.promoted-property-inner,
.promoted-property-book {
  margin-left: -20px;
  margin-right: -20px;
  width: 100vw;
}

.promoted-property-inner {
  background: $white;
  padding: 10px;
}

.promoted-property-book {
  p {
    text-align: center;
    color: $white;
    margin: 0 0 0.5em;
  }
}

.promoted-property-book-inner {
  padding: 30px;
  margin-bottom: 20px;
  background: rgba(0,0,0,0.25);
}

.promoted-property-preferences-saved {
  padding: 0 10px;
  p {
    text-align: center;
  }
}

.promoted-property-return-home {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}


@media only screen and (min-width: $screen-sm) {
  .promoted-property {
    position: static;
    padding-top: 0;
    padding-right: 15px;
    padding-left: 15px;
    background-color: $white;
  }
  .promoted-property-discount-description {
    text-align: left;
  }
  .promoted-property-heading {
    text-align: center;
    font-size: 20px;
  }
  .promoted-property-inner,
  .promoted-property-book {
    margin: 0;
    width: auto;
  }
  .promoted-property-return-home {
    text-align: right;
  }
  .modal-content-body-padding .promoted-property {
    display: none;
  }
}
