.transportation-amenities-layout {
  background: #fff;
  color: #000;
  height: 100vh;
  padding: 0 20px 200px;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 40000;

  .btn-back {
    background: none;
    border: none;
    background-image: url("/images/profiles_2018/back-arrow--black.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 17px;
    width: 31px;
    padding: 0;
    margin: 0;

    span {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }
  }

  &__container {
    margin: 0 auto;
    width: 100%;
    max-width: 568px;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoints-lg) {
      max-width: 930px;
    }
  }

  &__back-bar {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px 0 30px;
  }

  &__row {
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (min-width: $breakpoints-lg) {
      flex-direction: row;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__header {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    padding: 0 0 30px;
    margin: 0;
    border-bottom: 1px solid #d8d8d8;

    @media screen and (min-width: $breakpoints-md) {
      padding-bottom: 20px;
    }

    &__content {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 30px;
      flex-grow: 1;
      flex-shrink: 1;

      p,
      h1,
      h2,
      h3 {
        margin: 0;
        padding: 0;
        text-align: inherit;
      }

      @media screen and (min-width: $breakpoints-md) {
        text-align: initial;
      }
    }

    &__image {
      display: none;

      img {
        display: block;
        max-width: 100%;
      }

      @media screen and (min-width: $breakpoints-md) {
        display: block;
        flex-grow: 0;
        flex-shrink: 0;
        width: 131px;
      }
    }
  }

  &__content {
  }

  &__sidebar {
    width: 100%;

    @media screen and (min-width: $breakpoints-lg) {
      flex-grow: 0;
      flex-shrink: 0;
      width: 370px;
    }
  }
}
