/*
 * Colours
 */
$theme-black: #000000;
$theme-gray1: #2D2D2D;
$theme-gray1-1: #767676;
$theme-gray2: #7D7D7D;
$theme-gray2-1: #A8A8A8;
$theme-gray2-2: #595959;
$theme-gray3: #D8D8D8;
$theme-gray4: #F5F5F5;
$theme-white: #FFFFFF;

/*
 * Buttons
 */

$theme-button-min-height: 20px;
$theme-button-padding: 10px 20px;

@mixin theme-button {
  min-height: $theme-button-min-height;
  min-width: 130px;
  padding: $theme-button-padding;
  letter-spacing: 0.12em;
  &.disabled,
  &:disabled {
    border: none;
    background: $theme-gray3;
    color: $theme-black;
  }
}

@mixin theme-button-primary {
  background: $theme-black;
  color: $theme-white;
  border: 1px solid $theme-black;
  border-radius: 0;
  font-weight: bold;
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    border: 1px solid $theme-black;
    background: $theme-white;
    color: $theme-black;
  }
}

@mixin theme-button-secondary {
  background: $theme-white;
  color: $theme-black;
  border: 1px solid $theme-black;
  font-weight: bold;
  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: $theme-black;
    color: $theme-white;
    border: 1px solid $theme-black;
  }
}

@mixin theme-link {
  text-decoration: underline;
  &:hover,
  &:focus {
    color: $theme-gray2;
    text-decoration: none;
    font-weight: normal;
  }
}

@mixin theme-cta-link {
  @include theme-link;
  font-weight: bold;
  text-transform: uppercase;
}

/* Inputs */
$theme-input-height: 45px;
@mixin theme-input-focus {
  border: 2px solid $theme-black;
  background: $theme-white;
  box-shadow: none;
}
