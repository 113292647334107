.rooms-selection {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px 0 0;
  margin: 0 -15px;
  overflow: hidden;

  @media only screen and (min-width: $screen-sm) {
    margin-left: 0;
    margin-right: 0;
  }

  &__row-container {
    overflow: hidden;
    margin-bottom: -100px;

    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }
  }

  &__row {
    $hide-scrollbar-padding: 100px;

    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0 calc(50% - 90px) $hide-scrollbar-padding;
    overflow: scroll;

    @media only screen and (min-width: $screen-sm) {
      padding: 0;
      overflow: hidden;
    }
  }

  &__room {
    width: 180px;
    padding: 0 25px;
    margin: 0;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;

    font-family: $hn;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 1.3;
    letter-spacing: 0.5px;

    @media only screen and (min-width: $screen-sm) {
      width: 25%;
      text-align: left;
    }

    &__inner {
      border-bottom: solid 3px transparent;
      height: 100%;
      padding: 0 0 15px;
    }

    &__title {
      margin: 0 0 5px;

      &__room-number {
        font-weight: bold;
        letter-spacing: 2.5px;
        text-transform: uppercase;
      }

      &__guest-count {
      }
    }

    &__selected-room {
      margin: 0 0 5px;

      &--is-highlighted {
        font-weight: bold;
      }
    }

    &__selected-package {
      &--is-highlighted {
        font-weight: bold;
      }
    }

    &__edit-button {
      background: none;
      border: none;
      border-bottom: solid 1px !important;
      padding: 2px 0 !important;
      margin: 0;
      max-width: none !important;
      min-width: auto !important;
      min-height: auto !important;
      font-family: $hn;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 0.75;
      text-align: center;
      letter-spacing: 2px !important;
      text-transform: uppercase;
      text-decoration: none;
      width: auto;
    }
  }

  &__room--is-selected {
    .rooms-selection__room__inner {
      border-bottom-color: #000;
    }
  }
}

.rooms-selection + .search-filters {
  border-top: none;
}

.selected-itinerary + .rooms-selection {
  border-top: none;
}
