@use "sass:math";

@function em($pixels, $context: 18px) {
  @return #{math.div($pixels, $context)}em;
}

@function rem($pixels, $context: 18px) {
  $remSize: math.div($pixels, $context);
  @return #{$remSize}rem;
}

@mixin typography($size) {
  @if $size == h1 {
    font-family: $hn;
    font-size: rem(28px);
    font-weight: 250;
    font-style: normal;
    letter-spacing: em(6, 28);
    line-height: em(42, 28);
    text-transform: uppercase;

    // @include viewport(medium) {
    //   font-family: $hn-thin;
    //   font-size: rem(32px);
    //   letter-spacing: em(5, 32);
    //   line-height: em(47, 32);
    // }

    // @include viewport(large) {
    //   font-size: rem(44px);
    //   letter-spacing: em(6, 44);
    //   line-height: em(62, 44);
    // }

    // @include viewport(xlarge) {
    //   font-size: rem(72px);
    //   letter-spacing: em(10, 72);
    //   line-height: em(100, 72);
    // }
  }

  @if $size == h2 {
    font-family: $hn;
    font-size: rem(24px);
    font-weight: 300;
    letter-spacing: em(4, 24);
    line-height: em(31, 24);
    text-transform: uppercase;
  }

  @if $size == h3 {
    font-family: $hn;
    font-size: rem(16px);
    font-weight: 700;
    letter-spacing: em(4px);
    line-height: em(21px);
    text-transform: uppercase;
  }

  @if $size == h4 {
    font-family: $garamond;
    font-size: rem(18px);
    font-style: italic;
    font-weight: 600;
    letter-spacing: em(0.5, 18);
    line-height: em(27, 18);
  }

  @if $size == b1 {
    font-family: $garamond;
    font-size: rem(22px);
    font-weight: normal;
    letter-spacing: em(0.5, 22);
    line-height: em(33, 22);
  }

  @if $size == b2 {
    font-family: $garamond;
    font-size: rem(18px);
    font-weight: normal;
    letter-spacing: em(0.5, 18);
    line-height: em(27, 18);
  }

  @if $size == b3 {
    font-family: $garamond;
    font-size: rem(15px);
    font-weight: normal;
    letter-spacing: em(0.5, 15);
    line-height: em(25, 15);
  }

  @if $size == b4 {
    font-family: $garamond;
    font-size: rem(18px);
    font-weight: 700;
    letter-spacing: em(0.5, 18);
    line-height: em(27, 18);
  }

  @if $size == q1 {
    font-family: $garamond;
    font-size: rem(32px);
    font-style: italic;
    letter-spacing: em(-0.5, 32);
    line-height: em(45, 32);

    @include viewport(medium) {
      font-size: rem(36px);
      letter-spacing: em(-0.5, 36);
      line-height: em(50, 36);
    }

    @include viewport(large) {
      font-size: rem(48px);
      letter-spacing: em(-0.5, 48);
      line-height: em(67, 48);
    }

    @include viewport(xlarge) {
      font-size: rem(76px);
      letter-spacing: em(-0.5, 76);
      line-height: em(107, 76);
    }
  }

  @if $size == q2 {
    font-family: $garamond;
    font-size: rem(21px);
    font-style: italic;
    letter-spacing: em(0.5, 21);
    line-height: em(32, 21);
  }

  @if $size == c1 {
    font-family: $hn;
    font-size: rem(12px);
    font-weight: 700;
    letter-spacing: em(3, 12);
    line-height: em(16, 12);
    text-transform: uppercase;
  }

  @if $size == c2 {
    font-family: $hn;
    font-size: rem(12px);
    font-weight: normal;
    letter-spacing: em(3, 12);
    line-height: em(18, 12);
    text-transform: uppercase;
  }

  @if $size == c3 {
    font-family: $hn;
    font-size: rem(10px);
    font-weight: 700;
    letter-spacing: em(3, 10);
    line-height: em(13, 10);
    text-transform: uppercase;
  }

  @if $size == c4 {
    font-family: $hn;
    font-size: rem(10px);
    font-weight: normal;
    letter-spacing: em(3, 10);
    line-height: em(13, 10);
    text-transform: uppercase;
  }

  @if $size == d2 {
	font-family: 'Helvetica Neue', helvetica, sans-serif;
	font-size: rem(14px);
	font-weight: 400;
	line-height: em(18, 14);
	letter-spacing: em(1.5, 14);
	text-transform: none;
  }
  @if $size == d4 {
	font-family: 'Helvetica Neue', helvetica, sans-serif;
	font-size: rem(12px);
	font-weight: 400;
	line-height: em(18, 12);
	letter-spacing: em(1, 12);
	text-transform: none;
  }
}

$typographyStyles: "h1", "h2", "h3", "h4", "b1", "b2", "b3", "b4", "q1", "q2",
  "c1", "c2", "c3", "c4", "d2", "d4";

@each $style in $typographyStyles {
  .ty-#{$style} {
    @include typography($style);
  }
}
