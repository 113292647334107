.view-my-stay {

  @media only screen and (max-width: $screen-sm) {
    .panel-nav__heading {
      display: none;
    }
  }

  .panel-nav__title {
    font-size: 18px;
    color: $black;
    font-weight: bold;
  }

  .panel-nav__item {

    a {
      color: $black;

      &:hover {
        background-color: $new-lighter-grey;
      }
    }

    &.is-selected {
      background-color: $new-lighter-grey;
    
      &:before {
        content: none;
      }

      &:after {
        content: ' ';
        display: block;
        height: 100%;
        width: 1px;
        background-color: $new-lighter-grey;
        border: none;
      }

      a {
        background-color: transparent;
        color: $black;
      }
    }
  }

  .panel-nav__item__link {
    font-family: $my-stay-header-font;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
  }

  ul.panel-nav__group--wrap > *:last-child {
    border-bottom: 1px solid $new-light-grey;
  }

}
