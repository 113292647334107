.new-modal--alipay {
  .alipay-modal-content-wrapper {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .aliay-modal-content {
      padding: 0 20px;
    }

    .alipay-modal-button {
      @media only screen and (min-width: $screen-lg) {
        margin: 3px;
      }
    }
  }
}
