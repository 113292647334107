$z_beneath: 1;
$z_reset: 0;
$z_buttonBorder: 1;
$z_calendar: 12;
$z_errorHint: 11;
$z_calendarArrow: 11;
$z_carouselNavigation: 12;
$z_carouselContainer: 13;
$z_autocompleteList: 20;
$z_guestDropdownList: 20;
$z_roomDetailsTabSelect: 30;
$z-fsBtnSelect: 31;
$z_searchSummary: 40;
$z_headerDropDown: 50;
$z_tooltip: 99;
$z_mobileHeader: 200;
$z_modalOverlay: 300;
$z_modalBackButton: $z_modalOverlay;
$z_modalDialog: 400;
$z_modalOverlayDesktop: 600;
$z_loadingScreen: 9999;
