$profile-body-width: 1140px;

$profile-modal-text-size: 14px;
$profile-modal-text-color: $new-dark-grey;

.hero--profile {
  @extend %hero--high-cut;
}

.profile-heading,
.profile-heading--top,
.profile-heading--name {
  @extend %hero__title;
}

.profile-heading--top {
  font-size: 16px;
}

.profile-body {
  max-width: $profile-body-width;
}

.iframe--modal {
  .modal-content-body {
    overflow: hidden;
  }

  .profile-modal-body {
    .modal-content-body-padding {
      overflow: auto;
      height: 100%;
    }
  }
}

.profile-modal-body {
  background: $panel-background;
  margin-bottom: 1px;
  min-height: 100%;
  height: 100%;
  color: $profile-modal-text-color;

  h1,
  h2,
  h3,
  h4,
  dt {
    font-family: $primary-font;
    font-weight: $primary-font-weight;
  }

  p {
    margin-top: 0;
  }

  .form-input__label {
    letter-spacing: 2px;
    font-size: 10px;
    text-transform: uppercase;
    color: #000;
    margin-bottom: 3px;
  }

  .modal-content-body-padding {
    background: $panel-background;
  }
}

.profile-modal-error {
  @extend %hero-error--simple;
}

@media only screen and (min-width: $screen-sm) {
  .profile-heading,
  .profile-heading--name {
    font-size: 42px;
  }
}

.accordion-item {
  overflow: visible;
  transition: max-height 0.4s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;

  &__opacity {
    transition: opacity 0.2s;
    opacity: 1;
  }

  &.collapsed {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s cubic-bezier(0, 1, 0, 1);

    .accordion-item__opacity {
      transition: opacity 0.2s 0.2s;
      opacity: 0;
    }
  }

  &.isTransitioning {
    overflow: hidden;
  }
}

.login-phone__checkbox {
  display: flex;
}
