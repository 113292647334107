.search-filters {
  border-top: 1px solid #d8d8d8;
  font-family: Helvetica Neue !important;
  margin: 0 -15px 20px;
  padding: 0;

  @media only screen and (min-width: $screen-sm) {
    margin-left: 0;
    margin-right: 0;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 10px 35px;

    @media only screen and (min-width: $screen-sm) {
      padding-left: 20px;
      padding-right: 20px;
    }
    &__toggle {
      width: auto !important;
      min-width: auto !important;
      max-width: auto !important;
      border: none !important;
      background: none !important;
      padding: 0 22px 0 0 !important;
      margin: 0 !important;
      position: relative;

      font-family: Helvetica Neue !important;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 1.3;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #2d2d2d;

      &:after {
        border: solid 6px transparent;
        border-top-color: #2d2d2d;
        border-bottom: none;
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -3px;
      }

      &--is-open:after {
        border-top: none;
        border-bottom: solid 6px #2d2d2d;
      }
    }
  }

  &__options {
    border-top: 1px solid #d8d8d8;
    background: #f5f5f5;
    color: #000;

    @media only screen and (min-width: $screen-sm) {
      border: none;
      background: transparent;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin: 0 -15px;
    }

    &__row {
      border-bottom: 1px solid #d8d8d8;
      display: flex;
      flex-direction: row;
      height: 60px;

      @media only screen and (min-width: $screen-sm) {
        border-bottom: none;
        height: auto;
      }
    }

    &__col {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 50%;
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 0;
      padding: 15px;

      @media only screen and (min-width: $screen-sm) {
        width: auto;
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
        padding: 15px 0;
        margin: 0 15px;
        justify-content: flex-end;
      }
    }

    &__label {
      font-family: Helvetica Neue !important;
      font-style: normal;
      font-weight: bold;
      font-size: 9px;
      line-height: 1.44;
      letter-spacing: 2px;
      text-transform: uppercase;

      @media only screen and (min-width: $screen-sm) {
        font-style: normal;
        font-weight: 300;
        font-size: 7px;
        line-height: 2.14;
        letter-spacing: 2.5px;
        margin: 0 0 5px;
      }
    }

    &__select__wrapper {
      position: relative;
    }

    &__select__arrow {
      position: absolute;
      background-image: url(/images/profiles_2018/down_arrow--black.svg);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      top: 0;
      bottom: 0;
      right: 0;
      width: 20px;
      z-index: 10;
      pointer-events: none;
    }

    &__select {
      appearance: none;
      background: none;
      border: none;
      padding: 0 20px 0 0;
      margin: 0;
      font-family: Helvetica Neue !important;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.29;
      letter-spacing: 0.5px;
      color: #000;
      text-indent: 0;
      width: auto;
      max-width: 140px;
      height: 24px;

      @media only screen and (min-width: $screen-sm) {
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 3px;
        height: 13px;
        display: block;
      }
    }

    .checkboxes {
      overflow: hidden;

      .checkbox {
        font-family: Helvetica Neue !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 0.93;
        letter-spacing: 0.5px;
        color: #000;
        width: auto;
        // float: left;
        padding: 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        @media only screen and (min-width: $screen-sm) {
          text-transform: uppercase;
          font-size: 10px;
          letter-spacing: 3px;
        }

        &__custom-checkbox {
          background: transparent;
          border: solid 1px #000;
          height: 15px;
          width: 15px;
          margin: 0 10px 0 0;
        }

        input {
          display: none;
        }

        input:checked + .checkbox__custom-checkbox {
          background: #000;
          background-image: url(/images/profiles_2018/tick--white.svg);
          background-size: 10px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }
      }
    }

    .all-in-pricing {
      font-size: 14px;
      line-height: 0.93;
      letter-spacing: 0.5px;

      @media only screen and (min-width: $screen-sm) {
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 3px;
      }
    }

    .slider {
      background-color: transparent;
      border: solid 1px #000;

      &:before {
        bottom: 1px;
        left: 2px;
        background-color: #000;
      }
    }

    input:checked + .slider {
      background-color: #000;

      &:before {
        left: 5px;
        background-color: #fff;
      }
    }
  }

  .search-filters__options__col + .search-filters__options__col {
    border-left: 1px solid #d8d8d8;

    @media only screen and (min-width: $screen-sm) {
      border-left: none;
    }
  }
}

.rtl {
  .search-filters {
    .search-filters__options__select {
      padding-left: 20px;
      padding-right: 0;
    }

    .search-filters__options__select__arrow {
      left: 0;
      right: auto;
    }

    .search-filters__options__col + .search-filters__options__col {
      border-left: none;
      border-right: 1px solid #d8d8d8;

      @media only screen and (min-width: $screen-sm) {
        border-left: none;
        border-right: none;
      }
    }

    &__options {
      .checkboxes {
        .checkbox__custom-checkbox {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }
}
