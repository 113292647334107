$closing-animation: height 0.8s ease-in-out;
$fade-animation: opacity 0.6s ease;

.bottom-drawer {
  height: 90px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f5f5f5;
  border-top: 1px solid rgba(216, 216, 216, 0.5);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px 20px 0px 0px;
  z-index: 100000;
  transition: $closing-animation;

  &--open,
  &--opening {
    height: 100vh;
  }

  &__closed-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 90px;
    transition: $fade-animation;
  }

  &__collapse-icon {
    span {
      font-size: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__close-icon {
    span {
      font-size: 20px;
    }
  }

  &--open,
  &--opening {
    .bottom-drawer__closed-content {
      opacity: 0;
    }
  }

  &__open-content {
    opacity: 0;
    transition: $fade-animation;
  }

  &--closed {
    .bottom-drawer__open-content {
      pointer-events: none;
    }
  }

  &--open,
  &--opening {
    .bottom-drawer__open-content {
      opacity: 1;
    }
  }

  &__expand-button {
    padding: 10px 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
    background: none;
    border-bottom: 1px solid #d8d8d8;
    cursor: pointer;
  }

  &__details {
    padding: 13px 30px 22px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__close {
    padding: 0;
    margin: 0;
    display: flex;
    border: none;
    background: none;
    cursor: pointer;

    position: fixed;
    top: 20px;
    right: 20px;
  }

  &__children {
    height: 100vh;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

&__body__hidden {
  overflow: hidden;
}
