.room-item-tab-container.tab-services-active {
  button.tab-button-packages {
    display: none;
  }
}

.room-details-tab-select {
  $active-color: $theme-gray1;
  $inactive-color: $theme-white;
  $arrow-width: 10px;

  margin: 0;
  padding: 0;
  height: 32px;
  position: relative;
  z-index: $z_roomDetailsTabSelect;

  .tab-button {
    border: none;
    border-bottom: 1px solid darken($theme-gray3, 10%);
    border-top: 1px solid darken($theme-gray3, 10%);

    box-sizing: border-box;
    background-color: $inactive-color;
    border-radius: 0;
    font-size: 14px;
    line-height: 30px;
    position: relative;
    width: 100%;
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;

    & + button {
      border-left: 1px solid darken($theme-gray3, 10%);
    }

    & + button.is-active {
      border-left: none;
    }

    &.tab-button--services,
    &.tab-button--details {
      padding: 0;
    }

    &.is-active {
      border-bottom: 1px solid $active-color;
      border-top: 1px solid $active-color;
      background-color: $active-color;
      color: $inactive-color;

      & + button {
        border-left: none;
      }

      & + button + button {
        border-left: 1px solid darken($theme-gray3, 10%);
      }

      &:after {
        border-color: transparent;
        border-top-color: $active-color;
        border-style: solid;
        border-width: $arrow-width;
        border-bottom-width: 0;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        width: 0;
        bottom: -$arrow-width;
        left: 50%;
        margin-left: -$arrow-width;
      }
    }
  }

  @media only screen and (min-width: $screen-sm) {
    .tab-button {
      width: 50%;
    }
  }
}

.room-details-tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  background-color: #f5f5f5;
}

.room-details-modal {
  .room-details-tab-select {
    button {
      &.is-active {
        border-color: $dark-grey;
        background-color: $dark-grey;

        &:after {
          border-top-color: $dark-grey;
        }
      }
    }
  }
}

@media only screen and (max-width: $screen-sm - 1px) {
  .jp .room-details-tab-select {
    height: 68px;

    button {
      height: 100%;
    }
  }
}

.rtl .room-details-tab-select button {
  float: right;
}

@media only screen and (min-width: $screen-sm) {
  .room-item-tab-container {
    background: #fff;

    &.tab-packages-active {
      .room-details-tab-select {
        display: block;
      }
    }

    &.tab-services-active,
    &.tab-policies-active {
      button.tab-button-packages {
        display: block;
      }
    }
  }

  .room-details-tab-select {
    border-top: none;

    button {
      border-top: 1px solid $white;
      width: 50%;
    }

    &.non-bookable-direct {
      button {
        width: (100% * 0.5);
      }
    }
  }

  .room-details-tabs {
    .tab {
      display: none;

      &.is-active {
        display: block;
      }
    }
  }
}

// Removing a single pixel to stop packages tab displaying on a vertical iPad
@media only screen and (max-width: $screen-sm - 1px) {
  .tab.tab-packages {
    display: block !important;
  }
}
