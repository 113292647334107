@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=b083e0b5-f00e-4415-83f7-d8b45cc3dfc3");

$fonts-path: "/fonts/profile_2018";
$font-path-monotype-garamond: "/fonts/fs-font/monotype-garamond/webfont/";
$font-path-neue-helvetica: "/fonts/fs-font/neue-helvetica/webfont/";

// "Garamond MT Std";
@font-face {
  font-family: "Garamond MT Std";
  src: url($font-path-monotype-garamond + "GaramondMTStd-Regular.eot");
  src: url($font-path-monotype-garamond + "GaramondMTStd-Regular.eot?#iefix")
      format("embedded-opentype"),
    url($font-path-monotype-garamond + "GaramondMTStd-Regular.woff2")
      format("woff2"),
    url($font-path-monotype-garamond + "GaramondMTStd-Regular.woff")
      format("woff"),
    url($font-path-monotype-garamond + "GaramondMTStd-Regular.ttf")
      format("truetype"),
    url($font-path-monotype-garamond + "GaramondMTStd-Regular.svg#GaramondMTStd-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Garamond MT Std";
  src: url($font-path-monotype-garamond + "GaramondMTStd-Italic.eot");
  src: url($font-path-monotype-garamond + "GaramondMTStd-Italic.eot?#iefix")
      format("embedded-opentype"),
    url($font-path-monotype-garamond + "GaramondMTStd-Italic.woff2")
      format("woff2"),
    url($font-path-monotype-garamond + "GaramondMTStd-Italic.woff")
      format("woff"),
    url($font-path-monotype-garamond + "GaramondMTStd-Italic.ttf")
      format("truetype"),
    url($font-path-monotype-garamond + "GaramondMTStd-Italic.svg#GaramondMTStd-Italic")
      format("svg");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

// "Helvetica Neue LT Std";
@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Lt.eot");
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Lt.eot?#iefix")
      format("embedded-opentype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Lt.woff2")
      format("woff2"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Lt.woff") format("woff"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Lt.ttf")
      format("truetype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Lt.svg#HelveticaNeueLTStd-Lt")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Bd.eot");
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Bd.eot?#iefix")
      format("embedded-opentype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Bd.woff2")
      format("woff2"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Bd.woff") format("woff"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Bd.ttf")
      format("truetype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Md.eot");
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Md.eot?#iefix")
      format("embedded-opentype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Md.woff2")
      format("woff2"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Md.woff") format("woff"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Md.ttf")
      format("truetype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Md.svg#HelveticaNeueLTStd-Md")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Th.eot");
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Th.eot?#iefix")
      format("embedded-opentype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Th.woff2")
      format("woff2"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Th.woff") format("woff"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Th.ttf")
      format("truetype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Th.svg#HelveticaNeueLTStd-Th")
      format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Roman.eot");
  src: url($font-path-neue-helvetica + "HelveticaNeueLTStd-Roman.eot?#iefix")
      format("embedded-opentype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Roman.woff2")
      format("woff2"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Roman.woff")
      format("woff"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Roman.ttf")
      format("truetype"),
    url($font-path-neue-helvetica + "HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// old VVVVV
@font-face {
  font-family: "Monotype Garamond W05_n4";
  src: url($fonts-path + "/d5dc315c-6f72-46ff-ab31-7a866958fb16.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Monotype Garamond W05";
  src: url($fonts-path + "/d5dc315c-6f72-46ff-ab31-7a866958fb16.eot?#iefix");
  src: url($fonts-path + "/d5dc315c-6f72-46ff-ab31-7a866958fb16.eot?#iefix")
      format("eot"),
    url($fonts-path + "/2facc835-78c9-4456-b5dc-807374ccbaa4.woff2")
      format("woff2"),
    url($fonts-path + "/e9de9cfc-1efd-400f-a2d6-e934b42180ee.woff")
      format("woff"),
    url($fonts-path + "/639a7000-1f6f-4e79-968c-03640a20f45f.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Monotype Garamond W05_i4";
  src: url($fonts-path + "/6c3840fc-ac62-4397-9ecf-2b256730eae0.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Monotype Garamond W05";
  src: url($fonts-path + "/6c3840fc-ac62-4397-9ecf-2b256730eae0.eot?#iefix");
  src: url($fonts-path + "/6c3840fc-ac62-4397-9ecf-2b256730eae0.eot?#iefix")
      format("eot"),
    url($fonts-path + "/b25db2e7-6a38-4599-9f7a-9672078d6278.woff2")
      format("woff2"),
    url($fonts-path + "/2169b5c6-dae9-4259-85d0-044dee7be22e.woff")
      format("woff"),
    url($fonts-path + "/27a7d804-99f3-4851-b7e0-c4c8d85abca2.ttf")
      format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Monotype Garamond W05_i4";
  src: url($fonts-path + "/001c884c-da2e-47c3-8019-95f92a54d1a2.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Monotype Garamond W05";
  src: url($fonts-path + "/001c884c-da2e-47c3-8019-95f92a54d1a2.eot?#iefix");
  src: url($fonts-path + "/001c884c-da2e-47c3-8019-95f92a54d1a2.eot?#iefix")
      format("eot"),
    url($fonts-path + "/049dc18c-fa50-481e-be80-bc92cf8efdb9.woff2")
      format("woff2"),
    url($fonts-path + "/996be916-f1a3-4814-bcca-43f9272b0126.woff")
      format("woff"),
    url($fonts-path + "/3b626b7d-f9de-47ab-a885-00cabfca52e8.ttf")
      format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Monotype Garamond W05_n7";
  src: url($fonts-path + "/65c37283-5e79-4fe8-975b-bbc82070897d.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Monotype Garamond W05";
  src: url($fonts-path + "/65c37283-5e79-4fe8-975b-bbc82070897d.eot?#iefix");
  src: url($fonts-path + "/65c37283-5e79-4fe8-975b-bbc82070897d.eot?#iefix")
      format("eot"),
    url($fonts-path + "/61347fc1-f864-4e70-a757-03bc518a7a21.woff2")
      format("woff2"),
    url($fonts-path + "/8f7f3c0e-30c3-40f7-abbc-7b78021d4aaa.woff")
      format("woff"),
    url($fonts-path + "/bb9dcbee-09f2-4dfc-acb0-36edc6ee3282.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neue Helvetica W04_n2";
  src: url($fonts-path + "/5acb67e9-77f3-4dc1-a7fd-7a75a9c43813.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Neue Helvetica W04";
  src: url($fonts-path + "/5acb67e9-77f3-4dc1-a7fd-7a75a9c43813.eot?#iefix");
  src: url($fonts-path + "/5acb67e9-77f3-4dc1-a7fd-7a75a9c43813.eot?#iefix")
      format("eot"),
    url($fonts-path + "/18fbcb9a-96e4-4820-8f60-312aadce6d35.woff2")
      format("woff2"),
    url($fonts-path + "/e9722702-4fb8-436a-9342-c5f4f5c3a75d.woff")
      format("woff"),
    url($fonts-path + "/0cadd069-b191-4897-b28a-a7df7a06a582.ttf")
      format("truetype");
  font-weight: 250;
  font-style: normal;
}

@font-face {
  font-family: "Neue Helvetica W05_n4";
  src: url($fonts-path + "/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Neue Helvetica W05";
  src: url($fonts-path + "/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix");
  src: url($fonts-path + "/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix")
      format("eot"),
    url($fonts-path + "/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2")
      format("woff2"),
    url($fonts-path + "/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff")
      format("woff"),
    url($fonts-path + "/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf")
      format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Neue Helvetica W04_n7";
  src: url($fonts-path + "/f9872f03-b366-499d-9b0e-ce508b0a3539.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Neue Helvetica W04";
  src: url($fonts-path + "/f9872f03-b366-499d-9b0e-ce508b0a3539.eot?#iefix");
  src: url($fonts-path + "/f9872f03-b366-499d-9b0e-ce508b0a3539.eot?#iefix")
      format("eot"),
    url($fonts-path + "/f350f87a-2ea2-408b-98bb-580827d1a634.woff2")
      format("woff2"),
    url($fonts-path + "/0552ce48-950c-471f-b843-1afac814d259.woff")
      format("woff"),
    url($fonts-path + "/df2262d6-48cc-478c-aed1-5ce7421b4cf4.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Neue Helvetica W05_n7";
  src: url($fonts-path + "/293d1873-feb8-4e1b-ab98-a49ceafcf5ac.eot?#iefix")
    format("eot");
}

@font-face {
  font-family: "Neue Helvetica W05";
  src: url($fonts-path + "/293d1873-feb8-4e1b-ab98-a49ceafcf5ac.eot?#iefix");
  src: url($fonts-path + "/293d1873-feb8-4e1b-ab98-a49ceafcf5ac.eot?#iefix")
      format("eot"),
    url($fonts-path + "/d68aa150-ab14-4dc6-973e-68d88bdf3660.woff2")
      format("woff2"),
    url($fonts-path + "/06e01e5c-196d-42c0-b7a6-ea57b0fbb3e8.woff")
      format("woff"),
    url($fonts-path + "/8a48ac58-0a86-4c3f-abbe-6a8bcd2f1bf2.ttf")
      format("truetype");
  font-weight: 700;
  font-style: normal;
}
