.build-your-itinerary {
  border-top: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__container {
    max-width: 730px;
    width: 100%;
    padding: 15px 15px 30px;

    display: flex;
    flex-direction: column;
    gap: 15px;

    @media screen and (min-width: $breakpoints-md) {
      padding: 30px 30px 50px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
      max-width: none;
    }
  }

  .ty-h4 {
    text-transform: none;
    font-weight: 400;
    text-align: initial;
  }

  &__actions-bar {
    margin-left: -15px;
    margin-right: -15px;
  }

  .itinerary-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 9px 10px;
    gap: 10px;
    height: 100%;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    text-align: initial;
    text-decoration: none;

    @media screen and (min-width: $breakpoints-md) {
      padding: 15px;
    }

    svg {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}
