/* Note, this is setup as mobile first, so the font sizes are phone sized.  */
/* Desktop and tablet will be done in a media query. */
.view-booking-flow__plan-your-stay,
.view-booking-flow__choose-rooms,
.view-booking-flow__confirm-your-stay,
.view-booking-flow__personalize-your-stay {
  &,
  input,
  select,
  textarea {
    color: $theme-black;
  }

  input,
  .fs-autocomplete .autocomplete-input-wrapper .autocomplete-input {
    height: $theme-input-height;
  }

  input[type="checkbox"] {
    height: auto;
  }

  input[type="text"]:not(:read-only):focus,
  input[type="tel"]:not(:read-only):focus,
  input[type="email"]:not(:read-only):focus,
  input[type="password"]:not(:read-only):focus,
  input[type="date"]:not(:read-only):focus,
  input[type="datetime"]:not(:read-only):focus,
  input[type="number"]:not(:read-only):focus,
  input[type="search"]:not(:read-only):focus,
  textarea:not(:read-only):focus,
  .textarea textarea:not(:read-only):focus {
    @include theme-input-focus;
  }

  /* separate from the other focus styles so other browsers won't ignore the whole
  declaration if they don't understand -webkit-autofill */
  input:-webkit-autofill,
  select:-webkit-autofill,
  textarea:-webkit-autofill {
    &,
    &:focus {
      /* can't override the browser's background colour, so we'll use this
      box-shadow hack */
      box-shadow: 0 0 0px 1000px #fff inset;
    }

    &:focus {
      border: 2px solid $theme-black;
    }
  }

  legend {
    background: $theme-gray2-2;
  }

  a,
  .appear-as-link {
    color: $theme-black;

    &:hover,
    &:focus {
      color: $theme-gray1-1;
      text-decoration: none;
    }

    &.btn {
      text-decoration: none;
    }
  }

  .nav-flyout__link {
    color: $theme-white;
    &:hover,
    &focus {
      color: $theme-gray2-1;
      text-decoration: none;
      font-weight: normal;
    }

    @media only screen and (min-width: $screen-sm) {
      color: $black;

      &:hover,
      &:focus {
        color: $black;
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }

  .footer-inner {
    &,
    .legal {
      background: $theme-black;
    }

    a {
      color: $theme-white;
    }

    button {
      color: $theme-white;
    }
  }

  .footer-upper {
    .best_rate_guaranteed_wrapper {
      padding: 0;
      width: 220px;
    }
    .best_rate_guaranteed_title {
      height: 28px;
      font-size: 10pt;
      padding: 5px 0 2px 5px;
    }
  }

  &.rtl .footer-upper {
    .best_rate_guaranteed_wrapper {
      width: 157px;
    }
    .best_rate_guaranteed_title {
      padding-right: 5px;
    }
  }

  .logo {
    color: $theme-white;
    @media only screen and (min-width: $screen-sm) {
      color: $theme-black;
    }
  }

  .modal-content-body {
    background: $theme-gray4;
  }

  .btn,
  button {
    @include theme-button;
  }

  .btn-primary,
  .btn--primary {
    @include theme-button-primary;
  }

  .btn-secondary,
  .btn--secondary,
  .btn-plain {
    @include theme-button-secondary;
  }

  .btn-link {
    @include theme-link;
    color: $theme-black;
  }

  .btn-group .btn {
    font-weight: bold;
    &.is-active {
      background-color: $theme-gray1;
      border-color: $theme-gray1;
      &:after {
        border-top-color: $theme-gray1;
      }
    }
  }

  .btn-link {
    text-align: left;
    letter-spacing: 0.027em;
    span {
      @include theme-link;
      color: $theme-black;
    }
  }

  .btn-decrement,
  .btn-increment {
    min-width: 0;
  }

  .btn-group__third {
    min-width: 80px;
    padding: 6px;
    display: inline-block;
  }

  .cancel {
    font-weight: normal;
    text-transform: none;
  }

  .fs-btn-select-container {
    .toggle,
    .label {
      padding: $theme-button-padding;
    }
    .options {
      a.btn,
      button.btn {
        line-height: $theme-button-min-height;
      }
    }
  }

  .show-more-info-link {
    padding: 0;
    &.info-icon {
      min-width: 0;
      &:focus,
      &:hover,
      &:active {
        background: $theme-white;
        color: $theme-black;
        border: 1px solid $theme-black;
      }
      &:focus:active {
        background: $theme-black;
        color: $theme-white;
      }
    }
  }

  label.checkbox input {
    top: 1px;
  }

  .filter-results label.checkbox input {
    top: 5px;
  }

  .fs-select-mask.in-focus {
    @include theme-input-focus;
  }

  .form-row {
    label {
      @media only screen and (min-width: $screen-sm) {
        background: $theme-gray3;
      }
    }
    .inline-message .message {
      border: 2px solid $theme-black;
      color: $theme-black;
      &:before {
        border-top-color: $theme-black;
      }
    }
    .textarea:focus {
      background: $theme-white;
    }
  }

  .skipLink {
    background: #fff;
    position: absolute;
    left: -999em;
    z-index: 1000;

    &:focus {
      position: relative;
      left: 0em;
    }
  }

  .back-btn {
    background: $theme-black;
  }

  .brg_help a {
    text-decoration: underline;
  }

  .back-btn {
    min-width: 0;
  }

  .EmailListLabel {
    display: inline;
    font-size: 14px;
    text-align: left;
  }

  .AccessibilityLabel {
    display: block;
    font-size: 8px;
    text-align: start;
    font-family: HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, "Lucida Grande", sans-serif;
    line-height: 12px;
    letter-spacing: 3px;
    font-weight: bold;
    text-transform: uppercase;
  }

  #AccessibilityButton {
    height: 100%;
    padding: 0 0 30px 0;
    button {
      height: 100%;
    }
  }

  #closeModal {
    padding-top: 0px;
    border: none;
  }

  .terms-button {
    border: none;
    background: none;
    text-decoration: underline;
    text-align: inherit;
    padding: 0;
    margin: 15px 0 0;
    letter-spacing: 0.432px;
    min-width: unset;
    width: auto;

    &.trim {
      padding: 0px;
      max-width: 75%;
    }
  }
}

[dir="rtl"] .skipLink {
  left: auto;
  right: -999em;

  &:focus {
    left: auto;
    right: 0em;
  }
}
