.special-access-requests {
  overflow: hidden;
  padding: 10px 15px;

  h5 {
    font-family: $primary-font;
    font-size: 14px;
    color: $theme-black;
    line-height: 1.4;
    margin: 0 0 5px;
  }

  ul,
  li {
    list-style-type: disc;
    font-size: 12px;
    color: $theme-black;
  }
  ul {
    padding-left: 15px;
  }
}

@media only screen and (min-width: $screen-sm) {
  .special-access-requests {
    li {
      box-sizing: border-box;
      float: left;
      width: 33%;
    }
  }
}
