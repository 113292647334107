$margin: 10px;

.upgrade-option-block {
  background: #f6f6f6;
}

.upgrade-option-info {
  overflow: hidden;

  h2 {		
    margin: 0;
		line-height: 24px;
		text-align: left;
		font-size: .875em;
  }

  .upgrade-option-price {
    color: $theme-black;
    margin-top:15px;
  }
}

.rtl {
  .upgrade-option-info {
    [class*='col-']{
      float:right;
    }
  }
}

.upgrade-option-section {
  background: #fff;
  overflow: hidden;

  &.multiple-upgrade {
    background: #f6f6f6;

    .upgrade-option-info {
      background: #fff;
      border-radius: 10px;
      border: none;
      margin: 0px 10px 10px;
      overflow: hidden;

      h2 {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .upgrade-option-price {
        h2 {
          margin: 0px;
        }
      }
    }
  }

}

.upgrade-toggle-select {

  .btn-plain {
    background: $theme-black;
    border: none;
    color: #fff;
    border: 1px solid $theme-black;
    line-height: 25px;
    padding: 6px 0;
    width: 100%;
    margin-bottom: 15px;

    span {
      display: block;
    }

    .icon {
      display: none;
    }

    &.upgrade-accepted {
      background: #fff;
      color: $theme-black;

      span {
        display: none;
      }

      .icon {
        display: block;
        font-size: 34px;
        font-weight: 600;
        line-height: 27px;
      }
    }
  }
}

.upgrade-select {
  padding-bottom: 10px;
  width: 50%;
}

.upgrade-modal-select {
  padding-top: 15px;
  text-align: center;
  margin-bottom: 50px;

  button {
    margin-bottom: 15px;
  }
}

.upgrade-option-image {
  height: 180px;
}

.upgrade-option-inline {
  position: relative;

  .upgrade-option-info {
    margin: 0;

    h2 {
      padding-right: 20px;
    }
  }

  .icon-close {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    font-size: 30px;
    font-weight: 600;
    background-color: #dcdcdc!important;
    color: #fff;
    cursor: pointer;
    text-align: center;
  }
}
.rtl {
  .upgrade-option-inline {
    .upgrade-option-info {
      h2 {
        padding-left: 20px;
        padding-right: 0px;
      }
    }
    .icon-close {
      right: auto;
      left: 0;
      top: 0;
    }
  }
}

.upgrade-multi-select-section {
  background: #f6f6f6;
  padding: 15px 25px;
  text-align: center;

  .btn-upgrade-option {
    margin-bottom: 15px;
  }
}

.btn-upgrade-option {
  padding-left: 0;
  padding-right: 0;
  float: none !important;
  margin-top: 0 !important;
}

@media screen and (min-width: $screen-sm) {

  .upgrade-option-info {
    border: 1px solid $grey;

    h2 {
      margin: 0;
    }

    .upgrade-option-price {
      margin-top: 10px;
    }
  }

  .upgrade-option-section {
    margin: 10px 0 20px;

    &.multiple-upgrade {
      margin: 0;

      .upgrade-option-info {
        border: none;
        border-radius: 0;
        padding-top: 15px;

        h2 {
          margin: 0;
        }

        .upgrade-option-image {
          margin-bottom: 15px;
        }

        .upgrade-option-price {
          margin-top: 0;
        }
      }
    }
  }

  .upgrade-toggle-select {
    .btn-plain {
      width: 215px;
    }
  }

  .upgrade-multi-select-section {
    margin: 0 10px;
    padding: 20px 0 15px;

    .upgrade-multi-select {
      margin-left: 33.333%;
      width: 230px;
      padding-left: 15px;
    }
  }

}
