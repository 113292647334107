@use "sass:math";

$btn-inline-spacing: 8px;
$btn-inline-padding: ($btn-inline-spacing - 1) $btn-inline-spacing
  $btn-inline-spacing;
$btn-thin-line-height: 22px;
$btn-icon-size: 22px;
$btn-icon-margin: ($btn-inline-spacing + 2);

// Styles currently @extend-ed from fs-web-styleguide, in the future need updating to '%placeholder' or '@include mixin' when available.

.btn:focus {
  outline: 1px dotted #000;
  outline: 5px auto -webkit-focus-ring-color;
}

/* PRIMARY BUTTON */
.btn-primary,
.btn--primary {
  @extend .btn;
  @extend .btn, .btn-primary;
}

/* SECONDARY BUTTON */
.btn-secondary {
  @extend .btn;
  @extend .btn, .btn-secondary;
}

%btn-plain,
.btn-plain {
  @extend .btn;
  @extend .btn, .btn-secondary;
}

.btn-plain-primary {
  // Extends classes from the FS-styleguide dependency
  @extend .btn;
  @extend .btn, .btn-primary;
  width: auto;
  float: right;
}

@media only screen and (max-width: $screen-sm) {
  button.btn-plain-primary {
    width: auto;
  }
}

.btn-link {
  background: none;
  border: none;
  color: $primary-link-color;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: underline;
  width: auto;
  &:hover,
  &:active {
    background: none;
    border: none;
    // color: $primary-link-hover-color;
  }
}

.btn-group {
  overflow: hidden;
  margin: 0 0 12px;
  padding: 0 0 12px;
  font-size: ($secondary-font-size - 1px);
  display: flex;
  flex-direction: row;

  .btn {
    display: flex;
    background-color: $white;
    border-radius: 0;
    border: 1px solid $input-border-color;
    border-right: none;
    line-height: 16px;
    padding: 7px 8px 8px 8px;
    z-index: 1;
    width: math.div(100%, 3);
    text-align: center;
    justify-content: center;
    position: relative;

    &:hover {
      background-color: $light-grey;
    }

    &:before {
      display: none;
    }

    &:first-child {
      border-radius: $input-border-radius 0 0 $input-border-radius;
    }

    &:last-child {
      border-radius: 0 $input-border-radius $input-border-radius 0;
    }

    &.is-active {
      background-color: $dark-grey;
      border-color: $dark-grey;
      color: $white;
      z-index: 2;

      &:hover {
        background-color: $dark-grey;
      }

      &:after {
        @include triangle(
          $direction: top,
          $position: top 100% left 50%,
          $color: $dark-grey,
          $size: 12px
        );
      }
    }
  }
  .btn:last-child {
    border-right: solid 1px $input-border-color;
  }

  .btn.is-active + .btn {
    border-left: none;
  }
}

.rtl {
  .btn-group {
    [class*="col-"] {
      float: right;
    }

    .btn {
      &:first-child {
        border-radius: 0 $input-border-radius $input-border-radius 0;
      }

      &:last-child {
        border-radius: $input-border-radius 0 0 $input-border-radius;
      }
    }
  }
}

.btn-group__third {
  float: left;
  width: 33.3%;
}

.btn-group__half {
  float: left;
  width: 50%;
}

.btn-group__two-thirds {
  float: left;
  width: 66.6%;
}

.btn-stack {
  .btn {
    margin-bottom: 15px;
  }
  .btn:last-child {
    margin-bottom: 0;
  }
}

@media screen and (min-width: $screen-sm) {
  .btn-group {
    .btn {
      height: auto;
    }
  }
  .br {
    .btn-group {
      .btn {
        height: 49px;
      }
    }
  }
}

@media screen and (max-width: $screen-lg - 1) {
  .es,
  .ru {
    .btn-group.booking-form-context {
      .btn {
        height: 60px;
        padding: 7px 5px 8px;
      }
    }
  }
}

@media screen and (max-width: $screen-xs - 1) {
  .es,
  .ru,
  .br {
    .btn-group.booking-form-context {
      .btn {
        height: 90px;
        padding: 7px 5px 8px;
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .btn-group {
    font-size: ($primary-font-size - 1px);
  }
}

/* BUTTON DISPLAY LOGIC */

@media only screen and (min-width: $screen-sm) {
  a.btn.go-home {
    display: none;
  }

  .btn-group {
    button:first-child {
      border-radius: 3px 0 0 3px;
    }

    button:last-child {
      border-radius: 0 3px 3px 0;
    }
  }
}
