.horizontal-scrollview__container {
  position: relative;
}

.horizontal-scrollview {
  overflow: auto;
  padding: 0 15px;
  scroll-snap-type: x mandatory;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  max-width: min-content;
  width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &__item {
    scroll-snap-align: center;
  }

  &__side-button {
    appearance: none;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--left {
      left: 0;
      // background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.416663) 57.78%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(
        90deg,
        #ffffff 40%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &--right {
      right: 0;
      // background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0.416663) 57.78%, rgba(255, 255, 255, 0) 100%);
      background: linear-gradient(
        270deg,
        #ffffff 40%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    .icon-chevron-left,
    .icon-chevron-right {
      background: url("/images/profiles_2018/chevron_left.svg") 50% 50%
        no-repeat;
      background-size: contain;
      display: block;
      height: 15px;
      width: 15px;
    }

    .icon-chevron-right {
      background-image: url("/images/profiles_2018/chevron_right.svg");
    }
  }
}
