.profile-stay-preferences-view {
  .view--profile__your-preferences,
  .view--profile__your-interests {
    padding-top: 40px;
    padding-bottom: 40px;

    h2 {
      margin: 0 0 40px;
    }
  }
}

.profile-email-subscriptions {
  padding-top: 40px;
  padding-bottom: 40px;
}
