///////////////
//
// Panel Messages
//
//////////////

$panel-message-background-color: $white;
$panel-message-text-color: $new-dark-grey;

$panel-message-error-background-color: $new-error-color;
$panel-message-error-text-color: $white;

$panel-message-padding-h: $form-padding;
$panel-message-padding-v: $form-padding;
$panel-message-padding: $panel-message-padding-v $panel-message-padding-h;

$panel-message-desk-padding-h: $panel-body-content-padding--desk-h;
$panel-message-desk-padding-v: $panel-body-content-padding--desk-v;
$panel-message-desk-padding: $panel-message-desk-padding-v $panel-message-desk-padding-h;

$panel-message-icon-border-color: $new-dark-grey;
$panel-message-icon-size: 24px;
$panel-message-icon-font-size: 18px;

.panel-messages {
  overflow: hidden;
}

%panel-message,
.panel-message {
  display: block;
  position: relative;
  padding: $panel-message-padding;
  background-color: $panel-message-background-color;
  color: $panel-message-text-color;

  &.is-error {
    background-color: $panel-message-error-background-color;
    color: $panel-message-error-text-color;
  }

  .panel-body & {
    padding: $panel-message-desk-padding;
  }
}

.panel-message__icon--dismiss {
  @extend %icon;

  display: inline-block;

  margin: $panel-message-padding;
  padding: 0;

  position: absolute;
  bottom: 0;
  right: 0;
  top: 0;

  line-height: ($panel-message-icon-size - 1); // 1px alignment
  height: $panel-message-icon-size;
  width: $panel-message-icon-size;

  color: $panel-message-icon-border-color;
  border: 1px solid $panel-message-icon-border-color;
  border-radius: 50%;

  text-align: center;
  cursor: pointer;

  .is-error & {
    color: $panel-message-error-text-color;
    border-color: $panel-message-error-text-color;
  }

  .rtl & {
    right: auto;
    left: 0;
  }

  .panel-body & {
    margin: auto $panel-message-desk-padding-h;
  }
}

.panel-message__body {
  // leave room for the icon
  padding-right: ($panel-message-padding-h + $panel-message-icon-size);

  .rtl & {
    padding-right: ($panel-message-padding-h + $panel-message-icon-size);
    padding-left: 0;
  }
}

[class*="view-profile__employee"] {
  .panel-body {
    .panel-messages {
      display: none;
    }
  }
}

@media screen and (max-width: $screen-sm-max) {
  .panel-messages {
    border-bottom: $panel-border;
  }

  .panel-body {
    .panel-messages {
      margin-bottom: 20px;
    }
    .panel-message {
      padding: 15px;
    }
  }

}

@media screen and (min-width: $screen-sm) {
  [class*="view-profile__employee"] {
    .panel-body {
      .panel-messages {
        display: block;
      }
    }
  }

  .panel-nav {
    .panel-messages {
      display: none;
    }
  }
}
