.fsp-input--typeahead {
  $option-height: 48px;

  &__options-wrapper {
    position: relative;
  }

  &__options {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    max-height: 4 * $option-height;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__option {
    background: $gray4;
    color: $gray5;
    cursor: pointer;
    padding: 12px 15px;
    text-align: left;
    height: $option-height;

    strong {
      color: #000;
      font-weight: inherit;
    }

    &--selected,
    &:hover,
    &:focus,
    &--focused {
      background: $gray3;
    }
  }
}

.rtl {
  .fsp-input--typeahead {
    &__option {
      text-align: right;
    }
  }
}
