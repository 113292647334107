.fs-social-signin-button,
.gplus-provider,
.linkedin-provider {
  background: #fff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  display: inline-block;

  font-family: $hn;
  font-weight: 250;
  font-size: rem(14px, 16px);
  height: 40px;
  line-height: 40px;
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.22px;
  text-align: center;
  position: relative;
  width: 100%;
  padding: 0 45px;

  &:hover {
    background-color: #eee;
  }

  &:after {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    position: absolute;
    height: 18px;
    width: 18px;
    top: (40px - 18px) * 0.5;
    left: (40px - 18px) * 0.5;
    background-image: url("/images/profiles_2018/Google__G__Logo.svg");
  }
}

.linkedin-provider:after {
  background-image: url("/images/profiles_2018/In-2C-128px-R.png");
}
