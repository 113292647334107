.room-tab-section {
  display: block;
}

.info-block {
  border-top: 1px solid $light-grey;
  list-style: none;
  padding: 0;
  overflow: hidden;

  &:first-child {
    border-top: none;
  }
}

@media only screen and (min-width: $screen-sm) {
  .room-tab-section {
    display: inline-block;
  }
}
