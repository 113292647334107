.search-summary.room-summary {
  margin-top: 0;
  padding-top: 15px;
}

@media only screen and (min-width: $screen-sm) {
  .search-summary.room-summary {
    .container {
      padding-bottom: 5px;
    }
  }
  .multiple-rooms.search-summary.room-summary {
    margin-top: 0;
    padding-top: 15px;
  }
}
