.my-stay__category-tabs {
  display: none;
}

.my-stay__category-desc,
.my-stay__subcategory-desc {
  text-align: center;
  margin-bottom: 60px;
}


.my-stay_subcategories-wrapper-title {
  margin-top: 30px;
}

// desktop
@media screen and (min-width: $screen-sm) {
  .my-stay__category-tabs {
    display: block;
  }

  .my-stay_subcategories-wrapper {
    padding: 0;
  }
}
