.your-stay-perfected {
  background: #242424;
  color: #fff;
  text-align: center;
  padding: 50px 0 60px;
  overflow: hidden;

  &__container {
    position: relative;
    margin: 0 auto;

    @media screen and (min-width: $breakpoints-lg) {
      max-width: 930px;
    }
  }

  @media screen and (min-width: $breakpoints-lg) {
    text-align: left;
    padding-top: 174px;
    padding-bottom: 174px;
  }

  &__content {
    padding: 0 22px;

    @media screen and (min-width: $breakpoints-lg) {
      padding: 0;
      width: 360px;
    }
  }

  h3 {
    font-family: $hn-neue;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.29;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: $white;

    margin-bottom: 20px;
  }

  p {
    font-family: $garamond-mt-std;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.56;
    margin: 0 0 46px;
  }

  &__image {
    background-image: url("/images/profiles_2018/your-stay-perfected.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    height: 200px;
    margin: 0 0 36px;

    @media screen and (min-width: $breakpoints-lg) {
      height: 450px;
      width: 750px;
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-20px, -50%);
    }
  }
}

.rtl {
  .your-stay-perfected {
    @media screen and (min-width: $breakpoints-md) {
      text-align: initial;
    }

    h3 {
      @media screen and (min-width: $breakpoints-md) {
        margin-left: auto;
        margin-right: 0;
      }
    }

    &__image {
      left: unset;
      right: 50%;

      @media screen and (min-width: $breakpoints-md) {
        margin-right: -84px;
      }

      @media screen and (min-width: $breakpoints-md) {
        margin-right: -174px;
      }
    }
  }
}
