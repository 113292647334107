.fs-search-panel {
  margin: 12px 0 12px 0;
  padding: $page-padding;

  .phone {
    display: inline;
  }

  label {
    background: none;
    border: none;
    display: inline-block;
    font-weight: 200;
    line-height: inherit;
    margin: 0 0 5px;
    padding: 0;
    position: relative;
    text-align: center;
    width: 99%;
  }

  .datepicker {
    display: flex;

    div {
      display: block;

      &.fs-select-date {
        box-sizing: border-box;
        width: 50%;
      }
    }

    input[type="text"][readOnly] {
      cursor: text;
      background: white;
    }
  }

  a {
    display: block;
    width: 100%;
    text-align: center;
  }

  .form-row {
    border: none;
    padding: 0;
  }

  .form-row input {
    border: 1px solid $input-border-color;
  }

  .promo-code {
    margin: 0 0 20px 0;
    a {
      display: none;
    }
  }

  input:last-child,
  select:last-child {
    margin-bottom: 0;
  }

	.outage-error {
		p {
			text-align: center;
		}

		.btn-primary {
			float: none;
		}
	}

  .outage-contact {
    text-align: center;
    padding-bottom: 20px;

    a {
      display: inline;
    }

    a.btn {
      display: inline-block;
    }

    span {
      padding: 0 10px;
    }

    span + span {
      border-left: solid 1px;
    }
  }
}

.fs-search-panel.bl-external-1 {
  div.fs-select-date {
    display: none;
  }

  div.nights-selected-external {
    padding-bottom: 10px;
    font-size: 12px;
  }

	.multiple-rooms {
		float: left;
    a {
      color: #ee4023;
	    text-decoration: none;
	    text-align: left;
    }
	}

  .promo-code {
		.separator {
			float: left;
		}

    a {
      display: block;
      color: #ee4023;
	    text-decoration: none;
	    text-align: left;
      margin-bottom: 15px;
    }

    input {
	    width: 60%;
	    margin-left: 20%;
    }
  }

  .form-row {
    margin-bottom: -20px;
  }

  form button.btn-primary,
  form button.btn-primary:active,
  form button.btn-primary:hover {
  	background-color: #ee4023;
  }
}


@media only screen and (min-width: $screen-sm) and (max-width: $screen-md - 1px) {
  .br .fs-search-panel button[type="submit"] {
    padding-left: 25px;
    padding-right: 25px;
  }
}
