//
// "Contact Us" Callout
//

$callout-color: $panel-color;
$callout-background: $white;
$callout-border-color: $panel-border-color;
$callout-border: 1px solid $callout-border-color;
$callout-padding: 25px;
$callout-text-padding: 10px;
$callout-text-size: 15px;
$callout-desktop-modal-text-size: 14px;
$callout-number-size: 15px;
$callout-button-text-size: 13px;

$callout-icon-rotation: 90deg;

.contact-callout {
  @extend %panel-body__callout;

  box-sizing: border-box;
  position: relative;
  display: block;
  background-color: $callout-background;
  border-bottom: $callout-border;
  padding: $callout-padding;
  margin: 0 auto $callout-padding;
  color: $primary-text-color;
  overflow: hidden; // clearfix
}

.contact-callout__text {
  padding: 0;
  margin: 0;
  font-size: $callout-text-size;
  line-height: 1.6;
}

.contact-callout__btn-icon {
  -webkit-transform: rotate($callout-icon-rotation);
  -ms-transform: rotate($callout-icon-rotation);
  transform: rotate($callout-icon-rotation);
  display: inline-block;
}

.contact-callout__btn-text {
  font-size: $callout-button-text-size;
  text-transform: uppercase;
}

.contact-callout__btn-number {
  font-size: $callout-number-size;
}


// mobile only
@media screen and (max-width: $screen-sm-max) {
  .contact-callout__btn-col {
    padding-top: $callout-padding;
  }
}

@media screen and (min-width: $screen-sm) {
  .contact-callout {
    border: $callout-border;
  }

  .modal-message {
    .contact-callout__btn-col {
      padding-top: $callout-padding;
    }
  }

  .contact-callout__text-col,
  .contact-callout__btn-col {
    display: table-cell;
    vertical-align: middle;
    float: none;

    .modal-message & {
      width: 100%;
      display: block;
    }
  }

  .contact-callout__text {
    padding: $callout-text-padding 0;

    .modal-message & {
      padding-top: 0;
      padding-bottom: 0;
      font-size: $callout-desktop-modal-text-size;
    }
  }
}

