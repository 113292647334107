//
// Offer Items
//

$offer-items-margin: 0 (-$form-padding);
$offer-items-margin-desk: 0 auto;
$offer-item-padding: $page-padding;
$offer-item-border: $detail-item-border;

$offer-item-font-size: 10px;
$offer-item-line-height: 20px;

$offer-item-price-width: 8em;
$offer-item-font: "Helvetica Neue LT W01_71488914";

.offer-items {
  @extend %detail-items;

  margin: $offer-items-margin;
}

.offer-items--text p {
  font-size: 16px;
}

.offer-items__heading {
  margin-bottom: 15px;
  font-family: $offer-item-font;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.offer-items__subheading {
  text-align: center;
}

.offer-item {
  @extend %detail-item;
  color: $black;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: $offer-item-padding;
}

.offer-item__static {
  padding: $offer-item-padding;
}

.offer-item__wrap {
  display: table;
  width: 100%;
  vertical-align: middle;

}

.offer-item label {
  font-size: $offer-item-font-size;
}

.offer-item__body {
  font-family: $offer-item-font;
  line-height: $offer-item-line-height;
  display: block;
  max-width: 90%;
}

.offer-item__price {
  text-align: right;

  white-space: nowrap;

  // checkbox label
  label {
    &:before {
      font-weight: initial;
    }
  }
}

.offer-item__static_price {
  line-height: 27px;
  font-size: 10px;
}

[class*="my-stay"] {
  .offer-item__body {
    letter-spacing: 2px;
    font-size: 10px;
    text-transform: uppercase;
    color: #000;
  }
}

@media screen and (min-width: $screen-sm) {
  .offer-items {
    margin: $offer-items-margin-desk;
  }
}
