.fs-profile-edit-arrival-time {
  &-msg {
    font-family: $hn;
    font-weight: 250;
    font-size: rem(20px);
    line-height: 1.6;
    margin: 0 0 30px;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2d2d2d;
    display: inline-block;

    @media screen and (min-width: $breakpoints-md) {
      font-size: rem(18px);
      line-height: 1.78;
      letter-spacing: 0.8px;
      text-align: left;
      width: 100%;
    }

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(26px);
      line-height: 1.54;
      letter-spacing: 1px;
    }
  }

  &-form {
    display: flex;
    flex-direction: column;

    .arrival-time {
      flex-grow: 1;
    }

    @media screen and (min-width: $breakpoints-lg) {
      flex-direction: row;
      justify-content: space-between;
    }

    .arrival-time-selects {
      flex-grow: 1;

      @media screen and (min-width: $breakpoints-lg) {
        margin-right: 15px;

        > * {
          flex-basis: 33.33%;
          margin-left: 5px;
          margin-right: 5px;
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }

    .Dropdown {
      flex-grow: 1;
      padding-bottom: 20px;

      @media screen and (min-width: $breakpoints-lg) {
        padding-bottom: 0;
      }
    }

    fs-button {
      margin: auto 0 0 0;
      text-align: center;

      @media screen and (min-width: $breakpoints-md) {
        text-align: right;
      }
    }
  }

  .fs-profile-transportation-time-cta button {
    margin-bottom: 30px;
  }
}

.arrival-time-selects {
  display: flex;
  flex-direction: row;
  margin-left: -5px;
  margin-right: -5px;

  > * {
    flex-basis: 33.33%;
    margin-left: 5px;
    margin-right: 5px;
  }
}
