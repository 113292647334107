@use "sass:math";

$image-width: 260px;
$big-image-width: 300px;

.package-image {
  display: none;
}

.available-packages {
  .package {
    &--isSelected {
      margin-bottom: 0;
      border-top: solid 1px $black;
      border-left: solid 1px $black;
      border-right: solid 1px $black;
    }
    &--disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .choose-package {
    font-size: 2em;
  }

  .room-item.room-item-selected {
    border: 0;
    .room-item-preview {
      background: #ffffff;
    }
  }

  .available-packages {
    border: 0;

    h1 {
      display: none;
    }

    .package {
      padding: 0;
      border: solid 1px $light-grey;
      margin-bottom: 20px;

      flex-direction: row;

      .package-image {
        background: url("/images/package_ph_img.png");
        background-size: cover;
        display: block;
        width: $image-width;
        max-height: floor(9 * math.div($image-width, 16));
        overflow: hidden;
        flex-grow: 0;
        flex-shrink: 0;

        img {
          display: block;
          width: 100%;
        }
      }

      .package-description {
        @include clearfix;
        position: relative;
        padding: 10px 15px;
        display: block;
      }
      .package-book-now {
        width: auto;
      }

      &--isSelected {
        margin-bottom: 0;
        border-top: solid 1px $black;
        border-left: solid 1px $black;
        border-right: solid 1px $black;
      }
      &--disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }

  .room-item-tab-container .packages {
    border: none;
  }
}

@media only screen and (min-width: $screen-md) {
  .available-packages {
    .available-package {
      .package-image {
        width: $big-image-width;
        max-height: floor(9 * math.div($big-image-width, 16));
      }
    }
  }
}
