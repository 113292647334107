.my-stay__category-tabs {
  background: #fff;
  border: 1px solid $new-light-grey;
  padding: 0;
  margin: 10px 0 60px;
  list-style: none;
  overflow: hidden;
}

.my-stay__category-tabs--tab {
  float: left;
  width: 25%;

  &:last-child {
    border: none;
  }

  a {
    color: $black;
    display: block;
    padding: 20px 15px;
    position: relative;
    text-decoration: none;
    text-align: center;
    font-family: $my-stay-header-font;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
  }

  a.is-selected,
  a:hover {
    color: initial;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -1px;
      right: -1px;
      border-bottom: 3px solid $black;
    }
  }
}
