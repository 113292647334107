$hero-body-font-size: 16px;
$hero-body-padding: 28px;
$hero-body-padding--desk: $hero-padding;

$hero-body-border: $panel-border;

$hero-body-header-bg-color: $white;
$hero-body-title-color: $new-dark-grey;
$hero-body-subtitle-color: $new-medium-grey;

.hero-body {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: none;

  background: $panel-background;
  color: $primary-text-color;

  padding: $hero-body-padding;
  margin-right: auto;
  margin-left: auto;

  font-size: $hero-body-font-size;

  //clearfix
  &:after {
    content:"";
    display:block;
    clear:both;
  }

  // ugly overrides
  h1, h2, h3, h4 {
    margin-top: 0;

    font-family: $primary-font;
    font-weight: $primary-font-weight;
  }

  // container reset
  .container {
    max-width: 100%;
    width: auto;
    padding: 0;
  }
}

.hero-body__header {
  margin: -($hero-body-padding + 1);
  margin-bottom: ($hero-body-padding + 1);
  background: $hero-body-header-bg-color;
  padding: $hero-body-padding;
  border: 1px solid $hero-body-border;

  h3 {
    font-size: 18px;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    font-size: 12px;
    margin: 4px 0;
  }
}

.hero-body--error,
.hero-body__error {
  padding: 0;

  .error {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// overcome the parent hero-body spacing
.hero-body__error {
  margin: -($hero-body-padding + 1);
  margin-bottom: ($hero-body-padding + 1);
}

.hero-body__spacer,
.hero-body__spacer--sm-only {
  padding: 0 0 $hero-body-padding;
  margin: 0;
  height: 0;
  outline: none;
  border: none;
}

.hero-body__spacer {
  display: block;
}

.hero-body__spacer--xs-only,
.hero-body__spacer--sm-only {
  display: none;
}

%hero-body-title,
.hero-body--title,
.hero-body__title {
  margin: 0 auto;
  padding-top: 10px;
  padding-bottom: 10px;

  color: $hero-body-title-color;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
}

// outside the main body
.hero-body--title {
  padding-top: $hero-body-padding;
  padding-bottom: $hero-body-padding;
}

%hero-body-subtitle,
.hero-body__subtitle {
  margin: 0 auto;
  padding: 2px 0;
  color: $hero-body-subtitle-color;
  letter-spacing: 0.1em;
  text-align: center;
  font-size: 0.9em;
}

.hero-body__feature {
  margin: auto;
  text-align: center;
  font-size: 1.1em;
  letter-spacing: 0.05em;
}

.hero-body__sub-content {
  font-size: 0.8em;
}

.hero-body__image {
  display: block;
  max-width: 100%;
}

.hero-body__number {
  color: #a7a7a7;
  font-size: 0.8em;
  padding-bottom: 7px;
  text-transform: uppercase;
  letter-spacing: 0.15em;
}

.hero-body__inline-images {
  display: block;
  margin: 0;

  a {
    display: inline-block;
    text-decoration: none;
  }

  img {
    display: inline-block;
    padding: 0;
    margin: 0;
  }
}

.hero-body__list {
  margin: 0;
  padding: 10px 17px;

  li {
    margin: 0;
    padding: 2px 0;
  }
}

.hero-body__heading {
  text-transform: uppercase;
}

.hero-body__p {
  margin-bottom: 0;
}

// mobile only
@media screen and (max-width: $screen-sm-max) {
  .hero-body__spacer--xs-only {
    display: block;
  }
}
// desktop
@media screen and (min-width: $screen-sm) {
  .hero-body {
    border: $hero-body-border;
    margin-top: -$hero-body-padding--desk * 2.5;
    padding: $hero-body-padding--desk;
  }

  .hero-body--error,
  .hero-body__error {
    .error-contact-flush {
      padding-right: $hero-body-padding--desk;
      padding-left: $hero-body-padding--desk;
    }

    .error h2 {
      padding-right: $hero-body-padding--desk;
      padding-left: $hero-body-padding--desk;
      margin-right: -$hero-body-padding--desk;
      margin-left: -$hero-body-padding--desk;
    }
  }

  .hero-body--error {
    padding: 0;
  }
  // overcome the parent hero-body spacing
  .hero-body__error {
    margin: -($hero-body-padding--desk + 1);
    margin-bottom: ($hero-body-padding--desk + 1);
  }

  .hero-body__header {
    margin: -($hero-body-padding--desk + 1);
    margin-bottom: ($hero-body-padding--desk + 1);
  }

  .hero-body__spacer,
  .hero-body__spacer--sm-only {
    padding-bottom: $hero-body-padding--desk;
  }

  .hero-body__spacer--sm-only {
    display: block;
  }

  .hero-body--title {
    padding-top: $hero-body-padding--desk;
    padding-bottom: $hero-body-padding--desk;
  }

  .hero-body__title--sm-no-pad {
    padding-top: 0;
    padding-bottom: 0;
  }

  .hero-body__absolute-left--sm {
    position: absolute;
    left: $hero-body-padding--desk;
    top: $hero-body-padding--desk;
  }

  .hero-body__absolute-right--sm {
    position: absolute;
    right: $hero-body-padding--desk;
    top: $hero-body-padding--desk;
  }
}
