.layout--profile-fullscreen {
  background: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &--light {
    background: #fff;
    color: #000;
  }

  .btn-back {
    background-image: url('/images/profiles_2018/back-arrow--white.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 17px;
    width: 31px;

    span {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }
  }

  &--light {
    .btn-back {
      background-image: url('/images/profiles_2018/back-arrow--black.svg');
    }
  }

  &__top-bar {
    .col-sm-12 {
      padding-top: 5px;
      padding-bottom: 5px;
      height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media screen and (min-width: $breakpoints-md) {
        height: 120px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        height: 163px;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    color: inherit;
  }
}

.rtl .btn-back {
  transform: rotate(180deg);
}
