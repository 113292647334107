.room-item .perk-prompt {
  width: 100%;
  text-align: center;
  background-color: #fff;
  border-top: 1px solid #dcdcdc;
  padding: 10px 0;
}

.perk-terms {
  padding: 0 16px;
  text-align: left;
}
