.otp-sign-in-launch-block {
  font-size: 18px;
  background: #f5f5f5;
  padding: 40px;
  max-width: 600px;
  margin: 40px auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $screen-sm) {
    flex-direction: row;
    justify-content: space-between;

    margin-top: 0;
    margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  p {
    margin: 0 0 30px;
    font-size: 0.66666em;

    @media screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }
  }

  button {
    font-size: 0.55555em;

    @media screen and (min-width: $screen-sm) {
      margin-left: 20px;
      width: auto;
    }
  }
}
