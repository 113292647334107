// Button
//
// - Button classes can be used with button (`<button>`) and link elements (`<a>`)
// - Buttons can be disabled by applying a `disabled` attribute to a `<button>` and `<input type="submit">` tag or by applying a `.disabled` class to an `<a>` link.
//
// markup:
// <button class="btn btn-primary">Default &lt;button&gt;</button>
// <a href="#" class="btn btn-primary">Default &lt;a&gt;</a>
// <input type="submit" class="btn btn-primary" value="Default &lt;input&gt; submit">
// <br>
// <button class="btn btn-primary" disabled>Disabled &lt;button&gt;</button>
// <a href="#" class="btn btn-primary disabled">Disabled &lt;a&gt;</a>
// <input type="submit" class="btn btn-primary" disabled value="Disabled submit &lt;input&gt;">
//
// Styleguide 3.1.0

$btn-letter-spacing: 0.12em;

.btn {
  display: block;
  width: 100%;
  font-family: $base-font-family-sans;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
  letter-spacing: $btn-letter-spacing;
  text-align: center;
  text-transform: uppercase;
  padding: $measure-mobile-normal $measure-mobile-xlarge ($measure-mobile-normal - em(2px));
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
  font-size: $font-size-small;

  @include media($tablet) {
    display: inline-block;
    width: auto;
    padding: $measure-desktop-small $measure-desktop-large ($measure-desktop-small - em(2px));
  }

  &:focus {
    outline:0;
  }

  &:active {
    transform: scale(0.99);
  }

  &:disabled,
  &.disabled{
    cursor: not-allowed;
    transform: scale(1);
  }
}

// Primary button
//
// Primary buttons are used for the most prominent call to actions in a view, they should be used sparingly. If there is the need for more than a few instances on a page &mdash; like for &quot;View offer&quot; buttons under each offer on an offer page &mdash; use the Secondary button style.
//
// markup:
// <button class="btn btn-primary">Find rooms</button><br/>
// <button class="btn btn-primary" disabled>Disabled state</button></p>
//
// Styleguide 3.2.0

$btn-color-primary-text: $white;
$btn-color-primary: $teal;
$btn-color-primary-hover: $teal-light;
$btn-color-primary-active: $teal;
$btn-color-primary-disabled: $teal-lighter;

.btn.btn-primary {
  background: $btn-color-primary;
  border: none;
  color: $btn-color-primary-text;

  &:hover {
    background: $btn-color-primary-hover;
  }

  &:active {
    background: $btn-color-primary-active;
  }

  &:disabled,
  &.disabled{
    background: $btn-color-primary-disabled;
  }
}

// Secondary button
//
// markup:
// <p><button class="btn btn-secondary">Cancel</button><br/>
// <button class="btn btn-secondary" disabled>Disabled state</button></p>
//
// Styleguide 3.3.0

$btn-color-secondary-text: $teal;
$btn-color-secondary: $white;
$btn-color-secondary-hover: $teal-light;
$btn-color-secondary-active: $teal;
$btn-color-secondary-disabled: $teal-lighter;

.btn.btn-secondary {
  background: $btn-color-secondary;
  border: solid 1px $btn-color-secondary-text;
  color: $btn-color-secondary-text;

  &:hover {
    background: mix($gray-light, $white, 50%);
    border: solid 1px $btn-color-secondary-hover;
    color: $btn-color-secondary-hover;
  }

  &:active {
    border: solid 1px $btn-color-secondary-active;
    color: $btn-color-secondary-active;
  }

  &:disabled,
  &.disabled  {
    border: solid 1px $btn-color-secondary-disabled;
    color: $btn-color-secondary-disabled;
    background: $btn-color-secondary;
  }
}

// Overlay button
//
// Overlay buttons are for using over the top of images.
//
// markup:
// <p><span style="max-width: 400px; display: flex; align-items: center; justify-content: center; background: url(http://lorempixel.com/375/210/nature) no-repeat; background-size: cover; padding: 2em 0 1em">
//   <button class="btn btn-overlay">Overlay Button</button>
// </span></p>
//
// Styleguide 3.4.0

$btn-color-overlay-text: $white;
$btn-color-overlay: $gray-black;
$btn-color-overlay-hover: $gray-black;
$btn-color-overlay-active: $gray-black;
$btn-color-overlay-disabled: $teal-lighter;
$btn-overlay-opacity: 0.6;

.btn.btn-overlay {
  background-color: rgba($btn-color-overlay, $btn-overlay-opacity);
  border: solid 1px $btn-color-overlay-text;
  color: $btn-color-overlay-text;

  &:hover {
    background-color: rgba($btn-color-overlay, 0.8);
    border: solid 1px $btn-color-overlay-text;
    color: $btn-color-overlay-text;
  }

  &:active {
    background: $btn-color-overlay-active;
    border: solid 1px $btn-color-overlay-text;
    color: $btn-color-overlay-text;
  }

  &:disabled,
  &.disabled  {
    border: solid 1px $btn-color-overlay-disabled;
    color: $btn-color-overlay-disabled;
  }
}

// Small button
//
// Small buttons are specifically designed for use inline and in compact locations, for example, in list items.
//
// markup:
// <p><button class="btn btn-primary btn-small">Find rooms</button><br/>
// <button class="btn btn-secondary btn-small">Cancel</button>
//
// Styleguide 3.5.0

.btn.btn-small {
  padding: $measure-mobile-xsmall $measure-mobile-small $measure-mobile-xxsmall $measure-mobile-small;
  font-size: $font-size-xsmall;
}
