@use "sass:math";

$date-font-size: $secondary-font-size;
$datepicker-header-height: $calendar-header-height;
$datepicker-header-button-size: $calendar-header-button-size;
$datepicker-cell-size: $calendar-cell-size;
$calendar-offset: 10px;
$indicator-offset: 20%;

.fs-calendar-wrapper {
  clear: both;
  position: relative;
}

.fs-search-panel.bl-external-1 {
  .fs-calendar-wrapper {
    &.checkIn:before,
    &.checkOut:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      z-index: $z_calendarArrow;
      bottom: -$calendar-offset;
      border-left: $calendar-offset solid transparent;
      border-right: $calendar-offset solid transparent;
      border-bottom: $calendar-offset solid $calendar-header-color;
    }

    &.checkIn:before {
      left: $indicator-offset;
    }

    &.checkOut:before {
      right: $indicator-offset;
    }
  }

  .fs-calendar {
    $date-font-size: 12px !global;
    $datepicker-header-height: 28px !global;
    $datepicker-header-button-size: 30px !global;
    $datepicker-cell-size: 30px !global;

    position: absolute;
    z-index: 21;
    top: 10px;
    left: 0;
    right: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  }
}

.fs-calendar {
  background-color: $white;
  border-radius: 4px;
  $nights-selected-offset: 100px;

  .ui-datepicker-today {
    background-color: lighten($grey, 5%);
  }

  .datepicker-footer {
    border-radius: 0 0 4px 4px;
    border: 1px solid $light-grey;
    border-top: none;
    overflow: hidden;
    font-size: $date-font-size;
  }

  .ui-datepicker-calendar {
    border: 1px solid $light-grey;
    border-top: none;
    width: 100%;
  }

  .ui-datepicker a {
    text-decoration: none;
  }

  .nights-selected {
    box-sizing: border-box;
    border-right: solid 1px $light-grey;
    float: left;
    overflow: hidden;
    padding: 10px;
    width: $nights-selected-offset;

    span {
      font-size: 25px;
      display: block;
      line-height: 1;
      margin: 0 0 2px;
    }
  }

  .legend {
    border-left: solid 1px $light-grey;
    box-sizing: border-box;
    list-style: none;
    padding: 10px 10px 5px;
    margin: 0 0 0 ($nights-selected-offset - 1px);

    li {
      display: block;
      line-height: 14px;
      margin: 0 0 5px;
      max-width: 180px;
      padding: 0 20px 0 0;
      position: relative;
    }

    li.updated-at {
      padding-right: 0;
      font-size: $date-font-size;
      max-width: none;
    }

    .is-restricted {
      border: 1px solid $primary-btn-top-color;
    }

    .is-unavailable,
    .is-restricted {
      box-sizing: border-box;
      position: absolute;
      top: 0;
      right: 0;
      width: 14px;
      height: 14px;
    }
  }

  .ui-datepicker-header {
    height: $datepicker-header-height;
    background-color: $theme-black;
    color: $white;
    position: relative;
  }

  .ui-datepicker-group {
    border: 1px solid #ccc;
    display: inline-block;
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    display: inline;
    color: $white;
    cursor: pointer;
    font-weight: 100;
    line-height: $datepicker-header-height + 2px;
    font-size: 24px;
    background: none;
    position: absolute;
    top: 0;
    bottom: 0;
    width: $datepicker-header-button-size;
    text-align: center;

    &.ui-state-disabled {
      display: none;
    }

    &:active {
      transform: scale(0.95);
    }

    &:focus {
      color: $theme-gray1-1;
      text-decoration: none;
    }
  }

  .ui-datepicker-next {
    right: 0;
  }

  .ui-datepicker-prev {
    left: 0;
  }

  .ui-datepicker-title {
    text-align: center;
    line-height: $datepicker-header-height;
    margin: auto;
    height: $datepicker-header-height;
    font-size: 18px;
  }

  a {
    color: $theme-black;
  }

  .ui-state-disabled {
    color: #999;
  }

  .date-range-selected {
    background-color: $theme-black;

    .ui-state-default {
      color: $white;
    }
  }

  td {
    width: math.div(100%, 7);

    .ui-state-default {
      height: $datepicker-cell-size;
      line-height: $datepicker-cell-size;
      display: block;
      text-align: center;
      border: solid 1px transparent;
      cursor: not-allowed;
      outline: none;
    }

    &:not(.ui-datepicker-unselectable) {
      .ui-state-default {
        cursor: pointer;
        &:focus,
        &:hover {
          background: $theme-white;
          color: $theme-black;
          border: 1px solid $theme-black;
          outline: none;
        }
      }
    }
  }

  th {
    height: $datepicker-cell-size;
    line-height: $datepicker-cell-size;
    font-weight: normal;
  }

  thead {
    border-bottom: 1px solid $light-grey;
  }

  .is-unavailable:not(.ui-state-disabled) {
    background-color: #ccc;
  }
  .is-unavailable.date-range-selected {
    border: 1px solid #ff8313;
  }

  .is-restricted {
    border: 1px solid $primary-btn-top-color;
  }

  .cached-availability {
    border: 1px solid #999;
  }
}
.rtl {
  .fs-calendar {
    .ui-datepicker-next {
      right: auto;
      left: 0;
    }

    .ui-datepicker-prev {
      left: auto;
      right: 0;
    }
  }
}

.fr {
  .fs-calendar {
    $nights-selected-offset: 100px;
    .legend {
      margin-left: $nights-selected-offset - 1;
    }
    .nights-selected {
      width: $nights-selected-offset;
    }
  }
}

.desktop {
  .fs-search-panel {
    @import "calendar-desktop";
  }
}

.datepicker-container {
  display: flex;
}
