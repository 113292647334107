.flex-dates-date-selection {
  color: #000;
  margin: 0 -15px 20px;

  @media only screen and (min-width: $screen-sm) {
    margin: 0 0 30px;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    padding: 0 15px;

    @media only screen and (min-width: $screen-sm) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;
    }
  }

  &__title {
    color: inherit;
    margin: 0 0 6px;

    @media only screen and (min-width: $screen-sm) {
      margin-bottom: 0;
    }
  }

  &__lowest-rate-available {
    color: inherit;
    margin: 0;
    font-style: italic;
    font-weight: normal;
    font-size: 10px;
    line-height: 1.3;
    letter-spacing: 1px;
  }

  &__row-container {
    height: 166px;
    overflow: hidden;
    position: relative;
  }

  &__row {
    $hide-scrollbar-padding: 100px;

    display: flex;
    flex-direction: row;
    height: 166px + $hide-scrollbar-padding; // to hide scrollbar
    overflow: scroll;
    padding: 20px 0 $hide-scrollbar-padding;

    @media only screen and (min-width: $screen-sm) {
      overflow: hidden;
    }

    &__row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      padding: 0 calc(50% - 75px);
      margin: 0 -5px;
      @media only screen and (min-width: $screen-sm) {
        margin: 0 -12px;
      }
    }
  }

  &__flex-date {
    background: #fff;
    border: 1px solid #d8d8d8 !important;
    min-width: auto !important;
    width: 150px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    padding: 0 !important;
    position: relative;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 1.3;
    letter-spacing: 3px !important;
    text-transform: uppercase;

    flex-grow: 0;
    flex-shrink: 0;

    &:before {
      background: rgba(125, 125, 125, 0.5);
      content: "";
      display: block;
      height: 1px;
      width: 74px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -1px 0 0 -37px;
    }

    @media only screen and (min-width: $screen-sm) {
      margin: 0 12px;
    }

    &__best-rate {
      background: inherit;
      border: 1px solid #d8d8d8;
      font-size: 8px;
      position: absolute;
      top: -12px;
      left: 50%;
      height: 24px;
      transform: translateX(-50%);
      padding: 0 14px;
      white-space: nowrap;
      display: flex;
      align-items: center;
    }

    &__date,
    &__cost {
      height: 50%;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0;
      word-break: break-all;
    }

    &__date {
      font-size: 10px;
      font-weight: normal;
      margin: 0 0 10px;
      justify-content: flex-end;
    }

    &__cost {
      font-size: 12px;
      line-height: 1.1;
      margin: 10px 0 0;
      justify-content: flex-start;
    }

    &--is-selected {
      background: #2d2d2d;
      border-color: #2d2d2d !important;
      color: #fff;
      height: 146px;

      &:after {
        content: "";
        display: block;
        background: transparent;
        background-image: url("/images/profiles_2018/down_arrow.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        height: 16px;
        width: 16px;
        position: absolute;
        bottom: 16px;
        left: 50%;
        margin-left: -8px;
      }

      .flex-dates-date-selection__flex-date__date {
        font-weight: bold;
      }

      .flex-dates-date-selection__flex-date__best-rate {
        border-color: #fff;
      }
    }

    &--is-disabled {
      background: #d8d8d8;
      border-color: #d8d8d8 !important;
      color: #595959;
      cursor: not-allowed;

      &:before {
        background: #c5c5c5;
      }

      .flex-dates-date-selection__flex-date__cost {
        font-size: 10px;
      }
    }
  }

  &__button-container {
    background: #fff;
    box-shadow: 0 0 20px 22px #fff;
    position: absolute;
    top: -40px;
    bottom: -40px;
    z-index: 20;
    width: 44px;
    display: none;

    @media only screen and (min-width: $screen-sm) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &--previous {
      left: 0;
      align-items: flex-start;
    }

    &--next {
      right: 0;
      align-items: flex-end;
    }
  }

  &__button {
    background: #222222;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    border: 1px solid #7d7d7d;
    border-radius: 1000px;
    color: #fff;
    fill: #fff;
    min-width: auto !important;
    max-width: none !important;
    width: 34px !important;
    height: 34px !important;
    margin: 0 !important;
    padding: 0 !important;
    min-height: auto !important;

    &--previous {
      background-position: 9px 50%;
      background-image: url("/images/profiles_2018/chevron_left--white.svg");
    }

    &--next {
      background-position: 11px 50%;
      background-image: url("/images/profiles_2018/chevron_right--white.svg");
    }
  }
}
