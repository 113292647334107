.footer-inner {
  background-color: $mobile-footer-bg-color;
  text-align: center;

  dt {
    font-family: $primary-font;

    &:after {
      content: "|";
      position: relative;
      left: 10px;
    }
  }

  dl:last-child dt {
    padding: 0 0 8px 0;

    &:after {
      content: "";
    }
  }

  dd {
    display: none;
  }

  dt,
  a {
    color: #fff;
    padding: 0 20px 0 0;
  }

  .legal {
    background-color: $darker-grey;
    text-align: center;
    p {
      color: #fff;

      .icon-fs-icon {
        display: block;
        font-size: 50px;
        margin-top: 10px;
        overflow: hidden;

        &:before {
          display: block;
          margin-bottom: -4px;
        }
      }
    }
  }

  .cookie-settings-button {
    min-width: auto;
    letter-spacing: 0.027em;
  }
}
.rtl {
  .footer-inner {
    text-align: right;
    dl {
      float: right;
    }
    dt {
      padding: 0 0 0 20px;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  div.footer-inner {
    background-color: $desktop-footer-bg-color;
    font-weight: $secondary-font-weight;
    text-align: left;

    a {
      padding: 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    button {
      padding: 0;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    dl {
      display: block;
      margin: 28px 0;

      & > dt {
        color: white;
      }
    }

    dt {
      padding-bottom: 8px;

      &:after {
        content: "";
        position: relative;
        left: 10px;
      }
    }

    dd {
      display: block;
      margin: 0;
      overflow: hidden;

      a {
        font-size: $secondary-font-size;

        .icon {
          float: left;
          font-size: 20px;
          margin: 0 5px 0 0;
        }
      }

      button {
        font-size: $secondary-font-size;

        .icon {
          float: left;
          font-size: 20px;
          margin: 0 5px 0 0;
        }
      }
    }

    .legal {
      text-align: left;
      color: $medium-grey;
      background-color: transparent;
      border-top: 1px solid $darker-grey;

      p {
        color: $medium-grey;

        .icon-fs-icon {
          display: none;
        }
      }
    }
  }
  .rtl {
    .footer-inner {
      .legal {
        text-align: right;
      }
    }
  }
}

@media only screen and (max-width: ($screen-sm - 1px)) {
  .footer-inner {
    dl {
      display: none;
      float: none;
      margin: 0;
      padding: 10px 0;
      width: auto;
    }
  }
}

.footer-upper {
  /* Best Rate Guaranteed styles */
  .best_rate_guaranteed_wrapper {
    background-image: url(/images/best-rate-checkmark.png);
    background-repeat: no-repeat;
    background-size: 26px 22px;
    background-position: 3px 3px;
    position: relative;
    color: black;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 1px 1px 1px 1px;
    width: 212px;
    height: 28px;
  }

  .best_rate_guaranteed_title {
    font-family: $primary-font;
    font-size: 9pt;
    float: right;
    padding: 5px 7px 2px 7px;
    height: 26px;
    margin: 0px;
    letter-spacing: 0.1em;
  }
}
.rtl {
  .footer-upper {
    /* Best Rate Guaranteed styles */
    .best_rate_guaranteed_wrapper {
      background-position: right 3px top 3px;
    }

    .best_rate_guaranteed_title {
      float: left;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .footer-upper {
    .best_rate_guarantee_link {
      display: none;
    }
  }
}
