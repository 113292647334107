.error-message-block {
  margin: 40px 0;
  padding: 30px 50px;
  background-color: #ffeded;
  position: relative;

  svg {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);

    @media only screen and (min-width: $breakpoints-md) {
      left: 30px;
    }
  }

  @media only screen and (min-width: $breakpoints-md) {
    padding-left: 65px;
    padding-right: 65px;
  }

  &__sub-text {
    margin-top: 7px;
  }
}
