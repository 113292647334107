.fs-autocomplete {
  position: relative;

  input[type="text"].empty,
  input[type="email"].empty,
  input[type="password"].empty,
  input[type="search"].empty,
  input[type="range"].empty,
  textarea.empty              { color: $primary-text-color; }
  ::-webkit-input-placeholder { color: $primary-text-color; }
  :-moz-placeholder           { color: $primary-text-color; }
  ::-moz-placeholder          { color: $primary-text-color; }
  :-ms-input-placeholder      { color: $primary-text-color; }

  .autocomplete-input-wrapper {
    position: relative;

    .icon {
      color: $dark-grey;
      height: $input-height-with-border;
      width: $input-height-with-border;
      position: absolute;
      top: 0;
      text-align: center;
      line-height: 44px;
			font-size: 24px;
    }

    .icon-search {
      left: 0;
    }

		.icon-close {
			right: 0;
			cursor: pointer;
    }
	}

  .autocomplete-input-wrapper .autocomplete-input {
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    height: $input-height-with-border;
    overflow: hidden;
    padding: 0 $input-height-with-border;
    text-overflow: ellipsis;
		white-space: nowrap;
		&::-ms-clear {
			display: none;
		}

    &.no-search-icon {
      padding: 0 20px !important;
    }
  }

  .d-none {
    display: none;
  }

  .autocomplete-list {
    background: #fff;
    border: solid 1px $input-border-color;
    border-top: none;
    border-radius: 0 0 $input-border-radius $input-border-radius;
    position: relative;

    ul {
      list-style: none;
      margin: 0 -1px;
      padding: 0;

      max-height: 178px;
      overflow: auto;
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }

    li {
      border: none;
      margin: 0;
      overflow: hidden;
      padding: 0 12px;
      text-align: left;
    }

    .property {
      border-top: solid 1px $input-border-color;
      cursor: pointer;
      height: 38px;
      line-height: 38px;

      &:hover,
      &.is-selected {
        background: $dark-grey;
        border-color: $dark-grey;
        color: #fff;

        + li {
          border-top-color: $dark-grey;
        }
      }
    }

    .region-title {
      background: $darker-grey;
      color: #fff;
      height: 26px;
      font-family: $bold-font;
      font-size: $secondary-font-size;
      line-height: 26px;
    }

    .region-title + li {
      border-top: none;
    }

    .autocomplete-all-locations {
      background: $medium-grey;
      border: none;
      border-radius: 0 0 $input-border-radius $input-border-radius;
      color: #fff;
      cursor: pointer;
      display: block;
      height: 32px;
      line-height: 32px;
      padding: 0 6px 0 12px;
      position: absolute;
      text-align: left;
      bottom: 0;
      left: -1px;
      right: -1px;

      .icon-arrow-right {
        float: right;
        font-size: 20px;
        line-height: 32px;
      }
    }

    li:first-child {
      border-top: none;
    }

    li:last-child {
      border-radius: 0 0 $input-border-radius $input-border-radius;
    }
  }
}

.rtl{
  .fs-autocomplete {
    .autocomplete-input-wrapper {
      .icon-search {
        right: 0;
        left: auto;
      }
      .icon-arrow-down,
      .icon-arrow-up {
        left: 0;
        right: auto
      }
    }
    .autocomplete-list{
      .autocomplete-all-locations{
        text-align: right;
        .icon-arrow-right{
          float: left;
        }
      }
      li{
        text-align: right;
      }
    }
  }
}

// Open state
.fs-autocomplete.is-open {
  .autocomplete-input {
    border-radius: $input-border-radius $input-border-radius 0 0;
    @include theme-input-focus;
  }
}

// showing suggestions state
.fs-autocomplete.is-open.showing-suggestions {
  .autocomplete-list {
    ul {
      max-height: 152px;
    }

    li:last-child {
      border-radius: 0;
    }
  }
}


.desktop {
  .fs-search-panel .autocomplete-list {
    box-shadow: 0 8px 8px $widget-shadow-color;
    position: absolute;
    left: 0;
    right: 0;
    top: $input-height-with-border;
    z-index: $z_autocompleteList;
  }
}
