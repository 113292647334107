$calendar-offset: 10px;
$indicator-left-offset: 20%;
$indicator-right-offset: 40%;
$nights-selected-offset: 70px;
$padding: 10px;

.fs-calendar-wrapper {
  clear: both;
  min-width: 400px;
  position: absolute;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    z-index: $z_calendarArrow;
    bottom: -$calendar-offset;
    border-left: $calendar-offset solid transparent;
    border-right: $calendar-offset solid transparent;
    border-bottom: $calendar-offset solid $calendar-header-color;
    left: $indicator-left-offset;
  }

  &.checkOut:before {
    left: auto;
    right: $indicator-right-offset;
  }
}

.fs-calendar {
  position: absolute;
  z-index: $z_calendar;
  top: $calendar-offset;
  left: 0;
  right: 0;
  box-shadow: $calendar-shadow;

  .select-date {
    font-size: 14px;
  }

  .ui-datepicker-next,
  .ui-datepicker-prev {
    font-size: 14px;
    height: $calendar-header-height-desktop;
    line-height: $calendar-header-height-desktop + 2px;
    width: $calendar-header-button-size-desktop;
  }

  .datepicker-footer {
    border: none;
    font-size: 11px;
  }

  .ui-datepicker-header {
    font-size: 14px;
    height: $calendar-header-height-desktop;
    border-radius: 4px 4px 0 0;
    font-size: 12px;
  }

  .ui-datepicker-calendar {
    border: none;

    td {
      .ui-state-default {
        height: $calendar-cell-size-desktop;
        line-height: $calendar-cell-size-desktop;
      }
    }

    th {
      height: $calendar-cell-size-desktop;
      line-height: $calendar-cell-size-desktop;
    }
  }

  .nights-selected {
    padding: $padding;
    width: $nights-selected-offset;

    .de & {
      padding: 5px;
      width: $nights-selected-offset + 10px;
    }

    span {
      font-size: 20px;
    }
  }

  .ui-datepicker-title {
    line-height: $calendar-header-height-desktop;
    height: $calendar-header-height-desktop;
    font-size: 16px;
  }

  .legend {
    margin-left: $nights-selected-offset - 1px;
    padding-left: $padding;
    padding-right: $padding;

    .de & {
      margin-left: $nights-selected-offset + 9px;
    }

    .updated-at {
      margin-bottom: 0;
      font-size: 9px;
    }
  }
  .pt & {
    .legend {
      margin-left: 99px;
    }

    .nights-selected {
      width: 100px;
    }
  }
}

.fr {
  .fs-calendar {
    $nights-selected-offset: 100px;
    .legend {
      margin-left: $nights-selected-offset - 1;
    }
    .nights-selected {
      width: $nights-selected-offset;
    }
  }
}

.fr {
  .change-search {
    .fs-calendar {
      .nights-selected {
        padding: 5px;
        width: nights-selected-offset + 10px;
      }

      .legend {
        padding: 5px 5px 5px;
        margin-left: $nights-selected-offset + 10px;
        width: 180px;
      }
    }
  }
}
.fs-datepicker-wrapper {
  position: relative;
}
