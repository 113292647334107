.CTA--text--dark,
.CTA--text--dark:visited {
  font-family: $hn;
  font-size: rem(10px);
  font-weight: 700;
  letter-spacing: 0.25em;
  line-height: 1.33333em;
  text-transform: uppercase;
  background: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    border-color: $bhover;
    color: $bhover;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--text--light,
.CTA--text--light:visited {
  font-family: $hn;
  font-size: rem(10px);
  font-weight: 700;
  letter-spacing: 0.25em;
  line-height: 1.33333em;
  text-transform: uppercase;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  border-bottom-color: #000;
  color: #000;
  -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    border-color: $whover;
    color: $whover;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--secondary--light {
  font-family: $hn;
  font-size: rem(10px);
  letter-spacing: 0.3em;
  line-height: 1.3em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 700;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 130px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;

  &:visited {
    color: #000;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background: #000;
    color: #fff;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--secondary--light--on-dark,
.CTA--secondary--light--on-dark:visited {
  @extend .CTA--secondary--light;
  background: transparent;
  border-color: #fff;
  color: #fff;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background: #fff;
    color: #000;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--secondary--rounded {
  border-radius: 1000px;
}

.CTA--secondary--transparent {
  font-family: $hn;
  font-size: rem(10px);
  letter-spacing: 0.3em;
  line-height: 1.3em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 700;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 130px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    color: #fff;
    background-color: #000;
  }

  &--on-dark {
    &:not([disabled]):hover,
    &:not([disabled]):focus {
      border-color: #fff;
    }
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--secondary--dark {
  font-family: $hn;
  font-size: rem(10px);
  letter-spacing: 0.3em;
  line-height: 1.3em;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-weight: 700;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 130px;
  padding: 12px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  background-color: transparent;
  background-color: #000;
  border: 1px solid #000;
  color: #fff;

  &:visited {
    color: #fff;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--secondary--dark--on-dark {
  @extend .CTA--secondary--dark;
  background-color: #fff;
  border-color: #fff;
  color: #000;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    background-color: #000;
    border-color: #fff;
    color: #fff;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--underlined--light,
.CTA--underlined--light:visited {
  font-family: $hn;
  font-size: rem(10px);
  letter-spacing: 0.25em;
  line-height: 1.33333em;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  border-bottom: 1px solid;
  font-weight: 700;
  padding-bottom: 3px;
  text-transform: uppercase;
  border-bottom-color: #000;
  color: #000;
  -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    color: $whover;
    border-color: $whover;
  }

  &[disabled] {
    opacity: 0.5;
  }
}

.CTA--underlined--dark,
.CTA--underlined--dark:visited {
  font-family: $hn;
  font-size: rem(10px);
  letter-spacing: 0.25em;
  line-height: 1.33333em;
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  text-decoration: none;
  border-bottom: 1px solid;
  font-weight: 700;
  padding-bottom: 3px;
  text-transform: uppercase;
  border-bottom-color: #fff;
  color: #fff;
  -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;
  transition: background-color 0.3s ease-out, color 0.3s ease-out,
    border-color 0.3s ease-out;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    color: $bhover;
    border-color: $bhover;
  }

  &[disabled] {
    opacity: 0.5;
  }
}
