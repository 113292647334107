.sticky-footer {
  position: fixed;
  transform: translate3d(0);
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  color: $black;
  z-index: 2;
  &.is-open {
    background-color: rgba(0,0,0,0.75);
    z-index: 200;
    top: 0;
  }
  p.sticky-footer--need-help {
    float: left;
    margin-top: 11px;
    font-family: "AdobeCaslonW01-Regular";
    font-size: 18px;
    font-weight: 500;

    @media only screen and (max-width: $screen-sm-max) {
      float: none;
    }
  }
  .btn--primary.sticky-footer--contact-us {
    width: auto;
    float: right;
  }
  .icon-close {
    position: absolute;
    right: 0;
    top: -50px;
    cursor: pointer;
    font-size: 24px;
  }
}

.sticky-footer {
  &--bar {
    &__actions {
      display: flex;
      flex-direction: row;
      float: right;

      @media only screen and (max-width: $screen-sm-max) {
        float: none;
        flex-direction: column;
      }

      .btn--web-chat {
        margin-left: 20px;
        position: relative;

        @media only screen and (max-width: $screen-sm-max) {
          margin-left: 0;
          margin-top: 20px;
        }

        span {
          display: inline-block;
          vertical-align: top;
        }

        &__icon {
          background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMjUgMjUiIHdpZHRoPSIyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjUuNTkwNzAwOCAxMy4wMDI2NTI2YzYuNDAyOTEzNSAwIDExLjU5MDYzOTggNC40NDY1MjQ0IDExLjU5MDYzOTggOS45MzQ1MzI5IDAgMy4xODE0OTcxLTEuNzQ0MzMwMiA1Ljc0OTM0NTMtNC40NTg0OTIxIDcuNTY3NzU3NWwyLjIyODAyNiA1LjY3NjM5NzYtNi42ODY1NzkxLTMuNzg1MTAyYy0uODQ1OTMzNy4xNjg1MDcyLTEuNzcwNTAwNy40NzI4MjcxLTIuNjczNjU1Ni40NzI4MjcxLTYuNDAyOTEzNCAwLTExLjU5MDYzOTgtNC40NDY1MjQ0LTExLjU5MDYzOTgtOS45MzQ1MzI5IDAtNS40ODc3NTA5IDUuMTg3NjY1NC05LjkzNDUzMjggMTEuNTkwNjM5OC05LjkzNDUzMjh6IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDM4LjE4MTM0IC0xMikiLz48L3N2Zz4=);
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: contain;
          width: 25px;
          height: 100%;
          margin-right: 10px;
        }

        &:hover .btn--web-chat__icon {
          background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1IiB2aWV3Qm94PSIwIDAgMjUgMjUiIHdpZHRoPSIyNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjUuNTkwNzAwOCAxMy4wMDI2NTI2YzYuNDAyOTEzNSAwIDExLjU5MDYzOTggNC40NDY1MjQ0IDExLjU5MDYzOTggOS45MzQ1MzI5IDAgMy4xODE0OTcxLTEuNzQ0MzMwMiA1Ljc0OTM0NTMtNC40NTg0OTIxIDcuNTY3NzU3NWwyLjIyODAyNiA1LjY3NjM5NzYtNi42ODY1NzkxLTMuNzg1MTAyYy0uODQ1OTMzNy4xNjg1MDcyLTEuNzcwNTAwNy40NzI4MjcxLTIuNjczNjU1Ni40NzI4MjcxLTYuNDAyOTEzNCAwLTExLjU5MDYzOTgtNC40NDY1MjQ0LTExLjU5MDYzOTgtOS45MzQ1MzI5IDAtNS40ODc3NTA5IDUuMTg3NjY1NC05LjkzNDUzMjggMTEuNTkwNjM5OC05LjkzNDUzMjh6IiBmaWxsPSJub25lIiBzdHJva2U9IiMwMDAiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAxIDM4LjE4MTM0IC0xMikiLz48L3N2Zz4=);
        }
      }
    }
  }
}

.sticky-footer--bar,
.sticky-footer--contact-info {
  padding: 20px;
  background: $white;
}
.sticky-footer--contact-info {
  position: absolute;
  bottom: 75px;
  padding-top: 75px;
  width: 100%;
}
.sticky-footer--heading {
  font-size: 12px;
  letter-spacing: 2px;
  color: $black;
  display: inline;
}
.sticky-footer--heading__address {
  display: inline-block;

  &:before {
    margin: 0 auto;
    padding-bottom: 15px;
    border-top: 1px solid $new-light-grey;
    display: block;
    content: ' ';
    width: 85%;
  }
}
.sticky-footer--heading-wrapper {
  margin: 10px 0;
  display: block;
}
.sticky-footer--bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  box-shadow: 2px -4px 8px 0px rgba(0,0,0,0.1);
}
.sticky-footer--inner {
  display: block;
  position: relative;
  text-align: center;
  min-height: 0;
  max-width: 1140px;
  margin: 0 auto;
  @include clearfix;
}
.sticky-footer--contact-info-list {
  margin-top: 75px;
  dt,
  dd {
    margin-left: 0;
    float: left;
    width: 50%;
    font-size: 12px;
    letter-spacing: 3px;
  }
  dt,
  a {
    text-transform: uppercase;
  }
  a {
    text-decoration: none;
    display: inline-block;
    &:after {
      display: block;
      content: ' ';
      width: 100%;
      padding-top: 3px;
      border-bottom: 1px solid $black;
    }
  }
  dt {
    text-align: left;
    font-family: "Helvetica Neue LT W01_71488914";
  }
  dd {
    text-align: right;
    font-family: "HelveticaNeue-Light";
  }
}
.sticky-footer--contact-info-list-wrapper {
  padding-bottom: 30px;
}
.sticky-footer--contact-info-list-item-wrapper {
  border-top: 1px solid;
  padding: 25px 0px;
  max-width: 768px;
  margin: 0 auto;
  @include clearfix;
}
.sticky-footer--need-help,
.sticky-footer--contact-us {
  display: inline-block;
}
.sticky-footer--suppress-scroll-mobile {
  overflow: hidden;
  height: 100%;
  max-height: 100%;
}

@media only screen and (max-width: $screen-sm-max) {
  .sticky-footer--bar {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: $white;
  }
  .sticky-footer--heading {
    font-size: .75em;
  }
  .sticky-footer--contact-info {
    top: 0;
    padding-top: 20px;
    overflow: scroll;
    .sticky-footer--inner {
      padding-top: 100px;
    }
    .icon-close {
      top: 0;
      font-size: 40px;
    }
  }
  .sticky-footer--contact-info-list dt,
  .sticky-footer--contact-info-list dd {
    margin-top: 3px;
    width: 100%;
    float: none;
    text-align: left;
  }
}
