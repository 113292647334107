
.view-booking-flow__confirm-your-stay.redesign {
	.modal-title.empty-title {
		display: block;
		@media screen and (min-width: $screen-sm) {
			display: none;
		}
		.back-btn {
			background-color: white;
			color: black;
			padding-left: 15px;
			width: 100%;
			text-align: left;
		}
	}

	p {
		color: $black;
	}

	.btn-primary {
		font-family: Helvetica;
  	font-size: 10px;
		font-style: bold;
  	letter-spacing: 3px;
  	line-height: 13px
	}

  .collapse {
    display: none;
  }
  .collapse.in {
    display: block;
  }
  tr.collapse.in {
    display: table-row;
  }
  tbody.collapse.in {
    display: table-row-group;
  }
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
  }
  .panel {
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  }
  .panel-body {
    padding: 15px;
  }
  .panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .panel-heading > .dropdown .dropdown-toggle {
    color: inherit;
  }
  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
  }
  .panel-title > a,
  .panel-title > small,
  .panel-title > .small,
  .panel-title > small > a,
  .panel-title > .small > a {
    color: inherit;
  }
  .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ffffff;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel > .list-group,
  .panel > .panel-collapse > .list-group {
    margin-bottom: 0;
  }
  .panel > .list-group .list-group-item,
  .panel > .panel-collapse > .list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
  }
  .panel > .list-group:first-child .list-group-item:first-child,
  .panel
    > .panel-collapse
    > .list-group:first-child
    .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .panel > .list-group:last-child .list-group-item:last-child,
  .panel
    > .panel-collapse
    > .list-group:last-child
    .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .panel
    > .panel-heading
    + .panel-collapse
    > .list-group
    .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .panel-heading + .list-group .list-group-item:first-child {
    border-top-width: 0;
  }
  .list-group + .panel-footer {
    border-top-width: 0;
  }
  .panel-group {
    margin-bottom: 20px;
  }
  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
  }
  .panel-group .panel + .panel {
    margin-top: 5px;
  }
  .panel-group .panel-heading {
    border-bottom: 0;
  }
  .panel-group .panel-heading + .panel-collapse > .panel-body,
  .panel-group .panel-heading + .panel-collapse > .list-group {
    border-top: 1px solid #ffffff;
  }
  .panel-group .panel-footer {
    border-top: 0;
  }
  .panel-group .panel-footer + .panel-collapse .panel-body {
    border-bottom: 1px solid #ffffff;
  }
  .panel-default {
    border-color: #ffffff;
  }
  .panel-default > .panel-heading {
    color: #333333;
    border-color: #ffffff;
  }
  .panel-default > .panel-heading + .panel-collapse > .panel-body {
    border-top-color: #ffffff;
  }
  .panel-default > .panel-heading .badge {
    background-color: #333333;
  }
  .panel-default > .panel-footer + .panel-collapse > .panel-body {
    border-bottom-color: #ffffff;
  }

  .clearfix:before,
  .clearfix:after,
  .panel-body:before,
  .panel-body:after {
    display: table;
    content: ' ';
  }
  .clearfix:after,
  .panel-body:after {
    clear: both;
  }
  .center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
  }
  .pull-right {
    float: right !important;
  }
  .pull-left {
    float: left !important;
  }
  .hide {
    display: none !important;
  }
  .show {
    display: block !important;
  }
  .invisible {
    visibility: hidden;
  }
  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  .hidden {
    display: none !important;
  }
  .affix {
    position: fixed;
  }

  .visible-xs-flex {
    display: flex !important;
  }
  @media screen and (min-width: $screen-sm) {
    .visible-xs-flex {
      display: none !important;
    }
  }
  .fs-select-mask {
    width: 100%;
    border: solid 1px #bebebe;
    select {
      width: 100% !important;
    }
    .icon.css-arrow-down,
    .icon.css-arrow-down {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      margin: auto;
      right: 20px;
    }
  }
  &.rtl {
    .icon,
    .css-arrow-down {
      right: inherit !important;
      left: 20px !important;
    }
    .upgrade-option-info h2 {
      text-align: right;
    }
    [name='confirmYourStayForm'] {
      select,
      input {
        padding-left: 10px;
        padding-right: 20px;
      }
      .terms-agreement-row.checkbox {
        input {
          margin-right: 0;
        }
        label {
          padding-left: 0px!important;
          padding-right: 40px!important;
        }
      }
      .has-value {
        .flag-container {
          .selected-flag {
            padding-left: 0px;
            padding-right: 20px;
          }
          display: block;
        }
        .intl-tel-input input {
          padding-right: 50px;
          padding-left: 10px;
        }
      }
    }
    .room-item-tab-container {
      .room-details-tab-select {
        button {
          margin-right: 0px;
          margin-left: 24px;
        }
      }
      .tab-rate {
        .room-item-rate {
          .rate-list {
            p,
            li,
            dd {
              text-align: right;
            }
          }
        }
      }
    }
    @media screen and (min-width: $screen-sm) {
      .container.view-confirm-stay.step-3-redesign {
        .form-column {
          float: right;
        }
        .col-sm-1 {
          float: right;
        }
        .page-heading {
          text-align: right;
        }
        .top-block {
          .otp-sign-in-launch-block {
            margin: 0 0 0 50px;
          }
        }
      }
    }

    @media screen and (max-width: $screen-sm - 1) {
      fieldset {
        legend {
          span.icon:after {
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }
  .fs-errors-container .container {
    @media screen and (min-width: $screen-lg) {
      width: 1200px;
      padding: 0;
    }
  }

  header {
    .container {
      @media screen and (min-width: $screen-lg) {
        width: 1200px;
        padding: 0;
      }
    }
  }
  .container.view-confirm-stay.step-3-redesign {
    width: 100%;
    margin-bottom: 175px;
    @media screen and (min-width: $screen-sm) {
      width: 95%;
      margin-bottom: 0;
    }
    @media screen and (min-width: $screen-lg) {
      width: 1200px;
    }
    padding: 0;

    .row {
      margin: 0;
      @media screen and (min-width: $screen-sm) {
        margin: 0 -15px;
      }
    }
    .form-column {
      margin: 0 20px;
      padding: 0;
      @media screen and (min-width: $screen-sm) {
        padding: 0 15px;
        margin: 0;
      }
    }
    //sign in to book faster and book with us

    .top-block {
      display: flex;
      font-size: 18px;
      div {
        margin: 0;
      }
      .otp-sign-in-launch-block {
        flex-direction: column;
        padding: 30px 20px;
        width: 100%;
        max-width: initial;
        @media screen and (min-width: $screen-sm) {
          max-width: 300px;
          margin: 0 50px 0 0;
          padding: 15px 35px;
        }
        button {
          width: 100%;
          margin: 12px 0;
        }
        p {
          width: 100%;
          display: block;
					margin-bottom: 20px;
					text-align: center;
        }
      }
      ul {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        height: 66px;
        margin: 0;
        li {
          text-transform: uppercase;
          padding-left: 1em;
          text-indent: -1em;
          font-family: $hn;
          font-size: 11px;
          line-height: 12px;
          letter-spacing: 2.5px;
          padding-bottom: 15px;
        }

        li:before {
          content: '-';
          padding-right: 5px;
        }
      }
    }

    @media screen and (min-width: $screen-sm) {
      .page-heading {
        font-size: 24px;
        margin: 0 0 20px;
        text-align: left;
        color: black;
      }
    }


    @media screen and (max-width: $screen-sm - 1) {
	    .g-recaptcha.step-3-redesign {
	    	.grecaptcha-badge {
					bottom: 186px !important;
	     	}
	    }
    }

    //booking for buttons


    .btn-group {
      display: flex;
      justify-content: space-around;
      border-top: 1px solid $theme-gray2;
      border-bottom: 1px solid $theme-gray2;
      min-height: 78px;
      padding: 0;
      margin-top: 20px;
      margin-right: -15px;
      margin-left: -15px;
      @media screen and (min-width: $screen-sm) {
        margin-right: 0px;
        margin-left: 0px;
      }
      .btn {
        font-size: 10px;
        font-family: $hn;
				line-height: 15px;
				letter-spacing: 2.5px;
        border: none;
        color: #666666;
				height: auto;
				padding-bottom: 9px;
        @media screen and (min-width: $screen-sm) {
					font-size: 12px;
					width: 155px;
					letter-spacing: 3.5px;
					line-height: 18px;
        }
        &.is-active {
          background: none;
          color: black;
					border-bottom: 3px solid black;
					padding-bottom: 6px;
          &:after {
            border: none;
          }
        }
        &:hover {
          background-color: $lighter-grey;
        }
      }
    }

    //upsell
    .upgrade-option-inline {
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
      margin-right: 5px;
      margin-left: 3px;

      .upgrade-option-info {
        border: 0;
        .upgrade-option-image {
          height: 200px;
        }
        .upgrade-option-price {
          margin-top: 16px;
          h2 {
            font-size: 16px;
          }
        }
        .upgrade-room {
          margin-top: 12px;
        }
        p {
          font-family: $garamond;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 21px;
        }
        .upgrade-select {
          text-align: center;
          width: 100%;
          button {
            font-family: $hn;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 3px;
            line-height: 13px;
            height: 40px;
            width: 200px;
            padding: 10px 10px;
          }
        }
      }
      .icon-close {
        color: black;
        background: none !important;
        right: 10px;
        top: 10px;
        font-weight: normal;
        width: 20px;
        height: 20px;
        font-size: 20px;
      }
    }

    //booking secure text and icon
    .secure-booking {
      display: flex;
      &.bottom-secure-booking {
        @media screen and (max-width: $screen-sm - 1) {
          justify-content: center;
          margin-top: 30px;
        }
      }
      .secure-icon {
        width: 15px;
        height: 15px;
        background-image: url(/images/lock-icon.png);
        background-repeat: no-repeat;
        background-size: 15px 15px;
      }
      .secure-text {
        color: #417505;
        font-family: $hn;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 3px;
        line-height: 13px;
        text-transform: uppercase;
        margin: auto 0 auto 10px;
      }
    }

    //end container
  }
  //form
  .panel-body,
  [name='confirmYourStayForm'] {
    select,
    input {
      padding-left: 20px;
      color: $gray1;
      font-family: $garamond;
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 27px;
      @media screen and (min-width: $screen-sm) {
        height: 46px;
        background-color: #f5f5f5;
      }

      &::placeholder {
        color: $gray1;
        font-family: $garamond;
        font-size: 16px;
        letter-spacing: 0.5px;
        @media screen and (min-width: $screen-sm) {
          font-size: 18px;
        }
      }
      &:-ms-input-placeholder {
        color: $gray1;
        font-family: $garamond;
        font-size: 16px;
        letter-spacing: 0.5px;
        @media screen and (min-width: $screen-sm) {
          font-size: 18px;
        }
      }
      &::-ms-input-placeholder {
        color: $gray1;
        font-family: $garamond;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 24px;
        @media screen and (min-width: $screen-sm) {
          font-size: 18px;
          line-height: 27px;
        }
      }

      &[type='checkbox'] {
        height: auto;
      }
      @media screen and (max-width: $screen-xs - 1) {
        &[name='agencyName'] {
          font-size: 14px;
          &::placeholder {
            font-size: 14px;
          }
        }
      }
    }
  }

  .panel-body,
  .reservation-total,
  [name='confirmYourStayForm'] {
    @media screen and (max-width: $screen-sm - 1) {
      fieldset:not([name='termsAndConditions']) {
        margin: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid #D8d8d8;
      }

      fieldset {
        overflow: hidden;
        transition: height 500ms;
        legend {
          span {
            float: right;
            font-size: 20px;
            &.icon:after {
              content: '-';
            }
          }
        }
        &.hidden-fieldset {
          height: 60px !important;
          legend {
            span.icon:after {
              content: '+';
            }
          }
          .secure-booking {
            visibility: hidden;
          }
          section {
            visibility: hidden;
          }
        }
      }
    }

    legend {
      padding-top: 20px;
      color: #000000;
      font-family: $hn;
      background: none;
      text-align: left;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 3px;
      line-height: 24px;
      position: initial;
      @media screen and (min-width: $screen-sm) {
        font-size: 21px;
        letter-spacing: 3.5px;
        line-height: 31px;
        padding-top: 20px;
      }
    }
    @media screen and (max-width: $screen-sm - 1) {
      .name-rows {
        display: flex;
        justify-content: space-between;
        .form-row {
          width: 49%;
        }
      }
    }

    .send-guest-email-row {
      label {
        width: 100%;
      }
      margin-top: 20px;
    }
    .terms-agreement-row {
      @media screen and (min-width: $screen-sm) {
        .icon {
          padding-top: 30px;
        }
      }
      &.checkbox {
        label {
          padding-left: 20px;
      }
    }
    }
    .checkbox {
      span,
      label {
        top: auto;
        font-family: $garamond;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 27px;
      }
      label {
        padding: 0;
      }
      input {
        margin-right: 15px;
        top: 7px;
        left: 0;
      }
    }

    .form-row {
      padding: 0;
      border: 1px solid #979797;
      border-radius: 3px;
      margin-top: 24px;

      @media screen and (max-width: $screen-sm - 1) {
        label:not(.terms-label) {
          height: 13px;
          display: initial;
          border: 0;
          clip: rect(0 0 0 0) !important;
          height: 1px;
          margin: -1px !important;
          overflow: hidden;
          padding: 0;
          position: absolute !important;
          width: 1px;
          background: none;
        }
      }



      @media screen and (min-width: $screen-sm) {
        border-top-width: 0px;
        border-right-width: 0px;
        border-left-width: 0px;
        border-bottom-width: 1px;
        border-bottom-color: black;
        label:not(.terms-label) {
          margin: 0;
          padding: 0;
          border: 0;
          font-size: 100%;
          font: inherit;
          vertical-align: baseline;
          background: none;
          text-transform: uppercase;
          font-family: $hn;
          font-size: 10px;
          letter-spacing: 3px;
          line-height: 13px;
          color: black;
          top: -13px;
          height: 13px;
          width: 100%;
        }
      }

      .inline-error .message {
        right: 0;
      }

      &.form-row.credit-card-date {
        overflow: visible;
      }
      &.terms-agreement-row {
        @media screen and (min-width: $screen-sm) {
          border-top: 2px solid #d8d8d8;
          margin-top: 30px;
          padding-top: 30px;
        }
        border: 0;
        &.checkbox {
          label {
            padding-left: 30px;
            padding-right: 0px;
          }
        }

      }
      &.credit-cards {
        border: none;
        span {
					font-family: $garamond;
          font-style: normal;
					font-size: 18px;
					color: $gray1;
					letter-spacing: 0.5px;
					line-height: 27px;
        }
      }
    }
    .flag-container {
      display: none;
      cursor: auto;

      .iti-arrow {
        display: none;
      }
    }
    .intl-tel-input input {
      padding-left: 20px;
    }
    .has-value {
      .flag-container {
        .selected-flag {
          padding-left: 20px;
        }
        display: block;
      }
      .intl-tel-input input {
        padding-left: 50px;
      }
    }

    button {
      width: 285px;
      margin: auto;
      @media screen and (min-width: $screen-sm) {
        width: 250px;
        margin: 15px 0 0 0;
      }
    }
    .secure-booking {
      padding: 12px 0;
    }
    @media screen and (min-width: $screen-sm) {
      .secure-booking {
        float: right;
        clear: both;
        padding: 16px 0;
      }
    }
  }
  //booking info
  .booking-info-column {
    display: none;

    @media screen and (min-width: $screen-sm) {
      display: block;
    }
  }
  .panel-body,
  .booking-info-column {
		padding-left: 0;
		padding-top: 0;
    .page-heading {
      border-bottom: 1px solid #d8d8d8;
      padding-bottom: 24px;
    }
    .summary {
      padding-left: 20px;
      .destination-name {
				font-family: $garamond;
        font-size: 22px;
        line-height: 33px;
        letter-spacing: 0.5px;
      }
      .guests-children,
      .rooms-guests,
      .booking-dates {
				font-family: $garamond;
        font-size: 18px;
        line-height: 27px;
				letter-spacing: 0.5px;
      }
    }
    .package {
	    padding: 0;
      background-color: white;
      margin-top: 25px;
      .room-image {
        height: 200px;
      }
      .room-info {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        .room-name {
          font-family: $garamond;
          font-size: 22px;
          line-height: 33px;
          letter-spacing: 0.5px;
        }
        p {
          font-family: $garamond;
          font-size: 18px;
          letter-spacing: 0.5px;
          line-height: 27px;
        }
        .see-details {
					text-decoration: none;
					border-bottom: 1px solid #000;
					padding-bottom: 3px;
					strong {
						text-transform: uppercase;
						font-family: $hn;
						letter-spacing: 3px;
						line-height: 16px;
						font-size: 12px;
					}
				}
      }
      .costs {
        margin-top: 30px;
        dt {
          border-bottom: 1px solid #d8d8d8;
        }
        dd {
          padding-left: 20px;
          padding-top: 8px;
          padding-bottom: 8px;
          border: none;
          .label {
            font-family: $garamond;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 24px;
          }
          .amount {
						font-family: $hn;
            font-size: 16px;
            font-weight: 300;
            letter-spacing: 3px;
            line-height: 24px;
          }
        }
        dt,
        dd {
          margin: 0;
          background-color: #f8f8f8;
          button {
						padding: 12px 15px;
						width: 100%;
						@media screen and (min-width: $screen-sm) {
							padding: 20px;
						}
            .cost-text {
              display: inline;
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 3px;
              line-height: 13px;
              font-family: $hn;
              text-transform: uppercase;
              text-decoration: none;
            }
            .icon {
              right: 20px;
              font-weight: bold;
            }
          }
				}
				dd {
					padding: 8px 29px;
					&:first-of-type {
						padding-top: 16px;
					}
					&:last-of-type {
						padding-bottom: 16px;
					}
				}
      }
    }
    .reservation-total {
			border: 1px solid $gray3;
      margin-top: 25px;
      .costs {
        margin: 0;
        padding: 20px;
        background-color: #f8f8f8;
      }
			.label {
				font-family: $garamond;
				font-size: 16px;
				letter-spacing: 0.5px;
				line-height: 24px;
			}
			.amount {
				font-family: $hn;
				font-size: 16px;
				font-weight: 300;
				letter-spacing: 3px;
				line-height: 24px;
			}
			.tax {
				.label {
					padding-left: 7px;
				}
				.amount {
					padding-right:7px;
				}
			}
      .room-rates {
				border-bottom: 1px solid #d8d8d8;
				padding: 0 0 16px 0;
        .label {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0.57px;
					line-height: 27px;
        }
        .amount {
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 3px;
          line-height: 21px;
        }
      }
    }
    .booking-total {
      padding: 25px 20px;
      background-color: white;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .estimated-total {
        font-family: $garamond;
        font-size: 21px;
        letter-spacing: 0.5px;
        line-height: 32px;
				margin-top: auto;
      }
      .fs-currency-select-total {
        font-family: $hn;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 3px;
				line-height: 36px;
      }
    }
    .fs-currency-select {
      padding: 0px 20px 25px;
      margin: 0;
      .AccessibilityLabel {
        font-size: 10px;
        letter-spacing: 3px;
        line-height: 13px;
      }
      select {
        height: 46px;
        background-color: #f5f5f5;
        border-bottom: 1px solid black;
      }
    }
    .cancellation-description {
      font-family: $garamond;
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 27px;
      margin-top: 24px;
      font-weight: normal;
      width: 100%;
      text-align: center;
    }
  }
  .disclaimer {
    p {
      font-size: 16px;
      letter-spacing: 0.5px;
      line-height: 24px;
      font-family: $garamond;
    }
  }
  .contact-links {
    width: 100%;
    display: block;
		padding-top: 30px;
		text-align: center;
    a {
      text-transform: uppercase;
      font-family: $hn;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2.5px;
      line-height: 12px;
			text-decoration: none;
			padding: 0 0 3px 0;
			border-bottom: 1px solid #000;
			white-space: nowrap;
    }
  }
  @media screen and (max-width: $screen-sm - 1) {
    .original-footer {
      display: none;
    }
  }

  #accordion {
    position: fixed;
    bottom: 0;
    width: 100%;
    overflow: scroll;
    max-height: 80%;
    background: white;
  }
  .accordion-expanded {
    .panel-heading {
      position: sticky;
      width: 100%;
      top: -1px;
      background: white;
			z-index: 10;
			.panel-title {
				a {
					padding-bottom: 0;
				}
			}
    }
  }
  .accordion-collapsed {
    .panel-title {
      a {
        min-height: 135px;
        .view-icon {
          display: flex;
        }
        .hide-icon {
          display: none;
        }
        .summary {
          display: block;
        }
      }
    }
  }
  .panel-body {
    overflow: visible;
    margin: 0 20px;
    .your-trip h3 {
      display: none;
    }
    .reservation-total {
      .room-rates {
        .amount {
          font-size: 14px;
        }
      }
    }
    .booking-total {
      .estimated-total {
        font-size: 18px;
      }
    }
  }

  .panel-heading {
    padding: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }

  .panel-group {
    margin-bottom: 0;
    z-index: 2;
		box-shadow: 0px -16px 30px 0px rgba(0,0,0,.24)
  }
  .panel-group .panel {
    border-radius: 0;
  }

  .panel-title {
    width: 100%;
    height: 100%;
    display: block;
    a {
      width: 100%;
      display: block;
      outline: none;
      text-decoration: none;
      padding: 16px;
      .top {
        padding-bottom: 15px;
        border-bottom: 1px solid black;
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        span {
          font-family: $hn;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 3px;
          line-height: 18px;
          color: black;
          margin-top: auto;
          margin-bottom: auto;
        }
        .hide-icon,
        .view-icon {
          flex-direction: column;
          span.icon {
            margin: auto;
            font-size: 18px;
            font-family: 'fs-rbf-font';
          }
          span.icon-text {
            letter-spacing: 1.5px;
            line-height: 10px;
            font-size: 10px;
            text-transform: uppercase;
          }
        }
      }
      .summary {
        display: none;
        p span {
          text-transform: none;
          color: black;
          font-family: $garamond;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 24px;
          &.booking-dates {
            color: #7d7d7d;
          }
        }
      }
      .view-icon {
        display: none;
      }
      .hide-icon {
        display: flex;
      }
    }
  }

}
.modal-dialog {
  .icon-close {
    color: black;
    background: none !important;
    right: 25px;
    top: 25px;
    font-weight: normal;
    width: 35px;
    height: 35px;
    font-size: 35px;
  }
}
.room-details-modal.redesign {
  background-color: white;

  .room-item-tab-container {
    margin: 55px 100px 85px;
    .room-details-tab-select {
      display: flex;
      justify-content: left;
      border-bottom: 2px solid #d8d8d8;
      padding: 0;
      height: 30px;
      button {
        width: auto;
        min-width: auto;
        font-size: 12px;
        font-family: $hn;
        line-height: 18px;
        border: none;
        color: #666666;
        margin-right: 24px;
        letter-spacing: 3px;
        padding: 0;

        &.is-active {
          background: none;
          color: black;
          border-bottom: 3px solid black;
          &:after {
            border: none;
          }
        }
        &:hover {
          background-color: $lighter-grey;
        }
      }
    }
  }
  .room-details-tabs {
    padding-top: 20px;
    background-color: white;
  }
  .room-item-details {
    padding: 0;
    @media screen and (max-width: $screen-sm - 1) {
      .description-text {
        margin-top: 20px;
        margin-bottom: 0;
      }
      p,
      li {
        font-size: 16px;
        letter-spacing: 0.5px;
				line-height: 24px;
				margin-top: 10px !important;
      }
      .label {
        width: 100%;
        padding-bottom: 10px;
      }
    }

    .label {
      font-family: $hn;
      font-size: 10px;
      letter-spacing: 3px;
      line-height: 13px;
      padding-bottom: 5px;
    }
    li {
      margin-top: 35px;
      margin-bottom: 0;
    }
    p,
    li {
      font-size: 18px;
      letter-spacing: 0.5px;
      line-height: 28px;
      font-family: $garamond;
    }
    .room-details-floorplan {
			padding-top: 20px;
			width: 100%;
			text-align: center;
      font-family: $hn;
      font-size: 10px;
      font-weight: bold!important;
      letter-spacing: 3px;
      line-height: 13px;
      color: black;
			text-transform: uppercase;
			@media screen and (min-width: $screen-sm) {
				padding-top: 45px;
				width: auto;
			}
			a {
				font-weight: bold!important;
				text-decoration: none;
				padding: 0 0 3px 0;
				border-bottom: 1px solid #000;
			}
		}
		.floorplan {
			text-align: center;
			margin-top: 20px;
			img {
				max-width: 100%;
			}
		}
  }

  .tab-rate {
    .room-item-rate {
      .rate-list {
        dt {
          font-family: Helvetica;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 3px;
          line-height: 18px;
          margin-bottom: 12px;
          text-transform: uppercase;
          padding-top: 12px;
        }
        ul {
          padding-left: 20px;
        }
        p,
        li,
        dd {
          font-size: 18px;
          letter-spacing: 0.5px;
          line-height: 27px;
          font-family: $garamond;
          float: none;
          text-align: left;
          margin: 0;
          height: auto;
          list-style-type: disc;
          position: initial;
          background: none;
          color: black;
          font-weight: normal;
					padding-left: 0;
        }
        .terms-block {
					margin: 25px 0;
					width: 100%;
					text-align: center;
          h3 {
            color: black;
            font-family: Helvetica;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 3px;
            line-height: 18px;
            margin: 25px 0;
					}
					a {
						text-decoration: none;
					}
          .terms-button {
						text-transform: uppercase;
						text-decoration: none;
            font-size: 10px;
            font-weight: bold;
            letter-spacing: 3px;
            line-height: 13px;
            font-family: $hn;
						padding: 0 0 3px 0;
						border-bottom: 1px solid #000;
					}
					.terms {
						margin-top: 25px;
					}
        }
      }
    }
  }
  .tab-services {
    .room-item-services {
      display: flex;
      flex-direction: column;
      .rate-list,
			.amenities-list,
			.special-access,
      .image-feature-list {
        padding: 0;
        width: 100%;
        margin-top: 24px;
        @media screen and (max-width: $screen-sm - 1) {
          border: none;
          .img-container {
            width: 100%;
            max-width: 100%;
            padding-bottom: 10px;
          }
          .features {
            margin-left: 0;
          }
        }
        h3 {
          font-family: Helvetica;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 3px;
          line-height: 18px;
          margin-bottom: 12px;
        }
        ul {
          padding-left: 20px;
          @media screen and (min-width: $screen-sm) {
            column-count: 3;
          }
          margin-bottom: 12px;
          li {
            padding-right: 50px;
            font-size: 18px;
            letter-spacing: 0.5px;
            line-height: 27px;
            font-family: $garamond;
						break-inside: avoid-column;
						width: 100%;
          }
        }
      }
      .special-access-requests {
        padding: 0px;
        font-size: 18px;
        letter-spacing: 0.5px;
        line-height: 27px;
        font-family: $garamond;
        ul {
          padding-left: 15px;
          column-count: 3;
          margin-bottom: 12px;
          li {
            break-inside: avoid-column;
          }
        }
      }
    }
	}
	fieldset:last-of-type {
		margin-bottom: 20px!important;
	}
	.back-to-your-res {
		font-family: $hn;
		font-size: 10px;
		font-weight: bold;
		letter-spacing: 3px;
		line-height: 13px;;
		text-transform: uppercase;
		text-decoration: none;
		border-bottom: 1px solid #000;
		padding-bottom: 3px;
	}


}

.terms-modal {
  @media screen and (max-width: $screen-sm - 1) {
    top: 0;
    position: fixed;
    z-index: 0;
    height: 100%;
    background: white;
    padding-top: 50px;
    overflow: scroll;
    button {
      background: white !important;
      color: black !important;
      width: 100%;
      text-align: left;
    }
  }

  .terms-title {
    font-family: $hn;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 3.33px;
    line-height: 31px;
    text-transform: uppercase;
		padding-left: 15px;
		@media screen and (min-width: $screen-sm) {
			font-size: 15px;
		}
  }
  .termsAndConditionsText {
		p {
			font-family: $garamond;
			font-size: 16px;
			letter-spacing: 0.5px;
			line-height: 24px;
			padding: 20px 15px;
		}
  }
  @media screen and (min-width: $screen-sm) {
    button {
      display: none;
    }
    .terms-title {
      text-align: center;
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 3px;
      line-height: 18px;
      padding: 50px 0 0 0;
      display: block;
    }
    .termsAndConditionsText {
			padding: 20px 50px 50px 50px;
			p {
				font-size: 18px;
				line-height: 27px
			}
    }
  }
}
