.view-booking-flow__personalize-your-stay {
  .sign-in-or-register-block {
    text-align: center;
    padding: 20px 40px;

    P {
      font-family: "Neue Helvetica W04", Neue Helvetica, Helvetica, sans-serif;
      font-size: 16px;
      margin: 0 0 30px;
    }

    .sign-in-or-register-block__greeting {
      font-size: 20px;
      margin-bottom: 10px;
    }

    .CTA--secondary--dark,
    .CTA--underlined--light {
      display: inline-block;
      margin: 0 auto 20px;
      width: auto;
      min-width: auto;
      font-size: 10px;
    }

    .CTA--secondary--dark {
      min-width: 140px;
    }

    &__actions {
      @media only screen and (min-width: $screen-sm) {
        display: flex;
        flex-direction: column;
      }

      @media only screen and (min-width: $screen-md) {
        flex-direction: row;
        align-items: center;

        > div {
          flex-basis: 50%;
          flex-grow: 0;
          flex-shrink: 0;
          margin-right: 20px;
        }
      }
    }

    @media only screen and (min-width: $screen-sm) {
      text-align: left;
    }
  }

  .profile-benefits {
    background: #f5f5f5;
    padding: 30px 40px;
    text-align: left;
    margin: 0 0 20px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0 0 0 20px;
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: -2px;
        width: 2px;
        background: #000;
        z-index: 1;
      }
    }

    li {
      padding: 0;
      margin: 0 0 20px;
      position: relative;

      &:after {
        background: #fff;
        border: solid 2px #000;
        border-radius: 100px;
        content: "";
        height: 10px;
        width: 10px;
        display: block;
        position: absolute;
        top: 2px;
        left: -26px;
        margin: 0;
        z-index: 2;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .app-booking-information {
    display: none;
  }
  .modal-dialog-content {
    padding: 20px;
  }
  .form-row.textarea.addition-requests-textbox {
    overflow: visible;
    height: 72px;
  }
  .form-row.textarea.addition-requests-textbox.pp {
    height: 150px;

    label {
      line-height: 1.6;
      padding-top: 10px;
      max-height: 148px;
      height: 148px;
      cursor: default;
      text-transform: initial;
    }

    textarea {
      height: 148px;
      max-height: 148px;
    }
  }
  .form-row.textarea.addition-requests-textbox-small-label {
    display: none;
    padding: 10px;
    background-color: #eee;
  }

  .form-input--arrivalTime {
    overflow: hidden;

    .fs-select-mask {
      position: relative;
      border: 1px solid #bebebe;
      margin: -1px;
      min-width: 33.333%;
      float: left;
    }

    .fs-select-mask.in-focus {
      border: 1px solid #bebebe;
      box-shadow: inset 0 0 2px #000;
    }

    .fs-select-mask:last-of-type {
      border-right: none;
    }
  }

  .is-mobile-app & {
    .modal-content-body {
      padding: 20px;
    }
  }

  #submit-personalisation-btn {
    margin-top: 16px;
    float: right;
  }
}

@media only screen and (max-width: $screen-md - 1) {
  .view-booking-flow__personalize-your-stay {
    .form-row.textarea.addition-requests-textbox.pp {
      height: 190px;

      label {
        max-height: 188px;
        height: 188px;
      }

      textarea {
        height: 188px;
        max-height: 188px;
      }
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .view-booking-flow__personalize-your-stay {
    .modal-dialog-content {
      padding: 0;
    }
  }
}

@media only screen and (max-width: $screen-sm - 1) {
  .view-booking-flow__personalize-your-stay {
    .reservation-services-link {
      padding-left: 20px;
    }

    .form-row.textarea.addition-requests-textbox {
      overflow: visible;
      height: 82px;
    }
    .form-row.textarea.addition-requests-textbox.pp {
      height: 82px;

      textarea {
        height: 80px;
        max-height: 80px;
      }
    }
    .form-row.textarea.addition-requests-textbox-small-label {
      display: block;
      border-top-width: 3px;
    }
  }
}

.view-booking-flow__personalize-your-stay {
  .quick-links {
    margin: 40px 0;

    @media screen and (min-width: $screen-sm) {
      margin-top: 20px;
    }
  }
}
