.new-modal {
  top: 0;
  bottom: 0;
  right: 0;
  overflow: visible;
  overflow-y: auto;
  background-color: rgba(50, 50, 50, 0.66);
  position: fixed;
  transition: opacity 0.3s;
  z-index: 200;
  width: 100%;

  .modal-dialog {
    background-color: #fff;
    margin: auto;
    padding: 0;
    z-index: 10;
    height: 100%;

    @media only screen and (min-width: 768px) {
      width: 630px;
      background-color: #323232;
      margin: 10% auto;
      height: auto;
    }

    &__content {
      position: relative;
      height: 100%;
      background-color: #f2f2f2;
      display: flex;
      flex-direction: column;

      .icon-close {
        top: 7px;
        right: 10px;
        position: absolute;
        display: block;

        @media only screen and (min-width: 768px) {
          top: 18px;
        }
      }
    }

    &__title {
      background-color: #fff;
      height: 50px;
      display: flex;

      @media only screen and (min-width: 768px) {
        padding: 20px 20px 20px 20px;
        overflow: hidden;
        height: auto;
        position: relative;
      }

      .back-btn {
        background: transparent;
        color: black;
        position: relative;
      }

      h1 {
        text-align: left;
        font-weight: 300;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 80%;
        padding: 15px 0;

        @media only screen and (min-width: 768px) {
          display: block;
          margin: 0;
          padding-bottom: 0;
          padding-top: 0;
          font-size: 20px;
          text-transform: uppercase;
          min-height: 28px;
        }
      }
    }

    &__body {
      height: 100%;
      overflow: scroll;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;

      @media only screen and (min-width: 768px) {
        position: static;
        overflow: visible;
      }
    }

    .btn-upgrade-option {
      width: 100%;
    }
  }
}

.rtl {
  .new-modal {
    .modal-dialog__title {
      h1 {
        text-align: right;
      }
    }

    .modal-dialog__content {
      .icon-close {
        right: auto;
        left: 10px;
      }
    }
  }
}
