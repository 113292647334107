@mixin rtl {
	// Used to fix node-sass version issue
	#{'&'}:lang(ar) {
		@content;
	}
}

@mixin hidden {
  display: none;
  visibility: hidden;
}

@mixin hide {
  display: none;
}

@mixin show-block {
  display: block;
  visibility: visible;
}

@mixin input-focus {
  background: $focus-color;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.2);
  border-color: darken($input-border-color, 4%);
}

@mixin arrow-defaults($size: 10px) {
  border-color: transparent;
  border-style: solid;
  border-width: $size;
  content: "";
  display: block;
  height: 0;
  width: 0;
}

@mixin arrow-top($color: $white, $size: 10px) {
  @include arrow-defaults($size);
  border-top-width: 0;
  border-bottom-color: $color;
}

@mixin arrow-bottom($color: $white, $size: 10px) {
  @include arrow-defaults($size);
  border-bottom-width: 0;
  border-top-color: $color;
}

// position mixin
// $position: 'absolute', 'relative', 'static'
// $args: e.g. 'top 100% left 50px'
@mixin position($position, $args) {
  @each $o in top right bottom left {
    $i: index($args, $o);

    @if $i and $i + 1 <= length($args) and type-of(nth($args, $i + 1)) == number
    {
      #{$o}: nth($args, $i + 1);
    }
  }

  position: $position;
}

// triangle mixin
// $direction: 'top', 'bottom', 'left' or 'right'
// $position: e.g. 'top 100% left 50px' (will always be position: absolute)
// $color: valid CSS color
// $size: valid css size
@mixin triangle($direction, $position, $color, $size: 10px, $opposite: "") {
  $direction: if(
    function-exists("to-lower-case") == true,
    to-lower-case($direction),
    $direction
  );

  @if $direction == top {
    $opposite: bottom;
    margin-left: -$size;
  } @else if $direction == bottom {
    $opposite: top;
    margin-left: -$size;
  } @else if $direction == right {
    $opposite: left;
    margin-top: -$size;
  } @else if $direction == left {
    $opposite: right;
    margin-top: -$size;
  }

  border: solid transparent $size;
  border-#{ $opposite }-width: 0;
  border-#{ $direction }-color: $color;
  content: "";

  @include position(absolute, $position);
}

@mixin block-link {
  display: inline-block;
  text-decoration: none;
  &:after {
    display: block;
    content: " ";
    padding-top: 3px;
    border-bottom: 1px solid $black;
  }
}

@mixin override-block-link {
  display: inline;
  text-transform: none;
  text-decoration: underline;
  letter-spacing: 1px;
  &:after {
    display: none;
  }
}
