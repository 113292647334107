.view--profile-edit {
  padding-top: 112px;

  @media screen and (min-width: $breakpoints-md) {
    padding-top: 212px;
  }

  @media screen and (min-width: $breakpoints-lg) {
    padding-top: 0;
  }

  .layout--profile-fullscreen__top-bar {
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;

    @media screen and (min-width: $breakpoints-lg) {
      position: static;
    }
  }

  &__navigation {
    order: 1;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      @media screen and (min-width: $breakpoints-md) {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    li {
      margin: 0;
      padding: 0;

      @media screen and (min-width: $breakpoints-lg) {
        margin-bottom: 29px;
      }
    }

    a {
      display: block;
      padding: 0 0 0 20px;
      text-transform: uppercase;
      text-decoration: none;
      color: #bbb;
      font-size: rem(9px, 16px);
      font-family: $hn;
      font-weight: 700;
      line-height: 2;
      position: relative;
      letter-spacing: 2.25px;

      &:hover,
      &:focus {
        color: inherit;
      }

      &.is-selected {
        color: inherit;

        &::before {
          background: #fff;
          border-radius: 100%;
          content: "";
          display: block;
          height: 5px;
          width: 5px;
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -3px;
        }
      }
    }

    &--inline {
      border-top: solid 1px #595959;
      border-bottom: solid 1px #595959;
      margin: 0 -15px;
      height: 40px;
      overflow: hidden;

      @media screen and (min-width: $breakpoints-md) {
        margin-left: 0;
        margin-right: 0;
        height: 90px;
      }

      ul {
        padding: 0 0 100px;
        white-space: nowrap;
        overflow: auto;

        @media screen and (min-width: $breakpoints-md) {
          padding-top: 25px;
        }
      }

      li {
        display: inline-block;
        padding: 0;
      }

      a {
        line-height: 40px;
        padding: 0 10px 0 15px;
      }

      li:first-child {
        padding-left: 20px;
      }

      li:last-child {
        padding-right: 20px;
      }

      @media screen and (min-width: $breakpoints-md) {
        // same as .container
        max-width: $containerWidth-md;
      }

      @media screen and (min-width: $breakpoints-lg) {
        max-width: $containerWidth-lg;
      }
    }
  }

  &__user-details {
    order: 2;

    .user-details {
      padding: 75px 0 0;
      margin: 0 0 80px;

      @media screen and (min-width: $breakpoints-md) {
        padding-top: 60px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        padding-top: 0;
      }
    }

    .user-details__avatar {
      background: #fff;
      border-radius: 100%;
      width: 102px;
      height: 102px;
      margin: 0 0 20px;
    }

    .user-details__name-and-edit {
      border-bottom: solid 1px #595959;
      overflow: hidden;

      .fs-toggle-block {
        &__row {
          .col-lg-8.col-md-10 {
            width: 100%;
          }
        }

        &__label {
          color: inherit;
          display: block;
          letter-spacing: 1.17px;
          font-family: $hn;
          font-weight: 250;
          font-size: rem(28px, 16px);

          .fs-toggle-button {
            margin-top: 40px;
          }

          @media screen and (min-width: $breakpoints-md) {
            margin-bottom: 0;
            text-align: left;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .fs-toggle-button {
              position: static;
              margin-top: 0;
            }
          }
        }

        &__container {
          &__inner {
            margin-top: 20px;
          }
        }

        @media screen and (min-width: $breakpoints-lg) {
          .col-lg-3,
          .col-md-3 {
            padding-top: 20px;
          }
        }
      }

      .CTA--secondary--dark {
        margin-left: auto;
        display: block;
        padding-left: 0;
        padding-right: 0;

        @media screen and (min-width: $breakpoints-md) {
          margin-top: 22px;
        }
      }
    }
  }

  &__wrapper {
    order: 3;
    padding-bottom: 60px;
  }

  &__sidebar {
    display: none;
  }

  &__block {
    margin-bottom: 0px;
    padding-bottom: 80px;

    + .view--profile-edit__block {
      border-top: solid 1px #595959;
      padding-top: 60px;
    }

    &__title-row {
      min-height: 40px;
      overflow: hidden;

      &--limit-reached {
        margin-bottom: 40px;
      }
    }

    h3 {
      font-family: $hn;
      font-weight: 700;
      font-size: rem(12px);
      text-transform: uppercase;
      letter-spacing: 3.6px;

      @media screen and (min-width: $breakpoints-md) {
        font-size: rem(14px);
      }

      @media screen and (min-width: $breakpoints-lg) {
        font-size: rem(16px);
      }
    }
  }

  @media screen and (min-width: $breakpoints-lg) {
    &__navigation--inline {
      display: none;
    }

    &__wrapper {
      padding-bottom: 1200px;
    }

    &__sidebar {
      display: block;
    }
  }

  fs-phone-input {
    .fsp-input--phone-input__country-code {
      label {
        display: none;

        @media screen and (min-width: $breakpoints-sm) {
          display: block;
        }
      }
    }

    label {
      top: 15px;

      @media screen and (min-width: $breakpoints-sm) {
        top: 0;
      }
    }

    .col-sm-8,
    .TextInput {
      position: static;

      @media screen and (min-width: $breakpoints-sm) {
        position: relative;
      }
    }
  }

  .view--profile-edit__sidebar {
    position: sticky;
    top: 50px;
  }
}

.rtl {
  .view--profile-edit__navigation--inline {
    padding-right: 15px;
    padding-left: 10px;
  }

  .view--profile-edit__navigation a {
    padding-right: 20px;
    padding-left: 0;

    &.is-selected::before {
      left: auto;
      right: 0;
    }
  }
}
