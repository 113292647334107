.shopping-cart-summary {
  background-color: #f5f5f5;
  padding: 50px 30px;

  &__title {
    margin: 0 0 30px;
    text-align: center;
    text-transform: none;
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 0 20px;
  }

  &__no-items {
    font-size: 12px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0.5px;
    text-transform: none;
  }

  .shopping-cart-item {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__row {
      display: flex;
      flex-direction: row;
    }

    &__col {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 50%;
      width: 50%;

      &:last-child {
        text-align: right;
      }
    }

    &__item-name {
      margin: 0 0 10px;
    }

    &__quantity {
      color: #2d2d2d;
    }

    &__requestDate {
      margin-top: 5px;
      color: #2d2d2d;
    }

    &__price {
      font-size: 12px;
      letter-spacing: 2.5px;
      margin: 0 0 5px;
    }

    &__taxes {
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #666;
      text-transform: none;
    }

    &__remove {
      background: transparent;
      color: #2d2d2d;
      display: block;
      margin: 0;
      padding: 0;
      border: none;
      text-decoration: underline;
    }
  }

  &__total {
    border-top: 1px solid #d8d8d8;
    padding: 20px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__price {
      font-size: 16px;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }
}
