.view--upgrade-your-room {
  padding: 60px 0 0;

  .fs-tree {
    display: block;
    height: 36px;
    width: 23px;
    margin: 0 auto 20px;
    background: url("/images/profiles_2018/fs-profiles-header/tree.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;

    @media screen and (min-width: $breakpoints-md) {
      height: 60px;
      width: 39px;
    }
  }

  .property-name {
    font-family: $hn;
    font-weight: 250;
    font-size: rem(24px);
    line-height: 1.3;
    letter-spacing: 4px;
    margin: 0 0 24px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: $breakpoints-md) {
      font-size: rem(44px);
      line-height: 1.4;
      letter-spacing: 5px;
    }
  }

  .stay-dates {
    font-family: $hn-roman;
    font-weight: 400;
    font-size: rem(12px);
    line-height: 1.1;
    letter-spacing: 3px;
    margin: 0 0 30px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: $breakpoints-md) {
      font-size: rem(16px);
      line-height: 1.125;
      letter-spacing: 4px;
    }
  }

  .property-image-container {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 64px;
    }
  }

  .property-image {
    padding-top: 56.25%; // 16:9 Aspect Ratio
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }

  .welcome-message {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.3);
    padding: 30px 20px;
    margin: -52px 20px 42px;
    text-align: center;
    position: relative;
    z-index: 20;
    overflow: hidden;
    max-width: 790px;
    backdrop-filter: blur(20px);

    @media screen and (min-width: $breakpoints-md) {
      margin-top: 0;
      margin-bottom: 0;
      bottom: 35px;
      position: absolute;
      padding: 36px 48px;
    }

    p {
      font-family: $hn;
      font-weight: 350;
      font-size: rem(18px);
      line-height: 1.3;
      letter-spacing: 0.5px;
      margin: 0 0 24px;

      @media screen and (min-width: $breakpoints-md) {
        font-size: rem(24px);
        line-height: 1.5;
        letter-spacing: 1px;
      }
    }
  }

  .your-trip-details {
    &__title {
      font-family: $hn;
      font-weight: 100;
      font-size: rem(24px);
      line-height: 1.3;
      letter-spacing: 4px;
      margin: 0 0 30px;
      text-align: left;
      text-transform: uppercase;
    }

    &__list {
      margin: 0 0 50px;
      overflow: hidden;

      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 70px;
      }

      dt {
        border-top: solid 1px #d8d8d8;
        padding: 15px 0 0;
        margin: 0 0 13px;
        font-family: $garamond;
        font-size: rem(22px);
        line-height: 1.5;
        letter-spacing: 0.5px;

        @media screen and (min-width: $breakpoints-md) {
          float: left;
          clear: left;
          width: 40%;
          padding: 30px 0;
          margin: 0;
        }
      }

      dd {
        margin: 0 0 30px;
        font-family: $hn-roman;
        font-weight: 400;
        font-size: rem(12px);
        line-height: 1.5;
        letter-spacing: 3px;
        margin: 0 0 30px;
        text-align: left;
        text-transform: uppercase;

        @media screen and (min-width: $breakpoints-md) {
          float: right;
          border-top: solid 1px #d8d8d8;
          width: 60%;
          padding: 38px 0 30px;
          margin: 0;
          text-align: right;
        }
      }
    }
  }

  .chat-with-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
    color: #fff;
    margin: 0 auto 96px;
    padding: 40px;
    text-align: center;
    max-width: 770px;

    @media screen and (min-width: $breakpoints-md) {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 262px;
    }

    p {
      font-family: $garamond;
      font-size: rem(18px);
      font-style: italic;
      letter-spacing: 0.5px;
      line-height: 1.5;
      text-align: center;
      margin: 0 0 20px;

      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 0;
      }
    }

    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: $breakpoints-md) {
        flex-direction: row;
      }

      button {
        @media screen and (min-width: $breakpoints-md) {
          margin-left: 25px;
        }
      }

      a {
        margin: 0 0 27px;

        @media screen and (min-width: $breakpoints-md) {
          margin-bottom: 0;
        }
      }
    }
  }
}
