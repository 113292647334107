.page-heading {
  line-height: 1.4;
  text-transform: uppercase;
  font-weight: $primary-font-weight;
}


@media screen and (min-width: $screen-sm) {
  .page-heading {
    font-size: 20px;
    margin: 0 0 20px;
  }

  .page-heading.pp {
    margin-bottom: 0px;
  }
}
