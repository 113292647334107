// Color Variables
//
// markup:
// <div class="color-grid">
//   <div class="color teal">#007b8a</div>
//   <div class="color white">#fff</div>
//   <div class="color gray-light">#f2f2f2</div>
//   <div class="color gray-mid-light">#dadada</div>
//   <div class="color gray-mid">#898989</div>
//   <div class="color gray-mid-dark">#686868</div>
//   <div class="color gray-dark">#444</div>
//   <div class="color gray-black">#1d1d1b</div>
//   <div class="color red">#ff4641</div>
// </div>
//
// Styleguide 1.1.0

$white: #fff; // Component backgrounds and overlay text
$teal: #007b8a; // Call to action
$teal-light: mix($white, $teal, 25%);
$teal-lighter: mix($white, $teal, 55%);
$gray-light: #f2f2f2; // View/page background for forms
$gray-mid-light: #dadada; // Border
$gray-mid: #898989; // Placeholders and subheadings
$gray-mid-dark: #686868;
$gray-dark: #444; // System/menu
$gray-black: #1d1d1b; // Brand black and text
$red: #ff4641;  // Error

$color-error: $red;
