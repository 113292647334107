.container,
.row,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  box-sizing: border-box;
}

@media (min-width: 768px) {
  .show-sm {
    display: inherit !important;
  }
  .hide-sm {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .show-md {
    display: inherit !important;
  }
  .hide-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .show-lg {
    display: inherit !important;
  }
  .hide-lg {
    display: none !important;
  }
}

.is-hidden {
  display: none;
}

.full-height {
  // full height hack
  padding-bottom: 100% !important;
  margin-bottom: -100% !important;
}

#tt_price-check,
.tt_widget {
  visibility: hidden;
  opacity: 0;
}
