.layout--profile-auth {
  .view--register-reservation-found {
    .intro {
      margin-bottom: 40px;
    }

    .panels {
      margin: 0 0 50px;
    }

    .panel {
      background: #f9f9f9;
      padding: 20px;
      margin: 0 0 10px;

      @media screen and (min-width: $breakpoints-md) {
        padding-left: 60px;
        padding-right: 60px;
      }
    }

    .reservation-details {
      margin: 0;
      padding: 0;
      text-align: left;

      &__row {
        display: flex;
        flex-direction: row;
        margin: 0 0 10px;

        &__label {
          color: #6c6c6c;
          text-transform: uppercase;
          font-size: rem(12px, 18px);
          letter-spacing: 1px;
          margin: 0 10px 0 0;
          padding-top: 2px;

          flex-basis: 100px;
          flex-grow: 0;
          flex-shrink: 0;
        }

        &__value {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }

    &__actions {
      margin: 0 auto 30px;
      max-width: 300px;
      width: 100%;

      .bricks {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
        }
      }

      .gplus-provider,
      .linkedin-provider {
        margin: 0 0 20px;
      }

      .CTA--secondary--light,
      .CTA--secondary--dark {
        width: 100%;
      }
    }
  }
}
