.layout--profile-modal {
  background: #000;
  color: #fff;
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;

  &--light {
    background: #fff;
    color: #000;
  }
}
