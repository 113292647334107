.fs-membership {
  // background-color: #000;
  // color: #fff;
  padding-top: 70px;

  .view--profile-edit__block {
    margin-bottom: 0;
  }
}

.fs-membership-header {
  margin: 0 0 20px;
  padding: 50px 0 0;

  @media screen and (min-width: $breakpoints-md) {
    padding-top: 10px;
  }

  .container .row {
    position: relative;

    @media screen and (min-width: $breakpoints-lg) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;
    margin-top: 0px;
    margin-left: 0px;

    @media screen and (min-width: $breakpoints-md) {
      margin-top: 40px;
      margin-left: 40px;
    }
  
    @media screen and (min-width: $breakpoints-lg) {
      margin-left: 130px;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    @media screen and (min-width: $breakpoints-xl) {
      margin-left: 40px;
    }
  }

  &__property-container {
    margin-right: 0px;

    @media screen and (min-width: $breakpoints-md) {
      margin-right: 90px;
    }
  }
  &__property-name {
    text-align: start;
    font-size: rem(24px);
    font-family: $hn;
    font-weight: 250;
    letter-spacing: 5px;
    line-height: 1.4;
    margin: 0 0 20px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 15px;
    }
  }

  &__property-address {
    background-image: url("/images/profiles_2018/marker.svg");
    background-repeat: no-repeat;
    background-size: 14px 20px;
    background-position: 0 50%;
    font-family: $hn;
    font-weight: 700;
    font-size: rem(12px);
    text-transform: uppercase;
    padding: 0 0 0 30px;
    line-height: 20px;
    margin: 0 0 40px;
  }

  &__image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    position: relative;

    @media screen and (min-width: $breakpoints-lg) {
      width: auto;
      left: auto !important;
      right: auto !important;
    }
  }

  &__contact-buttons {
    font-family: $hn;
    font-weight: 700;
    font-size: rem(10px);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;

    display: flex;
    flex-direction: row;
    position: absolute;
    top: -105px;
    right: 7.5px;
    margin: 0;
    justify-content: flex-end;
    gap: 30px;


    @media screen and (min-width: $breakpoints-md) {
      top: -110px;
      right: 15px;
      gap: 40px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      position: static;
      margin-top: 36px;
      gap: 50px;
    }

    .contact-button {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 40px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      color: $black;
      text-decoration: none;
      border: none;
      padding: 0;
      margin: 0;

      font-family: $hn;
      font-weight: 700;
      font-size: rem(10px);
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 3px;

      &__icon {
        height: 0;
        width: 100%;
        padding-top: 100%;
        background: $black;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border-radius: 10000px;
        background-size: 100%;

        &--itinerary {
          background-image: url("/images/profiles_2018/itinerary---white.svg");
          background-size: 50%;
        }

        &--chat {
          background-image: url("/images/profiles_2018/chat.svg");
        }

        &--call {
          background-image: url("/images/profiles_2018/call.svg");
        }
      }

      span {
        display: none;
      }

      @media screen and (min-width: $breakpoints-md) {
        height: auto;
        width: 50px;

        span {
          display: inline-block;
          margin-top: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}

.rtl {
  .fs-membership-header {
    &__contact-buttons {
      right: unset;
      left: 7.5px;
    }

    &__property-address {
      background-position: 100% 50%;
      padding-left: 0;
      padding-right: 30px;
    }

    &__image {
      left: unset;
      right: calc(-50vw + 50%);
    }
  }
}

.fs-membership-details {
  margin: 0 0 60px;
  padding: 50px 0 0;

  @media screen and (min-width: $breakpoints-md) {
    padding-top: 10px;
  }

  @media screen and (min-width: $breakpoints-lg) {
    margin-bottom: 120px;
  }

  .container .row {
    position: relative;

    @media screen and (min-width: $breakpoints-lg) {
      display: flex;
      flex-direction: row;
    }
  }

  @media only screen and (max-width: ($breakpoints-sm - 1px)) {
    padding-bottom: 60px;
  }

  &-heading {
    margin-top: 0;
    letter-spacing: 0.1em;
    font-size: rem(16px);
  }

  &-item-heading {
    color: #787878;
    font-size: rem(12px);
    font-weight: 900;
    margin-top: 40px;
    letter-spacing: 0.1em;
  }

  &-item {
    font-size: rem(32px);
    font-family: $hn;
    font-weight: 250;
    letter-spacing: 1.16px;
    line-height: 1.4;
    margin-top: 20px;
  }

  .fs-member-name {
    text-transform: capitalize;
  }
}

.ar.rtl {
  .fs-membership-details {
    .fs-toggle-block__container__inner.fs-toggle-block__container__inner--dark {
      margin-top: 45px;
    }
  }
}
