.filter-results {
  background: #fff;
  border-top: solid 1px $theme-gray4;
  position: relative;

  fieldset {
    margin-top: 0;
    margin-bottom: 0;
  }

  .room-selector {
    background: #999;
    border: none;
    border-right: 1px solid #dcdcdc;
    color: #fff;
    float: left;
    font-size: 12px;
    padding: 6px 6px 2px;

    .room-selector-occupancy {
      font-size: 10px;

      span {
        position: relative;
        top: -2px;

        &.icon {
          font-size: 14px;
          top: 0;
        }

        &.icon-adult {
          margin: 0 -3px;
        }

        &.icon-child {
          margin: 0 -4px;
        }
      }
    }
    .room-number-heading:focus {
      outline: none;
    }

    &:first-child {
      border-left: none;
    }

    &.current-room {
      background: #fff;
      border-right: 1px solid #dcdcdc;
      color: #333;
    }
  }

  .estimated-total {
    display: none;
  }

  /* Best Rate Guaranteed styles */
  .filter-results-brg {
    float: right;

    .best_rate_guaranteed_wrapper {
      background-image: url(/images/best-rate-checkmark-white.png);
      background-color: #000000;
      background-repeat: no-repeat;
      background-size: 26px 22px;
      background-position: 3px 3px;
      position: relative;
      color: black;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
      padding: 1px 1px 1px 1px;
      width: 198px;
      height: 28px;
    }

    .best_rate_guaranteed_title {
      font-family: $primary-font;
      font-size: 9pt;
      background-color: #FFFFFF;
      float: right;
      padding: 5px 7px 2px 7px;
      height: 26px;
      margin: 0px;
      letter-spacing: 0.1em;
    }
  }
}

@media screen and (max-width: $screen-sm - 1) {
  .filter-results {
    .best_rate_guaranteed_wrapper {
      display: none;
    }
  }
}

.filter-results-options {
  background: $theme-gray4;
  padding: 15px 0 0;
  overflow: hidden;

  > .container {
    display: flex;
    flex-direction: column-reverse;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__col {
    flex-basis: 50%;
    padding: 0 15px;
  }

  .fs-select-mask {
    margin: 0;
    min-width: 140px;
    border: none;
    letter-spacing: 0.5px;
    line-height: 22.5px;
    font-size: 16px;
    background: transparent;

    select {
      height: 30px;
      padding-right: 55px;
      padding-left: 0;
    }
  }

  .fs-select-mask + .fs-select-mask {
    margin-top: 10px;
  }

  .checkboxes {
    margin: 15px 0;
    overflow: hidden;
  }

  label.checkbox {
    float: left;
    width: 100%;
    white-space: normal;

    input {
      left: 3px;
    }
  }
}

@media screen and (max-width: $screen-sm - 1) {
  .filter-results-options.ru label.checkbox{
    width:300px;
  }
}

html[dir="rtl"],
.rtl {
  .filter-results-options {
    .fs-select-mask {
      select {
        padding-left: 55px;
        padding-right: 0;
      }

      .icon {
        left: 1px;
        right: auto;
      }
    }

    .checkboxes {
      label.checkbox {
        float: right;
        padding: 0px 22px 0px 0px;
        margin-left: 10px;
        margin-right: 0px;
        input {
          left: inherit;
          right: 3px;
        }
      }
    }

    .filter-currency-order {
      #currency-col-5,
      #rate-col-5{
        select{
          padding-left: 35px;
          padding-right: 0px;
          width: 100% !important;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .filter-results {
    border-top: none;
    border-bottom: solid 1px $light-grey;

    > .container {
      padding: 0 15px
    }

    .room-selector {
      font-size: initial;

      .room-selector-occupancy {
        font-size: 11px;

        span {
          &.icon {
            font-size: 18px;
          }
        }
      }

      &:first-child {
        border-left: 1px solid #dcdcdc;
      }
    }

    &.multi-room {
      padding-right: 0;
    }
  }

  .estimated-total {
    display: block;
    border-bottom: none;
    float: right;
    margin: 0 12px 0 0;
    padding: 0;

    span {
      float: left;
    }

    .estimated-total-label {
      line-height: 26px;
      margin-right: 10px;
      padding: 12px 0;
    }

    .estimated-total-value {
      border-radius: $input-border-radius 0 0 $input-border-radius;
      border: solid 1px $input-border-color;
      border-right: none;
      box-sizing: border-box;
      display: inline-block;
      height: 26px;
      line-height: 24px;
      margin: 12px 0 0;
      min-width: 70px;
      padding: 0 12px;
      text-align: right;
    }

    .change-currency {
      background: #999;
      border-radius: 0 $input-border-radius $input-border-radius 0;
      display: block;
      border: none;
      height: 26px;
      line-height: 24px;
      margin-top: 12px;
      position: relative;
      min-width: 65px;
      overflow: hidden;

      select {
        background: transparent;
        border-radius: 0;
        height: 26px;
        border-color: #999;
        color: #fff;
        padding: 0 0 0 2px;
        position: relative;
        z-index: 2;
        width: 130%;
        -webkit-appearance: none;
            -moz-appearance: none;
                appearance: none;
      }

      select:focus {
        color: $primary-text-color;
      }

      .icon-arrow-down {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 20px;
        color: #FFF;
        pointer-events: none;
        z-index: 1;
      }
    }
  }


  .filter-results-options {
    > .container {
      flex-direction: row;
      justify-content: flex-end;
    }

    &__col {
      flex-basis: auto;
    }

    .checkboxes {
      margin-bottom: 0;
    }

    label.checkbox {
      margin-right: 10px;
      width: auto;
    }

    .fs-select-mask {
      display: inline-block;
      width: auto;
    }

    .fs-select-mask + .fs-select-mask {
      margin-top: 0;
    }

    fieldset {
      margin: 0px;
    }
  }
}

.filter-results-options {
  .filter-currency-order{
    .AccessibilityLabelDiv {
      position: relative;
      height: 20px;
    }

    .AccessibilityLabel {
      position: absolute;
      bottom: 0;
      padding-left: 2px;
    }
  }
}


@media screen and (min-width: $screen-sm) {
  .jp .filter-results-options .filter-currency-order .fs-select-mask {
    width: 42%;
  }
}


@media screen and (max-width: $screen-md) {
  .filter-results-options {
    #access-col-6{
      width: 58%;
      margin-top: 8px;

      .checkboxes {
        margin-top: 15px;
      }
    }

    .filter-currency-order{
      width: 39%;
    }
  }
}

@media screen and (max-width: $screen-sm - 1) {
  .filter-results-options {
    .filter-currency-order{
      width: 100%;

      .col-sm-5 {
        width: 45%;
      }

      #currency-col-5{
        float: right;
      }
    }

    .filter-currency-order{
      .AccessibilityLabel {
        padding-left: 12px;
      }
    }
  }

  .rtl {
    .filter-results-options {
      .filter-currency-order{
        #currency-col-5{
          float: left;
        }
      }
    }
  }
}
