@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=31389174-2d9d-4960-9463-19d4ea0d5720");

@font-face{
    font-family:"AdobeCaslonW01-Regular";
    src:url("/fonts/adobe-caslon/ef429e53-4846-40b9-a3fe-4295cefc7e94.eot?#iefix");
    src:url("/fonts/adobe-caslon/ef429e53-4846-40b9-a3fe-4295cefc7e94.eot?#iefix") format("eot"),url("/fonts/adobe-caslon/3531483a-a370-4cac-bcd3-9cbd5c0aa8fe.woff2") format("woff2"),url("/fonts/adobe-caslon/29fc7b6f-c777-4db4-99c7-98a753629a74.woff") format("woff"),url("/fonts/adobe-caslon/492aae7f-5ec2-484f-8c4a-5dceb929def5.ttf") format("truetype"),url("/fonts/adobe-caslon/27231d8a-1ac1-4ede-8e91-c7bb60852443.svg#27231d8a-1ac1-4ede-8e91-c7bb60852443") format("svg");
}
@font-face{
    font-family:"Adobe Caslon W01 It";
    src:url("/fonts/adobe-caslon/48e53584-22ac-4328-a940-54ac91ee35db.eot?#iefix");
    src:url("/fonts/adobe-caslon/48e53584-22ac-4328-a940-54ac91ee35db.eot?#iefix") format("eot"),url("/fonts/adobe-caslon/df7ba2f1-324a-404c-88c7-f68adad9ac33.woff2") format("woff2"),url("/fonts/adobe-caslon/67620760-8bd8-45d7-b70d-de7936d7a6e1.woff") format("woff"),url("/fonts/adobe-caslon/d39c7172-0364-4db6-80c5-116c450e3e98.ttf") format("truetype"),url("/fonts/adobe-caslon/c1570ebd-fc91-4d70-b33b-8f19e9a0fabf.svg#c1570ebd-fc91-4d70-b33b-8f19e9a0fabf") format("svg");
}
@font-face{
    font-family:"Adobe Caslon W01 SmBd";
    src:url("/fonts/adobe-caslon/d2804130-67b0-4fcf-98fe-d781df92a56e.eot?#iefix");
    src:url("/fonts/adobe-caslon/d2804130-67b0-4fcf-98fe-d781df92a56e.eot?#iefix") format("eot"),url("/fonts/adobe-caslon/becfadb1-eaca-4817-afbd-fe4d61e1f661.woff2") format("woff2"),url("/fonts/adobe-caslon/6530bac7-21ac-4e52-a014-dce6a8d937ab.woff") format("woff"),url("/fonts/adobe-caslon/b34e8a45-c92d-4402-89bc-43cc51c6b4e0.ttf") format("truetype"),url("/fonts/adobe-caslon/0804bb4a-399c-4547-9fa8-0acf6268d8f6.svg#0804bb4a-399c-4547-9fa8-0acf6268d8f6") format("svg");
}
@font-face{
    font-family:"Helvetica Neue LT W01_71488914";
    src:url("/fonts/helvetica-neue/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
    src:url("/fonts/helvetica-neue/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"),url("/fonts/helvetica-neue/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"),url("/fonts/helvetica-neue/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"),url("/fonts/helvetica-neue/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype"),url("/fonts/helvetica-neue/e4d5b881-6835-45b7-8766-3e5b9e7cab8b.svg#e4d5b881-6835-45b7-8766-3e5b9e7cab8b") format("svg");
}
