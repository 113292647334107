$my-stay-header-font: "Helvetica Neue LT W01_71488914";
$my-stay-serif-font: "AdobeCaslonW01-Regular";

@mixin my-stay-serif-paragraph {
  font-family: $my-stay-serif-font;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 0.5px;
  word-break: break-word;
}

@mixin my-stay-input-down-arrow {
  content: '\25BE';
  color: $black;
  padding-left: 10px;
}

.profile-body.view-my-stay {
  padding-top: 25px;
  .panel-body__content {
    overflow: auto;
  }

  .panel-nav__heading {
    margin: 15px 0 0;
    padding-bottom: 0;
  }

  .panel-nav__item__link {
    text-decoration: none;
  }

  .panel-body {
    padding: 25px;
  }

  .profile-booking-title {
    display: none;
  }

  .panel-body__heading {
    margin-bottom: 45px;

    h2 {
      margin-bottom: 15px;
    }
  }
}

.my-stay-heading--top,
.my-stay-heading--property {
  text-align: center;
  /*@extend %hero;*/
}

.view-my-stay-landing-page  .panel-nav__item {
  border-top: 0;
  border-bottom: 1px solid $new-light-grey;
}

.my-stay--subheading-main {
  font-size: $font-size-large;
  margin-top: 40px;
  margin-bottom: 15px;
  color: $black;
}

.my-stay-modal_error {
  text-align: center;
  h3 {
    color: $white;
  }
}

.cancel-booking-info {
  margin-top: 10px;
}

@media screen and (min-width: $screen-xs) {
  .profile-body.view-my-stay {
    padding-top: 0;

    .panel-body {
      padding: 0 0 20px;
    }
  }

  .hero {
    position: relative;

    .hero__content {
      line-height: 1.2;
      position: relative;
      z-index: 1;
    }
  }

  .my-stay--header .my-stay__tile {
    padding-bottom: 0;

    .my-stay__tile--content-wrapper {
      min-height: initial;
    }
  }

  .my-stay--subheading-main {
    font-size: 24px;
    margin-top: 70px;
    margin-bottom: 15px;
  }
  .fr .my-stay--header .my-stay__tile {
    .my-stay__tile--content-wrapper {
      height: auto;
    }
  }
}

.my-stay-nav--back-to-profile {
  display: block;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: 12px;
  text-decoration: underline;
  padding-top: 10px;
  padding-bottom: 60px;
}

[class*="view-my-stay"] {
  header a,
  main a,
  .modal-dialog-content a,
  .appear-as-link,
  .form-input__link {
    color: $black;
    letter-spacing: 2px;
    font-family: $my-stay-header-font;
    text-transform: uppercase;
    &.underline {
      text-decoration: none;
    }
    &.nav-flyout__link {
      @media only screen and (max-width: $screen-sm-max) {
        color: $white;
      }
    }

    &.icon {
      font-family: "fs-rbf-font";
      text-transform: none;
    }

    &:hover,
    &:focus {
      color: #7D7D7D;
      text-decoration: none;
    }
  }
  header {
    .header-background {
      background-color: $black;
    }
    .logo {
      color: $white;
    }
  }
  p {
    color: $black;
  }
  .footer-inner {
    &,
    .legal {
      background-color: $black;
    }
    .icon {
      color: $white;
    }
  }
  .form-input {
    input,
    .selectize-input {
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid $black;
      padding-left: 0;
      font-family: HelveticaNeue-Light,"Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 18px;
      color: $black;
    }
    .selectize-input {
      > .item {
        padding-left: 0;
      }
      &.input-active > .item {
        left: 14px;
      }
    }
  }
  .form-input--checkbox {
    width: 100%;

    span:first-of-type {
      float: left;
    }
  }
  .form-input--checkbox__box {
    border-color: $black;
    color: $black;
    background: transparent;
  }
  input:checked + .form-input--checkbox__box {
    border-color: $black;
    color: $white;
    background: $black;
  }
  .selectize-control {
    &.single {
      // arrow override
      .selectize-input:after {
        @include my-stay-input-down-arrow;
      }
    }
  }
  .form-input--tel .intl-tel-input .selected-flag .arrow {
    margin-top: -5px;
    &:before {
      @include my-stay-input-down-arrow;
    }
  }
  .hero__content {
    padding-bottom: 20px;
    h1,
    h4 {
      color: $white;
    }
  }
  .is-on {
    border-color: $black;
    .form-input__switch__knob {
      background: $black;
    }
  }
  .intl-tel-input {
    .flag-dropdown,
    .flag-container {
      background: transparent;
      border: none;
      border-bottom: 1px solid $black;
    }
  }
  .has-error .flag-dropdown,
  .has-error .flag-container {
    border-bottom: 1px solid #ff4641;
  }
  .modal-title {
    padding: 60px;
    h4 {
      font-weight: bold;
      text-align: center;
    }
  }
  .panel-nav__item__link {
    max-width: 250px;
  }
  .panel-nav__content {
    border-bottom: 1px solid $new-light-grey;
  }
  .panel-body__heading,
  .panel-body__heading--xs-only {
    display: block;
  }
}

.view-my-stay {
  .detail-items {
    border-top: none;

    > ul {
      border: none;
    }
  }

  .detail-item {
    border: none;
    margin-bottom: 10px;
    background: $white;
  }

  .detail-item__title {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 18px;
  }

  .detail-item--mystaytotal {
    .detail-item__body {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .detail-item__title {
      font-family: "Helvetica Neue LT W01_71488914";
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 10px;
    }

    .detail-item__subtitle {
      font-family: "AdobeCaslonW01-Regular";
      color: $black;
      line-height: 1.5;
      font-size: 1em;
      letter-spacing: .027em;
      font-weight: 400;
    }
  }

  .detail-items__collapse--xs-only {
    background-color: transparent;
  }

  .detail-item__icons > span {
    display: block;
    border-bottom: 1px solid $black;
    font-family: $my-stay-header-font;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $black;
    position: relative;
    height: 26px;
    left: -15px;
    cursor: pointer;
    &.icon-arrow-left,
    &.icon-arrow-right {
      border-bottom: none;
      font-family: "fs-rbf-font";
      font-size: 18px;
      font-weight: normal;
      left: -5px;
    }
  }

  .profile-booking-cancel-text {
    margin-top: 30px;
    padding-bottom: 30px;

    p {
      font-size: 10px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  .panel-body__strapline {
    text-align: center;
    text-transform: none;
  }
}

@media only screen and (min-width: $screen-sm) {
  .view-my-stay .hero-panel__nav {
    width: 250px;
  }
}
