.my-stay-sign-in-body {
  .hero-body__title {
    background-color: #fff;
    border-bottom: $panel-border;
    margin: -($hero-body-padding);
    margin-bottom: 0;
    padding: 20px;
    text-align: left;
  }

  .wrapper__title {
    margin-top: $hero-body-padding;
  }
}

// desktop
@media screen and (min-width: $screen-sm) {
  .my-stay-sign-in-body {
    max-width: 450px;

    .hero-body__title {
      margin: -($hero-body-padding--desk);
      margin-bottom: 0;
    }
  }
}
