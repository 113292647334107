//
// Choice Items
// (i.e. radio boxes)
//

$choice-items-margin: 10px (-$form-padding) 0;
$choice-items-margin-desk: 10px auto 0;
$choice-item-padding: $page-padding;
$choice-item-border: $detail-item-border;

$choice-item-font-size: 16px;
$choice-item-line-height: 24px;
$choice-item-text-color: $new-dark-grey;

$choice-item-control-margin: $page-padding;
$choice-item-control-size: $choice-item-line-height;
$choice-item-control-border: $detail-item-border;
$choice-item-control-background: $detail-item-background;
$choice-item-control-dot-transition: background 0.1s linear 0.1s;
$choice-item-control-dot-spacing: 4px;
$choice-item-control-dot-size: ($choice-item-control-size - ($choice-item-control-dot-spacing * 3));
$choice-item-control-dot-active-color: $new-medium-grey;

.choice-items {
  @extend %detail-items;

  margin: $choice-items-margin;
}

.choice-item {
  @extend %detail-item;

  padding: $choice-item-padding;

  .choice-item__body {
    display: block;
    overflow: hidden;
    color: $choice-item-text-color;
    font-size: $choice-item-font-size;
    line-height: $choice-item-line-height;
    // one-liners
    white-space: no-wrap;
    text-overflow: ellipsis;
  }
}

.choice-item__control {
  border-radius: 50%;
  float: right;

  position: relative;
  display: inline-block;
  margin-left: $choice-item-control-margin;
  width: $choice-item-control-size;
  height: $choice-item-control-size;
  border: $choice-item-control-border;
  background: $choice-item-control-background;

  .rtl & {
    float: left;
    margin-right: $choice-item-control-margin;
    margin-left: auto;
  }

  // radio type, ideally
  input {
    display: none;
  }
}

.choice-item__control__dot {
  -webkit-transition: $choice-item-control-dot-transition;
  transition: $choice-item-control-dot-transition;
  position: absolute;
  display: block;
  margin: auto;
  height: $choice-item-control-dot-size;
  width: $choice-item-control-dot-size;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  background-color: $choice-item-control-background;
  border-radius: 50%;

  input:checked + & {
    background-color: $choice-item-control-dot-active-color;
  }
}


@media screen and (min-width: $screen-sm) {

  .choice-items {
    margin: $choice-items-margin-desk;
  }

  .choice-item {
    border-right: $detail-item-border;
    border-left: $detail-item-border;
    &:last-of-type{border-bottom: $detail-item-border;}
  }

}

