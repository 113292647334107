.available-packages {
  list-style: none;
  margin: 20px 0 0;
  padding: 0;
  border-top: solid 1px $light-grey;

  @media only screen and (min-width: $screen-sm) {
    border-top: none;
  }
}
