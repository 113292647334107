// Type Variables
//
// If you have included the Style Kit SCSS in your project, you can use measurement variables to keep components consistent and in line with other Four Seasons digital content.
//
// #### Text sizes
// Text variables use a function to calculate the relative `em` value from the pixel value. The equivilent `em` value is a relative size ratio based on the base font size (`16px`). See [this resource](http://www.w3schools.com/cssref/css_pxtoemconversion.asp "W3 em conversion") for more info.
//
// | SCSS variable | px size | em size
// |----------------------------|------|
// | `$font-size-xsmall`  | 12px  | 0.75em
// | `$font-size-small`   | 14px  | 0.875em
// | `$font-size-normal`   | 16px | 1em
// | `$font-size-large`    | 18px | 1.125
// | `$font-size-xlarge`   | 24px | 1.5em
// | `$font-size-xxlarge`  | 36px | 2.25em
//
// ### Uses
// Type size variables are used to keep consistent text sizing for views and components. Example:
//
//`$text-offer-promo-title: $font-size-large;`
//
// Styleguide 1.3.0

$base-font-size: 16px;
$base-font-weight: 400;
$base-font-weight-overlay: 400;
$base-font-weight-bold: 400;
$base-line-height: 1.5;

$base-font-family-sans: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-family-sans-weighted: 'HelveticaNeue-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; // Needed for when bolder weights are referenced in css (not yet implemented)
$base-font-family-serif: 'Adobe Caslon Pro', 'Adobe Caslon', Times, 'Times New Roman', Georgia, serif;
$base-font-icon: "FSIcons";

$letter-spacing-normal: 0.027em;
$letter-spacing-large: 0.08em;
$letter-spacing-xlarge: 0.1em;

$font-size-xsmall: em(12px);
$font-size-small: em(14px);
$font-size-normal: em($base-font-size);
$font-size-large: em(18px);
$font-size-xlarge: em(24px);
$font-size-xxlarge: em(36px);
