.fs-profile-modal-container {
  background: rgba(0,0,0,.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
  padding: 20px;
  overflow: auto;

  @media screen and (min-width: 460px) {
    padding: 40px;
  }
}

.fs-profile-modal {
  background: #fff;
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
  padding: 40px 20px;
  color: #000;
  text-align: center;
  position: relative;

  @media screen and (min-width: 460px) {
    padding: 40px;
  }

  h1 {
    font-size: rem(26px);

    @media screen and (min-width: 460px) {
      font-size: rem(32px);
    }
  }

  form {
    margin-top: 60px;
    text-align: center;

    .form-error {
      display: inline-block;
    }
  }

  .verification-code {
    margin-left: -20px;
    margin-right: -20px;
  }

  &__form-actions {
    margin: 60px 0 0;

    > * {
      margin: 0 20px;
    }
  }

  &__content {
    margin: 30px 0;
  }

  &__close {
    height: 32px;
    width: 32px;
    position: absolute;
    top: 10px;
    right: 10px;
    text-align: center;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    font-size: 32px;
    line-height: 32px;
  }
}

.tick-animation {
  height: 200px;
  margin: 0 0 40px;

  svg {
    background: #fff;
    height: 100%;
    margin: 0 auto;

    .path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 0;

      &.circle,
      &.check {
        opacity: 0;
      }
    }
  }

  &--start {
    svg {
      .path {
        $duration: 1.8s;
        &.circle {
          opacity: 1;
          animation: circle-2 $duration ease-in-out;
        }
        &.check {
          opacity: 1;
          animation: dash-check-2 $duration ease-in-out;
        }
      }
    }
  }
}

@keyframes circle-2 {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-check-2 {
  0% {
    stroke-dashoffset: 1000;
  }
  35% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

.profiles--tooltip__container {
  .profiles--tooltip {
    display: none;
  }

  &:hover {
    .profiles--tooltip {
      display: block;
    }
  }
}

.profiles--tooltip {
  padding: 15px;
  background: #000;
  border: solid 1px #fff;
  color: #fff;
  position: absolute;
  top: 100%;
  right: -40px;
  z-index: 100;
  width: 200px;
  margin-top: 12px;

  &:after {
    content: "";
    border: solid 10px transparent;
    border-top-width: 0;
    border-bottom-color: #000;
    position: absolute;
    top: -10px;
    right: 40px;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  &__trigger {
    border-radius: 100%;
    border: solid 1px #000;
    cursor: pointer;
    display: inline-block;
    height: 16px;
    width: 16px;
    text-decoration: none;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }

  p {
    font-family: $hn;
    font-size: rem(12px);
  }
}
