//
// Price Summary View
//
$price-summary-font-size: 13px;
$price-summary-reg-font: "Helvetica Neue LT W01_71488914";
$price-summary-line-height: 1;

$price-summary-color: $black;

$price-summary-content-font-size: 12px;
$price-summary-content-line-height: 1.3;
$price-summary-content-spacing: 0 0 20px;

$price-summary-disclaimer-symbol-spacing: 5px;
$price-summary-disclaimer-spacing: 20px 0;

$price-summary-block-spacing: 20px 0 5px;
$price-summary-row-spacing: 2px 0;

$price-summary-amount-width: 12em;

.price-summary {
  display: block;
  color: $price-summary-color;
  font-size: $price-summary-font-size;
  line-height: $price-summary-line-height;
  letter-spacing: 0.2em;

  .rtl & {
    text-align: left;
  }
}

.price-summary__heading {
  font-family: $price-summary-reg-font;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 5px;
}

%price-summary__block,
.price-summary__block {
  display: block;
  padding: $price-summary-block-spacing;
  border-top: 1px solid;
  margin-top: 20px;

  list-style: none;
}

.price-summary__taxes {
  @extend %price-summary__block;
}

.price-summary__costs {
  @extend %price-summary__block;
  margin-bottom: 0;
  & + .price-summary__block {
    border-top: none;
    padding-top: 0;
    margin-top: 0;
  }
}

.price-summary__total {
  @extend %price-summary__block;

  width: 100%;
}

.price-summary__row {
  width: 100%;
  padding: $price-summary-row-spacing;
  margin: 0;

  list-style: none;

  &:not(:first-of-type) {
    border-top: 1px solid;
    margin-top: 20px;
    padding-top: 20px;
  }
}

.price-summary__amount {
  width: $price-summary-amount-width;
}

.price-summary__link {
  font-family: $price-summary-reg-font;
  font-weight: bold;
  text-transform: uppercase;
}

%price-summary__content,
.price-summary__content {
  display: block;
  text-align: initial;
  padding: $price-summary-content-spacing;
  font-size: $price-summary-content-font-size;
  line-height: $price-summary-content-line-height;

  p {
    margin: 0;
    padding: 0;
    font-size: 16px;
  }
}

.price-summary__disclaimer {
  @extend %price-summary__content;
  padding: $price-summary-disclaimer-spacing;

  &:before {
    content: "*";
    float: left;
    padding-right: $price-summary-disclaimer-symbol-spacing;
  }

  .rtl & {
    &:before {
      float: right;
      padding-left: $price-summary-disclaimer-symbol-spacing;
      padding-right: 0;
    }
  }
}
