.cancellation {
  margin: 25px 30px 0;

  .cancel-error {
    background-color: #e63a2e;
    color: #FFF;
    display: block;
    margin-bottom: 10px;
    padding: 10px;
  }

  .receive-additional {
    font-weight: 600;
    padding: 5px 0 15px;
  }

  .cancel-checkbox {
    position: relative;

    label {
      position: relative;
      display: block;
      line-height: 20px;
      padding-left: 20px;
      margin-bottom: 5px;
    }

    input[type="checkbox"] {
      position: absolute;
      left: 0;
      top: 3px;
    }    
  }

  .input-error {
    color: #F12625;
    font-style: italic;
    line-height: 24px;
  }

  .cancel-btn {
    padding-top: 20px;
    width: 200px;
  }

  .col-xs-12 {
    margin-bottom: 20px;
  }

  p {
    margin-top: 0;
    margin-bottom: 5px;
  }

}
