[class*="view-my-stay"] {
  .panel-messages {
    margin: 0 0 30px;
  }
  .panel-message {
    padding: 45px;
    background: $black;
    color: $white;
    font-family: "Helvetica Neue LT W01_71488914";
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
    text-align: center;
  }
  .panel-body .panel-message__icon--dismiss {
    border: none;
    margin: 10px;
    color: $white;
    height: 36px;
    width: 36px;
    font-size: 36px;
  }
}

@media screen and (min-width: $screen-sm) {
  [class*="view-my-stay"] {
    .panel-messages {
      margin: 25px 35px 0;
    }
    .panel-message {
      padding: 35px 60px;
      text-align: left;
    }
    .panel-body .panel-message__icon--dismiss {
      margin: auto 35px;
    }
  }
}
