.employee-portal {
  background-color: #fff;
  color: black;

  .form-container {
    padding: 30px;
    @media screen and (min-width: $breakpoints-md) {
      padding: 50px 60px 60px;
    }
  }

  .error-banner {
    background-color: $error-red;
    color: $white;

    .message {
      padding: 24px 15px 20px;
      @media screen and (min-width: $breakpoints-md) {
        padding: 24px 60px 20px;
      }
      max-width: 1120px;
      margin: 0 auto;

      a {
        color: $white;
      }
    }

    .actions {
      text-align: center;
      padding-bottom: 24px;

      button {
        background-color: $error-red;
        border-color: $white;

        &:hover {
          background-color: $white;
        }
      }
    }
  }

  h1 {
    margin: 0 0 30px 0;

    @media screen and (min-width: $breakpoints-md) {
      margin: 0 0 50px 0;
    }
  }

  .booking-options-form {
    max-width: 1120px;
    margin: 0 auto;

    @media screen and (min-width: $breakpoints-md) {
      box-shadow: 0 8px 51px -14px rgba(0, 0, 0, 0.3);
      padding: 30px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      padding: 30px 90px 90px 90px;
    }

    .stay-history-link {
      width: 100%;
      text-align: center;
      margin: -6px 0 25px;

      @media screen and (min-width: $breakpoints-md) {
        text-align: right;
        margin: -95px 0px 70px 90px;
      }

      &::before {
        content: url("/images/profiles_2018/stay-history.svg");

        @media screen and (min-width: $breakpoints-md) {
          content: "";
        }
      }

      a {
        color: #7d7d7d;
        vertical-align: super;
        margin-left: 5px;
      }

      @include rtl {
        @media screen and (min-width: $breakpoints-md) {
          margin: -95px 30px 95px;
          text-align: left;
        }

        @media screen and (min-width: $breakpoints-lg) {
          margin: -95px 90px 95px;
        }

        a {
          margin-left: 0;
          margin-right: 5px;
        }
      }
    }

    .fs-radio-inputs {
      text-align: left;
      margin: 0 20px 30px;

      @include rtl {
        text-align: right;
      }

      @media screen and (min-width: $breakpoints-md) {
        text-align: center;
      }

      p {
        margin: 0 0 20px 0;
        font-size: 20px;
        line-height: 1em;

        @media screen and (min-width: $breakpoints-md) {
          margin-bottom: 30px;
        }
      }

      ul.radio-buttons {
        @media screen and (min-width: $breakpoints-md) {
          text-align: center;
        }
      }
    }

    ul.radio-buttons {
      list-style: none;
      text-align: left;
      margin: 0;
      padding: 0;

      @include rtl {
        text-align: right;
      }

      @media screen and (min-width: $breakpoints-md) {
        text-align: center;
      }

      .fs-radio {
        margin: 0 0 20px;

        input {
          height: 20px;
          width: 20px;
          margin: 0 20px 0 0;

          @include rtl {
            margin: 0 0 0 20px;
          }
        }

        input:checked {
          box-shadow: inset 0 0 0 5px #fff;
        }
      }

      li {
        @media screen and (min-width: $breakpoints-md) {
          display: inline-block;
          margin-right: 30px;

          @include rtl {
            margin-left: 30px;
            margin-right: 0;
          }
        }
      }
    }

    .rate-options {
      .Search-By-Navigation-bar {
        display: none;
        text-align: center;
        margin: 0;
        padding: 0 0;

        @media screen and (min-width: $breakpoints-lg) {
          padding: 0 12%;
        }
    
        @media screen and (min-width: $breakpoints-md) {
          display: block;
        }

        .Search-By-Navigation-tab {
          float: left;
          width: 50%;
          text-transform: capitalize;

          .Search-By-Navigation-item {
            display: inline-block;
            font-weight: 500;
            border: none;
            background-color: #fff;
            padding-bottom: 20px;
      
            &.is-selected {
              font-weight: 700;
              border-bottom: 3px solid #000;
            }
          }
        }
      }

      .Search-By-Dropdown {
        display: block;
        @media screen and (min-width: $breakpoints-md) {
          display: none;
        }

        .Dropdown {
          padding-bottom: 0px;
          margin: 15px 10px 10px;

          .formElement-field {
            background-color: #fff;
            border-bottom: none;
            height: 40px;
            font-size: 10px;
            line-height: 26px;
            padding: 0;
          }

          select.formElement-field {
            padding-left: 10px;
          }

          &:after {
            top: 10px;
          }

        }
      }
    }
    hr.employee-booking-options {
      @media screen and (max-width: $breakpoints-md) {
        margin-left: -30px;
        margin-right: -30px;
      }
    }

    .location-and-dates {
      .destination-count {
        float: right;
        padding-top: 8px;
        text-transform: none;
      }

      .change-search {
        position: relative;

        /* -- OLD GRID STYLES --*/
        &.calendar-inline {
          @media screen and (min-width: $breakpoints-md) {
            padding-left: 15px;
            padding-right: 15px;
          }

          margin-bottom: 30px;

          .datepicker {
            background-color: $gray6;

            &.calendar-open {
              border: solid 1px $gray2;
              border-bottom: none;

              &.checking-which-checkIn {
                .check-in {
                  .date-trigger-day {
                    background-color: $gray1;
                  }
                }
              }

              &.checking-which-checkOut {
                .check-out {
                  .date-trigger-day {
                    background-color: $gray1;
                  }
                }
              }
            }

            .fs-select-date {
              width: 50%;
              display: inline-block;

              &.check-in {
                .date-trigger-day {
                  margin-right: 1px;

                  @include rtl {
                    margin-right: 0px;
                    margin-left: 1px;
                  }
                }
              }

              label {
                @include typography(c4);
                width: 100%;
                display: inline-block;
                text-align: center;
              }

              button {
                width: 100%;
                border: none;
              }

              .date-trigger-day {
                @include typography(c3);
                line-height: 12px;
                padding: 6px 0;
                background-color: $gray2;
              }

              .date-trigger__date-month {
                border-bottom: 1px solid $black;
                background-color: $gray6;

                .date-trigger-month {
                  border: none;
                  @include typography(c4);
                }
                .date-trigger-date {
                  @include typography(h1);
                  font-size: 32px;
                  line-height: 48px;
                  letter-spacing: 4px;
                  border: none;
                }
              }
            }
          }

          .fs-calendar-wrapper {
            border: solid 1px $gray2;
            border-top: none;
          }
        }

        &.calendar-floating {
          .datepicker {
            input {
              padding-left: 20px;
              padding-right: 20px;
              width: 100%;
            }
          }
          .fs-calendar-wrapper {
            top: 179px;
            left: 25%;

            @include rtl {
              right: 25%;
              left: unset;
            }

            @media screen and (min-width: $breakpoints-lg) {
              top: 75px;
              left: 50%;

              @include rtl {
                right: 50%;
                left: unset;
              }
            }

            @media screen and (min-width: $breakpoints-md) {
              &.checkOut:before {
                right: 20%;
                @include rtl {
                  left: 20%;
                  right: unset;
                }
              }
            }

            .fs-calendar {
              box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.1);
              border: 1px solid $gray3;
              border-top: 0;
            }
          }

        &.dates-first {
          .fs-calendar-wrapper {
            left: 0%;
            @include rtl {
              right: 0%;
              left: unset;
            }
          }
        }
      }

        /* -- OLD GRID STYLES --*/

        /* -- Calendar styles for both */
        .fs-calendar-wrapper {
          z-index: 55;

          .fs-calendar {
            border-radius: 0;

            .ui-datepicker-header {
              height: 45px;
              background-color: $gray1;
              border-radius: 0;

              .ui-datepicker-title {
                height: 45px;
                line-height: 45px;
                font-size: 18px;
                font-style: italic;
              }

              .icon-button {
                height: 45px;
                font-size: 18px;
                width: 15px;
              }

              .ui-datepicker-prev {
                margin-left: 20px;

                @include rtl {
                  margin-left: 0;
                  margin-right: 20px;
                }
              }
              .ui-datepicker-next {
                margin-right: 20px;

                @include rtl {
                  margin-right: 0;
                  margin-left: 20px;
                }
              }
            }

            .ui-datepicker-calendar {
              border: none;

              th {
                @include typography(c1);
                line-height: 16px;
              }
            }

            .date-field {
              border: 1px solid $gray2;

              &.outside-checkin-range {
                border: none;
              }

              span {
                @include typography(c1);
                line-height: 30px;
              }

              is-unavailable:not(.ui-state-disabled) {
                background-color: $gray7;
              }
            }

            .datepicker-footer {
              height: auto;
              border: 0;
              .nights-selected {
                padding: 15px;
                width: 112px;
                font-size: 15px;
                line-height: 16px;
                font-style: italic;
                letter-spacing: 0;
                height: 100%;

                span {
                  @include typography(h1);
                  font-size: 21px;
                  line-height: 24px;
                  font-style: normal;
                  margin-bottom: 11px;
                }

                @include rtl {
                  float: right;
                  border-right: 0;
                  border-left: solid 1px $gray3;
                }
              }

              .legend {
                margin-left: 111px;
                padding: 15px 16px 15px 15px;
                height: 100%;
                border: none;
                border-left: solid 1px $gray3;

                @include rtl {
                  padding: 15px 15px 15px 16px;
                  margin-left: 0px;
                  margin-right: 111px;
                  border-left: 0;
                  border-right: solid 1px $gray3;
                }

                .availability {
                  @include typography(c4);
                  margin-bottom: 21px;

                  .is-unavailable {
                    width: 15px;
                    height: 15px;
                    color: $gray3;
                    margin-left: 10px;
                    right: unset;
                    left: unset;

                    @include rtl {
                      margin-left: 0px;
                      margin-right: 10px;
                    }
                  }
                }

                .restrictions {
                  display: none;
                }

                .updated-at {
                  @include typography(q2);
                  font-size: 12px;
                  line-height: 16px;
                }
              }
            }
          }
        }
        /* -- Calendar styles for both */
      }
    }

    .view__actions {
      text-align: center;
    }

    .section-header {
      color: $gray2;
      margin-bottom: 15px;
    }

    .display-field {
      label {
        display: block;
        margin-top: 15px;
        margin-bottom: 20px;
      }

      span {
        display: block;
        margin-bottom: 30px;
      }
    }

    hr {
      border: none;
      border-top: 1px solid $gray7;
      margin: 0 0 30px 0;

      &.sub-divider {
        margin-bottom: 20px;
      }
    }

    .occupancy {
      margin-bottom: 30px;

      .alignment {
        width: 311px;
        margin: 0 auto;

        .adults,
        .children {
          display: inline-block;
        }

        .adults {
          margin-right: 30px;
          padding-right: 31px;
          position: relative;

          @include rtl {
            margin-right: 0px;
            padding-right: 0px;
            margin-left: 30px;
            padding-left: 31px;
            border-right: 0;

            &:after {
              left: 0;
              right: unset;
            }
          }

          &:after {
            content: "";
            display: block;
            background-color: $gray7;
            position: absolute;
            height: 60px;
            width: 1px;
            right: 0;
            top: 5px;
          }
        }
      }
    }
  }

  .room-details {
    margin-bottom: 35px;

    @media screen and (min-width: $breakpoints-md) {
      .section-header {
        width: 50%;
        display: inline-block;

        &.total-nights {
          line-height: 16px;
          text-align: right;
          color: $black;

          @include rtl {
            text-align: left;
          }
        }
      }
    }

    .room-detail {
      position: relative;
      margin-bottom: 15px;

      .room-number {
        text-align: center;
        margin-bottom: 20px;

        h3 {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .btn-close {
        top: 20px;
        right: 0px;

        @include rtl {
          right: unset;
          left: 0;
        }
      }

      ul.radio-buttons {
        text-align: left;

        @include rtl {
          text-align: right;
        }

        li {
          display: inline-block;
          margin-right: 30px;

          @include rtl {
            margin-left: 30px;
            margin-right: 0;
          }
        }
        .fs-radio {
          input {
            height: 16px;
            width: 16px;
            margin: 0 15px 0 0;

            @include rtl {
              margin: 0 0 0 15px;
            }
          }

          span {
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
    }

    .add-room {
      text-align: center;
      padding-left: 20px;

      @include rtl {
        padding-left: 0;
        padding-right: 20px;
      }

      button {
        position: relative;
        font-size: 12px;
        line-height: 16px;

        @include rtl {
          &:after,
          &:before {
            left: unset;
            right: -20px;
            transform: translate(50%, -50%);
          }
        }
        &:after,
        &:before {
          content: "";
          display: block;
          background-color: #000;
          position: absolute;
          top: 50%;
          left: -20px;
          transform: translate(-50%, -50%);
          -webkit-transition: background-color 0.3s ease-out,
            color 0.3s ease-out, border-color 0.3s ease-out;
          transition: background-color 0.3s ease-out, color 0.3s ease-out,
            border-color 0.3s ease-out;
        }

        &:before {
          height: 16px;
          width: 1px;
        }

        &:after {
          height: 1px;
          width: 16px;
        }

        &:hover {
          &:after,
          &:before {
            background-color: $whover;
          }
        }
      }
    }
  }

  .special-requests {
    margin-bottom: 15px;
  }

  .employee-notes {
    h2 {
      color: $gray2;
    }

    ul.radio-button {
      margin: 20px 0 40px;
    }
  }

  .booking-options-form {
    .fsp-input {
      padding-bottom: 27px;
    }

    .relation-to-guest {
      padding-bottom: 11px;
    }
  }
}

/*
	Styles for modals in Employee Portal
*/
.fs-modal-container.employ-portal-modal-container {
  background-color: rgba(0, 0, 0, 0.5);

  @media screen and (min-width: $breakpoints-md) {
    padding-top: 180px;
  }

  .modal--friends-and-family.view--profile-folio,
  .modal--profile-language-support.view--profile-folio {
    max-width: 930px;
    padding: 60px 0;
    height: auto;

    @media screen and (min-width: $breakpoints-md) {
      padding: 30px 30px;
    }

    h3 {
      margin-bottom: 30px;
    }

    p {
      margin: 0 0 30px;
    }
    p.email-error {
      color: red;
    }

    .btn-close {
      height: 18px;
      width: 18px;
      top: 30px;
      right: 30px;

      @include rtl {
        right: unset;
        left: 30px;
      }

      &.email-error {
        color: red;
      }
    }
  }
}
