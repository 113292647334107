.cancellation-date {
  background-image: url("/images/profiles_2018/offers.svg");
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 22px;
  font-style: normal;
  font-family: $hn;
  font-weight: bold;
  font-size: 10px;
  line-height: 1.4;
  letter-spacing: 1px;
  padding-left: 28px;
  text-transform: uppercase;
  min-height: 22px;
  display: flex;
  align-items: center;
}

.rtl {
  .cancellation-date {
    background-position: 100% 50%;
    padding-left: 0;
    padding-right: 28px;
  }
}
