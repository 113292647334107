.fsp-input {
  color: #000;
  padding: 0 0 38px;
  position: relative;
  text-align: left;

  &--dark {
    color: #fff;
  }

  &--is-invalid {
    color: $error-red;
  }

  &--checkbox {
    padding: 0;

    &.exclusives-consent {
      font-size: rem(15px);

      .phone-nobr {
        white-space: nowrap;
      }
    }
  }

  input.checkbox {
    appearance: none;
    cursor: pointer;
    height: 20px;
    width: 20px;
    background: transparent;
    border: solid 1px #000;
    display: inline-block;
    vertical-align: text-top;

    &:checked {
      background-color: #000;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSIxMXB4IiB2aWV3Qm94PSIwIDAgMTEgMTEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJGaWx0ZXIvY2hlY2tib3gvb24iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC01LjAwMDAwMCwgLTUuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIj4gICAgICAgICAgICA8cG9seWdvbiBpZD0iUGF0aC0yIiBwb2ludHM9IjUgMTAgNSAxMiA5IDE2IDE2IDcgMTYgNSA5IDE0Ij48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: 50% 25%;
      background-size: 12px;
    }
  }

  &__checkbox {
    display: block;
    position: relative;
    padding-left: 30px;

    &[disabled] {
      opacity: 0.5;
    }

    input.checkbox {
      position: absolute;
      top: 3px;
      left: 0;
    }
  }

  &--dark {
    input.checkbox {
      border: solid 1px #fff;

      &:checked {
        background-color: #fff;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgICAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGRlZnM+PC9kZWZzPiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0ic3Vic2NyaXB0aW9ucy9tb2JpbGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCAtMjk3LjAwMDAwMCkiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDIwOS4wMDAwMDApIj4gICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTE4LUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCA4NC4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgIDxnIGlkPSJpY29ucy9jaGVja2JveC9vbi93IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwLjAwMDAwMCwgNC4wMDAwMDApIj4gICAgICAgICAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlLTQiIGZpbGw9IiNGRkZGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIgeD0iMCIgeT0iMCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48L3JlY3Q+ICAgICAgICAgICAgICAgICAgICAgICAgPHBvbHlnb24gaWQ9IlBhdGgtMiIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIiBwb2ludHM9IjUgMTAgNSAxMiA5IDE2IDE2IDcgMTYgNSA5IDE0Ij48L3BvbHlnb24+ICAgICAgICAgICAgICAgICAgICA8L2c+ICAgICAgICAgICAgICAgIDwvZz4gICAgICAgICAgICA8L2c+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
        background-position: 50% 50%;
        background-size: 20px;
      }
    }
  }

  &--expiry-date {
    padding-bottom: 30px;
    margin: 30px 0 30px;

    .Dropdown {
      padding-bottom: 0;
      margin-bottom: 0;

      &--dark {
        color: $white;
      }
    }

    &.formElement-field--invalid {
      color: $error-red;

      .Dropdown {
        color: $error-red;
      }
    }
  }

  &--verification-code {
    border-width: 1px;
    border-color: #d8d8d8;
    border-style: solid;
    border-radius: 5px;
    height: 60px;
    font-family: $hn;
    font-weight: 250;
    width: 44px;
    font-size: rem(32px, 16px);
    line-height: 1.5;
    margin: 0 5px;
    padding: 5px;
    text-align: center;
  }
}

.fsp-input--phone-input {
  .Dropdown,
  .TextInput {
    padding-bottom: 0;
  }

  .formElement-message {
    position: static;
  }

  &__country-code {
    position: relative;

    &__label {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      z-index: 11;
      pointer-events: none;

      @include rtl {
        left: unset;
        right: 0;
        text-align: right;
      }
    }
  }
}

.rtl {
  .fsp-input__checkbox {
    padding-left: 0;
    padding-right: 30px;
    text-align: right;

    input.checkbox {
      left: unset;
      right: 0;
    }
  }
}

.view--upcoming-trip__modal--change-date {
  .fsp-input--checkbox {
    float: left;
  }
}

.rtl {
  .view--upcoming-trip__modal--change-date {
    .fsp-input--checkbox {
      float: right;
    }
  }
}

.DisplayOnlyInput {
  &-text {
    margin-top: 10px;
    .TextInput {
      margin-top:0;
      padding-bottom: 0;
    }
  }

  &-field {
    text-align: left;
	cursor: default;
	padding: 10px 0;
	&.formElement-field {
		cursor: not-allowed;
		padding: 0.5em 0 0.5em 10px;
	}
  }
  &-label {
    cursor: default;
  }
  &-phone-countryCode {
    padding-right: 0;
    .Dropdown::after {
      right: 8px;
      @include viewport(large) {
        right: 14px;
      }
    }
    .DisplayOnlyInput-field span::after {
      content: "";
      height: 27px;
      outline: 1px solid #d8d8d8;
      position: absolute;
      right: 0;
    }
  }
  &-phone-phoneNumber {
    padding-left: 0;
  }
}