.view-three-sixty-images {
  header, footer {
    display: none;
  }
  .main-inner {
    padding: 0;
  }
}

#three-sixty-iframe {
  height: 80vh;
  width: 100%;
  display: block;
  border: 0;
}

.optly-360-svg-wrapper {
  display: none;
  position: absolute;
  z-index: 9;
  background: rgba(0,0,0,0.4);
  cursor: pointer;
  left: 167px;
  top: 121px;

  p {
    width: 100px;
    color: #fff;
    padding-left: 20px;
    margin: 0.5em 0;
  }

  p, .optly-360-deg-icon {float: left;}
}

.optly-three-sixty-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999;
  cursor: -webkit-grab;
  cursor: grab;
}

.optly-switch-360 {
  display: inline-block;
  position: absolute;
  top: 14px;
  right: 10px;
  margin-left: 44px;
  margin-right: 40px;
  height: 43px;
  .btn {
    height: 43px;
    padding: .8125em 0;
    display: inline-block;
    width: 160px;
  }
}

@media only screen and (min-width: $screen-sm) {
  .optly-360-svg-wrapper.show-360-imgs {
    display: block;
  }
  .modal-360 .modal-dialog.container {
    padding: calc(5% - 10px) 0;
  }
}

@media only screen and (min-width: $screen-md) {
  .optly-360-svg-wrapper {
    left: 245px;
    top: 166px;
  }
}
