$detail-button-padding: 20px;

.detail-buttons {
  display: block;
  padding-top: $detail-button-padding;
}

// default display modes
.detail-buttons__add {
  display: block;
}

.detail-buttons__delete {
  display: block;

  // icon size override
  .btn__icon {
    font-size: 18px;
  }
}

.detail-buttons__done {
  display: none;
}

@media screen and (max-width: $screen-sm-max) {
  .detail-buttons {
    padding-right: $detail-button-padding;
    padding-left: $detail-button-padding;
  }

  .detail-buttons__delete-toggles {
    padding-top: 10px;
  }

  // toggle button display on mobile only
  .detail-buttons--deleting {
    .detail-buttons__add,
    .detail-buttons__delete {
      display: none;
    }
    .detail-buttons__done {
      display: block;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  // do not display delete toggles on desktop
  .detail-buttons__delete-toggles {
    display: none;
  }
}
