.confirm-stay-form {
  .btn-primary {
    margin-top: 15px;
  }

  .privacy-wrapper {
    padding: 0;
  }
}

@media screen and (min-width: $screen-sm) {
  .confirm-stay-form {
    overflow: auto;

    .form-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .btn-primary {
      width: 220px;
    }

    .privacy-wrapper {
      padding: 0 10px;
    }
  }

  .confirm-stay-form {
    input,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="search"],
    input[type="range"],
    textarea {
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
      &.empty {
        color: transparent;
      }

      &[disabled] {
        &::-webkit-input-placeholder {
          color: transparent;
        }
        &:-moz-placeholder {
          color: transparent;
        }
        &::-moz-placeholder {
          color: transparent;
        }
        &:-ms-input-placeholder {
          color: transparent;
        }
        &.empty {
          color: transparent;
        }
      }
    }
  }
}
