.employee-stay-history {
  background-color: #fff;
  color: black;

  .container {
    padding: 30px 20px;
    @media screen and (min-width: $breakpoints-md) {
      padding: 50px 20px 60px;
    }
  }

  .employee-stats {
    max-width: 930px;
    margin: 0 auto;
    padding: 0;
  }
  
  h1 {
    margin: 0 0 30px 0;

    &.user-name {
      font-size: rem(20px);
      font-weight: 600;
      margin-bottom: 10px;
      line-height: 28px;
      letter-spacing: 0.03em;
      text-transform: capitalize;
    }

    &.status,
    &.continuous-service-date {
      font-size: rem(14px);
      font-weight: 300;
      margin-bottom: 10px;
      line-height: 28px;
      letter-spacing: 0.06em;
      text-transform: capitalize;
      margin-bottom: 0px;
    }

    &.continuous-service-date {
      margin-bottom: 30px;
    }

    &.entitlement {
      font-size: rem(15px);
      font-weight: 700;
      letter-spacing: em(4px);
      text-transform: none;

      @media screen and (max-width: 767px) {
        font-size: rem(14px);
      }
    }
  }

  hr {
    border: none;
    border-top: 1px solid $black;
    margin: 0 auto 30px;

    &.small-divider {
      width: 50px;
      margin-bottom: 15px;
    }
  }

  .employee-stats h1 {
    text-align: left;
  }

  .employee-booking-stats {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    background: #F4F4F4;
    padding: 50px 60px 20px;
    margin-bottom: 70px;
    
    .stat-column {
      width: 100%;
      padding-bottom: 30px;
  
      @media screen and (min-width: $breakpoints-md) {
        width: 50%;
      }
      @media screen and (min-width: $breakpoints-lg) {
        width: 25%;
      }
    }

    .stat-value {
      font-size: rem(20px);
      font-weight: 300;
      margin-bottom: 0px;
      line-height: 32px;
      letter-spacing: 0.03em;
      text-transform: none;
    }

    .stat-label {
      font-size: rem(12px);
      font-weight: 500;
      margin-bottom: 0px;
      line-height: 18px;
      letter-spacing: 0.15em;
    }
  }

  body {
    font-family: 'lato', sans-serif;
  }
  
  h2 {
    font-size: 26px;
    margin: 20px 0;
    text-align: center;
    small {
      font-size: 0.5em;
    }
  }
  
  .stay-history {
    max-width: 930px;
    margin: 0 auto;
    padding: 0;
  }
  .year-history {
    .year-heading {
      border-top: 1px solid #878787;
      margin: 0;
      padding: 30px 0 30px 20px;

      @include rtl {
        padding: 30px 20px 30px 0;

        @media screen and (max-width: 767px) {
          padding-right: 0;
        }
      }

      @media screen and (max-width: 767px) {
          padding-left: 0;
      }

      .year-wrapper {
        position: relative;
      }
      button {
        font-family: $hn;
        font-size: 10px;
        font-weight: 700;
        line-height: 13px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #000000;
        position: absolute;
        right: 20px;
        border: none;
        background: transparent;
        color: #000;
        top: 5px;

        @media screen and (max-width: 767px) {
          right: 0;
        }

        &.expand {
          &::after {
            content: url('/images/profiles_2018/expand-arrow.svg');
          }
        }
        &.collapse {
          &::after {
            content: url('/images/profiles_2018/collapse-arrow.svg');
          }
        }

        @include rtl {
          left: 20px;
          right: auto;

          @media screen and (max-width: 767px) {
            left: 0;
          }

          &.expand {
            &::after {
              padding-right: 4px;
            }
          }
          &.collapse {
            &::after {
              padding-right: 4px;
            }
          }
        }
      }

      .year-row {
        font-family: $hn;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 4px;
        text-transform: uppercase;
        color: #000;
      }
    }

    &:last-of-type {
      border-bottom: 1px solid #878787;
    }
  }

  table {
    border: none;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    color: #2D2D2D;

    tr {
      background: #fff;
      border-top: 1px solid #D8D8D8;
    }
      
    td {
      padding: 20px 0 20px 20px;
      text-align: left;

      &:last-of-type {
        padding-right: 20px;
      }
    }
        
    th {
      background: #F4F4F4;
      font-family: $hn;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 3px;
      text-transform: uppercase;
      padding: 24px 0 24px 20px;
      text-align: left;

      &:last-of-type {
        padding-right: 20px;
      }
    }

    @include rtl {
      td {
        padding: 20px 20px 20px 0;
        text-align: right;

        &:last-of-type {
          padding-left: 20px;
        }
      }

      th {
        padding: 24px 20px 24px 0;
        text-align: right;

        &:last-of-type {
          padding-left: 20px;
        }
      }
    }
  }
  
  @media screen and (max-width: 767px) {

    table {
      border: 0;

      thead { display: none; }

      tr {
        border-bottom: none;
        display: block;
        margin-bottom: .625em;
      }

      td {
        border: none;
        display: block;
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 25px;
        text-align: right;
        padding: 10px 0;

        &:first-of-type {
          padding-top: 25px;
        }

        &:last-of-type {
          padding-right: 0;
        }

        &::before {
          content: attr(data-label);
          float: left;
          font-family: $hn;
          font-size: 10px;
          font-weight: bold;
          line-height: 13px;
          letter-spacing: 3px;
          color: #2D2D2D;
          text-transform: uppercase;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      @include rtl {
        td {
          text-align: left;
          padding: 10px 0;

          &:first-of-type {
            padding-top: 25px;
          }

          &:last-of-type {
            padding-left: 0;
          }
  
          &::before {
            content: "";
          }

          &::after {
            content: attr(data-label);
            float: right;
            font-family: $hn;
            font-size: 10px;
            font-weight: bold;
            line-height: 13px;
            letter-spacing: 3px;
            color: #2D2D2D;
            text-transform: uppercase;
          }
        }
      }
    }  
  }
}
