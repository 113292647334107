.guests-dropdown {
  position: relative;

  .guests-dropdown-options {
    background: $white;
    border: 2px solid $theme-black;
    border-top: none;
    border-bottom-left-radius: $input-border-radius;
    border-bottom-right-radius: $input-border-radius;
    display: none;
    left: 0;
    right: 0;
    top: $theme-input-height + 2px;
    z-index: $z_guestDropdownList;
  }

  .guests-dropdown-selected {
    background: #fff;
    border-radius: $input-border-radius;
    border: 1px solid $input-border-color;
    cursor: pointer;
    color: $theme-black;
    overflow: hidden;
    padding: 0 38px 0 10px;
    height: 43px;
    line-height: 43px;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: content-box;

    .icon {
      color: $dark-grey;
      height: $input-height-with-border;
      width: $input-height-with-border;
      position: absolute;
      top: 0;
      text-align: center;
      line-height: 42px;
      font-size: 24px;
      right: 0;
    }
  }

  .guests-dropdown-more-options {
    @include theme-link;
    cursor: pointer;
    padding: 10px 0;
    text-align: center;
  }

  button.guests-dropdown-more-options {
    border: none;
    border-top: 1px solid $input-border-color;
    letter-spacing: 0.027em;
    background: inherit;
  }

  button.guests-dropdown-more-options:hover {
    background: $lighter-grey;
  }

  .guests-dropdown-option {
    border-top: 1px solid $input-border-color;
    cursor: pointer;
    padding: 10px;

    &:hover {
      background: $lighter-grey;
    }

    &:focus {
      background: $lighter-grey;
    }

    &:first-child {
      border-top: none;
    }
    .room-heading {
      padding: 0 0 5px;
      line-height: 24px;
      margin-top: -5px;

      .remove-room {
        color: #878787;
        cursor: pointer;
        float: right;
        font-size: 24px;
      }
    }

    .room-occupancy-fieldset {
      float: none !important;
      margin: 0;

      legend {
        background: inherit;
        color: inherit;
        text-transform: none;
        text-align: left;
        letter-spacing: 0.027em;

        button {
          width: 24px;
          border: none;
          min-width: auto;
          padding: 0;
          margin: 0;
          background: transparent;
        }
      }

      &.multi-room {
        margin-top: -5px;
      }

      fieldset {
        &.fs-occupancy-input {
          float: none;
          margin: 0 0 10px 0;

          .occupancy-input {
            float: left;
          }
        }
      }

      .child-occupancy {
        fieldset {
          &.fs-occupancy-input {
            margin: 0;
          }
        }
      }
    }
  }

  .guests-dropdown-call-option {
    border-top: 1px solid $input-border-color;
    padding: 10px 14px;
    text-align: center;

    &:hover {
      background: $lighter-grey;
    }

    a {
      display: inline;
    }
  }
}

.rtl {
  .guests-dropdown {
    .guests-dropdown-selected {
      .icon {
        left: 0;
        right: auto;
      }
    }
  }
  .guests-dropdown-option {
    .room-heading {
      .remove-room {
        float: left;
      }
    }

    .room-occupancy-fieldset {
      legend {
        text-align: right;
      }

      fieldset {
        &.fs-occupancy-input {
          .occupancy-input {
            float: right;
          }
        }
      }
    }
  }
}

.guests-dropdown.shows-options {
  .guests-dropdown-options {
    display: block;
  }

  .guests-dropdown-selected {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include theme-input-focus;
  }
}

.bl-external-1:not(.mobile):not(.cfg-mobile) {
  .guests-dropdown {
    .guests-dropdown-options {
      background: #eee;
    }
  }
}

.bl-external-1 {
  .guests-heading {
    padding-bottom: -10px !important;
  }

  .guests-heading.editing {
    font-weight: bold;
  }

  .guests-dropdown {
    .guests-dropdown-options {
      .fs-occupancy-input-small.adults {
        float: left;
      }

      .fs-occupancy-input-small.children {
        float: right;
      }

      .fs-occupancy-input-small {
        width: 48%;

        .fs-occupancy-label-small {
          float: left;
          padding-right: 5px;
        }

        .fs-occupancy-label-small.children {
          padding-left: 20px;
        }

        button {
          float: left;
          width: 15px;
          height: 22px;
          line-height: 1em;
          text-align: center;
          text-transform: uppercase;
          padding: 0.15em 0.15em 0.5em 0.35em;
          cursor: pointer;
          vertical-align: middle;
          text-decoration: none;
          font-size: 0.875em;
          background-color: #a6a8ab;
          color: white;
          text-indent: -0.44em;
          border-style: solid;
          border-color: #a6a8ab;
        }

        .fs-occupancy-small {
          float: left;
          margin: 0 20px;
          font-weight: bold;
        }
      }

      .guests-dropdown-separator {
        border-top: 1px solid #bebebe;
      }

      .guests-dropdown-more-options {
        float: left;
        border-top: none;
        color: #ee4023;
        text-decoration: none;
      }

      .guests-dropdown-call-option {
        float: left;
        width: 50%;
        text-align: left;
      }

      .guests-dropdown-edit-done {
        float: right;
        width: 30%;
        margin-top: 5px;
        line-height: 1;
        min-width: 90px;
      }
    }
  }
}

@media only screen and (max-width: calc($screen-sm / 2)) {
  .bl-external-1 {
    .guests-dropdown {
      .guests-dropdown-options {
        .fs-occupancy-input-small.children {
          width: 52%;
        }

        .fs-occupancy-input-small {
          .fs-occupancy-label-small.children {
            padding-left: 0px;
          }

          .fs-occupancy-small {
            margin: 0 5px;
          }
        }

        .guests-dropdown-edit-done {
          float: right;
          width: 30%;
          margin-top: 5px;
          line-height: 1;
          min-width: 90px;
        }
      }
    }
  }
}

.rtl {
  .guests-dropdown {
    .guests-dropdown-selected {
      padding-left: 38px;
      padding-right: 10px;
    }
  }
}
