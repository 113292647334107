/* Form Rows ===========
======================*/
.form-row {
  border: 1px solid $input-border-color;
  clear: both;
  margin-bottom: -1px;
  min-height: $input-height;
  position: relative;

  input[type="text"],
  input[type="tel"],
  input[type="number"],
  input[type="email"],
  input[type="password"],
  select {
    border: none;
    border-radius: 0;
    height: $input-height;
  }

  textarea {
    border: none;
    border-radius: 0;
    height: ($input-height * 2);
  }

  label {
    display: none;
  }

  .email-sign-in {
    display: inherit;
    border: none;
    background: lightgrey;
    padding-left: 10px;
    height: 40px;
  }

  .inline-error {
    display: none;
    pointer-events: none;
    padding: 5px;
    position: absolute;
    right: 0;
    width: 180px;
    top: 0;
    bottom: 0;
    z-index: $z_errorHint;

    .message {
      background: #fff;
      border-radius: 4px;
      border: 2px solid $error-color;
      bottom: 50%;
      color: $error-color;
      display: none;
      padding: 5px;
      position: absolute;
      right: -1px;
      text-align: center;
      z-index: $z_tooltip;
      margin-bottom: 22px;

      &:after,
      &:before {
        border-color: transparent;
        border-style: solid;
        border-width: 8px;
        border-bottom-width: 0;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        width: 0;
        bottom: -10px;
        right: 9px;
      }

      &:after {
        border-top-color: $white;
        bottom: -7px;
      }

      &:before {
        border-top-color: $error-color;
      }
    }
    .message.pp {
      right: 25px;
    }

    .icon {
      pointer-events: none;
      position: absolute;
      bottom: 55%;
      right: 0;
      height: $input-height;
      width: $input-height;
      color: $error-color;
      font-size: 20px;
      line-height: $input-height;
      margin-bottom: -($input-height * 0.5);
      text-align: center;
    }
    .icon.pp {
      margin-right: 25px;
    }
  }
  .inline-error.pp {
    width: 205px;
  }

  .inline-message {
    padding: 5px;
    position: absolute;
    right: 0;
    width: 40px;
    top: 0;
    bottom: 0;
    z-index: $z_errorHint;

    .message {
      background: #fff;
      border-radius: 4px;
      border: 2px solid $theme-black;
      bottom: 55%;
      color: $theme-black;
      width: 250px;
      padding: 5px;
      position: absolute;
      right: -1px;
      text-align: center;
      z-index: $z_tooltip;
      margin-bottom: 22px;

      &:after,
      &:before {
        border-color: transparent;
        border-style: solid;
        border-width: 8px;
        border-bottom-width: 0;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        width: 0;
        bottom: -10px;
        right: 9px;
      }

      &:after {
        border-top-color: $white;
        bottom: -7px;
      }

      &:before {
        border-top-color: $theme-black;
      }
    }

    .icon {
      pointer-events: none;
      position: absolute;
      bottom: 55%;
      right: 2px;
      height: 30px;
      width: 30px;
      color: $theme-black;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: -17px;
      text-align: center;
      border: 2px solid $theme-black;
      border-radius: 20px;
      font-family: $header-font;
    }
  }

  &.credit-cards {
    overflow: hidden;
    padding: 10px;
    vertical-align: middle;

    span {
      display: block;
      font-size: $secondary-font-size;
      font-style: italic;
      line-height: 24px;
      margin-right: 5px;
    }
    .card {
      margin: 0 8px 0 0;
      display: inline;
      vertical-align: middle;
    }
  }
}

.form-row.has-errors.in-focus,
.form-row.has-errors.checkbox {
  .inline-message {
    .message {
      margin-bottom: 82px;
    }
  }
}

.rtl {
  .form-row {
    &.credit-cards {
      padding-left: 0px;
      padding-right: 0px;
    }

    .inline-error {
      right: auto;
      left: 0px;

      .message {
        right: auto;
        left: -1px;

        &:after,
        &:before {
          right: auto;
          left: 9px;
        }
      }

      .icon {
        right: auto;
        left: 0px;
      }
    }
  }
}

.form-row.has-errors {
  input,
  textarea {
    padding-right: 40px;
  }

  .inline-error {
    display: block;
  }
}

.form-row.checkbox.is-disabled {
  background: $lighter-grey;
}

.form-row.has-errors.in-focus,
.form-row.has-errors.checkbox,
.form-row input:focus + .inline-error,
.form-row select:focus + .inline-error,
.form-row textarea:focus + .inline-error {
  .message {
    display: block;
  }
}

.prefix-row,
.country-row {
  label {
    display: block;
    line-height: $input-height;
    padding: 0 0 0 10px;
    width: 40%;
    float: left;
  }

  select {
    width: 80%;
  }
}

@media screen and (min-width: $screen-xs) {
  .form-row.credit-cards {
    span {
      display: inline;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .form-row label {
    text-transform: capitalize;
    background: $lighter-grey;
    border-right: 1px solid $input-border-color;
    bottom: 0;
    display: block;
    line-height: $input-height;
    left: 0;
    padding-left: 10px;
    position: absolute;
    top: 0;
    width: 202px;
  }
  .form-row.checkbox-marketing label {
    line-height: inherit;
  }

  .form-row {
    position: relative;
    padding-left: 202px;
  }

  .rtl {
    .form-row label {
      border-left: 1px solid $input-border-color;
      border-right: none;
      right: 0;
      padding-right: 10px;
      padding-left: 0px;
    }

    .form-row {
      padding-right: 202px;
      padding-left: 0px;

      .select-box {
        label {
          right: 16px;
        }
      }
    }
  }
  .pt {
    .form-row label {
      width: 370px;
    }
    .form-row {
      padding-left: 370px;
    }
  }
  .fr {
    .form-row label {
      width: 295px;
    }
    .form-row {
      padding-left: 295px;
    }
  }

  .de {
    .form-row label {
      width: 300px;
    }
    .form-row {
      padding-left: 300px;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .form-row label {
    width: 268px;
  }
  .form-row {
    padding-left: 268px;
  }

  .rtl {
    .form-row {
      padding-right: 268px;
    }
  }
  .fr {
    .form-row label {
      width: 350px;
    }

    .form-row {
      padding-left: 350px;
    }
  }

  .de {
    .form-row label {
      width: 350px;
    }

    .form-row {
      padding-left: 350px;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .it {
    .form-row label {
      width: 400px;
    }
    .form-row {
      padding-left: 400px;
    }
  }

  .br {
    .form-row.send-guest-email-row,
    .form-row.agent-id-confirmation-row {
      label {
        line-height: 1.2;
        overflow: hidden;
      }
    }
  }

  .es {
    .form-row.credit-card-date,
    .form-row.send-guest-email-row,
    .form-row.agent-id-confirmation-row {
      label {
        line-height: 1.2;
        overflow: hidden;
      }
    }
  }

  .kr,
  .jp {
    .form-row.send-guest-email-row {
      label {
        line-height: 1.2;
        overflow: hidden;
      }
    }
  }

  .ru {
    .form-row.email-confirmation-row,
    .form-row.credit-card-date,
    .form-row.send-guest-email-row,
    .form-row.agent-id-row,
    .form-row.agent-id-confirmation-row,
    .form-row.advisor-id-row {
      label {
        line-height: 1.2;
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: $screen-sm - 1px) {
  .form-row.send-guest-email-row {
    overflow: hidden;
  }

  .form-row {
    .email-sign-in {
      padding-top: 10px;
    }
  }
  .send-guest-email-row {
    label {
      display: block;
      padding: 10px 0 10px 10px;
      width: 50%;
      float: left;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
    }

    select {
      width: 30%;
    }
  }
  .rtl {
    .send-guest-email-row,
    .country-row {
      label {
        padding: 10px 10px 10px 0;
        float: right;
      }
    }
  }
}

/* TextArea Box Row ======
======================*/
.form-row.textarea,
.textarea {
  overflow: hidden;
  position: relative;

  textarea {
    background: $white;
    border-radius: 0;
    border: none;
    padding: 10px;
    position: relative;
    width: 100%;
    float: left;
    z-index: 1;
  }
}

@media screen and (min-width: $screen-sm) {
  .form-row.textarea,
  .textarea {
    background-color: $lighter-grey;

    textarea {
      padding-top: 5px;
    }

    textarea,
    label {
      height: 70px;
      max-height: 70px;
    }
  }
}

/* Credit Card Expiry ==
======================*/
.form-row.credit-card-date {
  border: none;
  border-bottom: 1px solid $input-border-color;
  overflow: hidden;

  .card-year,
  .card-month {
    clear: none;
    float: left;
    width: 50%;
    display: flex;
    flex-direction: row;

    .fs-select-mask {
      flex-grow: 1;
      flex-shrink: 1;
    }

    label {
      line-height: $input-height;
      padding: 0 0 0 10px;
      position: static;
      width: 60px;
      z-index: 1;
    }
    #labelCardExpiryMonth,
    #labelCardExpiryYear {
      border: none !important;
      background: none !important;
    }
  }

  .select-value {
    padding-left: 8px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: 0;
    left: 60px;
    right: 0;
    z-index: 1;
    text-indent: 0;
  }

  select {
    margin: 0 0 0 -55px;
    position: relative;
    z-index: 2;
    width: 145% !important;
  }

  .card-month {
    border-right: none;
  }
}
.rtl {
  .form-row.credit-card-date {
    .card-year,
    .card-month {
      clear: none;
      float: right;

      label {
        left: auto;
        right: 16px;
      }
    }
    .card-month {
      border-right: 1px solid $input-border-color;
    }
    .card-year {
      border-right: none;
    }
    .select-value {
      padding-left: 0;
      padding-right: 8px;
      top: 0;
      left: 0;
      right: 60px;
    }
    select {
      margin: 0 -50px 0 0;
    }
  }
}

@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
  .form-row.credit-card-date {
    .card-year.has-errors {
      .select-value {
        left: 38px;
      }
    }
    .card-month.has-errors {
      .select-value {
        left: 54px;
      }
    }
  }
}

.rtl {
  .form-row.credit-card-date {
    .card-year,
    .card-month {
      clear: none;
      float: right;

      label {
        left: auto;
        right: 16px;
      }
    }
    .card-month {
      border-right: 1px solid $input-border-color;
    }
    .card-year {
      border-right: none;
    }
    .select-value {
      padding-left: 0;
      padding-right: 8px;
      top: 0;
      left: 0;
      right: 60px;
    }
    select {
      margin: 0 -50px 0 0;
    }
  }
}

.personalise-form {
  margin-top: 20px;
  margin-bottom: 20px;

  ul {
    margin: 0;
    padding: 0;
  }

  &.room-item {
    border: none;
    min-height: initial;
  }

  &.room-item .room-item-preview {
    border: 1px solid $light-grey;
    border-bottom: none;
    overflow: hidden;
  }

  &.room-item .room-item-image {
    top: 0;
    left: 0;
  }
}

@media screen and (min-width: $screen-sm) {
  .form-row.credit-card-date {
    border: 1px solid $input-border-color;
    overflow: hidden;
    .form-row {
      border: none;
      padding: 0;

      label {
        background: none;
        border: none;
      }
    }

    .card-year {
      border-left: 1px solid $input-border-color;
    }
  }
  .rtl {
    .form-row.credit-card-date {
      .card-month {
        border-right: none;
        border-left: 1px solid $input-border-color;
      }
      .card-year {
        border-left: none;
      }
    }
  }
}

@media only screen and (max-width: ($screen-sm - 1px)) {
  .card-year,
  .card-month {
    label::after {
      content: "*";
    }
  }
}

/* First Name with Prefix ==
==========================*/
$prefix-width: 101px;

.form-row.first-name-row {
  .select-box {
    background: $white;
    border-right: 1px solid $input-border-color;
    border-left: 1px solid $input-border-color;
    float: left;
    margin-left: -$prefix-width;
    position: relative;
    width: $prefix-width;
    z-index: 1;
  }
}

/* Terms Fieldset ==
======================*/
.terms-fieldset {
  .form-row.checkbox {
    label {
      border-radius: 0 0 2px 2px;
      display: inline-block;
      padding-right: 0;
      background: $theme-white;
    }

    .original-opt-in {
      line-height: 1.5;

      input[type="checkbox"] {
        top: 14px;
      }
    }
  }

  .termsAndConditionsText {
    padding: 0 10px;
    p {
      margin-top: 0;
    }
  }
}

.emp-terms-fieldset {
  .form-row.checkbox {
    padding-left: 0;
    padding-right: 0;

    label {
      border-radius: 0 0 2px 2px;
      display: inline-block;
      padding: 0 0 0 24px;
      background: $theme-white;
    }
  }

  .termsAndConditionsText {
    padding: 10px 10px 0 10px;
    p {
      margin-top: 0;
    }
  }
}

.rtl {
  .emp-terms-fieldset {
    .form-row.checkbox {
      label {
        padding: 0 24px 0 0;
      }
    }
  }
}

/* Textareas
======================*/

.form-row.textarea,
.textarea {
  textarea {
    resize: none;
  }

  label {
    line-height: 33px;
    padding-left: 10px;
  }
}

@media only screen and (min-width: $screen-md) {
  .form-row.textarea,
  .textarea {
    background-color: $lighter-grey;

    textarea {
      background-color: #fff;
      width: 100%;
      padding: 10px;
      float: left;
      height: ($input-height * 2);
    }

    label {
      bottom: auto;
      height: ($input-height * 2);
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .room-item {
    .guests {
      padding: $page-padding;
      background-color: $grey;
      fieldset {
        margin: 0;
      }
    }
    .welcome-amenities {
      padding: $page-padding;
      background-color: $grey;
      fieldset {
        margin: 0;
      }
    }
  }
}

/* Online Transfers
======================*/
.form-row.online-transfer {
  padding-left: 10px;
  padding-right: 10px;
  background-color: $grey;

  .form-row.country-row {
    label {
      display: none;

      @media only screen and (min-width: ($screen-sm)) {
        display: block;
      }
    }
  }

  .online-transfer-description,
  .online-transfer-48hour {
    font-size: 14px;
    padding-top: 10px;

    .separator-bar {
      border-top: 1px solid $input-border-color;
      min-height: 10px;
    }

    .message-48hour {
      padding-top: 10px;
    }
  }

  .online-transfer-vehicle-options {
    background: #fff;

    .online-transfer-vehicle-title {
      display: none;
    }

    .online-transfer-airport-vehicles {
      height: auto;
      padding-left: 10px;
      padding-right: 12px;
      font-size: 14px;

      .online-transfer-vehicle {
        clear: both;
        padding-top: 10px;
        overflow: hidden;
        background: none;
        width: auto;
        border: none;
        position: static;
        line-height: 1.5;
        padding-left: 0;
        display: block;

        .online-transfer-vehicle-radio {
          float: left;
          margin-top: 3px;
          width: 20px;
        }

        .online-transfer-vehicle-description {
          padding-left: 30px;
        }

        .online-transfer-vehicle-price {
          float: right;
          text-align: right;
          width: 20%;
        }
      }

      .online-transfer-startingpointnote {
        clear: both;
        font-size: 14px;
        padding-top: 10px;
      }
    }
  }
}

@media only screen and (max-width: ($screen-sm)) {
  .form-row.online-transfer {
    .online-transfer-vehicle-options {
      .online-transfer-vehicle-title {
        display: block;
        padding: 10px 0 0 10px;
      }
    }
  }
}

.add-online-transfer-checkbox {
  label {
    display: inline;

    @media only screen and (min-width: ($screen-sm)) {
      width: auto;
      background: none !important;
      text-transform: none;
      border: none;
      position: relative;
      line-height: 1.3;
    }
  }
}

/* Welcome Amenities
======================*/
.welcome-amenities {
  .show-hide-amenities {
    cursor: pointer;
    color: white;
    background: #7d7d7d;
    border: none;
    text-transform: uppercase;
    float: right;
    width: 30%;
    margin: 0 15px 0 0;
  }
  .amenities-48hour {
    font-size: 14px;
    padding-top: 10px;

    .separator-bar {
      border-top: 1px solid $input-border-color;
      min-height: 10px;
    }

    .message-48hour {
      padding-top: 10px;
    }
  }
  .welcome-amenities-selections.form-row.checkbox {
    border-top-width: 3px;
    border-top-color: $medium-grey;
    border-bottom-width: 3px;
    border-bottom-color: $medium-grey;
    padding-left: 0;
    padding-right: 0;
  }
  .welcome-amenities-selections.separator-bar.form-row.checkbox {
    border-left-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    min-height: 20px;
  }
  .welcome-amenity-category-title {
    padding: 0px 12px 0px 32px;
    background-color: $grey;
    min-height: 22px;
  }
  .welcome-amenity.form-row.checkbox {
    border-left-width: 0px;
    border-right-width: 0px;
  }
  .form-row.checkbox {
    label {
      padding: 10px 12px 0px 32px;
      width: 100%;
    }
  }
  .welcome-amenity-price {
    float: right;
    text-align: right;
  }
}

@media only screen and (max-width: ($screen-sm)) {
  .welcome-amenities {
    .form-row.checkbox {
      label {
        width: 60%;
      }
    }
    .welcome-amenity-price {
      padding-top: 10px;
      width: 40%;
    }
    .welcome-amenities-selections.big-separator-bar.form-row.checkbox {
      border-left-width: 0px;
      border-right-width: 0px;
      border-top-width: 5px;
      border-bottom-width: 0px;
      min-height: 10px;
      margin-top: 20px;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .welcome-amenities {
    .add-welcome-amenities-checkbox {
      font-size: 16px;
    }
    .welcome-amenities-selections {
      .welcome-amenity-category-title {
        font-size: 19px;
        border-top-width: 1px;
      }
      .welcome-amenity-category {
        background-color: #fff;
      }
      .form-row.checkbox {
        background-color: #fff;

        input[type="checkbox"] {
          top: 14px;
        }
      }
    }
    .form-row.checkbox {
      label {
        padding: 0px 12px 0px 32px;
      }
    }
    .welcome-amenity.form-row.checkbox {
      &:not(:last-child) {
        border-bottom-width: 0px;
      }
    }
  }
}

/* Multi-room Options ==
======================*/
.multi-room-options {
  margin-bottom: 20px;

  .form-row.checkbox {
    label {
      display: block;
    }
  }

  .disclaimer {
    display: block;
    padding: 10px 0 0;
  }
}

@media screen and (min-width: $screen-sm) {
  .multi-room-options {
    .form-row.checkbox {
      label {
        padding-top: 0;
        padding-bottom: 0;
      }

      input[type="checkbox"] {
        top: 12px;
      }
    }

    .disclaimer {
      padding: 10px 10px;
    }
  }
}

/* Multi-room Options ==
======================*/
.multi-room-options {
  margin-bottom: 20px;

  .form-row.checkbox {
    label {
      display: block;
    }
  }

  .disclaimer {
    display: block;
    padding: 10px 0 0;
  }
}

@media screen and (min-width: $screen-sm) {
  .multi-room-options {
    .form-row.checkbox {
      label {
        padding-top: 0;
        padding-bottom: 0;
      }

      input[type="checkbox"] {
        top: 12px;
      }
    }

    .disclaimer {
      padding: 10px 10px;
    }
  }
}

/* Multi-room Options ==
======================*/
.multi-room-options {
  margin-bottom: 20px;

  .form-row.checkbox {
    label {
      display: block;
    }
  }

  .disclaimer {
    display: block;
    padding: 10px 0 0;
  }
}

@media screen and (min-width: $screen-sm) {
  .multi-room-options {
    .form-row.checkbox {
      label {
        padding-top: 0;
        padding-bottom: 0;
      }

      input[type="checkbox"] {
        top: 12px;
      }
    }

    .disclaimer {
      padding: 10px 10px;
    }
  }
}

.es .welcome-amenities .show-hide-amenities {
  width: 60%;
}

.welcome-amenities-selections {
  &__big-separator-bar {
    height: 3px;
    background: #878787;
    margin: 0 15px 30px;
  }
}

.form-row.string.persistent p {
    box-sizing: border-box;
    color: #1d1d1b;
    font-size: 16px;
    margin: 0;
    padding: 0 10px;
    text-overflow: ellipsis;
    width: 100%;
    border: none;
    border-radius: 0;
    height: 40px;
    background: #f5f5f5;
    cursor: not-allowed;
    line-height: 42px;
}
