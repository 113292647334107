.new-modal--room-details {
  .modal-dialog {
    @media only screen and (min-width: 768px) {
      width: 90%;
      max-width: 1050px;
    }

    .room-item-features {
      padding: 15px;
      margin: 0;
    }

    .room-details-floorplan {
      display: inline-block;
      cursor: pointer;

      .icon {
        font-size: 14px;
        margin-left: 5px;
        vertical-align: middle;
      }

      .button-as-link {
        font-size: 12px;
        padding: 0;
        min-width: auto;
        text-decoration: none;

        .button-as-link__label {
          text-decoration: underline;
        }
      }
    }

    .floorplan {
      text-align: center;
      margin: 36px auto;

      img {
        max-width: 100%;
      }
    }

    .room-details-tab-select {
      button {
        &.is-active {
          border-color: $dark-grey;
          background-color: $dark-grey;

          &:after {
            border-top-color: $dark-grey;
          }
        }
      }
    }
  }
}
