.free-cancellation {
  min-width: 239px;
  &:before,
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

.reservation-total .free-cancellation {
  width: auto;
  min-width: 1px;
  margin-top: 0;
}

.cancellation-description {
  float: right;
  font-weight: bold;
  color: $theme-black;
}

.reservation-total .cancellation-description {
  padding-left: 0;
  padding-right: 0;
}
