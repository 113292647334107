@use "sass:math";

$column-paddings: 15px;
$description-text-line-height: 1.4em;
$image-height: 160px;
$campaign-signin-title-font-size: 17px;

// Default room item mobile
.text-modal {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
}
.campaign-signin-banner {
  background: #f2f2f2;
  padding: $page-padding;
  font-size: $secondary-font-size;
  min-height: 100px;
  margin-bottom: 25px;

  .campaign-signin-banner-image {
    background-position: center;
    background-size: cover;
    display: block;
    height: $image-height;
    position: relative;
    width: 100%;
    img {
      display: none;
    }
  }

  .campaign-signin-title {
    color: $primary-text-color;
    font-family: $primary-font;
    font-size: $campaign-signin-title-font-size;
    font-weight: 300;
    margin: 0;
    padding: 0 40px 0 0;
    right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .campaign-signin-info {
    @include clearfix;
    position: relative;
    padding: 12px 0px;
    display: block;
    margin: 0;
  }

  .campaign-signin-description {
    overflow: hidden;
  }

  .description-text {
    line-height: $description-text-line-height;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;

    p {
      margin: 0;
    }
  }
}
.rtl {
  .campaign-signin-banner {
    .campaign-signin-title {
      padding: 0 15px 0 40px;
    }
  }
}

// Default room item desktop
@media only screen and (min-width: $screen-sm) {
  $image-width: 175px;

  .campaign-signin-banner {
    font-size: 14px;
    position: relative;
    height: auto;
    border-bottom: 1px solid $light-grey;
    padding-bottom: 10px;
    margin-bottom: 20px;

    .terms-link {
      display: block;
      position: absolute;
      left: 15px;
      bottom: 0;
    }

    .campaign-signin-banner-image {
      float: left;
      height: floor(9 * math.div($image-width, 16));
      width: $image-width;
      top: -1px;
      left: -1px;
      img {
        display: block;
        width: 100%;
      }
    }

    .campaign-signin-description {
      position: relative;
      padding: 5px 15px 0;
    }

    .campaign-signin-title {
      font-size: 19px;
      font-weight: 200;
      background: none;
      line-height: 1.4;
      padding-left: 15px;
    }

    .description-text {
      font-weight: normal;
      margin-top: 0;
      overflow: hidden;
    }

    .campaign-signin-info {
      margin-left: $image-width - 1px;
      padding: 0;
    }
  }
  .rtl {
    .campaign-signin-banner {
      .campaign-signin-banner-image {
        float: right;
      }
      .campaign-signin-info {
        margin-left: 0;
        margin-right: $image-width - 1px;
      }
      .campaign-signin-title {
        right: 0px;
      }
      .terms-link {
        left: inherit;
        right: 15px;
      }
    }
  }
}
