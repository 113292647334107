.view--upcoming-trip__modal {
  padding: 0 0 60px;

  .layout--profile-fullscreen__top-bar {
    .col-sm-12 {
      @media screen and (min-width: $breakpoints-md) {
        height: 138px;
      }
      @media screen and (min-width: $breakpoints-lg) {
        height: 118px;
      }
    }
  }

  .layout--profile-fullscreen__top-bar--with-close-button {
    .col-sm-12 {
      @media screen and (min-width: $breakpoints-md) {
        height: 100px;
      }
      @media screen and (min-width: $breakpoints-lg) {
        height: 110px;
      }
    }
  }

  &__close-button {
    background: transparent;
    background-image: url("/images/profiles_2018/black-cross.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: none;
    color: #000;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-left: auto;

    &--light {
      background-image: url("/images/profiles_2018/white-cross.svg");
      color: #000;
    }

    span {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }
  }

  h2 {
    font-size: rem(20px);
    font-family: $hn;
    font-weight: 250;
    letter-spacing: 3px;
    line-height: 1.5;
    margin: 0 0 30px;
    text-align: left;

    @media screen and (min-width: $breakpoints-md) {
      font-size: rem(24px);
      letter-spacing: 4px;
      line-height: 1.3;
    }

    &.estimated-total-title {
      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 50px;
      }
    }
  }

  &__intro {
    font-size: rem(18px);
    letter-spacing: 0.4px;
    line-height: 1.5;
    margin: 0 0 40px;

    @media screen and (min-width: $breakpoints-md) {
      font-size: rem(22px);
      letter-spacing: 0.5px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 50px;
    }
  }

  dl {
    margin: 0;
    padding: 0;
  }

  .booking-summary {
    border-top: solid 1px #d8d8d8;
    margin: 0 0 40px;
    padding: 0;

    dt {
      padding: 20px 0 0;
      margin: 0 0 10px;
      font-family: $garamond;
      weight: 400;
      font-size: rem(22px);
      letter-spacing: 0.5px;
      line-height: 1.5;
      text-transform: none;

      strong {
        font-weight: 700;
      }

      @media screen and (min-width: $breakpoints-lg) {
        clear: left;
        float: left;
        margin: 0;
        padding: 30px 0;
        text-align: left;
      }
    }

    dd {
      border-bottom: solid 1px #d8d8d8;
      font-size: rem(12px);
      font-family: $hn;
      letter-spacing: 3px;
      line-height: 1.5;
      margin: 10px 0 0;
      padding: 0 0 20px;
      text-transform: uppercase;

      @media screen and (min-width: $breakpoints-lg) {
        margin: 0;
        padding: 30px 0;
        text-align: right;
        min-height: 94px;
        line-height: 34px;
      }
    }

    .booking-summary__average-daily-rate-label {
      @media screen and (min-width: $breakpoints-lg) {
        padding-bottom: 0;
      }
    }

    &__fees-disclaimer {
      line-height: 18px;
      letter-spacing: 1px;
      text-transform: none;
    }

    &__toggle-daily-rates {
      margin-top: 15px;
      clear: left;
      text-align: left;

      @media screen and (min-width: $breakpoints-lg) {
        margin-top: 0;
      }

      .CTA--underlined--light {
        font-size: rem(10px);
      }
    }

    &__total {
      @media screen and (min-width: $breakpoints-lg) {
        font-size: rem(16px);
        letter-spacing: 4px;
      }
    }
  }

  .daily-rates {
    margin-bottom: 0;
    padding-left: 32px;

    @media screen and (min-width: $breakpoints-md) {
      padding-left: 58px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      padding-left: 90px;
      padding-top: 30px;
    }

    dt,
    dd {
      border: none;
      margin: 0;
      padding: 0;
    }

    dt {
      margin-top: 25px;
      margin-bottom: 10px;

      @media screen and (min-width: $breakpoints-lg) {
        margin: 0;
      }
    }

    dd {
      @media screen and (min-width: $breakpoints-lg) {
        line-height: 32px;
        min-height: 32px;
        margin-bottom: 30px;
      }
    }

    dd:last-child {
      @media screen and (min-width: $breakpoints-lg) {
        margin-bottom: 0;
      }
    }
  }

  .arrival {
    .booking-summary {
      dt {
        padding: 10px 0 0;
      }
      dd {
        padding: 10px 0;
        min-height: 54px;
      }
      &__toggle-daily-rates {
        text-align: center;
        @media screen and (min-width: $breakpoints-lg) {
          text-align: left;
        }
      }
    }
    .daily-rates {
      padding-top: 0;
      @media only screen and (max-width: $breakpoints-lg) {
        padding-left: 32px;
        padding-right: 32px;
      }

      dt {
        @media only screen and (max-width: $breakpoints-lg) {
          margin-top: 0;
          clear: left;
          float: left;
          text-align: left;
        }
      }
      dd {
        @media only screen and (max-width: $breakpoints-lg) {
          padding-top: 20px;
          text-align: right;
        }
      }
    }
  }

  fieldset {
    border: none;
    border-bottom: solid 1px #d8d8d8;
    padding: 0 0 10px;
    margin: 0 0 40px;

    h3 {
      background: none;
      border: none;
      border-radius: 0;
      color: inherit;
      font-family: $hn;
      font-weight: 700;
      font-size: rem(12px);
      letter-spacing: 3px;
      line-height: 1.3;
      padding: 0;
      margin: 0 0 30px;
      text-align: left;
      text-transform: uppercase;
      width: auto;
    }
  }

  fieldset + .booking-summary {
    margin-top: -41px;
  }

  .form-actions {
    margin: 80px -7.5px 160px;
    display: flex;
    flex-direction: row;

    > * {
      margin: 0 7.5px;
      flex-basis: 50%;
      padding-left: 0;
      padding-right: 0;
    }

    button {
      width: 100%;
    }

    @media screen and (min-width: $breakpoints-md) {
      justify-content: flex-end;
      margin-top: 30px;

      > * {
        flex-basis: 190px;
        flex-grow: 0;
        flex-shrink: 0;
      }
    }

    @media screen and (min-width: $breakpoints-lg) {
      margin-top: 60px;
    }
  }

  button.btn-back {
    border: none;
    background-color: white;
  }
}

.upcoming-trip__estimated-total-disclaimer {
  font-size: 14px;
  margin: 60px auto;

  p {
    margin: 0;

    &::before {
      content: "*";
    }
  }
}

.view--upcoming-trip__modal--change-dates {
  .layout--profile-fullscreen__top-bar {
    margin-bottom: 20px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 0;
    }
  }

  h2 {
    margin-bottom: 20px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 24px;
    }
  }

  .view--upcoming-trip__update-trip-dates__intro {
    margin: 0 0 40px;
  }

  .upcoming-trip__booking-message {
    border: solid 1px #d8d8d8;
    padding: 10px;
    margin: 20px auto;
    font-family: $hn;
    font-size: 14px;

    p {
      margin-top: 0;
    }
  }
}

.new-modal--change-detail {
  .modal-dialog__close {
    top: 20px;
    right: 20px;

    @include rtl {
      right: auto;
      left: 20px;
    }
  }

  .modal-dialog {
    &__body {
      background-color: white;
    }
  }

  @media screen and (min-width: $breakpoints-md) {
    .modal-dialog {
      height: 100%;
      max-height: 90vh;
      margin: 4% auto;

      &__content,
      &__body {
        height: 100%;
      }
    }
  }

  .change-detail-modal {
    padding: 64px 20px 20px;

    .fs-trip__arrival-details__occasion-form {
      .fs-trip__arrival-details__btns {
        margin-top: 0px;
        padding-top: 30px;
        border-top: 1px solid #d8d8d8;
      }
      @media screen and (min-width: $breakpoints-lg) {
        .fs-trip__arrival-details__btns {
          margin-top: 30px;
        }
      }
    }

    @media screen and (min-width: $breakpoints-md) {
      padding-inline: 0px;
      height: 100%;
      overflow: hidden;

      .fs-trip__arrival-details__occasion-form,
      .fs-trip__arrival-details__guest-form {
        height: 100%;
        padding-inline: 40px;
        padding-bottom: 150px;
        overflow: scroll;
        margin-top: 40px;
      }

      .fs-trip__arrival-details__guest-form {
        padding-top: 0;
      }
    }

    &__header {
      @media screen and (min-width: $breakpoints-md) {
        padding: 0 40px;
      }
    }

    &__title {
      font-family: $hn;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 3px;
      text-align: left;
      text-transform: uppercase;
    }

    &__copy {
      margin: 20px 0 20px 0;
    }

    &__form {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $breakpoints-md) {
        padding: 0 40px;
      }

      .arrival-time {
        margin-bottom: 30px;
        padding-bottom: 40px;
        border-bottom: solid 1px #d8d8d8;
      }

      button[type="submit"] {
        margin-left: auto;
      }
    }

    .fs-trip__arrival-details__occasion-form__textarea {
      display: flex;
      flex-direction: column;

      textarea {
        width: 100%;
        background: #f0f0f0;
        border: none;
        border-bottom: 1px solid black;
      }
    }

    .fs-trip__arrival-details__guest-form {
      .TextInput-field,
      .formElement-field {
        background: #f5f5f5;
      }

      .Dropdown::after {
        right: 16px;
      }

      .fs-trip__arrival-details__btns {
        margin-top: 0;
        padding-top: 30px;
        border-top: 1px solid #d8d8d8;
      }
    }

    .fs-trip__arrival-details__btns {
      margin-top: 40px;
      justify-content: flex-end;
    }
  }
}
