$font-family-heading: $base-font-family-sans;
$font-family-heading-mobile: $base-font-family-sans;
$font-family-body: $base-font-family-sans;
$font-color-normal: $gray-black;
$font-color-mid: $gray-mid-dark;
$font-color-light: $gray-mid-dark;

body,
.body {
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  font-family: $font-family-body;
  color: $font-color-normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: $letter-spacing-normal;
}


// Headings
//
// Margins have been removed so the spacing can be applied within a layout without the need of an override.
//
// markup:
// <h1>Example h1 heading</h1>
// <h2>Example h2 heading</h2>
// <h3>Example h3 heading</h3>
// <h4>Example h4 heading</h4>
// <h5>Example h5 heading</h5>
// <h6>Example h6 heading</h6>
//
// Styleguide 2.1.0

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $base-font-weight;
  font-family: $font-family-heading-mobile;
  letter-spacing: $letter-spacing-large;
  text-transform: uppercase;
  margin: 0;

  @include media($tablet) {
    letter-spacing: $letter-spacing-xlarge;
  }
}

h1,
h3,
h4,
h5,
h6 {
  color: $font-color-mid;
}

h1,
h2 {
  text-align: center;

  @include media($tablet) {
    font-family: $font-family-heading;
    font-size: $font-size-normal;
  }
}

h1 {
  font-size: $font-size-normal;

  @include media($tablet) {
    font-size: $font-size-xxlarge;
  }
}

h2 {
  font-size: $font-size-xsmall;
  color: $font-color-light;

  @include media($tablet) {
    font-size: $font-size-large;
  }
}

h3 {
  font-size: $font-size-small;

  @include media($tablet) {
    font-size: $font-size-normal;
  }
}

h4 {
  font-size: $font-size-small;

  @include media($tablet) {
    font-size: $font-size-large;
  }
}

h5 {
  font-size: $font-size-xsmall;

  @include media($tablet) {
    font-size: $font-size-small;
  }
}

h6 {
  font-size: $font-size-xsmall;

  @include media($tablet) {
    font-size: $font-size-xsmall;
  }
}


// Paragraphs
//
// markup:
// <p>This is an example of a long passage of text in a paragraph tag, this style should be used for descriptions and form input aids. Typography is the <strong>art and technique</strong> of <a href="#">arranging</a> type to make <em>written language</em> legible, readable, and appealing when displayed. The arrangement of type involves selecting typefaces, point size, line length, line-spacing (leading), letter-spacing (tracking), and adjusting the space within letters pairs (kerning). The term typography is also applied to the style, arrangement, and appearance of the letters, numbers, and symbols created by the process.</p>
// <p><small>The small tag should be used to display long passages of legal copy like terms &amp; conditions. This is an example of a long passage of text in a paragraph tag. Typography is the <strong>art and technique</strong> of arranging type to make <em>written language</em> legible, readable, and appealing when displayed. The arrangement of type involves selecting typefaces, point size, line length, line-spacing (leading), letter-spacing (tracking), and adjusting the space within letters pairs (kerning). The term typography is also applied to the style, arrangement, and appearance of the letters, numbers, and symbols created by the process.</small></p>
//
// Styleguide 2.3.0

p {
  font-family: $font-family-body;
  font-size: $font-size-small;
  line-height: $base-line-height;
  letter-spacing: $letter-spacing-normal;
  color: $font-color-normal;
  font-weight: $base-font-weight;

  @include media($tablet) {
    font-size: $font-size-normal;
  }
}
