$nav-arrow-size-modal: 60px;

.modal-content-body .room-modal-carousel {
  height: 100%;

  .info-container {
    text-align: right;
    padding-right: 5px;
    padding-top: 5px;
    height: 300px;
  }

  .fullprice,
  .from {
    display: inline;
  }

  .fullprice {
    font-size: 22px;
  }

  .night {
    clear: both;
    display: block;
  }

  .resort-fee {
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.06em;
  }

  .nightly-rate {
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    color: #000;
    text-align: right;
    margin-right: 5px;
  }

  .select-room {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;

    .fs-btn-select-container {
      position: relative;

      button.label {
        float: right;
        padding-top: 18px;
        padding-bottom: 18px;
      }

      .options {
        position: absolute;
        top: 55px;
        right: 0;
        width: 295px;
      }
    }
  }

  .room-item-image {
    margin: 0 auto;
    overflow-x: hidden;

    .room-carousel {
      position: relative;
      cursor: auto;
    }

    .flex-direction-nav {
      margin: 0;

      a {
        width: $nav-arrow-size-modal;
        height: $nav-arrow-size-modal;
        margin-top: -$nav-arrow-size-modal * 0.5;
        font-size: 40px;
      }
    }

    .single {
      .flex-direction-nav {
        display: none;
      }
    }
  }
}

.rtl {
  .modal-content-body .room-modal-carousel {
    .info-container {
      text-align: left;
    }
    .nightly-rate {
      margin-left: 15px;
      margin-right: 0;
    }
    .select-room {
      .fs-btn-select-container {
        button.label {
          float: left;
        }

        .options {
          right: auto;
          left: 0;
        }
      }
    }
  }
}

// Default room item desktop
@media only screen and (min-width: $screen-sm) {
  .modal-content-body .room-modal-carousel {
    .info-container {
      position: absolute;
      top: 0;
      z-index: 13;
      padding-right: 15px;
      padding-top: 15px;
      width: 100%;
      height: 200px;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.85),
        rgba(0, 0, 0, 0)
      );
    }

    .nightly-rate {
      z-index: 2;
      color: $white;
    }

    .select-room {
      margin-top: 0;
      .fs-btn-select-container {
        display: block;

        button.label {
          border: solid 1px #fff;
          min-width: 180px;
          background-color: rgba(29, 29, 27, 0.6);
          color: #fff;
          z-index: $z_modalBackButton;
        }
      }
    }

    .room-item-image {
      max-width: 1280px;

      .flex-direction-nav {
        a {
          transition: all 0.125s;

          &.flex-prev {
            left: -$nav-arrow-size;
          }

          &.flex-next {
            right: -$nav-arrow-size;
          }
        }
      }

      &:hover {
        .flex-direction-nav {
          li a.flex-prev {
            left: 0;
          }

          li a.flex-next {
            right: 0;
          }
        }
      }
    }
  }

  .rtl .modal-content-body .room-modal-carousel {
    .info-container {
      text-align: left;
    }

    .nightly-rate {
      left: 235px;
      right: auto;
      margin-left: 0;
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .modal-content-body .room-modal-carousel {
    .room-item-image {
      max-width: 1280px;

      .flex-direction-nav {
        a {
          transition: all 0.125s;

          &.flex-prev {
            left: -$nav-arrow-size;
          }

          &.flex-next {
            right: -$nav-arrow-size;
          }
        }
      }

      &:hover {
        .flex-direction-nav {
          li a.flex-prev {
            left: 0;
          }

          li a.flex-next {
            right: 0;
          }
        }
      }
    }
  }
}
