.campaign-signin-modal {
  font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

  .columns-wrapper {
    border: solid 1px #ccc;
    margin: 20px 15px;
    overflow: hidden;
    padding: 0 0 20px;
  }

  .campaign-signin-modal-tabs {
    margin: 0 0 10px;
    overflow: hidden;
  }

  .campaign-signin-modal-tab {
    background: #e8eaeb;
    cursor: pointer;
    float: left;
    line-height: 44px;
    height: 44px;
    text-align: center;
    text-transform: uppercase;
    width: 50%;

    &.is-active {
      background: #fff;
    }
  }

  .headline hr {
    border-top: 1px solid #ccc;
    border-bottom: none;
    margin-bottom: 28px;
  }

  h1 {
    font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-weight: 200;
    text-align: center;
  }

  h2 {
    font-family: "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
    font-size: 24px;
    font-weight: 200;
    text-transform: uppercase;
    margin-top: 0;
  }

  .cancel-row {
    text-align: center;
    margin: 0 15px;
    padding-bottom: 30px;
  }

  .btn {
    background: #fff;
    border: solid 1px $theme-black;
    color: $theme-black;
    font-weight: normal;
    padding: 10px;
    text-transform: uppercase;
    width: 100%;

    &:before,
    &:after {
      display: none;
    }

    &:hover {
      background-color: $theme-black;
      color: #fff;
    }

    &:active {
      background-color: darken($theme-black, 10%);
      border-color: darken($theme-black, 10%);
      color: #fff;
    }
  }

  .btn-primary {
    background: $theme-black;
    border: none;
    color: #fff;

    &:hover {
      background-color: darken($theme-black, 5%);
      color: #fff;
    }

    &:active {
      background-color: darken($theme-black, 10%);
      color: #fff;
    }
  }

  .btn-primary[disabled] {
    background: $theme-black !important;
    opacity: .5;
  }

  form {
    margin-top: 30px;
    text-align: left;
  }

  fieldset {
    margin: 0;
  }

  .form-actions {
    text-align: center;
    padding: 0;

    a {
      margin-bottom: 20px;
      display: inline-block;
    }
  }

  .actions {
    margin-top: 30px;
  }

  .form-row {
    border: none;
    margin: 0 0 10px;
    padding: 0;

    input {
      border-radius: 0;
      border: solid 1px #ccc;

      &::-webkit-input-placeholder { color: #999; }
      &:-moz-placeholder           { color: #999; }
      &::-moz-placeholder          { color: #999; }
      &:-ms-input-placeholder      { color: #999; }
      &:-ms-input-placeholder      { color: #999; }
    }
  }

}

@media screen and (min-width: $screen-sm) and (max-width: $screen-md - 1) {
  .campaign-signin-modal {

    .columns-wrapper {
      border-top: none;
      border-left: none;
      border-right: none;
      margin: 0 30px 30px;
      padding: 30px 0px 10px 0;
    }
  }
}

@media only screen and (min-width: $screen-md){
  .campaign-signin-modal {

    .columns-wrapper {
      border-top: none;
      border-left: none;
      border-right: none;
      margin: 0 30px 30px;
      padding: 30px 0;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .campaign-signin-modal {

    .campaign-signin-modal-tabs {
      display: none;
    }

    .col-sm-6 {
      display: block !important;
      min-height: 260px;
      position: relative;

      .actions,
      .form-actions {
        bottom: 0;
      }

      .actions {
        position: absolute;
        left: 0;
        right: 30px;
      }

      .form-actions {
        position: inherit;
        left: 30px;
        right: 0;
      }
    }

    .col-sm-6:first-child {
      border-right: solid 1px #ccc;
      padding-left: 0;
      padding-right: 30px;
    }

    .col-sm-6:last-child {
      border-left: solid 1px #ccc;
      margin-left: -1px;
      padding-left: 30px;
      padding-right: 0;
    }

    .btn {
      width: auto;
      display: block;
      margin: 0 auto;
    }

    .form-actions {
      text-align: left;

      a {
        float: right;
        line-height: 42px;
        margin: 0;
        max-width: 60%;
      }
    }

  }

  .es,
  .jp {
    .campaign-signin-modal {
      .form-actions {
        a {
          line-height: normal;
          max-width: 45%;
          text-align: center;
        }
      }
    }
  }

  .rtl {
    .campaign-signin-modal {
      .signin-form {
        .form-row {
          padding-right: 0px;
          padding-left: 0px;
        }
      }

      .form-actions {
        a {
          float: left;
        }
      }
    }
  }
}













