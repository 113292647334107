.view--profile {
  background: #fff;

  &__top-bar {
    background: #fff;
    color: #000;
  }

  &__your-interests {
    background: #252525;
  }

  h2 {
    font-family: $hn;
    font-weight: 250;
    font-size: rem(24px);
    text-align: left;
    color: inherit;
    margin: 0 0 12px;
    text-transform: uppercase;
    letter-spacing: 4px;
    line-height: 1.3;
  }
}

.user-details-row {
  font-family: $hn;
  font-weight: 250;
  padding: 80px 20px;
  text-align: center;

  @media screen and (min-width: $breakpoints-md) {
    padding: 110px 0;
    text-align: left;

    @include rtl {
      text-align: right;
    }
  }

  @media screen and (min-width: $breakpoints-lg) {
    padding-top: 145px;
    padding-bottom: 140px;
  }
}

.user-details {
  margin-bottom: 50px;

  @media screen and (min-width: $breakpoints-md) {
    margin-bottom: 0;
  }

  .CTA--underlined--light {
    font-size: rem(12px);
  }

  &__avatar {
    display: none;
  }

  &__private-residence-owner-label {
    font-family: $hn;
    font-size: 12px;
    font-weight: 100;
    line-height: 1.5;
    letter-spacing: 3px;
    margin: 0 0 15px;
    text-transform: uppercase;
  }

  &__name {
    font-size: rem(36px);
    line-height: 1.2;
    letter-spacing: 1.3px;
    margin: 0 0 30px;
    text-transform: capitalize;

    @media screen and (min-width: $breakpoints-md) {
      font-size: rem(40px);
      line-height: 1.1;
      letter-spacing: 1.43px;
      text-align: left;
    }
  }
}

.percent-complete {
  margin: 0 0 50px;
  padding: 0;

  @media screen and (min-width: $breakpoints-md) {
    max-width: 280px;
  }

  &__percent {
    letter-spacing: 3px;
    line-height: 1.3;
    margin: 0 0 10px;
    font-family: $hn;
    font-size: rem(10px);
    text-transform: uppercase;
  }

  &__bar {
    background-color: #d8d8d8;
    width: 100%;
    height: 4px;
    margin-bottom: 30px;

    &__fill {
      background-color: #7d7d7d;
      height: 4px;
    }
  }
}

.employee-stay-history-link {
  padding-top: 15px;

  .CTA--underlined--light,
  .CTA--underlined--light:visited {
    color: #7d7d7d;
  }
}

.contextual {
  @media screen and (min-width: $breakpoints-lg) {
    padding-top: 20px;
  }

  p {
    font-family: $hn;
    font-weight: 250;
    font-size: rem(20px);
    line-height: 1.6;
    margin: 0 0 30px;
    letter-spacing: 0.9px;
    text-align: center;
    color: #2d2d2d;

    @media screen and (min-width: $breakpoints-md) {
      font-size: rem(18px);
      line-height: 1.78;
      letter-spacing: 0.8px;
      text-align: left;
    }

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(26px);
      line-height: 1.54;
      letter-spacing: 1px;
    }
  }

  &__actions {
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    button,
    a {
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
    }

    .contextual__action + .contextual__action {
      margin-top: 25px;
    }

    @media screen and (min-width: $breakpoints-md) {
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: $breakpoints-xl) {
      flex-direction: row;
      margin-left: -12px;
      margin-right: -12px;

      .contextual__action {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;
        margin: 0 12px;
      }

      .contextual__action + .contextual__action {
        margin-top: 0;
      }
    }

    &.employee {
      @media screen and (min-width: $breakpoints-xl) {
        .contextual__action {
          flex-basis: 33%;
        }
      }
    }
  }
}

.view--profile__exclusive-offers {
  background-color: #000;
  border-bottom: solid 1px #2d2d2d;
  color: #fff;
}

.fs-exclusive-offers {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 65px 0;
  text-align: center;

  @media screen and (min-width: $breakpoints-lg) {
    align-items: center;
  }

  h2 {
    color: inherit;
    font-family: $hn;
    font-weight: 700;
    font-size: rem(12px);
    line-height: 1.75;
    letter-spacing: 3px;
    margin: 0 0 10px;
    text-align: inherit;

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(14px);
    }
  }

  p {
    margin: 0 0 28px;
    text-align: inherit;
    font-size: rem(15px);
    line-height: 1.86;

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(18px);
      line-height: 1.55;
      margin-bottom: 0;
    }
  }

  @media screen and (min-width: $breakpoints-md) {
    padding-left: 160px;
    padding-top: 45px;
    padding-bottom: 45px;
    text-align: left;
  }

  @media screen and (min-width: $breakpoints-lg) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    padding-left: 138px;
    padding-top: 62px;
    padding-bottom: 62px;
  }

  &__icon {
    background-image: url("/images/profiles_2018/gift-box.svg");
    background-size: 64px 54px;
    display: inline-block;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 54px;
    width: 100%;
    margin: 0 0 28px;

    @media screen and (min-width: $breakpoints-md) {
      position: absolute;
      top: 50%;
      left: 48px;
      width: 64px;
      margin-top: -27px;
      margin-bottom: 0;
    }

    @media screen and (min-width: $breakpoints-lg) {
      left: 0;
    }
  }

  &__copy {
    max-width: 250px;
    margin: auto;

    @media screen and (min-width: $breakpoints-md) {
      margin: unset;
      max-width: none;
    }
    @media screen and (min-width: $breakpoints-lg) {
      max-width: 460px;
    }
  }
}

.view--profile__member-details {
  background-color: #000;
  border-bottom: solid 1px #2d2d2d;
  color: #fff;
}

.fs-member-details {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 65px 0;
  // text-align: center;
  text-align: left;
  align-items: center;

  @media screen and (min-width: $breakpoints-md) {
    padding-left: 160px;
    padding-top: 45px;
    padding-bottom: 45px;
  }

  @media screen and (min-width: $breakpoints-lg) {
    flex-direction: row;
    justify-content: space-between;
    padding-left: 138px;
    padding-top: 62px;
    padding-bottom: 62px;
  }
}

.view--profile-membership,
.fs-member-details {
  &__title {
    margin-left: 20px;
  }

  h2 {
    color: inherit;
    font-family: $hn;
    font-weight: 700;
    font-size: rem(12px);
    line-height: 1.2;
    letter-spacing: 3px;
    margin: 0 0 10px;
    text-align: inherit;
  }

  p {
    margin-bottom: 0;
    font-family: $hn;
    text-align: inherit;
    font-size: rem(18px);
    line-height: 1.2;
  }

  .fs-member-detail-link {
    color: #fff;
    text-decoration: none;
  }

  .fs-member-detail-card {
    color: #fff;
    border-radius: 10px;
    float: left;
    background-repeat: no-repeat;
    margin: 15px 20px;
    padding: 20px 20px;
    width: 335px;
    height: 178px;
    background-size: 103% 105%;
    background-position: -5px -5px;

    .membership-property {
      margin: 0 auto 10px;
      font-size: 18px;
      text-align: left;
      line-height: 26px;
      letter-spacing: 0.35px;
      text-transform: none;
      font-weight: 300;
    }

    .membership-tier {
      margin: auto auto 5px;
      font-size: 10px;
    }

    .membership-account {
      display: none;
      font-size: 12px;
      margin: auto auto 5px;
      letter-spacing: 0.3em;
      text-transform: none;

      // @media screen and (min-width: $breakpoints-lg) {
      // display: block;
      // }
    }

    .membership-status {
      font-size: 12px;
      margin: auto auto 5px;
      letter-spacing: 0.1em;
      text-transform: none;
    }

    .expired-container {
      float: right;
      background-color: red;
      padding: 2px 20px;
      position: relative;
      overflow: hidden;
      right: -20px;
      bottom: -54px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;

      .warning-icon {
        border: 1px solid;
        border-radius: 100%;
        width: 15px;
        height: 15px;
        margin: 7px auto 0px;
        position: relative;
        float: left;

        &:after {
          content: "!";
          position: absolute;
          width: 100%;

          font-family: Garamond MT Std;
          font-size: 12px;
          line-height: 1.12;
          text-align: center;
          letter-spacing: 0.5px;
        }
      }
    }

    .membership-expired {
      font-size: 12px;
      margin: 8px 0 5px 22px;
      text-transform: uppercase;
    }
  }
}

.view--profile-membership {
  .fs-membership-header {
    .fs-member-detail-card {
      margin-top: 0px;
      margin-left: 0px;
      width: 250px;
      height: 150px;
      background-size: 103% 105%;
      background-position: -4px -4px;

      @media screen and (max-width: $breakpoints-sm) {
        width: 100%;
        padding-bottom: 53%;
      }
      .membership-property {
        margin: 10px;
        font-size: 18px;
        line-height: 26px;
      }

      .membership-tier {
        display: block;
        margin: 10px;
      }
    }
  }
}

.view--profile__upcoming-trips {
  background: #000;
  color: #fff;
  padding: 75px 0 0;

  @media screen and (min-width: $breakpoints-lg) {
    padding-top: 120px;
  }

  h2 {
    font-size: rem(24px);
    text-align: left;
    color: inherit;
    line-height: 1.3;
    letter-spacing: 4px;
    margin: 0 0 40px;

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 60px;
    }
  }
}

.view--profile__your-residences {
  background: #000;
  color: #fff;
  padding: 0;
  text-align: center;

  @media screen and (min-width: $breakpoints-md) {
    text-align: left;

    .col-md-4 {
      padding-top: 36px;
      text-align: right;
    }
  }

  &__inner {
    background: #252525;
    padding: 75px 0;
  }

  h2 {
    font-size: rem(24px);
    text-align: inherit;
    color: inherit;
    line-height: 1.3;
    letter-spacing: 4px;
    margin: 0 0 20px;
  }

  p {
    margin: 0 0 50px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 0;
    }
  }
}

.view--profile__upcoming-trips + .view--profile__your-residences {
  padding-top: 50px;
}

.view--profile__your-preferences {
  background: #000;
  color: #fff;
  padding: 75px 0 25px;

  @media screen and (min-width: $breakpoints-lg) {
    padding-top: 120px;
  }

  p {
    margin: 0 0 25px;
  }
}

.view--profile__your-interests {
  color: #fff;
  padding: 75px 0 0;

  @media screen and (min-width: $breakpoints-lg) {
    padding-top: 120px;
  }

  a,
  a:visited {
    color: #fff;
  }
}

.fs-your-interests {
  padding: 0 0 25px;

  @media screen and (min-width: $breakpoints-md) {
    padding-bottom: 0;
  }

  @media screen and (min-width: $breakpoints-lg) {
    padding-bottom: 55px;
  }

  p {
    margin: 0 0 60px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 70px;
    }
  }

  &__interest {
    margin: 0;
    padding: 0;

    + .fs-your-interests__interest {
      border-top: 1px solid rgba(0, 0, 0, 0.21);
      padding-top: 38px;
    }

    &__title {
      min-height: 36px;
      margin: 0 0 38px;
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;

      @media screen and (min-width: $breakpoints-lg) {
        min-height: 42px;
      }

      h3 {
        text-transform: uppercase;
        color: inherit;
        font-family: $hn;
        font-weight: 700;
        font-size: rem(12px);
        letter-spacing: 3px;
        line-height: 1.75;

        @media screen and (min-width: $breakpoints-md) {
          font-size: rem(16px);
          letter-spacing: 4px;
          line-height: 1.3;
        }
      }

      .icon {
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        height: 36px;
        width: 36px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 36px;
        margin: 0 18px 0 0;

        @media screen and (min-width: $breakpoints-md) {
          margin-right: 30px;
        }

        @media screen and (min-width: $breakpoints-lg) {
          margin-right: 32px;
          height: 42px;
          width: 42px;
          flex-basis: 42px;
        }
      }
    }

    &--TravelAndLifestyle .icon {
      background-image: url("/images/plane.svg");
    }

    &--HealthAndWellness .icon {
      background-image: url("/images/heartbeat.svg");
    }

    &--FoodAndDrink .icon {
      background-image: url("/images/fork-spoon.svg");
    }

    &__options {
      font-size: 0;
      padding: 0 0 30px;

      @media screen and (min-width: $breakpoints-md) {
        padding-left: 66px;
        padding-bottom: 45px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        padding-left: 74px;
        padding-bottom: 55px;
      }
    }

    &__option {
      background-color: #000;
      border: none;
      color: #fff;
      display: inline-block;
      padding: 16px 60px;
      text-transform: uppercase;
      font-family: $hn;
      font-weight: 700;
      font-size: rem(12px);
      letter-spacing: 3px;
      border-radius: 19px;
      margin: 0 30px 20px 0;
      position: relative;

      @media screen and (min-width: $breakpoints-md) {
        margin-right: 30px;
        margin-bottom: 30px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        margin-right: 20px;
        margin-bottom: 20px;
      }

      svg {
        display: none;
        position: absolute;
        height: 100%;
        top: 0;
        left: 14px;
        width: 28px;

        .path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 0;

          .circle {
            opacity: 0;
          }
        }
      }

      &--selected {
        background-color: #fff;
        color: #000;

        svg {
          display: block;

          .path {
            $duration: 1.8s;
            &.circle {
              opacity: 0;
              animation: circle $duration ease-in-out;
            }
            &.check {
              animation: dash-check $duration ease-in-out forwards;
            }
          }
        }
      }
    }
  }

  .plain-link {
    font-family: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    line-height: inherit;
    padding-bottom: 0;
    border-bottom: none;
    text-decoration: underline;
    font-weight: inherit;
    text-transform: none;
    transition: none;
    &:hover {
      color: inherit !important;
      border-color: inherit !important;
    }
  }
}

@keyframes circle {
  0% {
    opacity: 1;
    stroke-dashoffset: 1000;
  }
  50% {
    opacity: 1;
    stroke-dashoffset: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  12% {
    stroke-dashoffset: -100;
  }
  50% {
    stroke-dashoffset: 900;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

.view--profile__invoice-request {
  background-color: #000;
  color: #fff;
  margin: 0;
  padding: 0;
}

.fs-invoice-request {
  margin: 50px 0;
  padding: 10px;
  border: 1px solid #fff;
  text-align: center;

  @media screen and (min-width: $breakpoints-md) {
    text-align: left;
    margin-bottom: 1050px;
  }

  &__holder {
    position: relative;
    border: 1px solid #fff;
    padding: 32px 36px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $breakpoints-md) {
      flex-direction: row;
      justify-content: space-between;
      padding: 25px 50px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      padding: 40px 116px;
    }
  }

  h3 {
    text-transform: uppercase;
    color: inherit;
    font-family: $hn;
    font-weight: 700;
    font-size: rem(12px);
    letter-spacing: 3px;
    line-height: 1.3;
    text-align: center;
    margin: 0 0 12px;

    @media screen and (min-width: $breakpoints-md) {
      text-align: left;
    }

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(14px);
      margin-bottom: 16px;
    }
  }

  p {
    font-size: rem(15px);
    line-height: 1.6;
    margin: 0 0 24px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 0;
    }

    @media screen and (min-width: $breakpoints-lg) {
      font-size: rem(18px);
      line-height: 1.5;
    }
  }

  &__copy {
    max-width: 300px;

    @media screen and (min-width: $breakpoints-xl) {
      max-width: 500px;
    }
  }
}

.view--profile__mobile-app-promotion {
  position: relative;
  color: $white;
  background: $black;
  .desktop {
    display: flex;
    justify-content: center;
    margin: 0 120px 50px;

    .text {
      max-width: 539px;
      text-align: center;
      margin-right: 30px;
      margin-top: 120px;

      @include rtl {
        margin-right: 0;
        margin-left: 30px;
      }
      h1 {
        font-size: 51px;
        line-height: 64px;
        margin-bottom: 30px;
        letter-spacing: 1.85px;
        text-transform: none;
      }

      p {
        margin: 0 0 30px 0;
        padding: 0 50px;
      }
    }

    .image {
      img {
        max-width: 563px;
      }
    }
  }

  .mobile {
    max-width: 740px;
    margin: 0 auto 50px;

    h1 {
      text-transform: none;
      line-height: 39px;
      letter-spacing: 1.16px;
      font-size: 32px;
      margin-bottom: 50px;
      padding: 0 20px;
    }

    .image {
      width: 100%;
      margin-bottom: 50px;
    }

    p {
      margin: 0 0 30px 0;
      padding: 0 20px;
      text-align: center;
    }
  }

  .mobile-downloads {
    display: flex;
    justify-content: space-evenly;
    .app-button {
      max-height: 43px;

      @media screen and (min-width: $breakpoints-md) {
        max-height: 62px;
      }

      &.google-play {
        border: 1px;
        border-color: white;
        border-style: solid;
        border-radius: 8px;
      }
    }
  }
}

.profile-status {
  height: 100%;
  padding-bottom: 0;
  border-top-width: 3px;
  border-bottom-width: 3px;
}
