.title {
  clear: both;
  display: block;
  margin: 12px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  span {
    background-color: $white;
    display: inline-block;
    font-family: $header-font;
    font-size: 20px;
    letter-spacing: 0.08em;
    font-weight: normal;
    line-height: 1.4;
    margin: 0;
    padding: 0 10px;
    position: relative;
  }
}

.title::before {
  background: $light-grey;
  content: "";
  height: 1px;
  margin: -1px 0 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
}
