.new-modal--sign-in {
  .modal-dialog {

    @media only screen and (max-width: 767px) {
      position: fixed;
      bottom: 0;
      background-color: transparent;
      height: auto;
    }

    @media screen and (min-width: $breakpoints-md) {
      width: 690px;
    }

    &__content {
      background-color: $white;

      @media only screen and (max-width: 767px) {
        background-color: inherit;
      }


      & .modal-icon {
        &.modal-dialog__close {
          right: 40px;
          @media only screen and (max-width: 767px) {
          right: 20px !important;
          top: 20px !important;

          @include rtl {
            right: auto !important;
            left: 20px !important;
          }
        }
      }
      }
    }

    &__body {
      @media only screen and (max-width: 767px) {
        background-color: inherit;
      }
    }
  }

  .fs-otp-sign-in__request-otp,
  .fs-otp-sign-in__submit-otp,
  .fs-otp-sign-in__invalid-otp {
    max-width: none;
    width: 100%;
    height: 100%;
    padding: 72px 40px !important;
    margin: 0 !important;
    max-width: unset !important;

    h1 {
      margin-bottom: 20px !important;
    }
    @media only screen and (max-width: 767px) {
      border-radius: 15px 15px 0 0;
      border-style: solid;
      border-color: white;
      border-width: 0;
      padding: 100px 20px 82px !important;

    }

    &__container {
      justify-content: center !important;
    }

    p {
      font-family: $garamond;
      font-size: 18px;
    }

    button {
      width: auto;
      min-width: 200px;
    }

    .DisplayOnlyInput-field {
      text-align: center;

      @media only screen and (max-width: 767px) {
        padding: 0;
      }
    }

    .persistent-login-next {
      margin-bottom: 0;
    }

    .persistent-login.rates-may-apply {
      text-align: center;
      margin-bottom: 30px !important;
    }

    .fs-radio-inputs {
      margin-bottom: 10px !important;
      @media only screen and (max-width: 767px) {
        flex-direction: row !important;
        margin-bottom: 0 !important;
      }

      .fs-radio {
        @media only screen and (max-width: 767px) {
          text-align: center;
        }
      }
    }
  }

}

html.fs-profile-2018 .new-modal--sign-in {
   .fs-otp-sign-in {
    .intro {
      margin-bottom: 30px !important;
      @media only screen and (max-width: 767px) {
        margin-bottom: 25px !important;
      }
    }
    .verification-code {
      margin-bottom: 30px !important;
    }
    .persistent-login-next {
      margin-bottom: 0 !important;
    }
    .view__footer {
      margin-top: 30px !important;
    }
  }
}

