.arrival-amenities {
  @media screen and (min-width: $breakpoints-md) {
    margin-top: 0;
    padding-bottom: 0;

    &:after {
      content: none;
    }
  }

  &__category-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    line-height: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__services-icon {
    span {
      font-size: 21px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 0 50px;

    &--uncategorised {
      border-top: solid 1px #d8d8d8;
      padding-top: 20px;
    }
  }

  &__item {
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 15px;

    p {
      margin: 0;
    }

    @media screen and (min-width: $breakpoints-md) {
      grid-template-columns: 136px 1fr;
      gap: 30px;
    }

    &__image-container {
      grid-row-start: 1;
    }

    &__image {
      width: 100%;
    }

    &__content {
      display: grid;
      grid-template-columns: 1fr;
      gap: 15px;
      align-items: start;

      @media screen and (min-width: $breakpoints-md) {
        grid-template-columns: auto min-content;
        gap: 20px;
      }
    }

    &__description {
      display: flex;
      flex-direction: column;

      * {
        padding: 0;
        margin-top: 0;
      }

      p {
        margin: 0 0 10px;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    &__price-and-quantity-toggles {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: end;
      align-items: center;
      gap: 15px 30px;

      @media screen and (min-width: $breakpoints-md) {
        grid-template-columns: 1fr;
        gap: 10px 30px;
      }

      .arrival-amenities__item__price {
        justify-self: start;

        @media screen and (min-width: $breakpoints-md) {
          justify-self: unset;
          margin-bottom: 10px;
        }
      }

      .arrival-amenities__item__form-actions {
        grid-column-end: span 2;

        @media screen and (min-width: $breakpoints-md) {
          grid-column-end: 2;
        }
      }
    }

    &__confirmed-quantity {
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: flex-end;
      align-items: center;
      line-height: 20px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .formElement-label {
      text-wrap: wrap;
    }

    .TextInput {
      margin: 0;
      padding: 0;
    }

    .TextInput-field {
      background: #f0f0f0;
    }

    textarea.TextInput-field {
      white-space: normal;
    }

    &__additional-fields {
      display: flex;
      flex-direction: column;
      gap: 15px;

      @media screen and (min-width: $breakpoints-md) {
        grid-column-end: span 2;
      }

      textarea.TextInput-field {
        resize: none;
        min-height: 109px;
        padding: 10px 16px;

        @media screen and (min-width: $breakpoints-md) {
          min-height: 79px;
        }
      }

      .formElement:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }

    &__form-actions {
      display: flex;
      justify-content: flex-end;
      grid-column-end: 2;

      @media screen and (min-width: $breakpoints-md) {
        grid-column-end: span 2;
      }

      .CTA {
        height: 30px;
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .supporting-info {
      flex-direction: row;
      gap: 5px;
      display: flex;
      margin: 0 0 15px;

      svg {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }

  &__footnote {
    margin-top: 30px;

    @media screen and (min-width: $breakpoints-md) {
      margin-top: 60px;
    }
  }

  &__actions {
    border-top: 1px solid #2d2d2d;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 10px;
    padding: 20px 0 0;

    > * {
      flex-grow: 1;
      flex-shrink: 1;

      @media screen and (min-width: $breakpoints-md) {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }

  &__sidebar {
    background: #fff;
    flex-grow: 1;
    flex-shrink: 1;

    &__footnote {
      padding: 30px;
    }
  }

  // may not need
  .section__title {
    margin-bottom: 0;
  }

  .toggle-block:first-child {
    .toggle-block__condensed-item {
      border-top: none;
    }
  }
}
