.Navigation {
  font-size: 18px;
  width: 100%;
  position: relative;
  z-index: $navigation-z-index;
  height: $navigationHeight;

  @include media($navigationBreakpoint) {
    transition: height 0.3s;
  }

  &--is-loading,
  &--is-loading * {
    transition: none !important;
  }

  &--dark {
    background-color: $black;
  }

  &-sm {
    align-items: center;
    background-color: $white;
    display: flex;
    height: $navigationHeight;
    justify-content: space-between;
    padding: 0 10px 0 20px;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &.is-Stick {
      position: fixed;
    }

    @include rtl {
      padding: 0 20px 0 10px;
    }

    @include media($navigationBreakpoint) {
      visibility: hidden;
    }

    .Navigation {
      &-logo {
        margin: 0;
        color: $black;
        line-height: 0;
        font-weight: bold;

        .Navigation--dark & {
          color: $white;
        }

        &::before {
          width: 20px;
          height: 30px;
          display: inline-block;
          line-height: 1;

          .Navigation--dark & {
            content: url('/images/profiles_2018/fs-profiles-header/tree-white.svg');
          }
        }
      }

      &-checkAvailability {
        padding: 7px 20px;
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%, 50%);

        @include viewport(medium) {
          color: $white;
          background-color: $black;
          border-color: $black;

          &:focus,
          &:hover {
            color: $black;
            background-color: $white;
          }
        }
      }

      &-menuButton {
        background: none;
        border: 0;
        cursor: pointer;
        font-size: rem(14px);
        height: 48px;
        padding: 0;
        width: 48px;
        -webkit-transition: background-color 0.3s ease-out, color 0.3s ease-out,
          border-color 0.3s ease-out;
        transition: background-color 0.3s ease-out, color 0.3s ease-out,
          border-color 0.3s ease-out;
        position: relative;

        &::before {
          position: absolute;
          display: block;
          width: 30px;
          height: 15px;
          top: 15px;
          left: 8px;
        }

        .Button-label {
          display: block;
          font-size: 0;
          height: 1px;
          overflow: hidden;
        }
      }
    }
  }

  &--light {
    .Navigation-logo::before {
      content: url('/images/profiles_2018/fs-profiles-header/tree.svg');
    }

    .Navigation-menuButton::before {
      content: url('/images/profiles_2018/fs-profiles-header/menu.svg');
    }
  }

  &--dark {
    .Navigation-sm {
      background-color: $black;
      border-color: $separatorDark;

      @include viewport(medium) {
        .Navigation-checkAvailability {
          color: $black;
          background-color: $white;
          border-color: $white;

          &:focus,
          &:hover {
            color: $white;
            background-color: $black;
          }
        }
      }
    }

    .Navigation-logo::before {
      content: url('/images/profiles_2018/fs-profiles-header/tree-white.svg');
    }

    .Navigation-menuButton::before {
      content: url('/images/profiles_2018/fs-profiles-header/menu-white.svg');
    }

    .Navigation-menuButton {
      @include grayHover('dark');
      &::before {
        color: $white;
      }
    }
  }


  &-lg {
    visibility: hidden;
    background-color: $white;
    width: 100%;
    transition: transform 0.3s ease-in;
    position: fixed;

    &.is-Stick {
      position: fixed;
      top: 0;
      transform: translateY(-$navigationHeight);

      .Navigation-bar {
        height: 64px;
      }
    }

    &.is-Stick.is-StickExpanded {
      transform: translateY(0);
    }

    @include media($navigationBreakpoint) {
      visibility: visible;
    }

    .Navigation {
      &-topBar {
        display: flex;
        align-items: stretch;
        height: $navigationHeight;
        padding: 0 36px;

        &--left {
          display: flex;
          align-items: center;
          flex-grow: 1;
          justify-content: flex-start;
        }

        &--right {
          display: flex;
          align-items: stretch;
          flex-grow: 1;
          justify-content: flex-end;
        }

        .LanguageToggle {
          color: $whover;
          border-color: $whover;
          padding-left: 60px;
          position: relative;

          display: flex;
          align-items: center;

          @include rtl {
            padding-left: 0;
            padding-right: 60px;
          }

          &:hover,
          &:hover::after {
            color: $bhover;
          }

          &-select {
            @include typography(c3);
            max-width: 110px;
            padding-bottom: 0;

            font-size: rem(10px);
          }
        }
      }

      &-link {
        color: $whover;
        font-size: rem(10px);
        display: flex;
        align-items: center;

        &:hover {
          color: $bhover;
        }

        &.is-active {
          border-top: solid 3px black;
          padding-bottom: 2px;
        }
      }

      &-title {
        padding-right: 24px;

        @include rtl {
          padding-right: 0;
          padding-left: 24px;
        }
      }

      &-subTitle {
        font-size: rem(16px);
        text-transform: none;
        position: relative;
        top: -2px;
        padding-left: 24px;
        color: $whover;

        &:hover {
          color: $bhover;
        }

        &::before {
          content: '';
          position: absolute;
          width: 1px;
          height: 19px;
          top: 2px;
          left: 0;
          background-color: $separatorLight;
        }

        @include rtl {
          padding-left: 0;
          padding-right: 24px;
          top: 0;
          line-height: 1.2em;

          &::before {
            left: auto;
            right: 0;
          }
        }
      }

      &-logoContainer {
        flex-basis: 145px;
        flex-shrink: 0;
        height: 100%;
      }

      &-logo-container {
        display: inline-block;
        margin: 0;
        color: $black;
        font-size: 65px;
        line-height: 0;
        font-weight: bold;
        position: relative;
        height: 100%;

        &.is-Stick {
          .Navigation-logo {
            opacity: 0;
            transition-delay: 0;
          }
          .Navigation-logo--animate {
            opacity: 1;
            transition-delay: .2s;
          }
        }
      }

      &-logo {
        opacity: 1;
        transition: opacity .3s;
        transition-delay: .2s;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);

        &::before {
          content: '';
          display: block;
          height: 65px;
          width: 140px;
          line-height: 1;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &--animate {
          transition-delay: 0;
          margin: 0;
          color: $black;
          font-size: 40px;
          line-height: 0;
          font-weight: bold;
          opacity: 0;

          &::before {
            display: inline-block;
            line-height: 1;
            height: 30px;
          }
        }
      }

      &-mainLinks {
        margin: 0;
        list-style: none;
        display: flex;
        align-items: center;
        width: 1000px;
        justify-content: space-around;
        padding: 0 10px;
        height: 100%;
      }

      &-item {
        max-width: 250px;
        text-align: center;
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &:not(:last-child) {
          padding-right: 10px;

          @include rtl {
            padding-right: 0;
            padding-left: 10px;
          }
        }

        .CTA {
          display: inline-block;
          vertical-align: middle;
          font-size: rem(12px);

          @include mediaRange(
            $navigationBreakpoint,
            $navigationTextBreakpoint
          ) {
            font-size: rem(10px);
            letter-spacing: 0.3em;
          }

          &--underlined--dark,
          &--underlined--light {
            border: none;
            text-decoration-line: underline;
            padding-bottom: 0;
          }
        }
      }

      &-more {
        .Navigation-flyoutWrapper {
          display: block;
        }
      }

      &-checkAvailability {
        padding: 12px 20px;
        color: $white;
        background-color: $black;
        border-color: $black;

        &:focus,
        &:hover {
          color: $black;
          background-color: $white;
        }
      }

      &-emptyCAW {
        width: 130px;
      }
    }
  }

  &--light .Navigation-lg {
    .Navigation-logo {
      &::before {
        background-image: url('/images/profiles_2018/fs-profiles-header/tree-large.svg');
      }

      &--animate::before {
        background-image: url('/images/profiles_2018/fs-profiles-header/tree.svg');
      }
    }
  }

  &--dark .Navigation-lg {
    background-color: $black;
    border-color: $separatorDark;

    .Navigation-topBar {
      border-color: $separatorDark;
    }

    .Navigation-logo {
      color: $white;

      &::before {
        background-image: url('/images/profiles_2018/fs-profiles-header/tree-large-white.svg');
      }

      &--animate {
        color: $white;
      }
    }

    .Navigation-checkAvailability {
      color: $black;
      background-color: $white;
      border-color: $white;

      &:focus,
      &:hover {
        color: $white;
        background-color: $black;
      }
    }
  }

  &-flyout {
    display: inline-flex;
    background-color: $white;
    padding: 0 30px;
    list-style: none;
    max-height: 0;
    overflow: hidden;
    transition: max-height .1s;
    transition-delay: .5s;

    &Wrapper {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      position: absolute;
      right: 35px;
      transform: translateX(50%);
      width: 420px;
      z-index: 2;
      border-top: 1px solid $separatorLight;
      transition: opacity 0.3s;
      bottom: 0;
      height: 0;
      opacity: 0;

      &::after {
        content: '';
        border-color: $separatorLight;
        border-style: solid;
        position: absolute;
        background-color: $white;
        transform: rotate(45deg);
        margin: 0 auto;
        left: 0;
        right: 0;
        z-index: -1;
        border-left-width: 1px;
        border-top-width: 1px;
        top: -9px;
        width: 20px;
        height: 20px;
      }

      &.is-shown {
        opacity: 1;
        .Navigation-flyout {
          max-height: 600px;
          transition: none;
          transition-delay: 0;
        }
      }
    }

    &-col {
      max-width: 230px;
      text-align: left;
      padding: 42px 30px 28px;
      display: flex;
      flex-direction: column;

      .Navigation-flyoutLink {
        margin-bottom: 17px;
        line-height: 1em;

        &:last-child {
          margin-bottom: 0;
        }

        .CTA {
          display: inline;

          &--underlined--dark,
          &--underlined--light {
            border: none;
            text-decoration-line: underline;
            padding-bottom: 0;
          }
        }
      }
    }
  }

  &--dark .Navigation-flyout {
    background-color: $black;
    color: $white;
    border: 1px solid $separatorDark;
    border-top: none;

    &Wrapper {
      // To avoid flickering in the flyout border top when nav bar height changes
      box-shadow: 0 -1px 0 0 $separatorDark;
      border-top: none;
      top: 73px;

      &::after {
        background-color: $black;
        border-color: $separatorDark;
      }
    }
  }
}


.NavigationOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 10;

  &--Opened {
    pointer-events: initial;
  }

  &-CloseCTA {
    position: absolute;
    top: 0;
    right: 4px;
    visibility: hidden;
    opacity: 0;
    transition: color 0.3s, opacity 0.3s, visibility 0.3s;

    .Button-label {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }

    @include rtl {
      left: 0;
      right: auto;
      margin-left: 15px;
      margin-right: 0;
    }
  }

  &--Opened .NavigationOverlay-CloseCTA {
    visibility: visible;
    opacity: 1;
  }

  &-Background {
    background-color: rgba($black, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
  }

  &-Content {
    background-color: $black;
    color: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    max-width: 470px;
    margin: 0;
    transition: transform 0.3s ease-in, visibility 0s linear 0.3s;
    transform: translateX(100%);
    visibility: hidden;

    @include rtl {
      left: 0;
      right: auto;
      transform: translateX(-100%);
    }
  }

  &--Opened {
    .NavigationOverlay-Background {
      visibility: visible;
      opacity: 1;
    }

    .NavigationOverlay-Content {
      transition: transform 0.3s ease-in, visibility 0s linear 0s;
      transform: translateX(0);
      visibility: visible;
    }
  }

  &-InnerContent {
    overflow: scroll;
    padding: 0 35px;
  }

  &-Logo {
    color: $white;
    display: inline-block;
    text-decoration: none;
    line-height: 1;
    margin-top: 8px;
    margin-bottom: 0;

    &::before {
      display: block;
      width: 38px;
      height: 58px;
    }
  }

  &-Title {
    font-size: rem(21px);
    margin-top: 15px;
    margin-bottom: 0;
    display: inline-block;
    text-decoration: none;
    transition: color 0.3s;
    color: $white;
    text-transform: none;

    &:hover {
      color: $bhover;
    }
  }

  &-TopBar {
    align-items: center;
    background-color: $black;
    display: flex;
    flex-shrink: 0;
    flex-basis: $navigationHeight;
    top: 0;
    right: 0;
    padding: 0 62px 0 42px;
    width: 100%;
    z-index: 1;

    @include viewport(medium) {
      max-width: 470px;
    }
  }

  &-TopLink {
    color: $whover;
    font-size: rem(10px);
    letter-spacing: 2px;
    padding: 0;

    &:hover {
      color: $bhover;
    }
  }

  &-TopLinkContainer {
    display: flex;
    margin-right: 40px;

    @include rtl {
      margin-left: 40px;
      margin-right: 0;
    }

    .CTA {
      white-space: nowrap;
    }

    .LanguageToggle {
      color: $whover;
      border-color: $whover;
      display: flex;

      &::after {
        top: 4px;
      }

      &:hover,
      &:hover::after {
        color: $bhover;
      }

      &-select {
        @include typography(c3);
        padding-bottom: 0;
        padding-right: 18px;
        max-width: 110px;

        font-size: rem(10px);

        @include rtl {
          padding-left: 25px;
          padding-right: 0;
        }
      }
    }
  }

  &-LinksContainer {
    border-top: 1px solid $bhover;
    padding: 35px 0 10px;
    margin-top: 25px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &-LinksTitle {
    color: $whover;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: rem(18px);
    text-transform: none;
  }

  &-Link {
    margin-bottom: 5px;

    &--main {
      &:not(:last-child) {
        margin-bottom: 25px;
      }
    }

    .CTA {
      font-size: rem(11px);

      &--underlined--dark,
      &--underlined--light {
        border: none;
        text-decoration-line: underline;
        padding-bottom: 0;
      }
    }
  }
}

.Navigation--light {
  .NavigationOverlay-Content {
    background-color: rgba(0, 0, 0, 0.5);
    color: $black;
  }

  .NavigationOverlay-Logo {
    color: $black;

    &::before {
      background-image: url('/images/profiles_2018/fs-profiles-header/tree.svg');
      background-size: 20px 30px;
      display: inline-block;
      width: 20px;
      height: 30px;
      content:"";
    }
  }

  .NavigationOverlay-Title {
    color: $black;
    text-decoration: none;
    font-weight: 400;

    &:hover {
      color: $whover;
    }
  }

  .NavigationOverlay-TopBar {
    background-color: $white;
    box-shadow: 0px -2px 24px -2px rgba(196,192,196,1);
  }

  .NavigationOverlay-LinksContainer {
    border-top: 1px solid $separatorLight;
  }
}

.Navigation--dark {
  .NavigationOverlay-Logo {
    &::before {
      content: url('/images/profiles_2018/fs-profiles-header/tree-white.svg');
    }
  }
}


.LanguageToggle {
  position: relative;
  display: inline-block;
  z-index: 1;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid;
    position: absolute;
    top: 50%;
    right: 4px;
    z-index: -1;
    margin-top: -3px;
  }
}

.rtl {
  .LanguageToggle {
    &::after {
      right: auto;
      left: 4px;
    }
  }
}

.LanguageToggle-select {
  background: transparent;
  border: none;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  padding: 0 18px 0 0;
  text-align-last: right;
  cursor: pointer;

  height: auto;
  font-size: 10px;
  font-weight: bold;

  @include rtl {
    padding: 0 0 0 18px;
  }
}

.Button--close--dark {
  background: none;
  border: 0;
  cursor: pointer;
  height: 48px;
  padding: 0;
  width: 48px;
  transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: url('/images/profiles_2018/fs-profiles-header/close-white.svg');
    display: block;
    width: 16px;
    height: 16px;
  }
}

.Button--close--light {
  background: none;
  border: 0;
  cursor: pointer;
  height: 48px;
  padding: 0;
  width: 48px;
  transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: url('/images/profiles_2018/fs-profiles-header/close.svg');
    display: block;
    width: 16px;
    height: 16px;
  }
}


.Navigation__profile-flyout {
  &__overlay {
    background-color: rgba($black, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__box {
    background-color: #fff;
    padding: 0;
  }

  &__columns {
    // border-top: solid 1px #ccc;
    margin: 0 auto;
    max-width: $navigationTextBreakpoint;
    width: 100%;
    overflow: hidden;
    padding: 0;

    display: flex;
    flex-direction: column;
  }

  &__column {
    border-bottom: solid 1px #ccc;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 30px 46px 40px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include viewport(medium) {
      align-items: center;
      justify-content: space-between;
      text-align: center;
    }

    > div {
      width: 100%;
    }

    p {
      font-size: rem(18px);
      margin: 0 0 30px;
    }
  }

  &__columns--sign-out .Navigation__profile-flyout__column {
    padding-top: 40px;
    padding-bottom: 40px;

    h1 {
      margin-bottom: 40px;
    }

    .CTA--secondary--dark {
      width: 236px;
    }
  }

  .Button--close--light,
  .Button--close--dark {
    position: absolute;
    top: 30px;
    right: 30px;
    z-index: 20;

    .Button-label {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }
  }
}


.NavigationOverlay-InnerContent .Navigation__profile-flyout {
  margin: 0 -35px 18px;
}


.Navigation-lg .Navigation__profile-flyout {
  position: fixed;
  top: $navigationHeight;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  &__box {
    background-color: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  &__columns {
    border-top: none;
    flex-direction: row;
    padding-top: 80px;
    padding-bottom: 80px;

    &--sign-out {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__column {
    border-bottom: none;
    width: 50%;
    padding: 70px 114px;

    p {
      margin-bottom: 40px;
    }
  }

  &__columns--sign-out .Navigation__profile-flyout__column {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .Navigation__profile-flyout__column + .Navigation__profile-flyout__column {
    border-left: solid 1px #979797;
  }

}

.Secondary-Navigation-bar {
  background-color: #595959;

  .Secondary-Navigation-links {
    text-align: center;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .Secondary-Navigation-item {
    display: inline-block;
    padding: 0 20px;

    a {
      border-top: solid 2px transparent;
      border-bottom: solid 2px transparent;
      display: inline-block;
      padding: 18px 0;
      font-size: rem(10px);
      font-weight: 500;
      transition: none;

      &:hover,
      &:focus {
        border-color: transparent;
        color: #fff;
      }

      &.is-selected {
        border-bottom: 5px solid #fff;
      }
    }
  }

  .Secondary-Navigation-item:first-of-type {
    a {
      font-weight: 700;
    }

    &::before {
      display: block;
      float: right;
      margin: 16px -44px 0;
      width: 2px;
      height: 23px;
      content: "";
      background-color: #d8d8d8;
      opacity: .7;
    }
  }

  .Secondary-Navigation-item:nth-of-type(2) {
    padding-left: 40px;
  }
}
