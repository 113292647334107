.change-search {
  .destination {
    margin: 12px 0 12px 0;
    .title {
      display: none;
    }
  }

  #search-panel-container {
    &.search-panel-active {
      max-height: 100px;
      margin-bottom: 450px;
    }
  }
}

@media only screen and (max-width: $screen-sm - 1px) {
  .change-search {
    #search-panel-container {
      &.search-panel-active {
        max-height: 125px;
        margin-bottom: 800px;
      }
    }
    .guests-dropdown.show-rooms {
      > .guests-dropdown-selected {
        display: none;
      }

      .guests-dropdown-options {
        display: block;
        position: relative;
        top: 0;
        box-shadow: none;
        border: none;

        .guests-dropdown-option {
          padding: 10px 0;

          &:hover {
            background: none;
          }
        }
      }

      > .guests-dropdown-more-options {
        display: none;
      }
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .change-search {
    background: $grey;

    #search-panel-container {
      &.search-panel-active {
        max-height: 125px;
        margin-bottom: 500px;
      }
    }

    @import "datepicker/calendar-desktop";
    .fs-calendar {
      right: 10px;
    }

    .guests-dropdown {
      width: 330px;

      .guests-dropdown-options {
        position: absolute;
      }

      > .guests-dropdown-more-options {
        display: none;
      }

      .pt & {
        width: 290px;
      }
    }

    .fs-search-panel {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0;
      @include clearfix();

      label span {
        display: none;
      }

      fieldset {
        float: left;
        margin: 0;
        padding: 0;
      }

      div.fs-select-date {
        display: none;
      }

      input[name="promoCode"] {
        width: 185px;
      }

      .fs-occupancy-input {
        overflow: hidden;
        padding: 0 36px;

        input {
          padding: 0;
        }

        .btn {
          height: 45px;
          width: 36px;
          min-width: 0;
          .icon {
            font-size: 20px;
          }
        }

        .btn.btn-decrement {
          margin-left: -36px;
        }

        .btn.btn-increment {
          margin-right: -36px;
        }
      }

      .fs-autocomplete {
        width: 330px;

        .autocomplete-list {
          box-shadow: 0 8px 8px $widget-shadow-color;
          position: absolute;
          left: 0;
          right: 0;
          top: 45px;
          z-index: $z_autocompleteList;
        }
      }

      .btn-primary.find-rooms {
        float: left;
        font-size: 14px;
        width: 185px;
        padding: 10px 20px 11px;
      }

      .outage-error {
        .btn-primary.find-rooms {
          float: none;
          width: auto;
          padding: 10px 20px;
        }
      }

      input[type="text"].empty,
      input[type="email"].empty,
      input[type="password"].empty,
      input[type="search"].empty,
      input[type="range"].empty,
      textarea.empty {
        color: $primary-text-color;
      }
      ::-webkit-input-placeholder {
        color: $primary-text-color;
      }
      :-moz-placeholder {
        color: $primary-text-color;
      }
      ::-moz-placeholder {
        color: $primary-text-color;
      }
      :-ms-input-placeholder {
        color: $primary-text-color;
      }
    }
  }

  .rtl {
    .change-search {
      .guests-dropdown {
        margin-right: 0;
      }
      .fs-search-panel {
        fieldset {
          float: right;
        }
        .fs-occupancy-input {
          .btn.btn-increment {
            margin-left: -36px;
          }

          .btn.btn-decrement {
            margin-right: -36px;
          }
        }
        input[name="promoCode"] {
          margin-right: 0;
        }
        .btn-primary.find-rooms {
          float: right;
        }
      }
      .fs-calendar-wrapper {
        $indicator-offset: 20%;
        &.checkIn:before {
          left: auto;
          right: $indicator-offset;
        }

        &.checkOut:before {
          right: auto;
          left: $indicator-offset;
        }
      }
    }
  }
  .rtl {
    .change-search {
      .fs-search-panel {
        input.fs-select-date {
          float: right;
        }
      }
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .change-search {
    .guests-dropdown {
      width: 410px;
    }

    .fs-search-panel {
      .fs-autocomplete {
        width: 410px;
      }

      input.fs-select-date {
        width: 255px;
      }

      input[name="promoCode"] {
        width: 255px;
      }

      .btn-primary.find-rooms {
        width: 255px;
        font-size: 14px;
        padding: 10px 30px 11px;
      }
    }
  }
}

@media only screen and (min-width: $screen-lg) {
  .change-search {
    #search-panel-container {
      &.search-panel-active {
        max-height: 85px;
        margin-bottom: 350px;
      }
    }

    .guests-dropdown {
      width: 320px;
      .pt & {
        width: 290px;
      }
    }

    .fs-search-panel {
      margin-right: 0;

      .fs-autocomplete {
        width: 263px;

        .pt & {
          width: 243px;
        }
      }

      input.fs-select-date {
        width: 130px;
      }

      input[name="promoCode"] {
        width: 112px;
        .pt & {
          width: 163px;
        }
      }

      .btn-primary.find-rooms {
        font-size: 12px;
        padding: 12px 0;
        width: 134px;
      }
    }
  }
  .rtl {
    .change-search {
      .guests-dropdown {
        width: 210px;

        .guests-dropdown-selected {
          padding: 0 10px 0 10px;
        }
      }
      .fs-search-panel {
        .fs-autocomplete {
          width: 180px;
        }

        input.fs-select-date {
          width: 225px;
        }
      }
    }
  }
  .zh_hant {
    .change-search {
      .fs-search-panel {
        input.fs-select-date {
          width: 150px;
        }
      }
      .guests-dropdown {
        width: 280px;
      }
    }
  }

  .de {
    .change-search {
      .fs-autocomplete {
        width: 250px;
      }
      input[name="promoCode"] {
        width: 140px;
      }
      .btn-primary.find-rooms {
        width: 120px;
      }

      .guests-dropdown {
        width: 310px;
      }
    }
  }
  .it {
    .change-search {
      input[name="promoCode"] {
        width: 155px;
      }
      .guests-dropdown {
        width: 275px;
      }
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .fr {
    .change-search {
      .fs-autocomplete {
        width: 250px;
      }

      input[name="promoCode"] {
        width: 255px;
      }
      .btn-primary.find-rooms {
        width: 120px;
      }

      .guests-dropdown {
        width: 295px;
      }
    }
  }
}

input.search-panel-error {
  border: 4px solid #f00;
}

@media only screen and (min-width: $screen-lg) {
  .zh .change-search,
  .kr .change-search {
    .guests-dropdown {
      width: 290px;
    }

    input.fs-select-date {
      width: 145px;
    }
  }

  .fr .change-search {
    input[name="promoCode"] {
      width: 154px;
    }
  }
}

@media only screen and (min-width: $screen-lg) {
  .jp .change-search .fs-search-panel {
    .fs-autocomplete {
      width: 278px;
    }

    .guests-dropdown {
      width: 276px;
    }

    input.fs-select-date {
      width: 145px;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  // From: https://coryrylan.com/blog/css-gap-space-with-flexbox
  .emulated-flex-gap {
    $gap: 0.5rem;
    display: inline-flex;
    flex-wrap: wrap;
    margin: (-1 * $gap) 0 0 (-1 * $gap);
    width: calc(100% + #{$gap});

    .emulated-flex-gap-component {
      margin: $gap 0 0 $gap;
    }
  }

  .change-search .fs-search-panel .fs-select-date {
    width: 185px;
    padding-right: 9px;
  }
}

// FLEX DATES
// combined check-in and check-out date input
.change-search .fs-search-panel .datepicker {
  input.fs-select-date--check-in-check-out {
    @media only screen and (min-width: $screen-sm) {
      width: 382px;
    }

    @media only screen and (min-width: $screen-md) {
      width: 522px;
    }

    @media only screen and (min-width: $screen-lg) {
      width: 279px;
    }
  }
}

// FLEX DATES
// flex date window buttons
.change-search .fs-search-panel .flex-date-window {
  border-bottom: 1px solid $light-grey;
  padding: 0px 16px 20px 16px;
  display: flex;
  flex-direction: row;
  margin: 0 -5px;

  &__btn {
    border: 1px solid #000000;
    background: transparent;
    color: #000;
    margin: 0 5px;
    width: 80px;
    height: 30px;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    line-height: 13px;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 0 10px;
    width: auto;
    min-width: auto;
    max-width: none;

    &--is-selected {
      background: #000;
      color: #fff;
    }
  }
}

// FLEX DATES
.flex-dates-flexible-label {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 3px;
  text-transform: uppercase;
  flex: none;
  order: 0;
  flex-grow: 0;
  width: auto;
  min-width: auto;
  max-width: none;
  padding: 20px 16px 0px 16px;
  color: #000;
}

// FLEX DATES
// nights-selected
.change-search .fs-search-panel .flex-dates-nights-selected {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 16px;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
  color: #000000;

  .btn--primary {
    width: auto;
    min-width: auto;
    max-width: none;
    margin-left: 10px;

    font-weight: bold;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: 3px;
    text-transform: uppercase;
  }
}

.rtl {
  .change-search
    .fs-search-panel
    .flex-dates-nights-selected
    .flex-dates-flexible-label {
    .btn--primary {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

// FLEX DATES
// flex-dates-availability-legend
.change-search .fs-search-panel .flex-dates-availability-legend {
  background: #f5f5f5;
  padding: 15px 16px;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;

  .legend {
    list-style: none;
    margin: 0 0 5px;
    padding: 0;
    display: flex;
    flex-direction: row;
    border: none;

    li {
      margin: 0 20px 0 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      &:before {
        background: #ccc;
        box-sizing: border-box;
        content: "";
        display: inline-block;
        height: 18px;
        width: 18px;
        margin-right: 10px;
      }

      &.restrictions:before {
        background: transparent;
        border: solid 1px #c51f1f;
      }
    }
  }

  &__LastUpdatedDescription {
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 14px;
  }
}

.rtl {
  .change-search .fs-search-panel .flex-dates-availability-legend {
    .legend {
      li {
        margin-left: 20px;
        margin-right: 0;

        &:before {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
}
