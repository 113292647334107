.new-modal--russia-confirmation {
  @media only screen and (min-width: $screen-sm) {
    .modal-dialog {
      width: 90%;
      max-width: 1280px;
    }
  }

  .btn-primary {
    margin: 5px 10px;
  }
  .russia-confirmation-view {
    padding: 40px 100px;
    height: auto;
    min-height: auto;
    margin: 0 auto;
    width: 100%;
    text-align: center;


    .russia-confirmation-body {
      h1 {
        font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 28px;
        text-transform: none;
        letter-spacing: 0.05em;
      }

      p {
        font-family: "HelveticaNeue-Light","Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,sans-serif;
        font-size: 18px
      }
    }

    .russia-confirmation-actions {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      fs-button {
        padding: 0 10px;

        button {
          font-family: "Neue Helvetica W04",Neue Helvetica,Helvetica,sans-serif;
          font-size: .55556rem;
          letter-spacing: 0.3em;
          line-height: 1.3em;
          align-items: center;
          box-sizing: border-box;
          cursor: pointer;
          display: inline-flex;
          font-weight: 700;
          justify-content: center;
          min-width: 350px;
          max-width: 350px;
          padding: 12px 10px;
          text-align: center;
          text-decoration: none;
          text-transform: uppercase;
          transition: background-color 0.3s ease-out, color 0.3s ease-out, border-color 0.3s ease-out;
          background-color: #000;
          border: 1px solid #000;
          color: #fff;
          width: 100%;
          -webkit-appearance: button;
          overflow: visible;
          margin: 0;
        }
      }

      @media screen and (max-width: 960px) {
        flex-direction: column;

        fs-button {
          button {
            min-width: 130px;
          }
        }

        fs-button + fs-button {
          margin-top: 10px;
        }
      }

    }

    .russia-confirmation-error {
      color: red;
      padding-top: 40px;
    }

    @media screen and (max-width: 700px) {
      padding: 40px 25px;
    }
  }
}

.view-register_2018__do-you-have-areservation-with-us {
  .new-modal--russia-confirmation {
    .btn-primary {
      background-color: #000;
    }
  }
}
