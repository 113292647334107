*,
*:before,
*:after {
  box-sizing: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Placeholder colors
input[type="text"].empty,
input[type="email"].empty,
input[type="password"].empty,
input[type="search"].empty,
input[type="range"].empty,
textarea.empty {
  color: $primary-text-color;
}
::-webkit-input-placeholder {
  color: $primary-text-color;
}
:-moz-placeholder {
  color: $primary-text-color;
}
::-moz-placeholder {
  color: $primary-text-color;
}
:-ms-input-placeholder {
  color: $primary-text-color;
}

body {
  font-family: $primary-font;
  font-weight: $primary-font-weight;
  color: $primary-text-color;
  font-size: $primary-font-size;
  line-height: 1.4;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// IE11 requires height: 0; to work the flex layout
*::-ms-backdrop,
#root {
  height: 0;
} /* IE11 */

#main {
  flex-grow: 1;

  .main-inner {
    padding-bottom: 15px;
  }
}

h1,
h2,
h3,
h4,
h5,
dt {
  font-family: $header-font;
}

h1 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: $primary-font-weight;
}

a,
.appear-as-link {
  color: $primary-link-color;
  cursor: pointer;
  text-decoration: underline;

  // &:hover {
  //   color: $primary-link-hover-color;
  // }
}

strong {
  font-family: $bold-font;
}

table {
  border-collapse: collapse;
}

fieldset {
  border: none;
  margin: 12px 0;
  min-width: 0;
  padding: 0;
}

legend {
  background: $medium-grey;
  color: $white;
  display: inline-block;
  font-size: $primary-font-size;
  letter-spacing: 0.1em;
  line-height: 28px;
  margin-bottom: -1px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
  text-transform: uppercase;
}

fieldset.has-errors legend {
  background: $error-color;
}

fieldset + fieldset:not(.guest-inputs) {
  margin-top: -13px;
}

fieldset + fieldset.has-break {
  margin-top: 12px;
}

fieldset legend:first-child {
  border-radius: 2px 2px 0 0;
}

fieldset + fieldset legend,
fieldset + fieldset legend:first-child {
  border-radius: 0;
}

fieldset + fieldset.has-break legend:first-child {
  border-radius: 2px 2px 0 0;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="number"],
input[type="search"],
select,
textarea {
  background: $white;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  box-sizing: border-box;
  color: $primary-text-color;
  font-size: $primary-font-size;
  height: $input-height + 2px;
  margin: 0;
  padding: 0 10px;
  text-overflow: ellipsis;
  width: 100%;

  &.is-disabled,
  &[disabled] {
    background: $lighter-grey;
    // color: $dark-grey; commented out becuase they requested normal color.
    cursor: not-allowed;
    &::-webkit-input-placeholder {
      color: $medium-grey;
    }
    &:-moz-placeholder {
      color: $medium-grey;
    }
    &::-moz-placeholder {
      color: $medium-grey;
    }
    &:-ms-input-placeholder {
      color: $medium-grey;
    }
    &.empty {
      color: $medium-grey;
    }
  }
}

input[type="text"]:not(:read-only):focus,
input[type="tel"]:not(:read-only):focus,
input[type="email"]:not(:read-only):focus,
input[type="password"]:not(:read-only):focus,
input[type="date"]:not(:read-only):focus,
input[type="datetime"]:not(:read-only):focus,
input[type="number"]:not(:read-only):focus,
input[type="search"]:not(:read-only):focus,
textarea:not(:read-only):focus {
  @include input-focus;
}

input {
  line-height: $input-height + 2px;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="search"],
input[type="range"],
textarea {
  -webkit-appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  height: auto;
  margin: 0;
  padding: 0;
  width: auto;
}

select {
  cursor: pointer;
  text-indent: 2px;
}

select:focus::-ms-value {
  background: transparent;
  color: $primary-text-color;
}

button {
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  padding: 0;
  width: 100%;
  border-radius: 0; // New versions of Chrome will default to rounded buttons.
}

label {
  cursor: pointer;
  max-width: 100%;
}

ul {
  &.no-bullets {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
    }
  }

  &.dash-bullets {
    li {
      list-style-type: none;

      &:before {
        content: "- ";
      }
    }
  }
}

.center-text {
  text-align: center;
}

.block-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.div-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.phone {
  display: inline-block;
  direction: ltr;
}

@media screen and (min-width: $screen-sm) {
  main .main-inner {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0) !important;
  height: 1px;
  margin: -1px !important;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  background: none;
}

.span {
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  text-transform: inherit;
  font-family: inherit;
}

button.icon-button,
button.icon-button:focus,
button.icon-button:active {
  min-height: inherit !important;
  min-width: inherit !important;
  padding: inherit !important;
  letter-spacing: inherit !important;
  background: none !important;
  border: none !important;
}
button span {
  position: relative;
}
.no-y-scroll {
  overflow-y: hidden;
}
