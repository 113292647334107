.layout--profile-auth {
  .view--register-send-code {
    h1 {
      margin-bottom: 30px;
    }

    .intro {
      margin-bottom: 80px;
    }

    .fs-radio-inputs {
      margin-bottom: 80px;
      width: 200px;
      text-align: left;
    }

    .fs-radio {
      display: block;
      margin: 0 0 20px;
    }

    .rates-may-apply {
      margin: -10px 0 40px;
    }
  }
}
