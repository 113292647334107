.new-modal--floorplan {
  .floorplan {
    text-align: center;
    padding: 36px 0;

    img {
      max-width: 100%;
    }
  }
}
