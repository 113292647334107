.ButtonOptions {
  padding-top: 15px;
  gap: 10px;
  position: relative;
  grid-column-end: span 2;

  &--selected {
    grid-column-end: span 1;
  }

  &--list {
    display: flex;
    flex-direction: column;
  }

  &--grid {
    display: grid;
    grid-template-columns: 1fr;

    @media only screen and (min-width: 961px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .ButtonOption {
    padding: 15px 20px;
    border: solid 1px #d8d8d8;
    background-color: #fff;
    text-align: initial;

    &:focus,
    &:hover {
      outline: none;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
    }

    &--selected {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:focus,
      &:hover {
        box-shadow: none;
      }
    }

    &__cost {
      margin-top: 10px;
    }

    div {
      pointer-events: none;
    }
  }

  &__showHideBtn {
    padding: 14px 20px;
    display: flex;
    gap: 10px;
    justify-content: center;
    border: 1px solid black;

    span {
      font-size: 5px;
      display: grid;
      place-content: center;
      transition: transform 0.3s ease;
    }
  }

  &__showHideBtn--visible {
    span {
      transform: rotate(-180deg);
    }
  }

  &__label {
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.5s ease;
    white-space: nowrap;
    z-index: 50;
    opacity: 1;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  &__errorMessage {
    position: initial;
  }
}
