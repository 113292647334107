.room-item-details {
  padding: 15px;
  width: 100%;

  .description-text {
    line-height: $description-text-line-height;
    margin-bottom: 10px;
    position: relative;
    overflow: hidden;

    p {
      margin: 0;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .room-item-details {
    .description-text {
      font-weight: normal;
      margin-top: 0;
      overflow: hidden;
    }
  }
}