.amenities-request-modal {
  .amenities-modal-intro {
    margin: 0 0 48px;
  }

  .fsp-input--checkbox {
    padding-right: 20px;
    float: left;
    clear: left;
    margin: 0 0 24px;
  }

  .fsp-input__checkbox {
    padding-left: 44px;
    font-size: rem(16px);
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;

    .amenity-cost {
      font-family: $hn;
      font-size: rem(10px);
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.3;
      letter-spacing: 3px;
    }
  }

  fieldset {
    margin: 0 0 34px;
    padding: 0 0 10px;
  }

  legend {
    background: none;
    color: inherit;
    font-family: $hn;
    font-size: rem(12px);
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 3px;
    margin: 0 0 12px;
    text-align: left;
    text-transform: uppercase;
  }

  .amenities-intro {
    color: #7d7d7d;
    margin: 0 0 24px;
  }
}
