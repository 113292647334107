$search-summary-height: 90px;

.rtl {
  .search-summary {
    &__property-name {
      background-position: 100% 50%;
      padding-right: 16px;
      padding-left: 0;
    }

    &__form-field {
      &--submit {
        margin-right: 20px;
        margin-left: 0;
      }
    }
  }
}

.search-summary {
  background: #fff;
  border-top: 1px solid #d8d8d8;
  color: #000;
  padding: 18px 15px 15px;
  position: sticky;
  left: 0;
  right: 0;
  z-index: $z_searchSummary;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  top: 50px;

  @media only screen and (min-width: $screen-sm) {
    top: 90px;
  }

  &__container {
    margin: 0 auto;
    max-width: 1228px;
  }

  &__property-name {
    background-image: url(/images/profiles_2018/brand-small.svg);
    background-position: 0 50%;
    background-repeat: no-repeat;
    background-size: 7px;
    margin: 0 0 10px;
    padding: 0 0 0 16px;

    &.ty-c3 {
      font-size: 10px;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__actions {
    .btn-plain {
      padding: 8px 20px !important;
      min-width: 72px !important;
      max-width: none !important;
      width: auto !important;

      font-style: normal !important;
      font-weight: bold !important;
      font-size: 10px !important;
      line-height: 1.3 !important;
      letter-spacing: 3px !important;
      text-transform: uppercase !important;
    }
  }

  &__details {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 1.7;
    letter-spacing: 1.5px;
  }

  &__form {
    display: flex;
    flex-direction: row;

    label.ty-c3 {
      font-size: 10px;
      cursor: default;
    }
  }

  &__form-fields {
    display: flex;
    flex-direction: row;
    margin: 0;

    flex-grow: 1;
    flex-shrink: 1;
  }

  &__form-field {
    border-left: solid 1px #d8d8d8;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0 15px;
    justify-content: flex-start;

    flex-grow: 1;
    flex-shrink: 1;

    label {
      margin: 0 0 10px;
    }

    &__value {
      font-family: $garamond;
      font-size: 16px;
      line-height: 1.12;
      letter-spacing: 0.5px;
    }

    &--destination {
      padding-left: 0;
      border-left: none;
    }

    &--check-in-check-out {
      min-width: 250px;
    }

    &--promo {
      flex-basis: 102px;
      padding-right: 0;
    }

    &--submit {
      border-left: none;
      padding: 0;
      margin: 0 0 0 20px;
      flex-grow: 0;
      flex-shrink: 0;

      .btn-primary {
        height: 40px !important;
        width: auto !important;
        min-width: none !important;
        max-width: none !important;

        font-style: normal !important;
        font-weight: bold !important;
        font-size: 10px !important;
        line-height: 1.3 !important;
        letter-spacing: 3px !important;
        text-transform: uppercase !important;
      }
    }
  }
}
