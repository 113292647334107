.toast-notification {
	width: 100%;
	z-index: 40001;
	position: fixed;
	top: 0;
	left: 50%;
	box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 20px;

	@media screen and (min-width: $breakpoints-md) {
		width: 375px;
		transform: translate(-50%, 20px);
	}

	&--isOpen {
		transform: translate(-50%, 0);
		@media screen and (min-width: $breakpoints-md) {
			transform: translate(-50%, 20px);
		}
	}

	&--isClosed {
		transform: translate(-50%, calc(-100% - 10px));
	}

	&--dark {
		background-color: $white;
		color: $black;
	}

	&--light {
		background-color: #2D2D2D;
		color: $white;
	}
	
	&__close-button {
		background: transparent;
		background-image: url("/images/profiles_2018/black-cross.svg");
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50% 50%;
		border: none;
		color: $black;
		width: 12px !important;
		height: 12px;
		line-height: 12px;
		margin-left: 10px;
		padding-left: 12px;
		@media screen and (min-width: $breakpoints-md) {
			width: 14px !important;
			height: 14px;
			line-height: 14px;
			padding-left: 14px;
		}


		&--light {
		  background-image: url("/images/profiles_2018/white-cross.svg");
		}
	
		span {
		  display: block;
		  font-size: 0;
		  height: 1px;
		  overflow: hidden;
		}

	  }
}

@keyframes slideIn {
	from {
		transform: translate(-50%, calc(-100% - 10px));
	}
	to {
		transform: translate(-50%, 20px);
	}
}

@keyframes slideOut {
	from {
		transform: translate(-50%, 20px);
	}
	to {
		transform: translate(-50%, calc(-100% - 10px));
	}
}

@keyframes slideInMobile {
	from {
		transform: translate(-50%, calc(-100% - 10px));
	}
	to {
		transform: translate(-50%, 0);
	}
}

@keyframes slideOutMobile {
	from {
		transform: translate(-50%, 0);
	}
	to {
		transform: translate(-50%, calc(-100% - 10px));
	}
}