$hero-panel-desktop-cut-in: $hero-high-cut;
$hero-panel-font-size: 16px;
$hero-panel-padding: 0;

$hero-panel-nav-width: 30%;
$hero-panel-nav-max-width: 329px;

.hero-panel {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: none;

  background: $panel-background;
  color: $panel-color;

  // 1px top to line up with mobile modals
  padding: 1px $hero-panel-padding $hero-panel-padding;
  margin-right: auto;
  margin-left: auto;

  font-size: $hero-panel-font-size;

  // ugly overrides
  h1, h2, h3, h4, h5, h6 {
    font-family: $primary-font;
    font-weight: $primary-font-weight;
  }

  // container reset
  .container {
    max-width: 100%;
    width: auto;
    padding: 0;
  }
}

// mobile only
@media screen and (max-width: $screen-sm-max) {

}
// desktop
@media screen and (min-width: $screen-sm) {
  .hero-panel {
    overflow: hidden;
    border: $panel-border;
    padding: $hero-panel-padding;
    margin-top: -$hero-high-cut;

    .container {
      display: table;
      min-height: 600px;
      width: 100%;
    }
  }

  .hero-panel__nav {
    width: $hero-panel-nav-width;
    max-width: $hero-panel-nav-max-width;
  }
}
