.heading-row {

  .btn-plain {
    margin: 15px auto;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

}

@media only screen and (min-width : $screen-sm) {
  .heading-row {
    overflow: hidden;

    h2, h3 {
      line-height: 34px;
    }

    .btn-plain {
      float: right;
      margin: 0 0 0 15px;
      padding-left: 15px;
      padding-right: 15px;
      width: auto;
    }

  }
}
