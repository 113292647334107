.otp-sign-in-modal-overlay {
  background: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2000;

  @media screen and (min-width: $breakpoints-md) {
    position: fixed; background: rgba(0,0,0,.5);
    padding: 120px 60px 60px;
  }
}

.otp-sign-in-modal-column {
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  @media screen and (min-width: $breakpoints-md) {
    max-width: 600px;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.otp-sign-in-modal {
  position: relative;
  min-height: 100%;

  &__close {
    background: none;
    border: none;
    float: right;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 32px;
    width: 32px;
    min-width: auto;
    max-width: none;
    margin: 0;
    padding: 0;
    font-size: 32px;
    z-indeX: 2000;

    span {
      line-height: 20px;
      height: 32px;
      display: block;
    }
  }

  .fs-otp-sign-in__submit-otp {
    height: auto;
  }
}

.otp-sign-in-modal-alert {
  @media screen and (min-width: $breakpoints-md) {
    margin: 0 0 20px;
    padding: 0;
    position: absolute;
    bottom: 100%;
    left: 50px;
    right: 50px;
  }
}

.otp-sign-in-modal-alert__inner {
  border-top: solid 1px #ccc;
  margin: auto 0 0;
  padding: 20px 40px;
  text-align: center;

  @media screen and (min-width: $breakpoints-md) {
    background: rgba(255,255,255,.8);
  }
}

.booking-flow-sign-in-modal {
  .modal-content-body {
    overflow: auto;

    .booking-flow-sign-in-view {
        margin: 0 auto;
        height: 100%;

        .fs-otp-sign-in__request-otp {
          height: 100%;
          max-width: 100%;
          background: #FFF;
        }

        .fs-otp-sign-in__submit-otp {
          height: 100%;
          max-width: 100%;
          background: #FFF;
        }
    }
  }

  @media screen and (max-width: 767px) {
    .booking-flow-sign-in-view {
        .otp-sign-in-modal-alert {
        background: #FFF;
        }
      }
  }

  @media screen and (min-width: 701px) and (max-width: 767px) {
    .booking-flow-sign-in-view {
        .fs-otp-sign-in__request-otp {
        padding: 80px 20px 20px;
          overflow: auto;
        }

        .fs-otp-sign-in__submit-otp {
        padding: 80px 20px 20px;
          overflow: auto;
        }

      .otp-sign-in-modal-alert {
        background: #FFF;
        margin: auto 0 0;
        position: static;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .modal-dialog {
      margin: 120px auto 20px;
    }

    .modal-title {
      display: none;
    }

    .booking-flow-sign-in-view {
        .fs-otp-sign-in__request-otp {
        padding: 40px;
          overflow: visible;
        }

        .fs-otp-sign-in__submit-otp {
        padding: 40px;
          overflow: visible;
        }

      .otp-sign-in-modal-alert {
        background: rgba(255,255,255,.8);
          margin: 0 50px 20px;
          position: absolute;
          bottom: 100%;
        }
      }
  }
}
