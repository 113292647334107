.property-missing-language {
  font-family: $primary-font;
  font-weight: $primary-font-weight;
  color: $primary-text-color;
  font-size: $primary-font-size;
  line-height: 1.4;
  padding: 30px $page-padding $page-padding;
  text-align: center;

  .btn-primary {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 280px;
  }
}

.es .property-missing-language {
  font-family: $primary-font;
  font-weight: $primary-font-weight;
  color: $primary-text-color;
  font-size: $primary-font-size;
  line-height: 1.4;
  padding: 30px $page-padding $page-padding;
  text-align: center;

  .btn-primary {
    margin-top: 15px;
    margin-bottom: 15px;
    max-width: 280px;
  }
}
