//
// Profile Forms
//

$profile-form-color: $black;
$profile-form-padding: 10px;

$profile-form-fieldset-padding: 0 0 ($profile-form-padding * 1.5);

$profile-form-legend-padding: 0;
$profile-form-legend-size: 15px;
$profile-form-legend-line-height: 20px;

$profile-form-text-size: 14px;
$profile-form-text-color: $new-dark-grey;

.profile-form {
  display: block;
  color: $profile-form-text-color;
}

%profile-form__fieldset {
  margin: 0 auto;
  padding: $profile-form-fieldset-padding;

  // stupid override for styling that shouldn't exist
  & + fieldset%profile-form__fieldset {
    margin-top: 0;
  }
}

.profile-form__fieldset {
  @extend %profile-form__fieldset;
}

%profile-form__legend {
  margin: 0 auto;
  padding: $profile-form-legend-padding;

  color: $profile-form-color;
  font-size: $profile-form-legend-size;
  line-height: $profile-form-legend-line-height;
}

.profile-form__legend {
  @extend %profile-form__legend;
}

.profile-form__text {
  font-size: $profile-form-text-size;
  color: $profile-form-text-color;
}

.profile-form__arrivalTime {
  display: flex;
  overflow: hidden;
  margin-bottom: 10px;

  .fs-select-mask {
    position: relative;
    border: 1px solid #bebebe;
    margin-right: 10px;
    flex: 1;
  }

  .fs-select-mask.is-disabled {
    background: #f2f2f2;
    border: 1px solid #dadada;
  }

  .fs-select-mask:last-of-type {
    margin-right: 0;
  }

  .fs-select-mask.in-focus {
    border: 1px solid #bebebe;
    box-shadow: inset 0 0 2px #000;
  }
}
