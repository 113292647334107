//
// Sign in
//

$sign-in-padding: 38px;

$sign-in-prompt-color: $new-dark-grey;
$sign-in-prompt-padding: $form-padding;
$sign-in-prompt-desk-padding: ($form-padding * 3);

.sign-in-heading {
  @extend %hero__title;
}

.sign-in-forgot {
  display: block;
  width: 100%;
  padding-top: $sign-in-prompt-padding;

  color: $accent-color;
  text-align: center;
  text-decoration: none;
}

.sign-in-modal-body {
  background: $panel-background;
  padding: $sign-in-prompt-padding;
}

.sign-in-modal-success {
  margin-bottom: ($sign-in-prompt-padding * 5);
}

.sign-in-prompt-text {
  margin: 0;
  padding-bottom: $sign-in-prompt-padding;

  color: $sign-in-prompt-color;
  text-align: center;
}

@media only screen and (max-width : $screen-sm-max) {
  .sign-in-forgot {
    padding-bottom: $sign-in-prompt-padding;
  }

  .sign-in-modal-body--prompt {
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    top: 25%;
  }
}


@media only screen and (min-width : $screen-sm) {
  .sign-in-heading {
    font-size: 42px;
  }

  .sign-in-body {
    max-width: 790px;
    margin-top: -165px;
    padding: 0;

    overflow: hidden;
  }

  .option-actions {
    .sign-in-body__option {
      padding-top: 0;
      padding-bottom: $sign-in-padding;
    }
  }

  .sign-in-body__option {
    float: left;
    width: 50%;
    padding: $sign-in-padding $sign-in-padding 0;
  }

  .sign-in-body__option--alt {
    background-color: $white;
  }

  .sign-in-modal-success {
    border-top: $panel-border;
  }

  .sign-in-modal-body--prompt {
    padding-right: $sign-in-prompt-desk-padding;
    padding-left: $sign-in-prompt-desk-padding;
  }

  .sign-in-prompt-text {
    padding-bottom: $sign-in-prompt-desk-padding;
    text-align: left;
    font-size: 18px;
  }
}

