body .accommodation-type-title {
  color: #000;
  margin-bottom: 15px;
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.33;
  letter-spacing: 3px;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 0 0;

  @media only screen and (min-width: $screen-sm) {
    text-align: left;
  }
}
