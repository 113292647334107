html,
body {
  background: #000;
  color: #000;
  font-family: $garamond;
  font-size: 18px;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
}

strong {
  font-family: inherit;
}

h1 {
  font-family: $hn;
  font-weight: 250;
  font-size: rem(32px);
  line-height: 1.4;
  text-transform: uppercase;
  margin-top: 0;
  letter-spacing: 3.76px;
}

h1, h2, h3, h4 {
  color: inherit;
}

a {
  color: #000;
}

.fs-modal-container {
  background: rgba(0,0,0,0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;
  height: 100%;

  @media screen and (min-width: $breakpoints-md) {
    padding: 40px;
  }
}

.suppress-scroll .fs-modal-container {
  bottom: 0;
}

button {
  width: auto;
}

.fieldset {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
}
