/* Vars =========
============================*/

$primary-font: $base-font-family-sans;
$bold-font: $base-font-family-sans-weighted;
$header-font: $base-font-family-sans;

$primary-color: #212121;
$secondary-color: #b1a99f;
$accent-color: #007b8a;
$disabled-accent-color: #6DADB6;

$primary-link-color: $accent-color;
$primary-link-hover-color: mix(white, $primary-link-color, 25%);

$error-color: #c51f1f; //background & text
$new-error-color: #c51f1f;

// Greys - http://i.imgur.com/17VOEBq.png
$white: #fff;
$black: #000;
$grey: #eee;
$lighter-grey: #f5f5f5;
$light-grey: #dcdcdc;
$medium-grey: #878787;
$dark-grey: #686868;
$darker-grey: #333;
$off-black: #1d1d1b;

// namespaced as 'new' with the intention
// to transition current colors to these
$new-lighter-grey: #f2f2f2;
$new-light-grey: #dadada;
$new-medium-grey: #a7a7a7;
$new-dark-grey: #686868;
$new-darker-grey: #686868;
// $new-darker-grey: #5b5a56;

$primary-font-weight: 200;
$primary-font-size: 16px;

$secondary-font-weight: 200;
$secondary-font-size: 14px;

$primary-text-color: $off-black;

$focus-color: #ffff83;

$plain-btn-background: $off-black;

$primary-btn-top-color: #c51f1f;
$primary-btn-bottom-color: #DC2322;
$primary-btn-text-color: $white;

$widget-shadow-color: rgba(0,0,0,.25);

$secondary-btn-top-color: #615F5C;
$secondary-btn-bottom-color: #5A5855;

// Header
$header-spacing: 40px;
$header-height-mobile: 50px;
$header-bg-color: $dark-grey;
$header-bg-breadcrumb-color: #595959;
$header-breadcrumb-color: lighten($dark-grey, 28%);
$header-height-desktop: 56px;


// Calendar
$calendar-highlight-color: $black;
$calendar-header-color: $off-black;
$calendar-header-height: 60px;
$calendar-header-button-size: 50px;
$calendar-header-button-size-desktop: 30px;
$calendar-cell-size: 35px;
$calendar-cell-size-desktop: 30px;
$calendar-header-height-desktop: 28px;
$calendar-shadow: 0 0 15px $widget-shadow-color;
$spinner-shadow: $calendar-shadow;

// Inputs
$input-height: 40px;
$input-height-with-border: $input-height + 2px;
$input-border-radius: 0px;
$input-border-color: #bebebe;

// Panels
$panel-background: $new-lighter-grey;
$panel-color: $dark-grey;
$panel-border-color: $new-light-grey;
$panel-border: 1px solid $panel-border-color;

$panel-nav-background: $white;

// Layout

// - Padding
$page-padding: 15px;
$secondary-padding: 10px;
$form-padding: ($secondary-padding * 2);


$mobile-footer-bg-color: #666;
$desktop-footer-bg-color: $black;

// for reverse media queries
$screen-sm-max: ($screen-sm - 1);
$screen-xs-max: ($screen-xs - 1);

.analytics-trigger {
  visibility: hidden;
  display: none;
  overflow: hidden;
  height: 0px;
  width: 0px;
}
