$fs-date-picker-horizontal-padding: 15px;

.fs-single-date-picker-container,
.fs-profile-trip-date-picker {
  &-fieldset {
    position: relative;
    padding: 0 !important;
    border: none !important;
  }

  .TextInput {
    padding-bottom: 0;
    margin-bottom: 20px;
    margin-left: ($fs-date-picker-horizontal-padding * 0.5);
    margin-right: ($fs-date-picker-horizontal-padding * 0.5);

    @media screen and (min-width: $breakpoints-md) {
      margin-left: $fs-date-picker-horizontal-padding;
      margin-right: $fs-date-picker-horizontal-padding;
      flex-basis: 50%;
    }
  }

  .fs-datepicker-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $breakpoints-md) {
      flex-direction: row;
    }
  }

  .fs-select-date {
    display: none;
  }

  .fs-date-picker-check-in-input,
  .fs-date-picker-check-out-input {
    display: block;
    @extend .formElement-field;
  }

  .fs-calendar-wrapper {
    position: absolute;
    top: calc(100% - 20px);
    left: 0;
    z-index: 1;

    @media screen and (min-width: $breakpoints-md) {
      left: $fs-date-picker-horizontal-padding;
    }

    .fs-calendar {
      background: $gray4;
      font-family: sans-serif;
      padding: 0 18.75px;
      margin-bottom: 20px;
      box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.3);

      @media screen and (min-width: $breakpoints-md) {
        padding: 0 15px 15px 15px;
      }

      table {
        border: none;

        thead {
          border-bottom: none;
        }

        td {
          width: 45px;
          height: 45px;
        }

        span,
        a {
          display: block;
          width: 35px;
          height: 35px;
          line-height: 35px;
          font-size: 0.8rem;
          margin: auto;
        }

        span {
          font-weight: bold;
          border-radius: 50%;

          &:hover {
            background: $black;
            color: white;
            border-radius: 50%;
          }

          &:focus {
            background-color: white;
            color: black;
          }
        }
      }
    }

    .ui-corner-all {
      color: $black;
      line-height: 88px;
    }
    button.ui-corner-all {
      border: none;

      &:focus {
        border: 1px dotted black;
      }
    }

    .ui-datepicker-today {
      border-radius: 50%;
    }

    .ui-datepicker-prev {
      margin-left: 15px;
    }

    .ui-datepicker-next {
      margin-right: 15px;
    }

    .ui-datepicker-header {
      background-color: transparent;
      color: $black;
      padding: 30px;
      height: auto;
    }

    .ui-datepicker-multi-2 {
      width: auto !important;
      display: flex;
      flex-direction: row;
    }

    .ui-datepicker-group {
      border: none;
      margin: 0;

      &-first {
        margin-right: 20px;
      }
    }

    .date-range-selected {
      &-first {
        border-radius: 50% 0 0 50%;
      }

      &-last {
        border-radius: 0 50% 50% 0;
      }
    }

    .is-restricted {
      border: none;

      span {
        position: relative;
        margin-right: 5px;
        border: 1px solid $black;
        border-radius: 50%;
        height: 15px;
        width: 15px;
      }
    }

    .is-unavailable {
      background: transparent;
      border: none;

      span {
        position: relative;
        margin-right: 5px;
        background: $gray3;
        border-radius: 50%;
        height: 16px;
        width: 16px;
      }
    }

    .ui-datepicker-other-month {
      opacity: 0;
      pointer-events: none;
    }

    .datepicker-footer {
      margin-top: 10px;
      border: none;

      .nights-selected {
        display: none;
      }

      .legend {
        border: none;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;

        .updated-at {
          display: none;
        }

        li {
          display: inline-flex;
          flex-direction: row-reverse;
          text-transform: uppercase;
          letter-spacing: 3px;
        }

        span {
          &.is-restricted {
            position: relative;
            margin-right: 5px;
            border: 1px solid $black;
            border-radius: 50%;
            height: 15px;
            width: 15px;
          }

          &.is-unavailable {
            position: relative;
            margin-right: 5px;
            background: $gray3;
            border-radius: 50%;
            height: 16px;
            width: 16px;
          }
        }
      }
    }
  }
}

.fs-single-date-picker-container {
  .fs-calendar-wrapper {
    left: 0;
    top: 100%;
  }
}
