.fs-toggle-block {
  $transition-time: 0.2s;

  margin: 0 0 40px;

  &__row {
    position: relative;

    .fs-toggle-button {
      display: block;
      margin-left: auto;
    }
  }

  &__label {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    > div:first-child {
      align-self: center;
      flex-grow: 1;
      flex-shrink: 1;
    }
  }

  &__container {
    &__inner {
      background: $white;
      border-radius: 4px;
      color: $black;
      margin: 45px 0 0;
      padding: 50px 40px 40px;
      position: relative;
      // opacity: 0;
      transition: opacity $transition-time ease;

      &--dark {
        background: $black;
        color: $white;
      }

      @media screen and (min-width: $breakpoints-md) {
        margin-top: 30px;
        padding-top: 40px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        padding: 36px 60px;
      }
    }
  }

  &__close-button {
    background: transparent;
    background-image: url("/images/profiles_2018/black-cross.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: none;
    color: #000;
    width: 14px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    top: 22px;
    right: 22px;

    &--light {
      background-image: url("/images/profiles_2018/white-cross.svg");
      color: #000;
    }

    span {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }

    @media screen and (min-width: $breakpoints-md) {
      top: 32px;
      right: 32px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      right: 42px;
    }
  }

  &--is-open {
    .fs-toggle-block__container {
      overflow: visible;

      &__inner {
        opacity: 1;
      }
    }
  }

  p {
    margin-top: 0;

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 0;
    }
  }

  &__label-html {
    &__form-label {
      color: $gray2;
      font-family: $hn;
      font-weight: 700;
      font-size: rem(10px, 16px);
      text-transform: uppercase;
      letter-spacing: 3px;
      line-height: 1.3;
      margin-bottom: 17px;

      .primary-label {
        color: $white;
        position: relative;
      }

      span + span.primary-label {
        margin-left: 7px;
        padding-left: 10px;

        &:after {
          content: "";
          display: block;
          background: $white;
          border-radius: 100%;
          height: 2px;
          width: 2px;
          position: absolute;
          top: 50%;
          left: -1px;
          margin-top: -1px;
        }
      }
    }

    &__form-value {
      color: $white;
      border-bottom: solid 1px $white;
      font-family: $hn;
      font-size: rem(22px, 16px);
      font-weight: 250;
      letter-spacing: 1px;
      line-height: 1.3;
      padding: 0 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;

      @media screen and (min-width: $breakpoints-md) {
        padding-right: 84px;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    > * {
      margin-left: 30px;
    }
  }

  &--profile-value-edit {
    .fs-toggle-block__row {
      .fs-toggle-button {
        margin-top: 16px;

        @media screen and (min-width: $breakpoints-md) {
          position: absolute;
          right: 0;
          bottom: 10px;
          margin-top: auto;
        }
      }
    }

    .fs-toggle-block__label {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      @media screen and (min-width: $breakpoints-md) {
        max-width: 530px;
      }

      > div {
        align-self: auto;
      }
    }
  }

  &--profile-address-edit {
    .fs-toggle-block__label-html__form-value {
      border-bottom-width: 0;
      padding-bottom: 0;
      padding-right: 30px;
      max-width: 500px;
    }

    .fs-toggle-block__row {
      .fs-toggle-button {
        margin-top: 40px;
        border: solid 1px #fff;

        @media screen and (min-width: $breakpoints-md) {
          margin-top: 30px;
        }
      }
    }

    .fs-toggle-block__label {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      @media screen and (min-width: $breakpoints-md) {
        flex-direction: row;
      }

      > div {
        align-self: auto;
      }
    }
  }
}

.rtl {
  .fs-toggle-block__label-html__form-label span.primary-label {
    margin-left: 0;
    padding-left: 0;
    margin-right: 10px;
    padding-right: 10px;
  }

  .fs-toggle-block__label-html__form-label span + span.primary-label {
    &:after {
      left: unset;
      right: -1px;
    }
  }

  .fs-toggle-block__label-html__form-value {
    padding-left: 84px;
    padding-right: 0;
  }

  .fs-toggle-block--profile-value-edit {
    .fs-toggle-block__row {
      .fs-toggle-button {
        @media screen and (min-width: $breakpoints-md) {
          right: auto;
          left: 0;
        }
      }
    }
  }
}
