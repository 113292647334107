.fs-profile-country-and-language {
  &__title {
    margin-bottom: 70px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 60px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 40px;
    }
  }

  .fsp-input .fsp-input__checkbox__checkbox {
    padding-left: 50px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
