.error-msg,
.error-msg-label {
  background-color: $error-color;
  color: $white;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: $secondary-font-weight;
  margin: 0 0 12px;
  padding: $page-padding;

  a {
    color: $white;
  }

  &.with-icon {
    padding-left: 60px;
    background-image: url(/images/error_icon.png);
    background-repeat: no-repeat;
    background-size: 44px 39px;
    background-position: 6px 6px;
  }
}

.error-msg-label {
  float: left;
}

.error {
  &.row {
    box-sizing: border-box;
    display: block;
  }

  .error-contact-flush {
    padding-left: $page-padding;
    padding-right: $page-padding;
    margin: 0;
    width: 100%;
  }

  h2 {
    background-color: $error-color;
    color: $white;
    font-family: $primary-font;
    font-size: $primary-font-size;
    font-weight: $secondary-font-weight;
    margin: 0;
    padding: $page-padding;
    text-align: left;
    text-transform: none;
    display: flex;
    flex-direction: row;

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      text-decoration: underline;
    }

    a:hover {
      opacity: 0.7;
    }

    span {
      display: block;
      flex-grow: 1;
      flex-shrink: 1;

      &.error-label {
        flex-grow: 0;
        flex-shrink: 0;
      }
    }
  }

  &.row h2 {
    margin-left: -$page-padding;
    margin-right: -$page-padding;
  }

  p {
    font-weight: $primary-font-weight;
    color: $dark-grey;
  }

  &.with-icon h2,
  .error-contact-flush.with-icon h2 {
    padding-left: 60px;
    background-image: url(/images/error_icon.png);
    background-repeat: no-repeat;
    background-size: 44px 39px;
    background-position: 6px 6px;
  }

  h2.error-label {
    float: left;
  }

  .error-contact-details {
    background-color: $white;
    min-height: 0;

    h2 {
      @include hide;
      background-color: $theme-white;
      color: $medium-grey;
    }

    > .row {
      max-height: 0;
      overflow: hidden;
      transition: all 0.3s ease-in-out;
    }
  }

  .error-contact-details-toggle {
    @extend .mobile-link-large;
    background-color: $grey;
    border-style: solid;
    border-color: $light-grey;
    border-width: 1px 0 0 0;
    display: inline-block;
    width: 100%;
    text-align: left;
    width: 100%;

    min-height: 20px;
    min-width: 130px;
    padding: 10px 20px;
    letter-spacing: 0.12em;

    i {
      float: right;
    }
    .icon-arrow-up {
      display: none;
    }
  }

  p + .error-contact-details-toggle {
    border-top: solid $light-grey 1px;
  }

  &.is-shown {
    .error-contact-details > .row {
      max-height: 300px;
    }
    .error-contact-details-toggle .icon-arrow-down {
      display: none;
    }
    .error-contact-details-toggle .icon-arrow-up {
      display: inline;
    }
  }
}

.rtl .error,
.rtl .error h2 {
  text-align: right;
}

@media only screen and (min-width: $screen-sm) {
  .error {
    margin: $page-padding auto;

    &.row {
      display: flex;
    }

    .error-contact-flush h2 {
      margin-left: -($page-padding + 1px);
    }

    .error-contact-details {
      background-color: $white;
      @include show-block;
      h2 {
        @include show-block;
        margin-right: -($page-padding + 1px);
      }
      > .row {
        max-height: none !important;
      }
    }

    .error-contact-details-toggle {
      @include hidden;
    }
  }
}

a.toll-free-phone-link {
  display: inline !important;
}
