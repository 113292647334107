@import "profile_2018/fonts";

html.fs-profile-2018,
html.fs-profile-2018 body {
  @import "profile_2018/variables";

  background: #000;
  color: #000;
  font-family: $garamond;
  font-size: 18px;
  line-height: 1.5;

  height: 100%;

  @import "~bootstrap-sass/assets/stylesheets/bootstrap/normalize";
  @import "~bootstrap-sass/assets/stylesheets/bootstrap/print";

  // HUGE things
  @import "profile_2018/mixins";
  @import "profile_2018/typography";
  @import "profile_2018/cta";
  @import "profile_2018/grid";
  @import "profile_2018/forms";
  @import "profile_2018/forms-gray";

  @import "profile_2018/undo-booking-flow-css-nonsense";
  @import "profile_2018/base";

  // Components
  @import "profile_2018/components/fs-otp-sign-in";
  @import "profile_2018/components/fs-social-signin-button";
  @import "profile_2018/components/fs-profiles-header";
  @import "profile_2018/components/fs-input";
  @import "profile_2018/components/fs-radio-inputs";
  @import "profile_2018/components/fs-typeahead";
  @import "profile_2018/components/fs-toggle-button";
  @import "profile_2018/components/fs-toggle-block";
  @import "profile_2018/components/fs-profile-emails";
  @import "profile_2018/components/fs-profile-email-address-form";
  @import "profile_2018/components/fs-profile-phone-numbers";
  @import "profile_2018/components/fs-profile-addresses";
  @import "profile_2018/components/fs-profile-country-and-language";
  @import "profile_2018/components/fs-profile-subscriptions";
  @import "profile_2018/components/fs-profile-linked-accounts";
  @import "profile_2018/components/fs-profile-payment-information";
  @import "profile_2018/components/fs-profile-preferences";
  @import "profile_2018/components/fs-trip";
  @import "profile_2018/components/fs-upcoming-trips";
  @import "profile_2018/components/your-stay-perfected";
  @import "profile_2018/components/fs-profile-trip-date-picker";
  @import "profile_2018/components/fs-profile-edit-arrival-time";
  @import "profile_2018/components/fs-profile-modal";
  @import "profile_2018/components/fs-profile-address-form";
  @import "profile_2018/components/web-chat-button";
  @import "profile_2018/components/chat-with-us";
  @import "profile_2018/components/transportation-amenities-layout";
  @import "profile_2018/components/arrival-transportation";
  @import "profile_2018/components/horizontal-scrollview";
  @import "profile_2018/components/build-your-itinerary";
  @import "profile_2018/components/transportation-amenities-toggle-block";
  @import "profile_2018/components/arrival-amenities";
  @import "profile_2018/components/quantity-input";
  @import "profile_2018/components/shopping-cart-summary";
  @import "profile_2018/components/error-message-block";
  @import "profile_2018/components/bottom-drawer";
  @import "profile_2018/components/exit-transportation-form-modal";
  @import "profile_2018/components/fs-membership";
  @import "profile_2018/components/ancillary-cards";
  @import "profile_2018/components/ancillary-booking-cards";

  // Layouts
  @import "profile_2018/layouts/layout--profile-auth";
  @import "profile_2018/layouts/layout--profile";
  @import "profile_2018/layouts/layout--profile-modal";
  @import "profile_2018/layouts/layout--profile-fullscreen";

  // Views
  @import "profile_2018/views/sign-in";
  @import "profile_2018/views/register";
  @import "profile_2018/views/register-details";
  @import "profile_2018/views/register-have-reservation";
  @import "profile_2018/views/register-reservation-details";
  @import "profile_2018/views/register-reservation-found";
  @import "profile_2018/views/register-send-code";
  @import "profile_2018/views/profile";
  @import "profile_2018/views/profile-edit";
  @import "profile_2018/views/profile-folio";
  @import "profile_2018/views/profile-upcoming-trip";
  @import "profile_2018/views/profile-trip";
  @import "profile_2018/views/upcoming-trip__modal";
  @import "profile_2018/views/itinerary";
  @import "profile_2018/views/amenities-request-modal";
  @import "profile_2018/views/unauthenticated-upcoming-trip-sign-in";
  @import "profile_2018/views/employee-portal";
  @import "profile_2018/views/employee-stay-history";
  @import "profile_2018/views/web-checkin";
  @import "profile_2018/views/find-reservations";
  @import "profile_2018/views/upgrade-your-room";
  @import "profile_2018/views/profile-stay-preferences-view";
  @import "profile_2018/views/profile-membership";
}
