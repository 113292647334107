.view-booking-flow__choose-rooms {
  main .main-inner {
    padding-top: 0;
  }

  button {
    border: none;
  }

  &.has-triptease {
    iframe#tt_price-check,
    .tt_widget {
      visibility: visible;
      opacity: 1;
    }
  }

  .filter-results-options__col {
    select {
      text-overflow: ellipsis;
      width: 105% !important;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .view-booking-flow__choose-rooms {
    .modal {
      z-index: 10000;
    }
  }
}

.search-results-title-block {
  padding-top: 38px;

  @media only screen and (min-width: $screen-sm) {
    padding-top: 50px;
  }

  .ty-h2 {
    color: #000;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 1.29;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin: 0 auto 30px;
  }
}

//Keep the room info width as high as possible.  The default bootstrap makes it too small between these widths
@media only screen and (min-width: 880px) and (max-width: 992px) {
  .view-booking-flow__choose-rooms {
    .container.room-list-container {
      width: 860px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 880px) {
  .view-booking-flow__choose-rooms {
    .container.room-list-container {
      width: 768px;
    }
  }
}

.booking-message-fee {
  font-weight: 700;
}

.button-as-link {
  border: none;
  background: transparent;
  text-decoration: underline;
  padding: 0;
  min-width: auto;
  width: auto;
}

.text-left {
  text-align: left;
}

// fixes the .container inside a .container
.view-booking-flow__choose-rooms {
  .fs-errors-container {
    .container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }
}
