///////////////
//
// Panel Body
//
//////////////

$panel-body-padding--v: 25px;
$panel-body-padding--desk-v: 0;
$panel-body-content-padding: 0;
$panel-body-content-padding--desk-h: 35px;
$panel-body-content-padding--desk-v: $panel-body-padding--v;

.panel-body {
  // do not set left/right padding!
  padding-bottom: $panel-body-padding--v;
  padding-top: $panel-body-padding--v;
  overflow: hidden;
}

.panel-body__heading,
.panel-body__heading--xs-only {
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: none;
  h2 {
    margin: 0; // tmp rendering issue
  }
}

.panel-body__heading--xs-only {
  display: block;
  padding-bottom: $panel-body-padding--v;
  text-align: center;
}

.panel-body__crumb {
  margin: 0;
}

.panel-body__title {
  margin: 0;
}

.panel-body__content {
  padding: $panel-body-content-padding;

  p:first-of-type {
    margin-top: 0;
  }
}

// button row should not expand beyond
// the max button height.
// - fixes a weird display issue in chrome
.panel-body__button-row {
  max-height: 50px;
}


// mobile only
@media screen and (max-width: $screen-sm-max) {
  .panel-body %panel-body__callout,
  .panel-body__callout {
    margin-top: (-$panel-body-padding--v);
  }

  .panel-body__content {
    p {
      padding-right: $page-padding;
    }
  }

  .panel-body__title {
    font-size: 18px;
  }
}

// desktop
@media screen and (min-width: $screen-sm) {
  .panel-body {
    padding-bottom: $panel-body-padding--desk-v;
    padding-top: $panel-body-padding--desk-v;
  }

  .panel-body__heading {
    padding-right: $panel-body-content-padding--desk-h;
    padding-left: $panel-body-content-padding--desk-h;
    padding-top: $panel-body-content-padding--desk-v;

    display: block;
  }

  .panel-body__heading--xs-only {
    display: none;
  }

  .panel-body__content {
    padding: $panel-body-content-padding--desk-v $panel-body-content-padding--desk-h;

    position: relative;
  }
}

