$min-height: 154px;
$column-paddings: 15px;
$right-column-width: 130px;
$description-text-line-height: 1.4em;
$item-title-height: 42px;
$item-title-height-small: 30px;
$room-item-title-font-size: 17px;

$big-right-column-width: 180px;
$image-width: 322px;
$big-image-width: 400px;
$small-image-width: 280px;
$step4-image-width: 274px;

$info-icon-width: 52px;

// This ensures the features don't overlap the 'View more details' link or 'Select Room' button
// Both these elements are absolute positioned, so can overlap with the features, depending on the content
$room-info-padding-bottom: 65px;

// Default room item mobile
.room-item {
  font-size: $secondary-font-size;
  min-height: $min-height;
  margin-bottom: $column-paddings;
  border-bottom: solid 1px $light-grey;
  position: relative;

  .hidden-xxs {
    display: none !important;
  }

  .visible-xxs {
    display: block !important;
  }

  &--is-best-rate {
    border: solid 2px #2d2d2d;
  }

  &__best-rate {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #000;
    color: #fff;
    height: 30px;
    padding: 0 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 1.62;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  .room-details-search-summary {
    display: none;
  }

  .room-item-preview {
    position: relative;
  }

  .room-item-title {
    color: $gray-mid-dark;
    font-family: $primary-font;
    font-size: $room-item-title-font-size;
    font-weight: 300;
    margin-bottom: 12px;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 2;
  }

  .room-item-info {
    position: relative;
    padding: 12px 15px $room-info-padding-bottom;
    display: block;
    margin: 0;

    &.has-book-direct {
      padding-bottom: 13px;
    }
  }

  .room-item-features,
  .room-item-actions {
    position: relative;
  }

  .room-item-features {
    overflow: hidden;
  }

  .room-item-actions {
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
    min-height: 48px;
    margin-left: 67px;
    float: none;
  }

  .room-item-description {
    position: relative;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .price {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.65);
    color: white;
    font-size: $base-font-size;
    padding: 8px 15px 12px;
    margin: 0;
    z-index: 10;
    font-weight: 600;
    text-align: right;

    .nightly-rate {
      font-size: 14px;
      font-weight: $primary-font-weight;
    }

    .from,
    .night {
      font-size: 12px;
      text-transform: lowercase;

      .de & {
        text-transform: none;
      }
    }

    .resort-fee {
      line-height: 1;
      font-size: 12px;
      max-width: 150px;
    }

    .fullprice {
      font-weight: 700;
      white-space: nowrap;
    }
  }

  .room-book-direct {
    .book-direct-info {
      float: right;
      margin: 0 0 0 10px;
      border: none;
      padding: 0;
      text-align: left;
      display: block;
    }

    .phone {
      font-size: 12px;
      display: inline;
    }

    a {
      text-decoration: none;
    }

    .book-direct-link {
      a {
        text-decoration: underline;
      }
    }
  }

  .show-more-info-link {
    display: block;
    position: absolute;
    left: $column-paddings;
    z-index: 36;
    min-width: auto;
    bottom: 21px;
    width: $info-icon-width;
    height: 40px;
    line-height: 40px;
    padding: 0;

    &:before {
      content: "i";
      font-size: 24px;
      font-weight: 600;
      font-family: Times New Roman;
      font-style: italic;
      text-transform: lowercase;
      position: relative;
      z-index: 40;
    }

    &.view {
      border: none;
      background: none;
      text-align: left;
      letter-spacing: 0em;
      text-decoration: underline;
    }
  }

  button.deselect {
    width: auto;
    padding: 14px;
  }

  .select-room {
    display: block;
    position: absolute;
    bottom: 21px;
    right: $column-paddings;
    width: 40%;
    min-width: 270px;
    text-align: right;

    .fs-btn-select-container {
      text-align: right;

      .label {
        float: right;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .select-room-mobile {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;

    button.deselect {
      width: 100%;
    }
  }

  .room-item-tab-container {
    display: none;
  }

  .visible-showmore {
    display: none;
  }

  &.show-packages {
    border: 0;
  }
}

// RTL
.rtl .room-item {
  &__best-rate {
    left: auto;
    right: 0;
  }

  .price {
    left: 0;
    right: auto;
    float: left;
    text-align: left;

    .resort-fee {
      text-align: left;
    }
  }

  .select-room {
    left: 15px;
    right: auto;
    text-align: left;

    .fs-btn-select-container {
      text-align: left;

      .label {
        float: left;
      }
    }
  }

  .room-item-actions {
    margin-left: 0;
    margin-right: 67px;
  }

  .room-book-direct {
    .book-direct-info {
      margin-left: 0;
      margin-right: 10px;
      text-align: right;
      float: left;
    }
  }

  .room-item__price-and-select-room {
    padding-left: 15px;
    padding-right: 0;
  }

  .show-more-info-link {
    left: auto;
    right: 15px;
  }
}

.room-item-expanded {
  height: auto;
  margin: 0;

  .room-item-title {
    position: static;
    height: auto;
    margin-bottom: 12px;
    text-overflow: clip;
    white-space: normal;
    overflow: visible;
    padding: 0;
    line-height: 1.4;
  }

  .room-item-tab-container {
    display: block;
  }

  .show-more-info-link {
    &.view {
      border: none;
      background: none;
      text-align: left;
      letter-spacing: 0em;
      text-decoration: underline;
    }
  }
}

.room-item-selected {
  .room-item-info,
  .room-item-image {
    display: none;
  }
  .room-item-features {
    margin-top: 12px;
  }
}

.room-item-expanded,
.room-item-selected {
  .visible-showmore {
    display: block;
  }
}

// MOBILE - LARGER
@media only screen and (min-width: $screen-xs) {
  .room-item {
    .room-item-info,
    .room-item-info.has-book-direct {
      padding-bottom: $room-info-padding-bottom;
    }

    .room-item-actions {
      float: right;
      margin-left: 10px;
      margin-top: 30px;
      margin-bottom: 12px;
      width: 120px;

      .book-direct-info {
        border: solid 1px $medium-grey;
        padding: 10px;
        text-align: center;
      }
    }

    .show-more-info-link {
      bottom: 4px;
      line-height: 34px;
      z-index: 31;
      width: auto;

      &:before {
        display: none;
      }
    }

    .hidden-xxs {
      display: block !important;
    }

    .visible-xxs {
      display: none !important;
    }
  }

  // RTL - MOBILE - LARGER
  .rtl .room-item {
    .room-item-actions {
      float: left;
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

// DESKTOP
@media only screen and (min-width: $screen-sm) {
  .room-item {
    border: 1px solid $light-grey;

    &--is-best-rate {
      border: solid 2px #2d2d2d;
    }

    .room-item-preview {
      background: $theme-gray4;
      height: auto;

      display: flex;
      flex-direction: row;

      .description-text:after {
        display: none;
      }
    }

    .room-item-info,
    .room-item-info.has-book-direct {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;

      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-direction: row;
    }

    .room-item__info-info {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 264px;
      padding: 0 15px;
    }

    .room-item__price-and-select-room {
      display: flex;
      flex-direction: column;
      width: 150px;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: flex-end;
      padding: 0 15px 0 0;
    }

    .room-item-title {
      font-size: 19px;
      font-weight: 200;
      background: none;
      line-height: 1.4;
      position: static;
    }

    .room-item-features {
      position: relative;
      padding: 0;
      margin: 0 0 $room-info-padding-bottom;
    }

    .select-room {
      z-index: 32;
      position: static;
      padding: 15px 0 10px;
      margin-top: auto;
    }

    .room-item-actions {
      border-left: solid 1px $light-grey;

      float: none;
      margin-left: 0;
      width: auto;
      margin-top: 0;
    }

    .room-item-description {
      padding: 0 15px;
    }

    .price {
      position: static;
      float: right;
      top: auto;
      right: auto;

      background-color: transparent;
      color: inherit;
      padding: 0;
      font-weight: normal;

      .nightly-rate {
        font-size: 16px;
      }

      .from,
      .night {
        font-size: 14px;
      }
    }

    .price .resort-fee {
      line-height: 1;
      font-size: 14px;
      max-width: 150px;
    }

    .room-book-direct {
      border-spacing: 14px 0px;
    }

    .room-feature-list {
      .col ul li .shrink {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &.fullwidth {
        right: 0;
      }
    }

    .rooms-remaining {
      border: 3px solid #007b8a;
      border-radius: 25px;
      padding: 5px 12px;
      margin-top: 30px;
      display: none;
    }
  }

  .rtl .room-item {
    .room-item-actions {
      border-left: none;
      border-right: solid 1px $light-grey;
    }
  }

  .room-item-expanded,
  .room-item-selected {
    .room-item-features {
      padding-top: 0;
    }

    .room-item-title {
      height: auto;
    }

    .room-feature-list {
      .col ul li .shrink {
        overflow: auto;
        white-space: normal;
      }
    }
  }

  .room-item-selected {
    .room-item-features {
      margin-bottom: 60px;
    }
  }

  .personalise-form.room-item {
    .room-item-preview {
      background-color: white;
    }

    .room-item-actions {
      p {
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    .room-item-description {
      overflow: hidden;
    }
  }

  .rtl .room-item {
    .select-room {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .room-details-modal {
    .room-item-features {
      display: none;
    }
  }
}

@media only screen and (min-width: 880px) {
  .room-item-info {
    .room-item__info-info {
      max-width: 356px;
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .room-item .room-item__info-info {
    max-width: 388px;
  }

  .personalise-form.room-item {
    .room-item-description {
      max-width: 262px;

      .room-item-title {
        padding: 0;
      }
    }

    .room-item-actions {
      height: auto;
      margin-top: 0;
      width: 160px;

      flex-grow: 0;
      flex-shrink: 0;
      padding: 0 15px;

      p {
        display: inline-block;
      }
    }
  }
}

// Default room item large desktop
@media only screen and (min-width: $screen-lg) {
  .room-item .room-item__info-info {
    max-width: 588px;
  }

  .personalise-form.room-item {
    .room-item-description {
      max-width: 410px;
    }
  }
}

// https://tasks.workbench.fourseasons.com/browse/SHC-758
// Bold NGMF Copy
.room-item .price .nightly-rate .night,
.room-item .price .nightly-rate .resort-fee,
.package .nightly-rate span.night,
.package .package-price .package-price-text .resort-fee {
  font-weight: bold !important;
}
