.fs-radio-inputs {
  margin: 0 auto 40px;
  text-align: center;
}

.fs-radio {
  cursor: pointer;
  font-family: $garamond;
  font-weight: 400;
  display: inline-block;
  margin: 0 10px;

  input {
    appearance: none;
    border: solid 1px #ccc;
    border-radius: 40px;
    height: 24px;
    width: 24px;
    margin: 0 10px 0 0;
    vertical-align: text-bottom;

    &:checked {
      background: #000;
      box-shadow: inset 0 0 0 2px #fff;
    }
  }

  span {
    vertical-align: top;
  }
}

.rtl {
  .fs-radio {
    input {
      margin-left: 10px;
      margin-right: 0;
    }
  }
}
