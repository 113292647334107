//
// Reservation Items
//

$reservation-item-color: $white;
$reservation-item-background: $black;
$reservation-item-min-height: 137px;
$reservation-item-max-height: 184px;
$reservation-item-margin: 20px;
$reservation-item-title-size: 20px;
$reservation-item-subtitle-size: 16px;
$reservation-item-button-spacing: 5px;
$reservation-item-spacing: 25px;

$reservation-item-padding-v: ($reservation-item-spacing - $reservation-item-button-spacing);
$reservation-item-padding: $reservation-item-padding-v $reservation-item-spacing;

$reservation-item-mobile-height: 140px;
$reservation-item-mobile-title-size: 16px;
$reservation-item-mobile-subtitle-size: 14px;


.reservation-items {
  @extend %detail-items;
}

.reservation-item {
  @extend %detail-item;
}

.reservation-item__title {
  @extend %detail-item__title;
  padding-right: 16px;
}

.reservation-item__subtitle {
  @extend %detail-item__subtitle;
}

.reservation-item__img {
  display: none;
}

.reservation-item__buttons {
  display: none;
}

.reservation-item__overlay {
  @extend %detail-item__overlay;
}

%reservation-item__img {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  transition: opacity 0.6s ease-in 0.1s;
  opacity: 0.5;

  &.is-loading {
    opacity: 0;
  }

  &.is-loaded {
    opacity: 0.5;
  }
}

.reservation-item__img {
  @extend %reservation-item__img;
  display: none;
}

.reservation-item--mob__img {
  @extend %reservation-item__img;
  display: block;
}

// mobile variant
.reservation-item--mob {
  display: block;
  position: relative;
  padding: $reservation-item-margin;
  margin: 0;
  border: none;
  height: $reservation-item-mobile-height;
  background: $reservation-item-background;
}

.reservation-item--mob__body {
    position: relative;
}

.reservation-item--mob__title {
  padding-top: $reservation-item-button-spacing;
  font-size: $reservation-item-mobile-title-size;
  color: $reservation-item-color;
  text-transform: uppercase;
  display: block;
}

.reservation-item--mob__subtitle {
  font-size: $reservation-item-mobile-subtitle-size;
  color: $reservation-item-color;
  display: block;
}

.reservation-item--mob__buttons {
  display: block;
  margin-top: ($reservation-item-button-spacing * 2);
}

// desktop only styles
@media screen and (min-width: $screen-sm) {
  .reservation-items {
    margin-top: $reservation-item-margin;
    border: none;
  }

  .reservation-item {
    display: table;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: $reservation-item-padding;
    min-height: $reservation-item-min-height;
    // tables won't obey this, it's just to make us feel better
    max-height: $reservation-item-max-height;
    background: $reservation-item-background;
    margin-bottom: $reservation-item-margin;
    border: none;
  }

  .reservation-item__overlay {
    display: none;
  }

  .reservation-item__title {
    font-size: $reservation-item-title-size;
    color: $reservation-item-color;
    text-transform: uppercase;
    overflow: hidden;
  }

  .reservation-item__subtitle {
    font-size: $reservation-item-subtitle-size;
    color: $reservation-item-color;
  }

  .reservation-item__img {
    display: block;
  }

  .reservation-item__body,
  .reservation-item__buttons {
    vertical-align: middle;
    display: table-cell;
    position: relative;
    width: 50%;
    z-index: 1;
    letter-spacing: 0.1em
  }

  .reservation-item--mob__body {
    position: relative;
  }

  .reservation-item__buttons {
    .btn {
      margin: $reservation-item-button-spacing auto;
    }
  }
}

.reservation-item-facebook-messenger {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 0px 0 10px 0;
  width: 100%;
  float: right;

  .rtl & {
    float: left;
  }
}
.facebook-messenger-text {
  max-width: 88%;
}
.fb-messengermessageus {
  float: right;
  margin-left: 10px;
  min-width: 10%;
  width: auto !important;

  .rtl & {
    float: left;
    margin-left: 0px;
    margin-right: 10px;
  }
}

.reservation-item--mob-facebook-messenger {
  padding: 10px;
  width: 100%;
  min-height: 50px;
  display: inline-block;
}
.mob-facebook-messenger-text {
  float: left;
  margin-left: 10px;
  max-width: 65%;

  .rtl & {
    float: right;
    margin-left: 0px;
    margin-right: 10px;
  }
}

.mob-facebook-messenger-button {
  float: right;
  margin-left: 10px;

  .rtl & {
    float: left;
    margin-left: 0px;
    margin-right: 10px;
  }
}

.upcoming-stay-facebook-messenger {
  float: right;
}

// desktop only styles
@media screen and (min-width: $screen-sm) {
  .reservation-item-facebook-messenger {
    margin-top: -20px;
  }
}

// mobile only styles
@media screen and (max-width: $screen-xs) {
  .mob-facebook-messenger-text {
    max-width: 40%;
  }
}
