.modal--language-support {
  top: 50px;
  display: flex;
  flex-direction: column;

  .modal-dialog {
    background: none;
    height: 100% !important;
    width: 100% !important;
    position: relative;

    .icon-close {
      display: none;
    }
  }

  .profile-modal-body {
    padding: 20px;
    max-width: 480px;
  }

  .btn-submit {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    width: auto;
    height: auto;
    text-decoration: underline;
    text-align: left;
    font-size: inherit;
    font-weight: inherit;
  }

  @media only screen and (min-width: $screen-xs) {
    background: rgba(50,50,50,.66);
    padding: 20px;
    top: 0;

    .modal-dialog {
      height: auto !important;
      width: auto !important;
      margin: auto;

      .icon-close {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }

    .profile-modal-body {
      padding-top: 50px;
    }
  }
}
