.campaign-banner .promoted-property {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0;
  background: rgba(0,0,0,0.25);
}

.campaign-banner .promoted-property-book {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 30px 10px 10px;
}

.campaign-banner .promoted-property-book-inner {
  padding: 20px 0 0;
  background: transparent;
}

@media only screen and (min-width: $screen-sm) {
  .campaign-banner .promoted-property {
    left: auto;
    right: 0;
    width: 50%;
    padding-top: 0;
  }
  .campaign-banner .promoted-property-book {
    padding: 0 10px 10px;
  }
}
