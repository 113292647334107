$verify-email-spacing: 10px;

.verify-email-container {
  display: block;
}

.verify-email-text {
  margin: 0;
}

.verify-email-button {
  margin-top: $verify-email-spacing;
}

.verify-email-complete {
  color: $accent-color;
  padding-top: ($verify-email-spacing * 2);
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

