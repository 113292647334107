$image-width: 321px;
$image-height: 136px;

.underline {
  padding-bottom: 5px;
  position: relative;

  &:after {
    content: ' ';
    display: inline-block;
    width: 28px;
    height: 1px;
    background-color: #000;
    position: absolute;
    bottom: 0px;
    left: 50%;
    margin-left: -14px;
  }

}

.my-stay__tile--wrapper {
  @include clearfix;

  .panel-body__title {
    margin-bottom: 20px;
  }
}

.my-stay__tile {
  margin-bottom: 15px;
  text-align: center;

  .btn-primary {
    font-size: 0.775em;
    margin: 20px auto 0;
  }

  p a {
    @include override-block-link;
  }
}

.my-stay__tile.no-image {
  .my-stay__tile--image {
    display: none;
  }
  .my-stay__tile--content-wrapper {
    width: 100%;
    max-width: none;
    padding-left: 0;
  }
}

.my-stay__tile--inner {
  background: $white;
  border: 1px solid $light-grey;
  position: relative;
}

.my-stay__tile--content-wrapper {
  overflow: hidden;
  padding: 10px 30px 0;

  p {
    padding: 0;
  }

  p:last-child {
    margin: 0;
  }
}

.my-stay__tile--content-wrapper.is-truncated {
  .my-stay__tile--desc {
    overflow: hidden;
    position: relative;
    height: 95px;

    &:after {
      background: #fff;
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);

      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 20px;
    }
  }
}

.read-more-link {
  display: block;
  margin: 5px 0 -5px;
}

.categories-index-mobile {
  padding-top: 0;

  .my-stay__tile--content-wrapper {
    height: auto;
    text-align: center;
  }
}

.my-stay__tile--content-wrapper {
  p, li {
    @include my-stay-serif-paragraph;
  }
}

.my-stay__tile--wrapper {
  position: relative;

  .my-stay__tile--heading {
    font-family: $my-stay-header-font;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 3px;
    text-align: center;
    margin-bottom: 15px;
    color: $black;
  }

  .my-stay__tile--content-wrapper p {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 80px;
  }
}

.my-stay__tile--image {
  font-size: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 20px;

  img {
    width: 100%;
  }
}

.my-stay__tile--cta {
  @include block-link;
  font-size: 12px;
  letter-spacing: 3px;
  text-align: center;
  margin-bottom: 40px;
  &.underline:after {
    padding-top: 0;
    border-bottom: 0;
    width: 28px;
  }
}

.my-stay__tile--cta-hide {
  display: none;
}

.my-stay__tile--variants {
  clear: both;
  margin: 0 5px;
  padding-left: 30px;
  padding-right: 30px;
}

.my-stay__tile--variant--wrapper {
  @include clearfix;
  border-top: 1px solid #dadada;
  margin-bottom: 10px;
  padding-top: 20px;
  padding-bottom: 10px;

  &:last-child {
    margin: 0;
  }
}

.my-stay__subcategory-activities {
  .underline {

    &:after {
      content: none;
    }
  }

  .my-stay__tile--inner {
    padding-bottom: 30px;
  }

  .my-stay__tile--content-wrapper {
    margin-bottom: 30px;
  }

  .my-stay__tile--heading {
    font-size: 24px;
    letter-spacing: 4px;
    margin-bottom: 21px;
    margin-top: 0;
  }
}

.my-stay__tile--variant-title {
  font-family: $my-stay-header-font;
  font-weight: normal;
  text-transform: uppercase;
  color: $black;
  letter-spacing: 2px;
  font-size: 12px;
}

.my-stay__tile--variant-cost {
  font-family: $my-stay-header-font;
  text-transform: uppercase;
  color: $black;
  letter-spacing: 2px;
  font-size: 12px;
  margin-top: 4.5px;
}

.my-stay__tile--currency,
.my-stay__tile--price {
  font-family: $primary-font;
}

.my-stay--header,
.activity-categories {
  .my-stay__tile--heading {
    margin-bottom: 0;
  }

  .my-stay__tile--heading-wrapper {
    position: relative;
    padding: 0 10px;
  }

  .my-stay__tile--heading {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 20px);
  }
}

.my-stay__tile--wrapper--grid.activity-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  .my-stay__tile {
    width: 100%;
  }
  .my-stay__tile--variants {
    margin: 0;
  }
  .my-stay__tile,
  .my-stay__tile--inner {
    height: 100%;
  }
  .my-stay__tile--inner {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .my-stay__tile--variants {
    display: table-footer-group;
  }
  .my-stay__tile--heading-wrapper {
    height: 43px;
  }
  .my-stay__tile--tile-wrapper {
    word-wrap: break-word;
  }
}

.my-stay--header {
  .my-stay__tile--heading-wrapper {
    height: 148px;
  }
  .my-stay__tile--desc {
    padding-top: 10px;
  }
}

.my-stay__tile--wrapper--grid {
  .my-stay__tile--header {
    .my-stay__tile--desc {
      padding-top: 31px;
    }
  }
}
@media screen and (min-width: $screen-xs) {
  .my-stay__tile--wrapper {
    padding: 0 6px;
    text-align: left;
  }

  .my-stay__tile--inner {
    border: 1px solid $light-grey;
  }

  .my-stay__tile--wrapper--grid {
    .my-stay__tile {
      width: 50%;
      float: left;
      padding: 10px 6px 12px;
    }
  }

  .my-stay__tile--wrapper--grid.activity-categories {
    .my-stay__tile {
      width: 100%;
    }
    .my-stay__tile--tile-wrapper {
      width: 49.5%;
      flex-basis: 49.5%;
      margin: 0;
      padding: 0;
      border: none;
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .my-stay__tile--wrapper--list {
    .my-stay__tile--content-wrapper {
      display: inline-block;
      vertical-align: top;
      max-width: 489px;
    }
  }

  .my-stay__subcategory-activities .my-stay__tile--inner {
    padding: 30px;
  }

  .my-stay__tile--variants {
    margin: 0 -5px;
    overflow: auto;
    font-family: $my-stay-header-font;
    font-size: 12px;
    padding: 0;

    .my-stay__tile--variant--wrapper {
      width: 100%;

      button {
        margin-bottom: 10px;
      }
    }
  }

  .my-stay__tile--wrapper--grid .my-stay__tile {
    padding: 0 6px 12px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: $screen-md) {

  .my-stay__tile--cta {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .my-stay__tile {
    text-align: left;

    .my-stay__tile--inner .btn-primary {
      float: right;
      display: block;
      margin: 0;
      width: auto;
    }
  }

  .my-stay__tile--variant-cost {
    float: left;
  }

  .my-stay__tile--wrapper--grid .my-stay__tile,
  .my-stay__tile--wrapper--grid.activity-categories .my-stay__tile--tile-wrapper {
    width: 32.5%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 32.5%;
  }
  .my-stay__tile--wrapper--list {
    .my-stay__tile--image {
      float: left;
      width: 39%;
    }
    .my-stay__tile--content-wrapper {
      width: 59%;
      padding: 0 0 0 30px;
    }
  }
  .fr .my-stay__tile--wrapper--list{
      .my-stay__tile--content-wrapper {
      height: auto;
    }
  }

}

@media only screen and (min-width: $screen-lg) {
  .my-stay__tile--wrapper--list {
    .my-stay__tile--image {
      max-width: $image-width;
      height: 176px;
      display: inline-block;
    }
  }
}
