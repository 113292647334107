.not-sure-where-to-travel {
  background: #f5f5f5;
  color: #000;
  margin: 30px auto;
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  max-width: 930px;

  @media screen and (min-width: $screen-sm) {
    background: transparent;
    border: solid 1px #d8d8d8;
  }

  &__content_actions_container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
    }
  }

  &__icon {
    display: none;
    border-right: solid 1px #d8d8d8;
    width: 120px;
    flex-basis: 120px;
    flex-grow: 0;
    flex-shrink: 0;

    @media screen and (min-width: $screen-sm) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  &__content {
    padding: 0 20px;
    flex-grow: 1;
    flex-shrink: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $screen-sm) {
      align-items: flex-start;
    }

    &__title {
      color: inherit;
      font-size: 10px !important;
      font-style: normal;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin: 0 0 12px;

      @media screen and (min-width: $screen-sm) {
        font-size: 12px !important;
        margin-bottom: 18px;
      }
    }

    &__description {
      color: inherit;
      font-family: "Monotype Garamond W05", Garamond, serif;
      font-size: 15px;
      font-style: italic;
      line-height: 1.67;
      letter-spacing: 0.5px;
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    padding: 16px 20px 0;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: $screen-sm) {
      align-items: flex-start;
      padding-top: 20px;
    }

    > * {
      margin: 0 0 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .CTA--secondary--dark,
    .CTA--secondary--light {
      height: 30px;

      &:after {
        background-image: url("/images/profiles_2018/right-arrow--white.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        content: "";
        display: inline-block;
        height: 11px;
        width: 13px;
        margin-left: 4px;
      }
    }

    .CTA--secondary--light:after {
      background-image: url("/images/profiles_2018/right-arrow.svg");
    }

    @media screen and (min-width: $screen-sm) {
      align-items: center;
      padding-top: 0;
    }
  }
}
