.view--register-have-reservation {
  h1 {
    margin-bottom: 30px;
  }

  p {
    margin-bottom: 60px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 80px;
    }
  }
}
