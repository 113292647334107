.view--profile-trip {
  background: #fff;
  color: #000;
  height: 100%;
  overflow: auto;

  display: flex;
  flex-direction: column;

  .btn-back {
    background-image: url('/images/profiles_2018/left-arrow-dark.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 42px;
    display: block;
    height: 17px;
    width: 50px;

    span {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }
  }


  &__local-gems {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;
    background: #000;
    order: 1;
    height: auto;
  }

  &__local-gems-title {
    font-size: 24px;
    color: #fff;
    text-align: left;
  }

  &__top-bar {
    order: 1;

    .col-sm-12 {
      height: 70px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      @media screen and (min-width: $breakpoints-md) {
        padding-top: 80px;
        padding-bottom: 80px;
      }
    }
  }
}

.rtl .view--profile-trip .btn-back {
  transform: rotate(180deg);
}

.fs-article {
  padding-top: 50px;

  @media screen and (min-width: $breakpoints-lg) {
    padding-bottom: 40px;
  }

  &__image {
    background-image: url("https://www.fourseasons.com/alt/img-opt/~70.1530.0,0000-300,0000-5760,0000-3240,0000/publish/content/dam/fourseasons/images/web/TRI/TRI_Scottish_Langoustine.jpg");
    height: 210px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: $breakpoints-md) {
      height: 430px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      height: 270px;
    }
  }

  &__copy {
    padding-top: 48px;

    @media screen and (min-width: $breakpoints-lg) {
      padding-top: 0px;
      padding-left: 114px;
    }
  }

  &__title {
    h3 {
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      @media screen and (min-width: $breakpoints-md) {
        font-size: 16px;
      }
    }
  }

  &__separator {
    border-bottom: 2px solid #fff;
    width: 40px;
    height: 2px;
    padding-top: 18px;
  }

  &__author {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 20px;
  }

  &__extract {
    padding-top: 30px;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    @media screen and (min-width: $breakpoints-md) {
      padding-right: 100px;
    }
    @media screen and (min-width: $breakpoints-lg) {
      padding-right: 0px;
    }
  }

  &__link {
    padding-top: 8px;

    @media screen and (min-width: $breakpoints-md) {
      padding-top: 40px;
    }
  }
}
