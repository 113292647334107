.fs-profile-addresses {
  .fsp-input__checkbox {
    text-transform: uppercase;
    font-family: $hn;
    font-weight: 250;
    font-size: rem(12px, 18px);
    padding-top: 2px;
    margin-bottom: 50px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 0
    }
  }

  .fs-toggle-button--edit {
    border-width: 0;
  }

  @media screen and (min-width: $breakpoints-md) {
    .fsp-input--checkbox {
      height: 38px;
      display: flex;
      align-items: center;
      padding-bottom: 0;
    }
  }

  @media screen and (min-width: $breakpoints-lg) {
    .col--primary-address {
      clear: left;
    }
  }
}
