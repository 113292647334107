.new-modal--upsells {
  .modal-dialog {
    .btn-upgrade-option {
      width: 100%;
    }

    @media only screen and (min-width: $screen-sm) {
      &__title {
        h1 {
          width: 95%;
        }
      }
    }
  }
}
