// Get a prop from layout-setup for a specific viewport
@use "sass:math";

@function get-viewport-prop($prop, $viewport: small) {
  @return map-get(map-get($_layout-setup, $viewport), $prop);
}

// Get gutter for a specific viewport
@function get-gutter($viewport: small) {
  @return get-viewport-prop(gutter, $viewport);
}

// Get columns width (+ inner gutters)
@function get-columns-fixed-unit($size: 1, $viewport: small) {
  $gutter: get-gutter($viewport);

  @if $viewport == "small" {
    @return percentage(math.div($size, $_total-columns));
  } @else {
    $container-width: get-viewport-prop(container, $viewport);

    @return (($container-width + $gutter) * $size * 0.083333333333) - $gutter;
  }
}

@function get-columns-vw($size: 1, $string: false) {
  $gutter: get-gutter(small);

  @if $string {
    @return "(((100vw - 15px) * #{$size * 0.083333333333}) - 15px)";
  }

  @return calc(((100vw - 15px) * #{$size * 0.083333333333}) - 15px);
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }

  @return $map;
}

$_total-columns: 12;
$_layout-setup: (
  small: (
    container: 100%,
    gutter: 15px,
    shortName: sm,
  ),
  medium: (
    container: $containerWidth-md,
    gutter: 30px,
    shortName: md,
  ),
  large: (
    container: $containerWidth-lg,
    gutter: 30px,
    shortName: lg,
  ),
  xlarge: (
    container: $containerWidth-xl,
    gutter: 30px,
    shortName: xl,
  ),
);

// gutter variables
$gutter_small: get-gutter(small);
$gutter_medium: get-gutter(medium);
$gutter_large: get-gutter(large);
$gutter_xlarge: get-gutter(xlarge);

// Bootstrap-esque Grid System

@mixin gridBuild($className) {
  @for $i from 0 through $_total-columns {
    .col-#{$className}-#{$i} {
      width: #{8.3333333333% * $i};
    }

    .offset-#{$className}-#{$i} {
      margin-left: #{8.3333333333% * $i};

      @include rtl {
        margin-left: 0;
        margin-right: #{8.3333333333% * $i};
      }
    }
  }
}

@mixin _gridBPContent($containerWidth, $className) {
  // We use max-width, so that the container adapts to its container
  // eg. ComponentContainer or components that host components
  .container {
    max-width: $containerWidth;
    width: 100%;
  }

  .hide-#{$className} {
    display: none;
  }

  .offset-#{$className}-start {
    margin-left: 0;

    @include rtl {
      margin-right: 0;
    }
  }

  @include gridBuild($className);
}

@mixin gridBP(
  $size,
  $className,
  $bpSize: null,
  $_layout-setup: $_layout-setup
) {
  $containerWidth: map-deep-get($_layout-setup, $size, "container");

  @if $bpSize {
    @media screen and (min-width: $bpSize) {
      @include _gridBPContent($containerWidth, $className);
    }
  } @else {
    @include _gridBPContent($containerWidth, $className);
  }
}

@mixin rowVCenter($size) {
  $className: map-deep-get($_layout-setup, $size, shortName);

  .row-#{$className}-vcenter {
    @include viewport($size) {
      display: flex;
      align-items: center;
    }
  }

  .row-#{$className}-vreset {
    @include viewport($size) {
      display: block;

      [class*="col-"] {
        float: left;
        display: block;
      }
    }
  }
}

// Helper mixin to create column widths without a class
// @param $column   Number of columns to span
// @param $size     Viewport to target specifically
//                  (Used when padding inheritance will cascade)
@mixin column($columns: 12, $size: null) {
  float: left;
  width: #{8.3333333333% * $columns};

  @if $size == null {
    padding-left: $gutter_small * 0.5;
    padding-right: $gutter_small * 0.5;

    @include viewport(medium) {
      padding-left: $gutter_medium * 0.5;
      padding-right: $gutter_medium * 0.5;
    }
  } @else {
    $gutterWidth: map-deep-get($_layout-setup, $size, gutter);
    padding-left: $gutterWidth * 0.5;
    padding-right: $gutterWidth * 0.5;
  }
}

@mixin column-reset {
  float: none;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
}

@mixin push($columns) {
  margin-left: #{8.3333333333% * $columns};
}

// Mixin to handle rows to be cleared when certain columns content are too tall.
@mixin row-first-child($col-type, $clear-type) {
  .col-#{$col-type}- {
    &1:nth-child(12n + 1),
    &2:nth-child(6n + 1),
    &3:nth-child(4n + 1),
    &4:nth-child(3n + 1),
    &6:nth-child(odd) {
      clear: $clear-type;
    }
  }
}

[class*="col-"] {
  width: 100%;
  float: left;
  min-height: 1px;
  vertical-align: middle;
  padding-left: $gutter_small * 0.5;
  padding-right: $gutter_small * 0.5;

  @include rtl {
    float: right;
  }

  @include viewport(medium) {
    padding-left: $gutter_medium * 0.5;
    padding-right: $gutter_medium * 0.5;
  }
}

.clearfix {
  @include clearfix();
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter_small;
  padding-right: $gutter_small;

  // Not sure if we want this or not
  // @include viewport(medium) {
  //   padding: 0;
  // }
}

.row {
  @include clearfix();
  margin-left: math.div($gutter_small, -2);
  margin-right: math.div($gutter_small, -2);

  @include viewport(medium) {
    margin-left: math.div($gutter_medium, -2);
    margin-right: math.div($gutter_medium, -2);
  }
}

// Build Bootstrap Classes for each breakpoint size
@include gridBP(small, sm);
@include gridBP(medium, md, $breakpoints-md);
@include gridBP(large, lg, $breakpoints-lg);
@include gridBP(xlarge, xl, $breakpoints-xl);

@each $viewportName in map-keys($_layout-setup) {
  @include rowVCenter($viewportName);
}

.auto-clear-sm {
  @include viewport(small) {
    @include row-first-child(sm, both);
  }
}

.auto-clear-md {
  @include viewport(medium) {
    @include row-first-child(md, both);
  }
}

.auto-clear-lg {
  @include viewport(large) {
    @include row-first-child(lg, both);
  }
}

.auto-clear-xl {
  @include viewport(xlarge) {
    @include row-first-child(xl, both);
  }
}
