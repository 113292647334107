.cwu-container {
  -webkit-box-shadow: 0 1px 30px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0 1px 30px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 30px 60px;
  margin-bottom: 30px;

  &__actions {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: stretch;
    justify-content: center;

    @media only screen and (min-width: $breakpoints-md) {
      flex-direction: row;
      gap: 30px;
    }
  }

  .cwu-text {
    font-family: "Neue Helvetica W04", Neue Helvetica, Helvetica, sans-serif;
    font-weight: 250;
    font-size: 1.15rem;
    color: #000;
    letter-spacing: 0.69px;
    line-height: 1.8;
    margin: 0;
    text-align: center;
    display: inline-block;
  }

  button {
    align-items: center;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: black;
    color: white;
    border: 1px solid #000;

    &.CTA--secondary--dark--on-dark {
      &:hover {
        border: 1px solid #000;
      }
    }

    &:not([disabled]):focus,
    &:not([disabled]):hover {
      background-color: black;
      color: white;
    }
  }

  .web-chat-button {
    position: static;
    border: none;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    z-index: 1000;

    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: $breakpoints-md) {
  .cwu-container {
    padding: 30px;
  }
}
