// 'Flyout'/Dropdown nav, meant for the header
// This assumrs it will be in a 'position: relative' container.

a.profile-icon {
    position: absolute;
    background-image: url(/images/global_nav_icon_acct_off.png);
    background-repeat: no-repeat;
    height: 20px;
    width: 20px;
    right: 5px;
    top: 10px;
}

.rtl a.profile-icon {
	right: auto;
  left: 5px;
}

@media only screen and (min-width: $screen-sm) {
	a.profile-icon {
	    background-image: url(/images/global_nav_icon_acct_on.png);
	}
}


$nav-flyout-desktop-bg: #ebebeb;

button.nav-flyout-trigger--mob,
button.nav-flyout-trigger--desk {
    min-width: 0px !important;
    min-height: 0px !important;
    padding: 0px !important;
    letter-spacing: 0px !important;
    border: none !important;
    background-color: transparent !important;
}

button.nav-flyout-trigger--mob:focus {
	outline-offset: -4px;
 	outline: 1px dotted #FFF;
  outline: 2px auto -webkit-focus-ring-color;
}



// ul
.nav-flyout {
  border-bottom: 1px solid transparent;
  background-color: $header-bg-color;
  left: 0;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  max-height: 0;
  right: 0;
  transition: max-height .2s ease-in;
  top: $header-height-mobile;
  z-index: $z_headerDropDown;
  display: none;

  &.is-open,
  .nav-flyout-toggle:checked ~ & {
    border-bottom-color: $darker-grey;
    max-height: 166px;
    display: block;
  }
}

[class*='my-stay'] .nav-flyout.is-open {
  max-height: none;
}

// li
.nav-flyout__item {
  border-top: 1px solid $darker-grey;
  list-style-type: none;

  &:hover {
    background-color: $darker-grey;
  }
}

.nav-flyout__item--cta {
  background-color: $darker-grey;
}

.nav-flyout__link {
  display: block;
  width: 100%;
  padding: 7px 12px;
  text-decoration: none;

  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: $white;
  }

  .nav__icon {
    position: relative;
    margin-right: 5px;
    top: 1px;
  }

  .rtl & .nav__icon {
    margin-left: 5px;
    margin-right: 0;
  }
}

.nav-flyout-toggle {
  display: none;
}

// label/span
.nav-flyout-trigger {
  float: right;
  font-size: 36px;
  height: $header-height-mobile;
  margin: 3px 0 0;
  text-align: center;
  width: $header-height-mobile;
  cursor: pointer;

  .rtl & {
    float: left;
  }
}

.nav-flyout-trigger--mob {
  line-height: $header-height-mobile - 2px;
  display: block;

  // display overrides
  .is-mobile-app &,
  .suppress-scroll &,
  .suppress-scroll-mobile & {
    display: none;
  }
}

.nav-flyout-trigger--desk {
  display: none;
}

@media only screen and (min-width: $screen-sm) {
  .nav-flyout {
    border: none;
    background: $nav-flyout-desktop-bg;
    box-shadow: 0px 3px 5px 0 rgba(0,0,0,0.3);

    width: 165px;

    left: auto;
    top: $header-spacing - 1;
    max-height: 0;

    .rtl & {
      left: 0;
      right: auto;
    }
  }

  .nav-flyout__item {
    border: none;

    &:hover {
      background: none;
    }
  }

  .nav-flyout__item--cta {
    position: relative;

    background-color: $white;

    &:hover {
      background: $white;
    }

    &:after {
      content: "";

      display: block;
      margin: auto;
      height: 0;
      width: 0;

      position: absolute;
      bottom: 0;
      right: 7px;
      top: 0;

      border: 4px solid transparent;
      border-right-width: 0;
      border-left-width: 6px;
      border-left-color: $dark-grey;
    }

    .rtl &:after {
      left: 7px;
      right: auto;

      border-left-width: 0;
      border-left-color: transparent;

      border-right-width: 6px;
      border-right-color: $dark-grey;
    }
  }

  .nav-flyout__link {
    display: inline-block;
    font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;
	padding: 12px 12px;
	letter-spacing: 0.10em;

    &,
    &:hover,
    &:focus,
    &:active,
    &:visited {
      color: $black;
    }

    &:hover {
      text-decoration: underline;
    }

    .nav__icon {
      display: none;
    }
  }
  .nav-flyout-trigger--mob {
    display: none;
  }

  .nav-flyout-trigger--desk {
    display: block;
    position: relative;

    line-height: $header-spacing;
    height: $header-spacing;
    width: auto;
    max-height: 160px;
    margin-top: -40px;
    padding: 0 7px;

    font-size: 46px;
    color: $darker-grey;

    .nav-flyout-toggle:checked ~ & {
      background-color: $nav-flyout-desktop-bg;
    }

    &:before {
      position: relative;
      right: 5px;
    }

    &:after {
      content: "";
      display: inline-block;
      position: absolute;
      right: 0;
      top: 20px;

      border: 4px solid transparent;
      border-top-color: $dark-grey;
      border-top-width: 6px;
      border-bottom-width: 0;
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .nav-flyout__link {
    font-size: 11px;
  }
}
