@use "sass:math";

$image-width: 322px;
$image-height: 184px;
$big-image-width: 400px;
$step4-image-width: 274px;

$thumbnails-height: 44px;
$big-thumbnails-height: 60px;

$nav-arrow-size: 70px;
$nav-arrow-size-small: 40px;
$nav-arrow-size-modal: 60px;

// Default room item image
.room-item-image {
  display: block;
  position: relative;
  overflow: hidden;

  .room-carousel {
    display: block;
    cursor: pointer;

    .slides {
      margin: 0;
      padding: 0;
      position: relative;
      display: block;

      li {
        position: relative;
        padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      }

      img {
        width: 100%;
        display: block;
        margin-top: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .flex-viewport {
    position: static !important;
  }

  .flex-direction-nav {
    list-style-type: none;
    direction: ltr;

    a {
      @extend %icon;

      display: block;
      width: $nav-arrow-size;
      height: $nav-arrow-size;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      z-index: $z_carouselNavigation;
      top: 50%;
      margin-top: -$nav-arrow-size * 0.5;
      color: $white;
      font-size: 50px;
      text-decoration: none;

      &.flex-prev {
        left: 0;
      }

      &.flex-next {
        right: 0;
      }
    }
  }
}

.room-item {
  .room-item-image {
    background-position: center;
    background-size: cover;
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    min-width: unset;
    min-height: unset;

    .slides {
      list-style: none;
      margin: 0;
      padding: 0;

      img {
        width: 100%;
      }
    }
  }

  .flex-control-thumbs {
    display: none;
    clear: both;
    margin: 0;
    padding: 4px;
    overflow: hidden;

    li {
      display: block;
      float: left;
      width: 25%;
      position: relative;
      border-width: 0 4px 4px 0;
      border-style: solid;
      border-color: transparent;
      box-sizing: border-box;

      &:nth-child(4n) {
        //every fourth element (end of row)
        border-right-width: 0;
      }

      &:after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }

      button {
        padding: 0;
        width: auto;
        min-width: auto;
      }

      img {
        width: 100%;
        display: block;
        height: $thumbnails-height;
        position: relative;
        z-index: 6;
        opacity: 0.6;

        &.flex-active {
          z-index: 4;
          opacity: 1;
        }
      }
    }
  }
}

.rtl {
  .flexslider {
    direction: ltr;
  }
}

.rtl .room-item-expanded {
  .room-carousel .flex-control-thumbs li {
    float: right;
  }
}

// Default room item desktop
@media only screen and (min-width: $screen-sm) {
  .room-item .room-item-image {
    height: floor(9 * math.div($image-width, 16));
    width: $image-width;

    flex-grow: 0;
    flex-shrink: 0;

    .flex-direction-nav {
      list-style-type: none;

      a {
        width: $nav-arrow-size-small;
        height: $nav-arrow-size-small;
        top: ($image-height * 0.5) + ($nav-arrow-size-small * 0.5);
        font-size: 20px;
        transition: all 0.125s;

        &.flex-prev {
          left: -$nav-arrow-size-small;
        }

        &.flex-next {
          right: -$nav-arrow-size-small;
        }
      }
    }

    &:hover {
      .flex-direction-nav {
        li {
          a {
            &.flex-prev {
              transform: translateX($nav-arrow-size-small);
            }

            &.flex-next {
              transform: translateX(-$nav-arrow-size-small);
            }
          }
        }
      }
    }
  }

  .room-carousel {
    .slides {
      img {
        margin-top: 0;
      }
    }
  }

  .room-item-expanded {
    .room-item-image {
      height: auto;

      .room-carousel {
        width: $image-width;
      }
    }

    .flex-control-thumbs {
      display: block;

      li img {
        margin-top: 0;
      }
    }
  }
}

// Default room item large desktop
@media only screen and (min-width: $screen-md) {
  .room-item .room-item-image {
    height: floor(9 * math.div($big-image-width, 16));
    width: $big-image-width;
  }

  .personalise-form.room-item .room-item-image {
    height: floor(9 * math.div($step4-image-width, 16));
    width: $step4-image-width;
  }

  .room-item-expanded {
    .room-item-image {
      min-height: floor(9 * math.div($big-image-width, 16));
      height: auto;
      width: $big-image-width;

      .room-carousel {
        width: $big-image-width;
      }

      .flex-control-thumbs {
        display: block;

        li {
          img {
            height: $big-thumbnails-height;
          }
        }
      }
    }
  }
}
