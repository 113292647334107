.fs-profile-address-form {
  .verify-button {
    margin-bottom: 40px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 0;
    }

    button {
      height: 39px;
    }
  }

  .fs-toggle-block__actions {
    margin-left: -10px;
    margin-right: -10px;

    > * {
      margin: 0 10px;
      flex-basis: 50%;
      flex-grow: 0;
      flex-shrink: 1;

      @media screen and (min-width: $breakpoints-md) {
        flex-basis: auto;
      }
    }

    button {
      width: 100%;

      @media screen and (min-width: $breakpoints-md) {
        width: auto;
      }
    }
  }
}
