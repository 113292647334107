.is-disabled,
[disabled] {
  cursor: not-allowed !important;
}

.suppress-scroll,
.suppress-scroll body {
  overflow: hidden;
  height: 100%;
  max-height: 100%;
  -webkit-overflow-scrolling: touch;
}

.mobile-link-large {
  font-size: 18px;
  text-decoration: none;
  color: $dark-grey;
  font-weight: $primary-font-weight;
}

.icon-large {
  font-size: 25px;
}

html[data-useragent*='msie 10.0'],
.ie9,
.ie8 {
  a[href^="tel"] {
    color: inherit !important;
    cursor: default !important;
    text-decoration: none !important;
    pointer-events: none !important;
  }
}

@media only screen and (max-width: $screen-sm-max) {
  // overflow control element
  .site-wrapper {
    .suppress-scroll &,
    .suppress-scroll-mobile & {
      // changing display type from 'table'
      // changes scrolling context
      display: block;
      // forces a boundary (probably not necessary)
      max-height: 100%;
      // obvious
      overflow: hidden;
    }
  }

  .suppress-scroll-mobile {
    overflow: hidden;
    height: 100%;
    max-height: 100%;
    position: fixed;
    -webkit-overflow-scrolling: touch;
  }

  /* NOTE:
   * we don't want to stop the screen from scrolling on
   * - Desktop, - choose rooms view, - list view
   * as the modal will appear inside the the room result,
   * and not a standard modal
   */
  .view-booking-flow__choose-rooms.layout-list.suppress-scroll-mobile {
    overflow: auto;
    height: auto;
    max-height: none;
    position: static;

    .site-wrapper {
      display: table;
      max-height: none;
      overflow-y: visible;
    }
  }
}

.view-booking-flow__folio-request__form {
  .main-inner > .fs-errors-container {
    display: none;
  }
}
