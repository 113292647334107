.layout--profile-auth .view--sign-in {

  .fs-otp-sign-in__request-otp,
  .fs-otp-sign-in__submit-otp,
  .fs-otp-sign-in__invalid-otp {
    max-width: none;
    padding: 60px 0 0;
    height: 100%;
  }

  .fs-otp-sign-in__request-otp {
    .view__actions {
      margin-top: 40px;
      max-width: none;
      justify-content: flex-end;

      > fs-button {
        padding: 0;
        width: 200px;
      }
    }

    .problem-please-call {
      margin: 20px 0 -10px;
    }

    @media only screen and (max-width: ($breakpoints-md - 1px)) {
      .view__actions {
        &.small-center {
          justify-content: center;
        }
      }
    }

    .DisplayOnlyInput {
      display: flex;
      justify-content: center;
    }

	.persistent-login-next {
		justify-content: center;
	}
  }

  .fs-otp-sign-in__invalid-otp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 120px;
  }
}
