$mystay-primary-button-bg-color: $black;
$mystay-primary-button-color: $white;
$mystay-primary-button-border-color: $black;
$mystay-primary-button-hover-bg-color: $white;
$mystay-primary-button-hover-color: $black;

$mystay-secondary-button-bg-color: $white;
$mystay-secondary-button-color: $black;
$mystay-secondary-button-border-color: $black;
$mystay-secondary-button-hover-bg-color: $black;
$mystay-secondary-button-hover-color: $white;

$mystay-ghost-button-bg-color: transparent;
$mystay-ghost-button-color: $black;
$mystay-ghost-button-border-color: $black;
$mystay-ghost-button-hover-bg-color: $black;
$mystay-ghost-button-hover-color: $white;

// replace with $theme-gray3 once this is merged w/ rbf restyle
$mystay-disabled-background-color: #D8D8D8;
$mystay-disabled-text-color: $black;

.my-stay--activity-request-form,
.view-my-stay,
body[class*="view-my-stay"] { // to target .view-my-stay.reservation-details
  .btn-primary,
  .btn--primary {
    font-family: $my-stay-header-font;
    font-size: 14px;
    color: $mystay-primary-button-color;
    letter-spacing: 2px;
    background-color: $mystay-primary-button-bg-color;
    border: 1px solid $mystay-primary-button-border-color;
    padding: 13px 30px;
    min-width: 200px;
    width: 100%;

    &.form-input__number-stepper--btn {
      min-width: auto;
      width: auto;
    }

    &:hover {
      background-color: $mystay-primary-button-hover-bg-color;
      color: $mystay-primary-button-hover-color;
    }

    &:disabled {
      background-color: $mystay-disabled-background-color;
      color: $mystay-disabled-text-color;
    }
  }

  .btn--secondary {
    font-family: $my-stay-header-font;
    font-size: 14px;
    letter-spacing: 2px;
    background-color: $mystay-secondary-button-bg-color;
    color: $mystay-secondary-button-color;
    border: 1px solid $mystay-secondary-button-border-color;
    padding: 13px 30px;
    min-width: 200px;

    &:hover {
      background-color: $mystay-secondary-button-hover-bg-color;
      color: $mystay-secondary-button-hover-color;
    }

    &:disabled {
      background-color: $mystay-disabled-background-color;
      color: $mystay-disabled-text-color;
    }
  }

  .btn--ghost {
    font-family: $my-stay-header-font;
    background-color: $mystay-ghost-button-bg-color;
    font-size: 14px;
    letter-spacing: 2px;
    color: $mystay-ghost-button-color;
    border: 1px solid $mystay-ghost-button-border-color;
    padding: 13px 30px;
    width: 100%;

    &:hover {
      background-color: $mystay-ghost-button-hover-bg-color;
      color: $mystay-ghost-button-hover-color;
    }
  }

  .btn--ok {
    margin: 0 auto;
    display: block;
  }

  .profile-modal-body {
    .btn--primary,
    .btn--secondary {
      width: auto;
      margin: 0 auto;
      display: block;
    }
    .btn--cancel {
      margin-top: 20px;
    }
  }
  .btn-stack {
    .btn {
      min-width: 225px;
    }
    .btn + .btn {
      margin-top: 10px;
    }
  }
}
