.fs-profile-linked-accounts {
  h3 {
    margin: 0 0 30px;
  }

  h4 {
    font-family: $hn;
    font-weight: 700;
    font-size: rem(10px, 16px);
    color: #7d7d7d;
    letter-spacing: 3px;
    line-height: 1.3;
    margin: 0 0 50px;
  }

  &__linked-accounts {
    color: #fff;
  }

  &__unlinked-accounts {
    color: #7d7d7d;
  }

  &__change-password {
    @media screen and (min-width: $breakpoints-md) {
      .row {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-shrink: 1;
      }
    }
  }

  .account {
    margin: 0 0 50px;
    position: relative;
    padding: 0 0 0 60px;
    letter-spacing: 0;
    line-height: 1.75;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 120px;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      @media screen and (min-width: $breakpoints-md) {
        padding-bottom: 38px;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }

  &__change-password .account {
    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 50px;
    }
  }
}
