.fs-select-mask {
  background: #fff;
  border: solid 1px $input-border-color;
  border-radius: $input-border-radius;
  margin: 0;
  position: relative;
  overflow: hidden;

  select {
    background: transparent;
    border: none;
    color: $primary-text-color;
    height: $input-height;
    padding-right: 60px;
    position: relative;
    width: 115% !important;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;
    z-index: 2;
  }

  // Hide dotted outline on selects in Firefox
  select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $primary-text-color;
  }

  &.in-focus {
    @include input-focus;
  }

  .icon {
    background: transparent;
    border-radius: 0 $input-border-radius $input-border-radius 0;
    display: block;
    font-size: 22px;
    line-height: 26px !important;
    margin: 0;
    padding: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    width: 34px;
    z-index: 2;

    top: 1px;
    right: 1px;
    bottom: 1px;

    &:before {
      height: 24px;
      margin-top: -12px;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
    }
  }
}

.rtl{
  .fs-select-mask {
    .icon {
      right: auto;
      left: 1px;
    }
    select{
    //   left: 16px;
      padding-left: 0;
      padding-right: 10px;
    }
  }
}


.form-row .fs-select-mask {
  border: none;
  border-radius: 0;
  position: static;

  .icon {
    border-radius: 0;
  }
}


// error state
.form-row.has-errors .fs-select-mask {
  .icon-arrow-down {
    right: 34px;
    background: white;
  }
  &.in-focus {
    .icon-arrow-down {
      background: $focus-color;
    }
  }
}
.rtl{
  .form-row.has-errors .fs-select-mask {
    .icon-arrow-down {
      right: auto;
      left: 34px;
    }
  }
}

/* Online Transfers
======================*/
.form-row.online-transfer {
  .fs-select-mask.hour {
    width: 50%;
    border-right: solid 1px #bebebe;
    float: left;

    select {
      width: 120% !important;
    }

    .icon {
      right: 35%;
    }
  }

  .fs-select-mask.minute {
    width: 50%;
    border-right: 0px;

    select {
      width: 120% !important;
    }
  }
}
.rtl{
  .form-row.online-transfer {
    .fs-select-mask.minute {
      .icon {
        right: inherit;
        left: 35%;
      }
    }
    .fs-select-mask.hour {
      .icon {
        right: inherit;
      }
    }
  }
}

@media only screen and (max-width: ($screen-lg - 1px)) {
  .form-row.online-transfer {
    .fs-select-mask.hour {
      .icon {
        right: 30%;
      }
    }
  }
  .rtl{
    .form-row.online-transfer {
      .fs-select-mask.minute {
        .icon {
          left: 30%;
        }
      }
    }
  }
}

@media only screen and (max-width: ($screen-sm - 1px)) {
  .form-row.online-transfer {
    .fs-select-mask.hour {
      .icon {
        right: 50%;
      }
    }
  }
  .rtl{
    .form-row.online-transfer {
      .fs-select-mask.minute {
        .icon {
          left: 50%;
        }
      }
    }
  }
}

@media only screen and (min-width: ($screen-lg)) {
  .fs-select-mask {

    select {
      padding-right: 75px;
    }
  }
}
