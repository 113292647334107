// error styling for hero layouts
$hero-error-text-color: $white;
$hero-error-color: $new-error-color;
$hero-error-padding: 20px;
$hero-error-font-size: 14px;
$hero-error-line-height: 20px;

%hero-error,
.hero-error,
%hero-error--simple,
.hero-error--simple {
  text-align: left;

}


%hero-error,
.hero-error {
  padding: 0;

  .error {
    margin-top: 0;
    margin-bottom: 0;

    h2 {
      background-color: $hero-error-color;
      padding-bottom: $hero-error-padding;
      padding-top: $hero-error-padding;
    }
    &.row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  a {
    color: $white;
  }

  a.phone {
    display: inline-block;
    color: $white;
  }
}

%hero-error--simple,
.hero-error--simple {
  background-color: $hero-error-color;
  padding: $hero-error-padding;
  color: $hero-error-text-color;
  font-size: $hero-error-font-size;
  line-height: $hero-error-line-height;
  a {
    color: $hero-error-text-color;
  }
}

@media screen and (min-width: $screen-sm) {
  %hero-error,
  .hero-error {
    border-color: $hero-error-color;

    .error.row {
      border: 1px solid $hero-error-color;
    }
  }
}
