.alert {
  background-color: $lighter-grey;
  color: $black;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: $secondary-font-weight;
  margin: 0 0 12px;
  padding: $page-padding;
}


.occupancy-alert-message {
  margin: 0;
  padding: 10px 15px;

  h3 {
    margin: 0;
    line-height: 24px;
    padding-right: 20px;
  }
}

.occupancy-alert-inline {
  margin-bottom: 20px;
  padding: 0;
  position: relative;

  .occupancy-alert-message {
  	border: 1px solid $grey;
  	padding: 10px 15px;
  }

  .icon-close {
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    font-size: 30px;
    font-weight: 600;
    background-color: #dcdcdc;
    color: #fff;
    cursor: pointer;
    text-align: center;

    border: none;
    min-width: auto;
    padding: 0;
    margin: 0;
  }
}

.rtl {
  .occupancy-alert-inline {
    .occupancy-alert-message {
      H3 {
        padding-left: 20px;
        padding-right: 0px;
      }
    }
    .icon-close {
      right: auto;
      left: 0;
      top: 0;
    }
  }
}

@media screen and (max-width: $screen-sm - 1) {
  .occupancy-alert-message {
    margin: 0;
    padding: 15px 15px;
  }

  .occupancy-alert-inline {
    margin: 20px 15px 0;
  }
}
