//////////////
//
// Panel Nav
//
//////////////
$panel-nav-background: $panel-nav-background; // set in theme.scss
$panel-nav-color: $panel-color;
$panel-nav-hover-color: $accent-color;
$panel-nav-padding: $form-padding;
$panel-nav-font-size: 14px;
$panel-nav-icon-size: 18px;
$panel-nav-title-size: 16px;
$panel-nav-subtitle-size: 12px;
$panel-nav-content-size: 13px;
$panel-item-selected-arrow-height: (
  $panel-nav-padding * 2 + $panel-nav-font-size
);
$panel-item-selected-arrow-width: ($panel-nav-padding * 0.5);

$panel-block-title-size: 18px;

.panel-nav {
  font-size: $panel-nav-font-size;
}

.panel-nav__heading {
  padding: ($panel-nav-padding * 1.25) $panel-nav-padding;
  background-color: $panel-nav-background;
  border-bottom: $panel-border;
  color: $dark-grey;
}

.panel-nav__title {
  margin: 0;
  padding: 0;
  font-size: $panel-nav-title-size;
}
.panel-nav__subtitle {
  margin: 0;
  padding: 0;
  font-size: $panel-nav-subtitle-size;
  line-height: ($panel-nav-subtitle-size * 2);
}

.panel-nav__content {
  padding: $panel-nav-padding;
  background-color: $panel-nav-background;
  font-size: $panel-nav-content-size;
  border-bottom: $panel-border;
}

%panel-nav__item {
  display: block;
  position: relative;
  padding: 0;
  margin: 0;
  border-top: $panel-border;

  line-height: $panel-nav-font-size;

  white-space: nowrap;
  z-index: 1;
}

.panel-nav__item {
  @extend %panel-nav__item;

  list-style: none;

  &:before,
  &:after {
    // selected arrow
    // pseudo-elements
    // hidden by default
    content: "";
    display: none;
    position: absolute;
    left: 100%;
    bottom: 0;
    top: 0;

    border: ($panel-item-selected-arrow-height * 0.5) solid transparent;
    border-left-width: $panel-item-selected-arrow-width;
    border-right-width: 0;
  }
  &:before {
    border-left-color: $panel-border-color;
    margin-left: 1px;
  }
  &:after {
    border-left-color: $panel-nav-background;
  }
  .rtl & {
    &:before,
    &:after {
      left: auto;
      right: 100%;

      border-right-width: $panel-item-selected-arrow-width;
      border-left-color: transparent;
      border-left-width: 0;
    }
    &:before {
      border-right-color: $panel-border-color;
      margin-right: 1px;
      margin-left: 0;
    }
    &:after {
      border-right-color: $panel-nav-background;
    }
  }

  &.is-selected {
    &:before,
    &:after {
      display: block;
    }
  }
}
.panel-nav__item__link {
  display: block;
  overflow: hidden;
  background-color: $panel-nav-background;
  padding: $panel-nav-padding;

  line-height: $panel-nav-font-size;
  color: $panel-color;

  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  cursor: pointer;
  max-width: 100vw;

  &:hover {
    color: $panel-nav-hover-color;
  }
}

.panel-nav__item--group {
  display: block;
  position: relative;
  list-style: none;
  border-top: $panel-border;
}

.panel-nav__item__icon {
  display: block;
  position: absolute;
  margin-top: 1px; // minor alignment adjustment
  bottom: $panel-nav-padding;
  right: $panel-nav-padding;
  top: $panel-nav-padding;

  line-height: $panel-nav-font-size;
  font-size: $panel-nav-icon-size;

  pointer-events: none;
}

.panel-nav__group {
  border-bottom: $panel-border;
  padding: 0;
  margin: 0;

  list-style: none;
}

// no border
.panel-nav__group--wrap {
  display: block;
  padding: 0;
  margin: 0;

  list-style: none;

  .panel-nav__item:nth-child(1) {
    border-top: none;
  }
}

.panel-nav__group--sub {
  position: relative;
  display: none;
  padding: 0;
  margin: 0;

  background-color: $panel-nav-background;
  list-style: none;

  &.is-open {
    display: block;
  }

  .panel-nav__item__link {
    padding-left: ($panel-nav-padding * 1.5);
    padding-right: ($panel-nav-padding * 1.5);
    background-color: transparent;
  }

  &:before {
    // left padding
    // for sub-items
    content: "";
    display: block;
    position: absolute;
    background: $panel-background;
    width: ($panel-nav-padding * 0.5);
    height: 100%;
    bottom: 0;
    left: 0;
    top: 0;
  }

  .rtl & {
    &:before {
      left: auto;
      right: 0;
    }
  }
}

// panel nav blocks are content areas
// that are only visible on mobile
.panel-nav__block {
  display: none;
  list-style: none;

  p {
    text-align: center;
  }
}

.panel-nav__block__title {
  font-size: $panel-block-title-size;
  color: $panel-color;
  margin: 10px 0;
  text-transform: uppercase;
  text-align: center;
}

// mobile only
@media screen and (max-width: $screen-sm-max) {
  .panel-nav__block {
    display: block;
  }
}

// desktop
@media screen and (min-width: $screen-sm) {
  .panel-nav {
    background-color: $panel-nav-background;
    border-right: $panel-border;

    position: relative;

    // requires a parent 'table' element
    display: table-cell;
    vertical-align: top;

    .rtl & {
      border-left: $panel-border;
      border-right: none;
    }
  }

  .panel-nav__heading {
    border-bottom: none;
  }

  .panel-nav__content {
    border-bottom: none;
    padding-top: 0;
  }
}
