.web-chat-button {
  background: #000;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 100%;
  border: solid 1px #fff;
  color: #fff;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 30000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  background-clip: content-box;

  &:hover {
    opacity: 0.5;
  }

  path {
    fill: #fff;
  }

  &--on-dark {
    background: #fff;
    border-color: #000;
    color: #000;
    path {
      fill: #000;
    }
  }
}
