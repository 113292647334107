.view--profile-upcoming-trip {
  .layout--profile-fullscreen__top-bar {
    .col-sm-12 {
      @media screen and (min-width: $breakpoints-md) {
        height: 127px;
        .btn-back {
          margin-top: 30px;
        }
      }
    }
  }

  .fs-your-preferences__preference__title {
    max-width: none;
  }

  .fs-toggle-block {
    margin: 0;
  }

  .fs-trip__no-changes {
    border-bottom: 1px solid rgba(0, 0, 0, 0.21);
  }
}
