$profile-booking-text-color: $new-darker-grey;
$profile-booking-title-size: 13px;
$profile-booking-text-size: 12px;
$profile-booking-text-padding: 10px;
$profile-booking-line-height: 20px;

$profile-booking-padding-h: $detail-item-h-padding;
$profile-booking-padding-v: ($profile-booking-text-padding * 2);
$profile-booking-content-padding: 0 $profile-booking-padding-h
  $profile-booking-padding-v;

$profile-booking-map-height: 180px;

.profile-booking {
  .angular-google-map-container {
    height: $profile-booking-map-height;
    margin-bottom: 10px;

    ng-map {
      height: 100% !important;
      width: 100% !important;
      display: block;
    }
  }

  .tel-number {
    display: block;
  }
}

%profile-booking-text {
  margin: 0 auto;
  padding: 0;
  color: $primary-text-color;
  line-height: $profile-booking-line-height;
  letter-spacing: 0.1em;
}

.profile-booking-title {
  padding: ($profile-booking-text-padding * 0.5) 0 $profile-booking-text-padding;
  font-size: $profile-booking-title-size;
  text-align: center;
  text-transform: uppercase;
}

.profile-booking-info {
  color: $black;
  padding: 0 0 ($profile-booking-text-padding * 2);
  text-align: center;
}

.profile-booking-details-block {
  margin-bottom: 10px;
  p {
    margin: 0;
  }
}

.profile-booking-cancel-text {
  @extend %profile-booking-text;
  padding: 0 $profile-booking-padding-h $profile-booking-text-padding;
  text-align: center;
  p {
    margin: 0;
  }
}

.profile-booking-text {
  @extend %profile-booking-text;
  padding: 0 0 $profile-booking-text-padding;
}

.profile-booking-fieldset {
  @extend %profile-form__fieldset;
}

.profile-booking-legend {
  @extend %profile-form__legend;
}

.profile-booking-button,
.profile-booking-check-in-button {
  padding-bottom: 20px;
}

.profile-booking-check-in-button {
  float: none;
  padding-left: 0;
  padding-right: 0;
}

.profile-booking-details-list {
  margin-top: 20px;
}

// datepicker
.profile-booking-dates {
  .fs-select-date label,
  .date-trigger,
  .title {
    display: none;
  }
}

@media screen and (max-width: $screen-sm-max) {
  .profile-booking {
    .tel-number {
      letter-spacing: initial;
    }
  }
}

@media screen and (min-width: $screen-sm) {
  .profile-booking-cancel-text {
    font-size: $profile-booking-title-size;
  }

  .profile-booking-location-row {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;

    // kill bottom spacing on child elements
    .profile-booking-text:last-child {
      padding-bottom: 0;
    }
  }

  .profile-booking-cancel-text {
    padding-right: 0;
    padding-left: 0;
    width: 90%;
  }

  .profile-booking-button {
    padding-right: 0;
    padding-left: 0;
  }

  .profile-booking-info-col {
    color: $primary-text-color;
    // required override for the check in button to escape and go rogue
    position: static;
  }

  // bottom padding in desktop layout only
  .profile-booking-totals {
    margin: $profile-booking-text-padding 0;
  }

  .profile-booking-details-list {
    margin-top: ($profile-booking-text-padding * 0.5);
  }
}
