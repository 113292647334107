$package-padding: 15px;
$package-min-height: 115px;

.choose-package {
  padding-top: 15px;

  .choose-package-title:focus {
    outline: none;
  }
}

.package {
  background: $white;
  border-bottom: solid 1px $light-grey;
  border-left: solid 1px $light-grey;
  border-right: solid 1px $light-grey;
  margin: 0;
  padding: 12px $page-padding;
  position: relative;
  min-height: ($package-min-height + (2 * $package-padding));

  display: flex;
  flex-direction: column;

  @media only screen and (min-width: $screen-sm) {
    border-top: solid 1px $light-grey;
    margin-bottom: 16px;
  }

  .heading {
    font-family: $primary-font;
    font-size: $secondary-font-size;
    margin: 0 0 4px;
  }

  .view-more-information {
    margin: 5px 0 0;
    padding: 0;
  }

  .call-to-book {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    border: solid 1px $medium-grey;
    margin: 0;
    padding: 10px;
    text-align: center;
    float: right;
    width: 120px;
  }

  .call-to-book span.phone {
    font-size: 12px;
  }

  .nightly-rate {
    font-size: 1em;
    font-weight: 700;
    overflow: hidden;

    span {
      display: block;
    }
  }

  .nightly-rate span.night {
    font-size: 0.875em;
    font-weight: $primary-font-weight;
  }

  .package-description {
    p {
      margin: 0 0 5px;
    }

    a {
      margin: 0 0 5px;
    }
  }

  .package-price {
    @include clearfix();
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .view-more-information {
      width: 50%;
      text-align: left;
    }

    .package-price-text {
      font-size: 14px;
      width: 50%;
      text-align: right;

      .resort-fee {
        max-width: 150px;
        text-align: right;
        line-height: 1;
        margin-top: 5px;
        margin-bottom: 10px;
        float: right;
        font-size: 0.875em;
        font-weight: $primary-font-weight;
      }

      &__no-whatIsIncluded {
        width: 100%;
      }
    }
  }

  .package-book-now {
    text-align: right;
    margin-top: 10px;

    .btn.btn--primary {
      width: auto;
      padding: $package-padding;
      text-align: center;
      display: inline-block;

      float: right;
      white-space: nowrap;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .package {
    .call-to-book {
      width: 180px;
    }
  }
}

.package.is-selected {
  background: $lighter-grey;
  .btn-plain {
    background: $dark-grey;
  }
}

.rtl {
  .package {
    .package-book-now {
      text-align: left;

      .btn.btn--primary {
        float: left;
      }
    }

    .view-more-information {
      text-align: right;
    }

    .package-price-text {
      text-align: left;

      .resort-fee {
        text-align: left;
        float: left;
      }
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .packages .package-description {
    padding-bottom: 0;
  }

  .package .package-book-now {
    margin-top: auto;
  }

  .package .package-info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .package .package-description {
    flex-basis: 70%;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .package .package-price {
    padding: 15px;
    flex-grow: 0;
    flex-shrink: 0;
    max-width: 150px;

    .package-price-text,
    .view-more-information {
      clear: both;
      width: 100%;
    }

    .package-price-text {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}

@media only screen and (max-width: ($screen-sm - 1px)) {
  .package {
    .nightly-rate {
      font-size: 0.875em;
    }
  }
}

.package-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.package__select-package {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;

  @media only screen and (min-width: $screen-sm) {
    padding: 0 15px 15px 15px;
  }

  &__cancellation {
    margin-right: 20px;
  }

  &__button {
    width: auto;
  }

  &--isSelected {
	visibility: hidden;
  }
}

.package__selected {
	border-bottom: solid 1px $black;
	border-left: solid 1px $black;
	border-right: solid 1px $black;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	gap: 10px;
	padding: 20px 15px;
	@media only screen and (min-width: $screen-sm) {
		gap: 20px 10px;
		margin-bottom: 20px;
	}

	&__closeButton {
		flex-basis: 100%;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		gap: 20px;
		align-items: center;
		@media only screen and (max-width: ($screen-sm - 1px)) {
			margin-bottom: 10px;
		}

		&-label {
			color: #666;
			font-feature-settings: 'clig' off, 'liga' off;
			font-family: $hn-neue;
			font-size: 16px;
			font-style: normal;
			font-weight: 300;
			line-height: 22.4px; /* 140% */
		}

		&-icon {
			cursor: pointer;
			width: 16px;
			height: 16px;
		}
	}

	&__button {
		@media only screen and (max-width: ($screen-sm - 1px)) {
			width: 100%;
		}
	}
}

.rtl {
  .package__select-package__cancellation {
    margin-right: 0;
    margin-left: 20px;
  }
}
