footer {
  height: auto;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
textarea:focus {
  background: inherit;
  box-shadow: inherit;
  border-color: inherit;
}

p {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  font-weight: inherit;
}
