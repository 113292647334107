@use "sass:math";

.layout--profile-auth {
  background: #fff;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;

  &__body,
  &__container,
  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__body {
    padding: 0;
    overflow: auto;
  }

  &__container {
    margin: 0 auto;
    width: 100%;

    @media screen and (min-width: $breakpoints-lg) {
      max-width: $breakpoints-xl;
    }
  }

  &__content {
    text-align: center;
    padding: 0;

    @media screen and (min-width: $breakpoints-lg) {
      width: math.div(100%, 12) * 7;
      padding-left: 100px;
      padding-right: 125px;
    }
  }

  &__background-image {
    background-color: #d8d8d8;
    background-image: url("/images/profiles_2018/register-bg.jpg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    position: absolute;
    top: $navigationHeight;
    right: 0;
    bottom: 0;
    left: 50%;
    margin-left: math.div($breakpoints-xl, 12);

    display: none;

    &.yachts {
      background-image: url("/images/profiles_2018/yachts/SSO-Image_Smaller.jpg");
      background-position: 95% 50%;
    }

    @media screen and (min-width: $breakpoints-lg) {
      display: block;
    }
  }
}

.rtl {
  .layout--profile-auth {
    &__background-image {
      left: 0;
      right: 50%;
      margin-left: auto;
      margin-right: math.div($breakpoints-xl, 12);
    }
  }
}

.layout--profile-auth {
  .view {
    margin: 0 auto;
    max-width: 450px;
    width: 100%;
    height: 100%;

    padding: 80px 15px 0;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;

    @media screen and (min-width: $breakpoints-md) {
      padding-top: 120px;
      padding-left: 0;
      padding-right: 0;
    }

    @media screen and (min-width: $breakpoints-lg) {
      max-width: 610px;
    }

    &--centered {
      flex-grow: 1;
      flex-shrink: 1;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media screen and (min-width: $breakpoints-md) {
        padding-top: 0;
        justify-content: center;
      }
    }

    &__actions {
      margin: 0 (-8px) 34px;
      overflow: hidden;
      text-align: center;
      font-size: 0;

      display: flex;
      flex-direction: row;
      justify-content: center;

	    margin-bottom: 50px;

      @media screen and (min-width: $breakpoints-md) {
        margin-left: -15px;
        margin-right: -15px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        width: 100%;
        max-width: 400px + (2 * 10px);
        margin-left: auto;
        margin-right: auto;
      }

      .view__action,
      > fs-button {
        padding: 0 8px;
        flex-basis: 50%;

        @media screen and (min-width: $breakpoints-md) {
          padding-left: 15px;
          padding-right: 15px;
        }

        @media screen and (min-width: $breakpoints-lg) {
          padding-left: 10px;
          padding-right: 10px;
        }

        > a,
        > button {
          width: 100%;
          min-width: auto;
        }
      }
    }

    &__footer {
      margin-top: auto;
      margin-bottom: 34px;

      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 100px;
      }

      p {
        margin: 0;
      }
    }

    .intro {
      margin: 0 0 30px;
    }
  }
}
