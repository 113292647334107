$breakpoints-xl: 1701px;
$breakpoints-lg: 961px;
$breakpoints-md: 701px;
$breakpoints-sm: 480px;
$containerWidth-xl: 1530px;
$containerWidth-lg: 930px;
$containerWidth-md: 690px;
$containerWidth-sm: 670px;

$black: #000;
$gray1: #2d2d2d;
$gray2: #7d7d7d;
$gray3: #d8d8d8;
$gray4: #f5f5f5;
$gray5: #4d4d4d;
$gray6: #f0f0f0;
$gray7: #989898;
$white: #fff;
$separatorLight: $gray3;
$separatorDark: $gray2;
$error-red: #ea0a0a;

// Fonts
$garamond: 'Monotype Garamond W05', Garamond, serif;
$hn: 'Neue Helvetica W04', Neue Helvetica, Helvetica, sans-serif;
$hn-roman: 'Neue Helvetica W05', Neue Helvetica, Helvetica, sans-serif;
// Fonts updated - chrxs - Nov 2022
$garamond-mt-std: "Garamond MT Std", Garamond, serif;
$hn-neue: "Helvetica Neue LT Std", Neue Helvetica, Helvetica, sans-serif;

// z-index values
$overlay-z-index: 100;
$navigation-z-index: $overlay-z-index - 1;

// Hover colors
$whover: #767676;
$bhover: #999;

$socialNetworks: (
  facebook: icon-facebook_tag,
  instagram: icon-instagram_tag,
  twitter: icon-twitter,
  pinterest: icon-pinterest
);

$navigationHeight: 50px;
$navigationBreakpoint: 1100px;
$navigationTextBreakpoint: 1240px;
