.radios {
  display: flex;
  gap: 10px;
  flex-direction: column;

  &--row {
    gap: 30px;
    flex-direction: row;
  }

  &.radios-cta {
    flex-wrap: wrap;
    gap: 10px;
  }

  @media only screen and (min-width: 701px) {
    gap: 30px;
    flex-direction: row;
  }
}

.radio {
  display: block;
  position: relative;
  padding-left: 40px;
  cursor: pointer;

  &.radio-cta {
    font-family: Neue Helvetica, helvetica, sans-serif;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.3em;
    line-height: 1.3em;
    text-transform: uppercase;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    gap: 10px;

    .radio__checkmark {
      display: none;
    }

    .icon {
      display: none;
    }

    input:checked + .icon {
      display: block;
    }

    &.CTA--primary--light {
      align-items: center;
      background-color: #000;
      border: 1px solid #000;
      box-sizing: border-box;
      color: #fff;
      cursor: pointer;
      display: inline-flex;
      font-family: Neue Helvetica, helvetica, sans-serif;
      font-size: 0.555rem;
      font-style: normal;
      font-weight: 700;
      justify-content: center;
      letter-spacing: 0.3em;
      line-height: 1.3em;
      min-width: 130px;
      padding: 12px 20px;
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color 0.3s ease-out, color 0.3s ease-out,
        border-color 0.3s ease-out;
    }

    &.CTA--primary--light:focus,
    &.CTA--primary--light:hover {
      background-color: #000;
      border-color: #fff;
      color: #fff;
    }

    &.CTA--secondary--light:focus,
    &.CTA--secondary--light:hover {
      background-color: #fff;
      border-color: #000;
      color: #000;
    }

    &.CTA {
      &--primary {
        &--light,
        &--dark {
          &:disabled,
          &[disabled] {
            &,
            &:hover,
            &:focus {
              background-color: #6d6d6d;
              border-color: #6d6d6d;
              color: #fff;
              cursor: not-allowed;
              text-decoration: line-through;
            }
          }
        }
      }
    }

    &.CTA {
      &--secondary {
        &--light,
        &--dark {
          &:disabled,
          &[disabled] {
            &,
            &:hover,
            &:focus {
              background-color: #d8d8d8;
              border-color: #d8d8d8;
              color: #fff;
              cursor: not-allowed;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }

  &__checkmark {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: white;
    border: 1px solid #7d7d7d;
    border-radius: 50%;

    &:after {
      content: "";
      display: none;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background-color: black;
    }
  }

  input:checked + .radio__checkmark:after {
    display: block;
  }

  input:focus + .radio__checkmark {
    outline: solid 1px;
    outline-offset: 2px;
  }

  input {
    position: fixed;
    top: -200%;
  }
}
