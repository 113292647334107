.scroll-link {
  margin: 20px auto;
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

fieldset[name="paymentOptions"] {
  .choice-items {
    margin: 0;
    border: 1px solid #bebebe;
  }

  input[type="radio"] {
    opacity: 0;
    filter: alpha(opacity=0);
    position: absolute;
    display: block;
    height: 1px;
  }

  @media (min-width: 768px) {
    .choice-items {
      border: none;
    }
  }

  .choice-item {
    border-color: #bebebe;
    :focus-within {
      background: $new-light-grey;
    }
  }

  .choice-item__control {
    float: left;
    margin-left: 0;
    margin-right: 15px;
  }

  .choice-item .choice-item__body {
    color: #000;
  }

  .choice-item .choice-item__body img {
    vertical-align: middle;
  }

  .available-cards {
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .available-cards .card {
    margin: 0 8px 0 0;
    display: inline;
    vertical-align: middle;
  }

  @media (max-width: 435px) {
    .available-cards {
      display: none;
    }
  }

  .alipayChina-content {
    background-color: #f9f9f9;
    text-align: center;
    border-color: #bebebe;
  }

  fieldset[name="creditCardDetails"] {
    margin: 0;

    @media (max-width: 768px) {
      .form-row {
        border-left: none;
        border-right: none;
      }
    }
  }

  #errorMessage .phone {
    color: #fff;
    display: inline;
  }
}

// 768px
@media screen and (min-width: $screen-sm) {
  .view-confirm-stay {
    .col-sm-3 {
      border-right: 2px solid $grey;
    }

    .col-sm-9 {
      float: right;
    }
  }
  .rtl {
    .view-confirm-stay {
      .col-sm-3 {
        border-right: none;
        border-left: 2px solid $grey;
        padding-right: 0px;
        padding-left: 40px;
      }

      .col-sm-9 {
        float: left;

        padding-right: 40px;
        padding-left: 0px;
      }
    }
  }
}

// 992px
@media screen and (min-width: $screen-md) {
  .view-confirm-stay {
    .col-sm-3 {
      padding-right: 40px;
    }

    .col-sm-9 {
      padding-left: 40px;
    }
  }
}

.g-recaptcha.iosBottom {
  .grecaptcha-badge {
    bottom: 65px !important;
  }
}

.view-booking-flow__confirm-your-stay {
  button.appear-as-link {
    text-align: inherit;
    padding: 0;
    background-color: transparent;
    border-width: 0;
    letter-spacing: inherit;
    min-width: auto;
    min-height: auto;
    width: auto;
    display: inline;
  }

  button.return-to-top {
    display: flex;
    margin: 15px auto;
  }
}

.package {
  %link {
    background: none;
    border: 0;
    display: inline-block;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
      Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.432px;
    margin: 0 auto 20px;
    margin: 0;
    min-width: auto;
    padding: 0;
    text-decoration-line: underline;
    width: auto;
  }
  .promo-title {
    @extend %link;
    font-weight: 400;
  }
  .room-name {
    @extend %link;
  }
}

.accordion-item {
  overflow: hidden;
  transition: max-height 0.2s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;

  &.collapsed {
    max-height: 0;
    transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
  }
}

.fs-modal-container {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;
  height: 100%;

  @media screen and (min-width: $breakpoints-md) {
    padding: 40px;
  }
}

.suppress-scroll .fs-modal-container {
  bottom: 0;
}

.view--profile-folio {
  margin: 0 auto;
  max-width: 836px;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 80px 0;
  position: relative;
  min-height: 100%;
  text-align: center;

  @media screen and (min-width: $breakpoints-md) {
    height: auto;
    min-height: auto;
    padding: 110px 105px;
  }

  @media screen and (min-width: $breakpoints-lg) {
    padding: 100px 98px;
  }

  .btn-close {
    background-image: url("/images/profiles_2018/black-cross.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 14px;
    width: 14px;
    position: absolute;
    top: 40px;
    right: 40px;
    border: none;
    background-color: transparent;

    span {
      display: block;
      font-size: 0;
      height: 1px;
      overflow: hidden;
    }
  }

  h1 {
    font-size: rem(34px);
    letter-spacing: 4px;
    line-height: 1.4;
    margin: 0 0 80px;
  }

  .intro {
    margin: 0 0 60px;
  }
}

.button-as-link {
  border: none;
  background: transparent;
  text-decoration: underline;
  padding: 0;
  min-width: auto;
  width: auto;
}

.view-booking-flow__confirm-your-stay {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  input[type="datetime"],
  input[type="number"],
  input[type="search"],
  select,
  textarea {
    &[readonly] {
      background: $lighter-grey;
      // color: $dark-grey; commented out becuase they requested normal color.
      cursor: not-allowed;
      &::-webkit-input-placeholder {
        color: $medium-grey;
      }
      &:-moz-placeholder {
        color: $medium-grey;
      }
      &::-moz-placeholder {
        color: $medium-grey;
      }
      &:-ms-input-placeholder {
        color: $medium-grey;
      }
      &.empty {
        color: $medium-grey;
      }
    }
  }
}

.view-booking-flow__confirm-your-stay {
  .cancellation-date-container {
    clear: both;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-top: 20px;
  }
}
