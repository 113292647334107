.compatibility-error-container{
  height:100%;
  width: 100%;
  background-color: white;

  .compatibility-error{
    margin:30px;
    background-color: #fcf8e3;
    border-color: #faebcc;
    color: #8a6d3b;
    padding: 5px;

    .compatibility-error-box{
      border: 1px solid #8a6d3b;
      padding: 15px;

      .center-block {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      .img-responsive{
        width:100%;
        height:auto;
      }
    }
  }
}
