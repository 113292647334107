$folio-body-width: 480px;

.folio-request-heading {
  @extend %hero__title;
}

.folio-request-body {
  .btn {
    text-transform: uppercase;
  }
}

.folio-form-title {
  padding-bottom: 30px;
}

.folio-form-submit {
  text-align: center;
}

.folio-request-privacy {
  width: 80%;
  text-align: center;
  margin: 10px auto 0;
}


@media only screen and (min-width : $screen-sm) {
  .folio-request-body {
    max-width: $folio-body-width;
  }
  .folio-request-heading {
    font-size: 42px;
  }
}
