.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z_loadingScreen;
  background-color: rgba(255, 255, 255, 0.7);
  background-image: radial-gradient(rgba(255, 255, 255, 0.5), transparent);

  &.mobile-app-spinner {
    background-color: rgba(0, 0, 0);
    background-image: radial-gradient(rgb(0, 0, 0), transparent);
  }

  .loading-screen-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
    height: 300px;
    width: 300px;
    background: url(/images/FS-loader-transparent.gif);
    background-position: center;
    background-repeat: no-repeat;

    &.mobile-app-spinner {
      background-image: url(/images/nbf_loader/NBF_Loader.gif);
      .ar & {
        background-image: url(/images/nbf_loader/AR_NBF_Loader.gif);
      }
      .br & {
        background-image: url(/images/nbf_loader/BR_NBF_Loader.gif);
      }
      .de & {
        background-image: url(/images/nbf_loader/DE_NBF_Loader.gif);
      }
      .es-es & {
        background-image: url(/images/nbf_loader/ES_ES_NBF_Loader.gif);
      }
      .es & {
        background-image: url(/images/nbf_loader/ES_NBF_Loader.gif);
      }
      .fr & {
        background-image: url(/images/nbf_loader/FR_NBF_Loader.gif);
      }
      .gr & {
        background-image: url(/images/nbf_loader/GR_NBF_Loader.gif);
      }
      .it & {
        background-image: url(/images/nbf_loader/IT_NBF_Loader.gif);
      }
      .jp & {
        background-image: url(/images/nbf_loader/JP_NBF_Loader.gif);
      }
      .kr & {
        background-image: url(/images/nbf_loader/KR_NBF_Loader.gif);
      }
      .pt & {
        background-image: url(/images/nbf_loader/PT_NBF_Loader.gif);
      }
      .ru & {
        background-image: url(/images/nbf_loader/RU_NBF_Loader.gif);
      }
      .tr & {
        background-image: url(/images/nbf_loader/TR_NBF_Loader.gif);
      }
      .zh_hant & {
        background-image: url(/images/nbf_loader/ZH_Hant_NBF_Loader.gif);
      }
      .zh & {
        background-image: url(/images/nbf_loader/ZH_NBF_Loader.gif);
      }
    }
  }

  /* Best Rate Guaranteed styles */
  .best_rate_guaranteed_outer_box {
    position: relative;
    background-color: #000000;
    outline: 3px solid black;
    border: 2px solid lightgray;
    width: 640px;
    padding: 10px 15px 5px;
    top: -160px;
    left: -270px;
    display: none;
  }

  .best_rate_guaranteed_outer_box.en,
  .best_rate_guaranteed_outer_box.ar {
    display: block;
    top: -160px;
  }

  .best_rate_guaranteed_outer_box.view-caw,
  .best_rate_guaranteed_outer_box.view-profile,
  .best_rate_guaranteed_outer_box.view-profile-assistance,
  .best_rate_guaranteed_outer_box.view-register,
  .best_rate_guaranteed_outer_box.view-verifyEmail {
    display: none;
  }

  .best_rate_guaranteed_outer_box.pp {
    display: none;
  }

  .best_rate_guarantee_link {
    text-decoration: none;
    color: #000;
  }

  .best_rate_guaranteed_wrapper {
    background-image: url(/images/best-rate-checkmark.png);
    background-repeat: no-repeat;
    background-size: 20px 16px;
    background-position: 3px 5px;
    position: relative;
    top: 245px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 4px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 255px;
    height: 30px;
  }
  font-family: $header-font;
  .best_rate_guaranteed_title {
    color: #000;
    font-family: $header-font;
    font-size: 16px;
    position: relative;
    left: 27px;
    padding: 0 0 4px 4px;
    margin: 0 -15px 0 0;
    height: 30px;
    line-height: 26px;
    text-align: left;
    letter-spacing: 0.1em;
    text-decoration: none;
    .it & {
      font-size: 12px;
    }
  }
  &.mobile-app-spinner {
    .best_rate_guaranteed_wrapper {
      background-image: url(/images/best-rate-checkmark-white.png);
    }
    .best_rate_guaranteed_title {
      color: #fff;
    }
  }

  .best_rate_guaranteed_text {
    // font-size: 9pt;
    background-color: #000000;
    color: white;
    text-align: center;
    position: relative;
    margin: 0px 55px 20px 55px;
  }
}

.loading-screen-opaque {
  background-color: #fff;
}

.rtl {
  .loading-screen {
    /* Best Rate Guaranteed styles */
    .best_rate_guaranteed_outer_box {
      left: 207px;
      display: none;
    }

    .best_rate_guaranteed_outer_box.ar {
      display: block;
    }
    .best_rate_guaranteed_wrapper {
      width: 145px;
      padding-left: 5px;
      background-position: right 3px top 2px;
    }

    .best_rate_guaranteed_title {
      padding: 2px 0px 2px 7px;
      left: 10px;
      float: left;
    }
  }
}

@media only screen and (max-width: $screen-sm-max) {
  .loading-screen {
    .best_rate_guaranteed_wrapper {
      width: 240px;
    }

    .best_rate_guaranteed_outer_box {
      height: auto;
      min-height: 85px;
      width: 280px;
      left: -100px;
      padding: 3px;
    }

    .best_rate_guaranteed_text {
      margin: 0px 0px 0px 0px;
    }
    .best_rate_guaranteed_text,
    .best_rate_guaranteed_title {
      font-size: 15px;
      .it & {
        font-size: 12px;
      }
    }
  }
  .rtl {
    .loading-screen {
      .best_rate_guaranteed_outer_box {
        height: auto;
        min-height: 85px;
        width: 280px;
        left: 100px;
      }

      .best_rate_guaranteed_text {
        margin: 0px 0px 0px 0px;
      }
    }
  }
}

.rtl {
  .loading-screen {
    .best_rate_guaranteed_wrapper {
      background-position: right 3px top 2px;
      margin-top: 10px;
      margin-bottom: 4px;
      margin-left: auto;
      margin-right: auto;
      padding: 1px 1px 1px 1px;
    }

    .best_rate_guaranteed_title {
      float: left;
      padding: 2px 0px 2px 7px;
      margin: 0px;
      left: 0px;
      right: 12px;
      bottom: 4px;
    }

    .best_rate_guaranteed_text {
      text-align: center;
      margin: 0px 55px 0px 55px;
    }
  }
}

.employee-flow {
  .loading-screen {
    .best_rate_guaranteed_wrapper {
      display: none;
    }
  }
}
