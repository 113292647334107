.ancillary-booking-cards {
  padding-top: 40px;
  border-top: 1px solid rgba(0, 0, 0, 0.21);
  margin-bottom: 40px;

  &__header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 30px;
  }
}

.ancillary-booking-card {
  display: flex;
  position: relative;
  align-items: flex-end;
  border: none;
  width: 100%;
  grid-column: span 3;

  &__text {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    color: white;
    padding: 20px;
    z-index: 2;
    width: 100%;

    &__title {
      flex-wrap: wrap;
    }

    &__subtitle {
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }
}

.ancillary-booking-card__img-container {
  position: relative;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.ancillary-booking-card__img-container:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70%;
  display: inline-block;
  background: linear-gradient(180deg, transparent 0, #000);
}

.ancillary-booking-card__img-container img {
  max-width: 100%;
  max-height: 100%;
  min-height: auto;
}

@media only screen and (min-width: 768px) {
  .ancillary-booking-card:first-child:nth-last-child(1) {
    grid-column: span 6;
  }

  .ancillary-booking-card:first-child:nth-last-child(odd)
    ~ .ancillary-booking-card:nth-child(n + 2):nth-last-child(-n + 3),
  .ancillary-booking-card:first-child:nth-last-child(3) {
    grid-column: span 2;
  }
}

@media only screen and (max-width: 767px) {
  .ancillary-booking-cards__grid {
    gap: 14px;
  }

  .ancillary-booking-card:first-child:nth-last-child(odd) {
    grid-column: span 6;
  }
}
