.arrival-transportation {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 42px;

  @media screen and (min-width: $breakpoints-md) {
    padding-top: 30px;
  }

  h2,
  p {
    margin: 0;
    text-align: inherit;
  }

  .Dropdown,
  .TextInput {
    .formElement-field {
      background-color: #f0f0f0;
      padding-left: 16px;
      padding-right: 16px;

      &:disabled {
        color: #666;
      }
    }
  }

  .TextArea {
    .formElement-field {
      height: auto;
      min-height: 198px;
      white-space: normal;
    }
  }

  &__checkbox {
    display: flex;
    flex-direction: row;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 42px;
  }

  &__info {
    margin: 42px 0;
  }

  &__fieldset {
    border: none;
    margin: 0;
    padding: 0;

    &__legend {
      border-radius: 0;
      background: transparent;
      border: none;
      margin: 0;
      margin-bottom: 42px;
      padding: 0;
      text-align: initial;
      width: auto;
      color: inherit;
    }

    &__fields {
      display: flex;
      flex-direction: column;
      gap: 42px;

      @media screen and (min-width: $breakpoints-lg) {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      > .Dropdown,
      > .TextInput {
        margin: 0;
        padding: 0;
      }

      > .Dropdown--error,
      > .TextInput--error,
      > .TextArea--error {
        padding-bottom: 30px;
      }
    }
  }

  &__estimated-total {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: $breakpoints-md) {
      justify-content: flex-end;
      gap: 100px;
    }
  }

  &__terms-list {
    border-top: 1px solid #d8d8d8;
    padding: 30px 0 0;
    margin: 0 0 42px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }

  &__terms {
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__title {
    }

    &__body {
    }
  }

  &__actions {
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media screen and (min-width: $breakpoints-md) {
      justify-content: flex-start;
      gap: 40px;
    }

    > * {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 50%;
      width: 50%;

      @media screen and (min-width: $breakpoints-md) {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        width: auto;
        min-width: 188px;
      }
    }
  }

  &__checkbox {
    &--has-error {
      input.checkbox {
        border-color: #ea0a0a;
      }
    }

    &__error {
      font-family: "Neue Helvetica W04", Neue Helvetica, Helvetica, sans-serif;
      font-size: 0.75rem;
      font-weight: 250;
      letter-spacing: 0.1em;
      line-height: 2em;
      color: #ea0a0a;
    }
  }

  &__more-details__item--special-request {
    .TextInput.TextArea {
      @media screen and (min-width: $breakpoints-lg) {
        grid-column-start: span 2;
      }
    }
  }
}

.arrival-transportation--success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  text-align: center;

  @media screen and (min-width: $breakpoints-lg) {
    gap: 50px;
  }

  &__content {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 620px;

    @media screen and (min-width: $breakpoints-lg) {
      gap: 50px;
    }

    p,
    h1,
    h2,
    h3 {
      margin: 0;
      padding: 0;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: $breakpoints-md) {
      flex-direction: row;
      gap: 20px;
    }
  }
}

.rtl .btn-back {
  transform: rotate(180deg);
}
