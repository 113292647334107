.russia-confirmation-modal {
	&.russia-no-header {
		top: 0;
	}

	.container {
		padding: 0 !important;
	}

	.modal-title {
		display: none;
	}

	.modal-content-body {
    	background-color: #fff;
		letter-spacing: 0.05em;
    }

    .icon-close {
    	display: none;
    }
}
