.view-caw {
  .site-wrapper {
    header {
      display: none;
      bottom: 0;
      top: auto;

      h1,
      .logo {
        display: none;
      }
    }

    > footer {
      display: none;
    }  
  }

  main .main-inner {
    padding: 0;

    button.btn-primary {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .loading-screen {
    display: none;

    .loading-screen-spinner {
      display: none;
    }
  }

  // hero layout differs slightly
  &.fs-hero {
    header {
      display: block;
    }
  }

  .change-search {
    .container {
      padding: 0;
    }

    .fs-search-panel {
      padding: 0;
    }

		.fs-search-panel.bl-external-1 {
			padding: 20px 20px 40px;
  	}

		.fs-search-panel.bl-external-1:not(.mobile):not(.cfg-mobile) {
		  background-color: #eee;
  	}
   }
   .skipLink{
     display: none;
   }
   #ScreenReaderOnly{
    display: none;
   }
}

@media screen and (max-width: $screen-sm) {
  .view-caw {
    .fs-search-panel {
      .single-property {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 305px) {
  .view-caw {
    .ar {
      .fs-search-panel label {
        font-size: 14px;
      }
    }
  }  
}

@media screen and (max-width: 275px) {
  .view-caw {
    .br .form-row .fs-occupancy-input input {
      font-size: 14px;
    }

    .es .form-row .fs-occupancy-input input {
      font-size: 14px;
    }
  }  
}

@media screen and (max-width: 265px) {
  .view-caw {
    .ar {
      .fs-search-panel label {
        font-size: 11px;
      }

      .form-row .fs-occupancy-input input {
        font-size: 13px;
      }
    }
  }  
}

@media screen and (max-width: 250px) {
  .view-caw {
    .form-row .fs-occupancy-input input {
      font-size: 14px;
    }

    .ar .form-row .fs-occupancy-input input {
      font-size: 12px;
    }

    .br .form-row .fs-occupancy-input input {
      font-size: 12px;
    }

    .es .form-row .fs-occupancy-input input {
      font-size: 12px;
    }

    .fs-calendar .ui-datepicker-title {
      font-size: 16px;    
    }

    .jp {
      .fs-search-panel label {
        font-size: 14px;
      }
    }

    .ar {
      .fs-calendar th {
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 230px) {
  .view-caw {
    .form-row .fs-occupancy-input input {
      font-size: 13px;
    }

    .ar .form-row .fs-occupancy-input input {
      font-size: 12px;
    }

    .br .form-row .fs-occupancy-input input {
      font-size: 12px;
    }

    .es .form-row .fs-occupancy-input input {
      font-size: 11px;
    }
  }
}
