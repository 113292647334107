.fs-toggle-button {
  background: transparent;
  border-radius: 1000px;
  border: solid 1px #fff;
  font-family: $hn;
  font-weight: 700;
  font-size: rem(10px);
  color: $white;
  letter-spacing: 3px;
  text-align: right;
  line-height: 13px;
  text-transform: uppercase;
  padding: 12px 20px;

  &--active {
    background: $white;
    color: $black;
  }
}

.fs-toggle-button--light {
  background: transparent;
  border-radius: 1000px;
  border: solid 1px #000;
  font-family: $hn;
  font-weight: 700;
  font-size: rem(10px);
  color: $black;
  letter-spacing: 3px;
  text-align: right;
  line-height: 13px;
  text-transform: uppercase;
  padding: 12px 20px;

  &-active {
    background: $black;
    color: $white;
  }
}

.tandc--button {
  &.appear-as-link {
    text-align: inherit;
    padding: 0;
    padding-left: 5px;
    background-color: transparent;
    border-width: 0;
    letter-spacing: inherit;
    min-width: auto;
    min-height: auto;
    width: auto;
    display: inline;
    @include rtl {
      padding-right: 5px;
    }
  }
}
