.ancillary-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  position: relative;

  @media only screen and (min-width: 701px) {
    padding: 0 20px;
  }

  &__btns {
    display: none;
    height: 0;
    position: absolute;
    top: 50%;
    left: -10px;
    right: -10px;
    margin-top: -10px;
    flex-direction: row;
    justify-content: space-between;

    @media only screen and (min-width: 701px) {
      display: flex;
    }
  }

  &__btn {
    background: none;
    border: none;
    height: 20px;
    width: 20px;

    svg {
      fill: #000;
    }

    &[disabled] {
      svg {
        fill: #d8d8d8;
      }
    }
  }

  &__dots {
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: center;

    @media only screen and (max-width: 701px) {
      display: none;
    }
  }

  &__dot {
    width: 6px;
    height: 6px;
    border: solid 1px #2d2d2d;
    border-radius: 1000px;

    &--selected {
      background-color: #2d2d2d;
    }
  }

  &__row {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 202px;

    padding: 0 20px;
    gap: 20px;
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (min-width: 701px) {
      padding: 0;
      grid-auto-columns: calc((100% / 3) - 13px);
    }

    @media only screen and (min-width: 492px) {
      &--two {
        grid-auto-flow: column;
        grid-auto-columns: calc(50% - 10px);
        grid-template-columns: unset;
      }
    }
  }

  &__col {
    scroll-snap-align: center;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}

//
.featured-ancillary-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 29px;
  border-radius: 5px;
  border: solid 1px #d8d8d8;
  background-color: #fff;
  padding-bottom: 30px;
  overflow: hidden;
  margin-left: 20px;
  margin-right: 20px;

  @media only screen and (min-width: 701px) {
    flex-direction: row;
    gap: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
  }

  h3,
  p {
    margin: 0;
  }

  &__img {
    width: 100%;
    @media only screen and (min-width: 701px) {
      flex-shrink: 0;
      width: 60%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 30px;
    font-feature-settings: "clig" off, "liga" off;
    flex-grow: 1;
    flex-shrink: 1;

    &__description {
      @media only screen and (min-width: 701px) {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 3;
      }
    }
  }
}

//
.ancillary-card {
  $transition-duration: 0.4s;

  display: block;
  background-color: #000;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  padding-top: 125%;

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    transition: opacity $transition-duration ease;
    z-index: 1;
  }

  &::before {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.76) 55%
    );
    z-index: 1;
  }

  &::after {
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.76) 55%,
      rgba(0, 0, 0, 0.76) 100%
    );
    z-index: 2;
    opacity: 0;
  }

  &__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media only screen and (min-width: 701px) {
    &--wide {
      padding-top: 56.25%;

      .ancillary-card__content__description {
        -webkit-line-clamp: 2;
      }
    }
  }

  &__content {
    position: absolute;
    align-items: flex-start;
    bottom: 0;
    left: 20px;
    right: 20px;
    padding: 0 0 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transform: translateY(calc(100% - 40px));
    transition: all $transition-duration ease;
    z-index: 5;

    &__title-container {
      position: absolute;
      bottom: calc(100% - 40px);
      padding: 0 0 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    h3,
    p {
      margin: 0px;
    }

    &__sub-title {
      opacity: 1;
      transition: opacity $transition-duration ease;
    }

    &__description-container {
      opacity: 0;
      transition: opacity $transition-duration ease;
      pointer-events: none;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__description {
      max-height: 25%;
      display: -webkit-box;
      max-width: 100%;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &__cta {
      &:after {
        background-image: url("/images/profiles_2018/right-arrow--white.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        content: "";
        display: inline-block;
        height: 8px;
        width: 13px;
        margin-left: 4px;
      }
    }
  }

  &:hover,
  &:focus {
    &::before {
      opacity: 0;
    }
    &::after {
      opacity: 1;
    }

    .ancillary-card__background {
      filter: blur(1px);
    }

    .ancillary-card__content {
      transform: translateY(0);
    }

    .ancillary-card__content__description-container {
      opacity: 1;
      pointer-events: unset;
    }

    .ancillary-card__content__sub-title {
      opacity: 0;
      pointer-events: none;
    }
  }
}
