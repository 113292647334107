.room-item-services {
  .columns {
    float: left;
    width: 50%;
  }

  .columns .amenities-list:last-child {
    border-bottom: 1px solid transparent;
  }

  .columns:last-child {
    .amenities-list {
      border-left: none;
    }
  }
}

.rtl .room-item-services {
  .columns {
    float: right;
  }
}

.amenities-list {
  padding: 15px;
  border: 1px solid $light-grey;
  border-top: none;

  h3, h5 {
    font-family: $primary-font;
    font-size: 14px;
    color: $theme-black;
    line-height: 1.4;
    margin: 0 0 5px;
  }

  ul {
    color: $theme-black;
    list-style-type: disc;
    padding-left: 15px;

    li {
      font-size: 12px;
    }
  }
}


@media only screen and (min-width : $screen-sm) {
  .room-item-services {
    .columns {
      border: none;
    }

    .amenities {
      margin-bottom: -1px;
    }

    .columns .amenities-list:last-child {
      border-bottom: none;
    }

    .amenities-list {
      border: none;
      float: left;
      width: 50%;
    }
  }
  .rtl .room-item-services {
    .amenities-list {
      float: right;
    }
  }
}
