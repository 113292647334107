@use "sass:math";

.confirmation {
  text-align: center;

  .date-triggers {
    border-bottom: 3px solid #999;
    overflow: hidden;
  }

  .date-trigger__date-month {
    display: flex;
    flex-direction: column;
  }

  .select-date {
    width: 50%;
    float: left;

    .date-trigger-stage {
      padding: 3px 0;
    }

    .date-trigger-day {
      background-color: #999;
      color: $white;
      display: block;
      padding: 2px 0;
    }

    .date-trigger > div {
      text-align: center;
      padding: 5px 0;
      span {
        border: none;
      }
    }

    .date-trigger-date {
      display: block;
      font-size: 38px;
      line-height: 1;
    }

    .date-trigger-month {
      font-size: $primary-font-size;
    }

    &:first-child {
      border-right: 2px solid $grey;
      margin-right: -2px;
    }
  }
}

.zh,
.zh_hant,
.jp {
  .confirmation {
    .date-trigger__date-month {
      flex-direction: column-reverse;
    }
  }
}

.confirmation {
  .divide-block {
    padding: 12px $page-padding;

    h1,
    p {
      margin: 0;
    }

    h1 {
      font-size: 16px;
      font-weight: $primary-font-weight;
      text-transform: uppercase;
    }
  }

  .hotel-details-property-title {
    margin: 0;
    text-align: left;

    a {
      color: $black;
      text-decoration: none;
      font-size: $primary-font-size;
    }
  }

  .hotel-details-property-address {
    color: $dark-grey;
    font-size: $primary-font-size;
    margin-top: 0;
    text-align: left;
  }

  .confirmation-contact {
    padding: 12px (140px + (2 * $page-padding)) 12px $page-padding;
    position: relative;
    text-align: left;

    .contact-icons {
      float: right;
      font-size: 0;
      height: 64px;
      margin-top: -60px;
      position: absolute;
      right: $page-padding;
      text-align: right;
      top: 50%;
      width: 140px;

      a {
        float: right;
        clear: right;
        color: $black;
        font-size: $secondary-font-size -1px;
        line-height: 1;
        text-align: center;
        text-decoration: none;
        width: 50%;
      }

      [class^="icon-"] {
        color: $black;
        display: block;
        font-size: 60px;
      }
    }
  }

  .confirmation-contact.facebook-messenger {
    margin-bottom: 40px;
    height: auto;
  }

  .mobile-multiple-cancel {
    padding-top: 10px;

    .cancel-reservation {
      padding-top: 5px;
      text-align: center;

      button {
        padding: 0;
        margin: 0;
        font-size: 15px;
      }

      p {
        font-size: 12px;
        font-style: italic;
        margin-top: 0;
      }
    }

    .cancellation-terms {
      border: solid 1px red;
      color: #878787;
      font-size: 12px;
      padding: 5px 10px;
    }
  }

  .your-booking {
    text-align: left;
    &,
    p,
    dd {
      font-size: $primary-font-size;
    }

    .row {
      padding: 0;
      border-bottom: none;

      .cancel-reservation {
        button {
          font-size: 13px;
          margin: 0;
          padding: 0;
        }

        p {
          font-size: 12px;
          font-style: italic;
          margin-top: 0;
        }
      }

      .cancellation-terms {
        clear: left;
        padding: 0 15px;
      }

      .guests {
        clear: left;
        padding: 0 15px 10px;

        .fieldset {
          margin: 0;
        }
      }

      .welcome-amenities {
        clear: left;
        padding: 0 15px 10px;

        .fieldset {
          margin: 0;
        }
      }
    }
  }
}
.rtl {
  .confirmation {
    .your-booking {
      text-align: right;
      [class*="col-"] {
        float: right;
      }

      .row {
        .cancellation-terms {
          clear: right;
        }

        .guests {
          float: left;
          width: 100%;
        }
      }
      dd:before {
        left: auto;
        right: -5px;
      }
    }
    .select-date {
      float: right;
    }
    .hotel-details-property-title {
      text-align: right;
    }
    .hotel-details-property-address {
      text-align: right;
    }
    .confirmation-contact {
      padding: 12px $page-padding 12px (140px + (2 * $page-padding));
      text-align: right;

      .contact-icons {
        float: left;
        right: auto;
        left: 15px;
      }
    }
  }
}

@media only screen and (min-width: $screen-sm) {
  .confirmation {
    border-bottom: 3px solid $theme-gray3;
    margin-top: -30px;
    margin-bottom: 40px;

    .divide-block {
      padding-top: 0;
      padding-bottom: 0;
      text-align: center;

      h1 {
        display: inline-block;
        font-family: $header-font;
        font-size: 21px;
        margin-bottom: 0;
        margin-right: 4px;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .email-sent-message {
      padding-bottom: 24px;
    }

    .thank-you-message {
      padding-top: 24px;

      p {
        display: inline-block;
        font-family: $header-font;
        font-size: 21px;
        text-transform: uppercase;
      }
    }

    .confirmation-summary {
      background-color: $light-grey;
      line-height: 30px;
      padding-top: 3px;
    }
  }
}

.confirmation-actions {
  .btn-link {
    line-height: 1.3;
    padding: 11px 0 11px 50px;
    position: relative;
    text-align: left;
  }

  li {
    clear: both;
    padding-top: 1px;
  }

  .icon {
    color: $primary-text-color;
    display: block;
    font-size: 40px;
    margin: 0 10px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
  }

  .see-all-reservations-link {
    display: block;
    line-height: 1.3;
    padding: 11px 0 11px 50px;
    position: relative;
    text-align: left;
  }
}

.rtl {
  .confirmation-actions {
    .btn-link {
      padding: 11px 50px 11px 0px;
      text-align: right;
    }

    .icon {
      margin: 0 0px 0 10px;
      top: 0;
      left: inherit;
      right: 0;
    }

    .see-all-reservations-link {
      padding: 11px 50px 11px 0px;
      text-align: right;
    }
  }
}

.reservation-number {
  font-size: 20px;
}

.cancellation-terms {
  p {
    font-size: $primary-font-size;
    margin: 5px 0;
  }

  p.same-day-heading {
    color: #333333;
  }
}

.facebook-messenger {
  margin-bottom: 10px;
  height: 30px;

  .fb-messengermessageus {
    float: left;
  }
}
.rtl {
  .facebook-messenger {
    .fb-messengermessageus {
      float: right;
    }
  }
}

.app-cta {
  border-top: 2px solid $light-grey;
  text-align: center;
  padding: 25px 0 0 0;

  img.app-logo {
    width: 62px;
  }

  .app-text-img {
    width: 100%;
    padding: 10px 0px;
  }

  .download-text {
    padding-bottom: 10px;
  }

  a {
    text-decoration: none;
  }

  .app-btn {
    width: 100%;
  }
}

.app-message {
  background-color: $lighter-grey;
}
@media only screen and (max-width: ($screen-sm - 1px)) {
  .app-cta {
    background-color: $lighter-grey;
    margin-top: 20px;
    border: none;

    img.app-logo {
      width: 100%;
    }

    .app-btn {
      padding-bottom: 30px;
    }

    .download-text {
      font-size: 20px;
    }
  }

  main .main-inner {
    padding-bottom: 0px;
  }

  .backup-padding {
    font-size: 0px;
    padding-bottom: 15px;
  }
}
@media screen and (min-width: $screen-sm) {
  .confirmation-personalize {
    input[type="text"]:not(.allow-placeholder),
    input[type="email"],
    input[type="password"],
    input[type="tel"],
    input[type="number"],
    input[type="search"],
    input[type="range"],
    textarea {
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
      &.empty {
        color: transparent;
      }

      &[disabled] {
        &::-webkit-input-placeholder {
          color: transparent;
        }
        &:-moz-placeholder {
          color: transparent;
        }
        &::-moz-placeholder {
          color: transparent;
        }
        &:-ms-input-placeholder {
          color: transparent;
        }
        &.empty {
          color: transparent;
        }
      }
    }
  }
  .rtl {
    .confirmation-personalize {
      [class*="col-"] {
        float: right;
      }
    }
  }
}

/* Confirmation Personalisation */
@media only screen and (max-width: ($screen-md - 1px)) {
  .confirmation-personalize .room-item {
    $image-width: 250px;

    .room-item-title {
      position: static;
      padding: 0;
    }

    .room-item-actions {
      border-top: solid 1px $light-grey;
      height: auto;
      padding: 5px 15px;
      width: 100%;
      margin: 0;

      .reservation-number {
        font-size: 16px;
      }
    }

    .room-item-info {
      padding-top: 6px;
      flex-direction: column;
    }

    .room-item-preview {
      height: auto;
    }

    .room-item-image {
      cursor: default;
      height: math.div($image-width, 16) * 9;
      width: $image-width;
    }

    .room-item-description {
      border-right: none;
      height: auto;
      margin-right: 0;
      padding-top: 0;
      max-width: 250px;
    }

    .dash-bullets {
      overflow: hidden;

      li {
        float: left;
        width: 50%;
      }
    }
  }
}

.confirmation-personalize {
  padding-bottom: 100px;
}
