.booking-messages {
  margin-bottom: 40px;

  .btn,
  button {
    @include theme-button;
    @include theme-button-secondary;
    border: none;
    text-transform: uppercase;
    width: 100%;
  }

  ul {
    border-bottom: 1px solid $grey;
    margin: 0;
    padding: 0;
  }

  li {
    padding: 10px;
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: $grey;
    margin-bottom: 10px;
    list-style-type: none;
    margin: 0;
  }

  .message-title {
    overflow: hidden;
    cursor: pointer;
    margin: 0;

    letter-spacing: 0.027em;
    padding: 0;
    text-align: inherit;
    text-align: match-parent;
    text-transform: none;

    &:hover,
    &:active {
      background: transparent !important;
      border: none !important;
      color: #000 !important;
    }

    strong {
      width: 80%;
      display: inline-block;
    }
    span {
      float: right;
      font-size: 20px;
    }
  }

  .booking-message__icon {
    display: none;
  }

  .message-title.pp {
    font-weight: 900;
    margin-top: 5px;
    cursor: default;
  }

  .message-body {
    padding: 10px 0 0;
    margin: 0;

    p {
      margin: 0;
    }
  }

  &--style-2 {
    .booking-message {
      background: #f5f5f5;
      border: none;
      margin: 0 0 10px;
      padding: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (min-width: $screen-sm) {
        margin-bottom: 15px;
        padding: 20px 40px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .message-title {
        background: transparent;
        font-family: $hn;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 1.33;
        letter-spacing: 3px;
        text-transform: uppercase;
      }

      .icon {
        display: none;
      }

      .booking-message__icon {
        display: flex;
        flex-direction: row;
        align-items: center;

        font-family: $hn;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 1.3;
        letter-spacing: 3px;
        text-transform: uppercase;

        &:after {
          content: "";
          display: block;
          height: 14px;
          width: 14px;
          margin-left: 6px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 10px;
        }

        &--arrow-up:after {
          background-image: url("/images/profiles_2018/collapse.svg");
        }
        &--arrow-down:after {
          background-image: url("/images/profiles_2018/caret.svg");
        }
      }

      .message-body {
        font-family: $garamond;
        font-size: 14px;
        line-height: 1.79;
        letter-spacing: 0.5px;

        p {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
          letter-spacing: inherit;
        }
      }
    }
  }
}

.rtl {
  .booking-messages {
    .message-title {
      span {
        float: left;
      }
    }
  }

  .booking-messages {
    .booking-message__icon {
      &:after {
        margin-right: 6px;
        margin-left: 0;
      }
    }
  }
}
