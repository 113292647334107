body {
  .selected-itinerary {
    color: #000;
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    margin: 0 -15px;
    border-bottom: solid 1px #d8d8d8;

    @media only screen and (min-width: $screen-sm) {
      border-top: solid 1px #d8d8d8;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      margin-right: 0;
      padding: 18px 0;
    }

    &__title {
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.5px;

      @media only screen and (min-width: $screen-sm) {
        margin-right: 10px;
        line-height: 1;
      }
    }

    &__dates-container {
      display: flex;
      flex-direction: row;
    }

    &__dates {
      font-style: italic;
      font-weight: normal;
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.5px;

      @media only screen and (min-width: $screen-sm) {
        line-height: 1;
      }
    }

    &__new-dates-button {
      background: none;
      border: none;
      text-decoration: underline;
      min-width: auto;
      max-width: none;
      width: auto;
      font-family: Helvetica Neue;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 1.12;
      text-align: center;
      letter-spacing: 3px;
      text-transform: uppercase;
      padding: 0;
      margin: 0 0 0 10px;
    }
  }
}
