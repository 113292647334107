.quick-links {
  color: #000;

  &__title {
    color: inherit;
    margin: 0 0 38px;
  }

  &__links {
    display: flex;
    flex-direction: column;
    margin: 0;
    text-align: left;

    @media screen and (min-width: $screen-sm) {
      flex-direction: row;
      margin-left: -10px;
      margin-right: -10px;
    }
  }
}

.quick-link {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  color: inherit;
  padding: 20px 20px 20px 0;
  display: flex;
  flex-direction: row;
  margin: 0 0 28px;
  text-decoration: none;

  &:hover,
  &:focus,
  z &:active {
    color: inherit !important;
    opacity: 1;
    background: #f5f5f5;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  }

  @media screen and (min-width: $screen-sm) {
    flex-direction: column;
    margin: 0 10px;
    width: 33.33%;
    max-width: 265px;
    padding: 25px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__icon {
    width: 72px;
    flex-basis: 72px;
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: $screen-sm) {
      width: auto;
      flex-basis: unset;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 0 0 25px;
    }

    img {
      width: 30px;
    }
  }

  &__content {
    flex-grow: 1;
    flex-shrink: 1;

    &__title {
      font-size: 10px !important;
      margin: 0 0 12px;

      &:after {
        background-image: url("/images/profiles_2018/right-arrow.svg");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: contain;
        content: "";
        display: inline-block;
        height: 8px;
        width: 13px;
        margin-left: 4px;
      }
    }

    &__description {
      font-family: "Monotype Garamond W05", Garamond, serif;
      font-size: 15px;
      font-style: italic;
      line-height: 1.66;
      letter-spacing: 0.5px;
    }
  }
}
