.flex-dates-promo-code-warning {
  background: #666;
  color: #fff;
  margin: 0;
  padding: 6px 20px;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 2.06;
  letter-spacing: 0.5px;

  &--is-error {
    background: #c51f1f;
  }

  @media only screen and (min-width: $screen-sm) {
    float: left;
    margin-bottom: 20px;
  }
}
