.fs-trip {
  // background-color: #000;
  // color: #fff;
  padding-top: 70px;

  .view--profile-edit__block {
    margin-bottom: 0;
  }

  &__text_area {
    margin-top: 30px;
    color: $black;
    width: 100%;
    font-size: 18px;
    // outline: none;
    background: $white;
    border: 1px solid $white;
    resize: none;
    min-height: 196px;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;

    &:focus {
      color: $black;
      background: $white;
      border-color: $white;
    }

    @media screen and (min-width: $breakpoints-md) {
      margin-top: 40px;
      min-height: 122px;
      padding-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      margin-top: 46px;
      min-height: 146px;
      padding-top: 22px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  &__submit {
    padding-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__adults {
    border-top: 1px solid rgba(0, 0, 0, 0.21);
  }

  &__children {
    border-top: 1px solid rgba(0, 0, 0, 0.21);
  }

  &__preferences {
    h3 {
      text-transform: uppercase;
      color: inherit;
      font-family: $hn;
      font-weight: 700;
      font-size: rem(16px);
    }

    p {
      margin: 10px 0 0;
    }
  }

  &__special-requests {
    border-top: 1px solid rgba(0, 0, 0, 0.21);
  }

  &__cancellation {
    padding-top: 40px;
    padding-bottom: 40px;
    border-top: 1px solid rgba(0, 0, 0, 0.21);
    position: relative;

    h3 {
      text-transform: uppercase;
      color: inherit;
      font-family: $hn;
      font-weight: 700;
      font-size: rem(16px);
      margin: 0 0 10px;
    }

    p {
      margin: 0;
      font-size: rem(15px);
    }

    &__actions {
      @media screen and (min-width: $breakpoints-md) {
        text-align: right;
        margin-left: auto;
      }
    }
  }

  &__quick-links {
    border-top: 1px solid rgba(0, 0, 0, 0.21);
    padding-top: 40px;
    padding-bottom: 40px;

    @media screen and (min-width: $breakpoints-md) {
      padding-bottom: 70px;
    }
  }
}

.fs-trip-header {
  margin: 0 0 40px;
  padding: 50px 0 0;

  @media screen and (min-width: $breakpoints-md) {
    padding-top: 10px;
  }

  @media screen and (min-width: $breakpoints-lg) {
    margin-bottom: 70px;
  }

  .container .row {
    position: relative;

    @media screen and (min-width: $breakpoints-lg) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__container {
    display: flex;
    flex-direction: row;

    @media screen and (min-width: $breakpoints-lg) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  &__property-container {
    margin-right: 90px;
  }
  &__property-name {
    text-align: start;
    font-size: rem(32px);
    font-family: $hn;
    font-weight: 250;
    letter-spacing: 5px;
    line-height: 1.4;
    margin: 0 0 20px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 15px;
    }
  }

  &__property-address {
    background-image: url("/images/profiles_2018/marker.svg");
    background-repeat: no-repeat;
    background-size: 14px 20px;
    background-position: 0 50%;
    font-family: $hn;
    font-weight: 700;
    font-size: rem(12px);
    text-transform: uppercase;
    padding: 0 0 0 30px;
    line-height: 20px;
    margin: 0 0 40px;
  }

  &__image {
    height: 398px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100vw;
    left: calc(-50vw + 50%);
    position: relative;

    @media screen and (min-width: $breakpoints-md) {
      height: 430px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      height: 150px;
      width: auto;
      left: auto !important;
      right: auto !important;
    }
  }

  &__contact-buttons {
    font-family: $hn;
    font-weight: 700;
    font-size: rem(10px);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 3px;

    display: flex;
    flex-direction: row;
    position: absolute;
    top: -105px;
    right: 7.5px;
    margin: 0;
    justify-content: flex-end;
    gap: 30px;

    @media screen and (min-width: $breakpoints-md) {
      top: -110px;
      right: 15px;
      gap: 40px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      position: static;
      margin-top: 36px;
      gap: 50px;
    }

    .contact-button {
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 40px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      color: $black;
      text-decoration: none;
      border: none;
      padding: 0;
      margin: 0;

      font-family: $hn;
      font-weight: 700;
      font-size: rem(10px);
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 3px;

      &__icon {
        height: 0;
        width: 100%;
        padding-top: 100%;
        background: $black;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        border-radius: 10000px;
        background-size: 100%;

        &--itinerary {
          background-image: url("/images/profiles_2018/itinerary---white.svg");
          background-size: 50%;
        }

        &--chat {
          background-image: url("/images/profiles_2018/chat.svg");
        }

        &--call {
          background-image: url("/images/profiles_2018/call.svg");
        }
      }

      span {
        display: none;
      }

      @media screen and (min-width: $breakpoints-md) {
        height: auto;
        width: 50px;

        span {
          display: inline-block;
          margin-top: 10px;
          white-space: nowrap;
        }
      }
    }
  }
}

.rtl {
  .fs-trip-header {
    &__contact-buttons {
      right: unset;
      left: 7.5px;
    }

    &__property-address {
      background-position: 100% 50%;
      padding-left: 0;
      padding-right: 30px;
    }

    &__image {
      left: unset;
      right: calc(-50vw + 50%);
    }
  }

  .fs-trip__details__arrival-time .fs-toggle-button--light {
    right: unset;
    left: 0;
  }

  .fs-trip__cancellation {
    &__actions {
      @media screen and (min-width: $breakpoints-md) {
        text-align: left;
        margin-right: auto;
      }
    }
  }
}

@media screen and (min-width: $breakpoints-lg) {
  .fs-trip-details-container {
    > .container > .row {
      display: flex;
      flex-direction: row-reverse;

      > .col-lg-9,
      > .col-lg-2 {
        flex-shrink: 0;
        flex-grow: 0;
      }
    }

    &.alternative-auth {
      padding-bottom: 200px;
    }
  }
}

.fs-trip__details {
  padding-bottom: 40px;

  h2 {
    font-family: $hn;
    font-weight: 700;
    font-size: rem(16px);
    letter-spacing: 4px;
    line-height: 1.3125;
    color: $black;
    margin: 0 0 40px;
    text-align: initial;
  }

  &__dates {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;

    @media only screen and (min-width: ($breakpoints-sm)) {
      padding-bottom: 40px;
    }

    @media screen and (min-width: $breakpoints-md) {
      padding: 0;
    }

    &--checkin {
      width: 50%;
      border-right: 1px solid #7d7d7d;
      order: 1;

      @media screen and (min-width: $breakpoints-md) {
        width: auto;
        margin-right: 50px;
        padding-right: 50px;
      }
    }

    &--checkout {
      order: 2;
    }
  }

  &__arrival-time {
    order: 4;
    min-height: 40px;

    @media screen and (min-width: $breakpoints-md) {
      min-height: 10px;
    }

    .fs-toggle-block__row {
      position: relative;
    }

    .fs-toggle-button--light {
      position: absolute;
      top: -40px;
      right: 0;
    }

    a.fs-toggle-button--light {
      text-decoration: none;
      text-align: center;
    }

    .fs-toggle-block__container {
      overflow: hidden;
    }

    .fs-toggle-block__container__inner {
      margin-bottom: 40px;
    }
  }

  &__date-label {
    text-transform: uppercase;
    font-family: $hn;
    font-weight: 700;
    font-size: rem(12px);
    color: $black;
    letter-spacing: 3px;
    line-height: 1.5;
  }

  &__date {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    font-family: $hn;
    font-weight: 400;
    font-size: rem(14px);
    color: $black;
    letter-spacing: 0.69px;
    line-height: 1.28571;
    white-space: nowrap;
  }

  &__checkin-action {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__change-dates {
    position: absolute;
    bottom: -30px;

    @media only screen and (min-width: ($breakpoints-sm)) {
      bottom: -20px;
    }

    @media screen and (min-width: $breakpoints-md) {
      position: initial;
      margin: auto 0 auto auto;
    }

    > .CTA--underlined--light {
      height: 39px;
      line-height: 1.5;
      letter-spacing: 3px;

      @media screen and (min-width: $breakpoints-md) {
        height: auto;
      }
    }

    > .fs-toggle-button--light {
      text-decoration: none;
    }

    .fs-toggle-block__row {
      position: static;
    }

    .fs-toggle-block__label {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media screen and (min-width: $breakpoints-md) {
    &__dates--checkin {
      margin-bottom: 0;
    }
    &__dates--checkout {
      order: 3;
    }
    &__arrival-time {
      order: 2;
    }
    &__change-dates {
      order: 4;
    }
  }

  @media only screen and (max-width: ($breakpoints-sm - 1px)) {
    padding-bottom: 60px;

    &__arrival-time {
      .fs-toggle-button--light {
        top: 25px;
        left: 0;
        right: unset;
      }
    }
  }
}
.ar.rtl {
  .fs-trip__details {
    .fs-toggle-block__container__inner.fs-toggle-block__container__inner--dark {
      margin-top: 45px;
    }

    @media only screen and (max-width: ($breakpoints-sm - 1px)) {
      &__arrival-time {
        .fs-toggle-button--light {
          left: unset;
          right: 0;
        }
      }
    }
  }
}

.fs-trip__arrival-details {
  border-top: 1px solid rgba(0, 0, 0, 0.21);

  &__container {
    margin: 40px 0;
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;

    @media screen and (min-width: $breakpoints-lg) {
      padding: 40px;
    }
  }

  &__title {
    font-family: $hn;
    font-weight: 300;
    font-size: rem(22px);
    letter-spacing: 0.85px;
    line-height: 1.363636;
    color: $black;
    text-transform: none;
  }

  &__subtitle {
    margin: 20px 0 0 0;
    text-align: center;

    @media screen and (min-width: $breakpoints-lg) {
      margin: 10px 0 0 0;
    }
  }

  &__eta-form {
    margin-top: 20px;
    width: 100%;
    max-width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: $breakpoints-lg) {
      margin-top: 30px;
    }

    .arrival-time {
      width: 100%;
    }

    .formElement-field.ty-b2.mask {
      padding: 10px 16px;
    }

    .Dropdown {
      @media screen and (min-width: $breakpoints-lg) {
        padding-bottom: 20px;
      }
    }

    .Dropdown::after {
      right: 16px;
    }
  }

  &__occasion-form {
    margin-top: 20px;
    width: 100%;

    @media screen and (min-width: $breakpoints-lg) {
      margin-top: 40px;
    }

    .fs-trip__arrival-details__btns {
      margin-top: 0;
      padding-top: 30px;
      border-top: 1px solid #d8d8d8;
    }

    @media screen and (min-width: $breakpoints-lg) {
      .fs-trip__arrival-details__btns {
        padding-top: 0px;
        border-top: none;
      }
    }

    &__checkboxes {
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (min-width: $breakpoints-lg) {
        flex-direction: row;
        justify-content: space-between;
      }

      &__col {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__header {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }

      .fsp-input--checkbox {
        padding: 2px 0;

        label {
          padding-left: 35px;
        }

        div {
          font-size: 15px;
        }
      }
    }

    &__textarea {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      margin-bottom: 30px;
      text-align: start;

      label {
        font-family: $hn;
      }

      textarea {
        margin: 10px 0 0 0;
        padding: 10px 16px;
        height: 104px;
        width: 100%;
        border: none;
        border-bottom: 1px solid black;
        background: #ffffff;
        font-size: 15px;
        line-height: 25px;
        letter-spacing: 0.5px;
      }
    }
  }

  &__guest-form {
    width: 100%;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: $breakpoints-lg) {
      padding-top: 40px;
    }

    &__holder-name {
      margin-top: 10px;
    }

    &__guest-fields {
      display: flex;
      flex-direction: column;

      @media screen and (min-width: $breakpoints-lg) {
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
      }

      .TextInput,
      .Dropdown {
        width: 100%;
        padding: 0;

        input {
          padding: 10px 16px;

          &:focus {
            background: white;
          }
        }

        .formElement-field {
          padding: 10px 16px;
        }
      }
    }

    .TextInput {
      padding: 0;
    }

    .TextInput-field {
      background: white;
    }
  }

  &__btns {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  &__page-num {
    margin-top: 20px;
    font-family: $hn;
    font-size: rem(12px);
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 3px;
  }

  &__cards-title {
    margin-top: 40px;
  }

  &__cards {
    padding: 30px 20px 40px;
    margin-inline: -20px;
    display: flex;
    gap: 20px;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  @media only screen and (min-width: 750px) {
    &__cards {
      margin-inline: 0px;
      padding-inline: 0px;
    }
  }

  &__cards::-webkit-scrollbar {
    display: none;
  }

  &__card {
    width: 209px;
    padding: 50px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    text-align: center;
    background: #f5f5f5;
    text-transform: uppercase;

    &__icon {
      width: 25px;
      height: 25px;
      margin-inline: auto;
    }

    &__title {
      margin-top: 20px;
      font-family: $hn;
      font-size: 10px;
      font-weight: 700;
      line-height: 15px;
      letter-spacing: 3px;
    }

    &__detail {
      margin-top: 5px;
      font-family: $hn;
      font-size: 10px;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 3px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__add-btn {
      margin-top: auto;
    }

    &__edit-btn {
      margin-top: 30px;
    }
  }
}

.fs-trip__no-changes {
  border-top: 1px solid rgba(0, 0, 0, 0.21);
  padding: 50px 0;
  font-size: 1rem;
  color: #7d7d7d;
  letter-spacing: 0.5px;
  line-height: 1.4;
}

.fs-trip__transport {
  border-top: 1px solid rgba(0, 0, 0, 0.21);
  padding: 50px 0 0;

  h2 {
    font-family: $hn;
    font-weight: 700;
    font-size: rem(16px);
    letter-spacing: 4px;
    line-height: 1.3125;
    color: $black;
    margin: 0 0 10px;
    text-align: initial;
  }

  p {
    margin: 0;
  }

  &__copy,
  &__actions {
    margin-bottom: 50px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
  }

  .amenity-requests {
    margin: 30px 0 0;
    padding: 0 0 0 30px;

    li {
      margin: 0 0 10px;
    }
  }

  .amenity-cost {
    font-family: $hn;
    font-size: rem(10px);
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.3;
    letter-spacing: 3px;
  }
}

.fs-trip__guests {
  border-top: 1px solid rgba(0, 0, 0, 0.21);
  border-bottom: 1px solid rgba(0, 0, 0, 0.21);
  color: $black;
  padding: 40px 0 40px;
  margin: 0 0 40px;

  @media screen and (min-width: $breakpoints-md) {
    margin-bottom: 40px;
  }

  h2 {
    font-family: $hn;
    font-weight: 700;
    font-size: rem(16px);
    letter-spacing: 4px;
    line-height: 1.3125;
    color: inherit;
    margin: 0 0 30px;
    text-align: initial;

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 40px;
    }
  }

  &__reservation-holder {
    font-family: $hn;
    font-weight: 700;
    font-size: rem(10px);
    color: #7d7d7d;
    letter-spacing: 3px;
    line-height: 1.3;
    text-transform: uppercase;
    margin: 0 0 20px;

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 5px;
    }
  }

  &__reservation-holder-name {
    font-family: $hn;
    font-weight: 250;
    font-size: rem(32px);
    letter-spacing: 1px;
    line-height: 1.25;
    margin: 0 0 6px;

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 10px;
    }
  }

  &__reservation-holder-email {
    font-size: 18px;
    color: #000000;
    letter-spacing: 0.5px;
    line-height: 27px;
    margin: 0 0 30px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 20px;
    }
  }

  &__reservation-message {
    font-size: rem(18px);
    color: #7d7d7d;
    letter-spacing: 0.5px;
    line-height: 1.4;
    margin: 0 0 20px;
  }

  &__guest {
    padding: 20px 0 0;
    min-height: 40px;

    &:first-child {
      padding: 0;
    }

    &__label {
      font-family: $hn;
      font-weight: 700;
      font-size: rem(12px);
      letter-spacing: 3px;
      line-height: 1.3;
      text-transform: uppercase;
      min-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &__guest-name {
      margin: 10px 0 60px;

      &__label {
        font-family: $hn;
        font-weight: 700;
        font-size: rem(10px);
        color: #7d7d7d;
        letter-spacing: 3px;
        line-height: 1.3;
        margin: 0 0 15px;
        text-transform: uppercase;
      }

      &__value {
        font-family: $hn;
        font-weight: 250;
        font-size: rem(22px);
        letter-spacing: 1px;
        line-height: 1.3;
        margin: 0;
      }
    }
  }
}

.fs-trip__highlights {
  margin: 0 0 75px;

  dl {
    margin: 0 0 6px;
    padding: 0;
    font-size: rem(12px);
    font-family: $hn;
    letter-spacing: 3px;
    line-height: 1.8;
    text-transform: uppercase;
  }

  dt,
  dd {
    margin: 0;
    padding: 0;
  }

  dt {
    font-family: $hn;
    font-weight: 700;
  }

  dd + dt {
    margin-top: 20px;
  }
}
