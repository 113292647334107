.view--profile-membership {
  .layout--profile-fullscreen__top-bar {
    .col-sm-12 {
      @media screen and (min-width: $breakpoints-md) {
        height: 130px;
      }

      @media screen and (min-width: $breakpoints-lg) {
        height: 178px;
      }
    }
  }

  .layout--profile-fullscreen__body {
    .fs-membership-benefits {
      color: #fff;
      background: #000;
      padding: 0 15px 50px 15px;

      @media screen and (min-width: $breakpoints-lg) {
        padding: 0 255px 50px 255px;
      }

      .fs-membership-benefits-heading {
        font-size: rem(32px);
        font-family: $hn;
        font-weight: 250;
        letter-spacing: 1.16px;
        line-height: 1.4;
        margin-top: 0px;
        text-align: center;
        text-transform: uppercase;
        padding: 80px 0 94px;
      }

      .fs-membership-benefit-block {
        border-top: 1px solid;
        padding: 32px 0 48px;

        .fs-membership-benefit__row {
          .fs-membership-benefit__label {
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            margin: 0 0 40px;
            height: 40px;

            .fs-membership-benefit-icon {
              background: none;
              background-position: 50% 50%;
              background-size: contain;
              background-repeat: no-repeat;
              height: 32px;
              width: 32px;
              margin-right: 20px;
              float: left;
            }

            &-text {
              font-family: $hn;
              font-size: rem(12px);
              font-weight: 900;
              text-transform: uppercase;
              padding: 10px 0 0 30px;
              line-height: 18px;
              width: 95%;
            }

            .fs-membership-benefit-toggle {
              bottom: 30px;
              margin-top: auto;
              position: relative;
              float: right;
              text-align: right;

              .fs-membership-benefit-toggle-button {
                font-size: 24px;
                font-weight: 700;
                background-color: black;
                border: none;
                color: white;

                &.icon-less_sign::before {
                  content: "\2013";
                }
                &.icon-plus_sign::before {
                  content: "+";
                }
              }
            }
          }

          .fs-membership-benefit-items {
            font-size: rem(18px);
            font-style: normal;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0.5px;
            padding-left: 30px;
          }

          &.fs-member-benefits--discounts {
            .fs-membership-benefit-icon {
              background-image: url("/images/profiles_2018/gift-box.svg");
            }
          }
          &.fs-member-benefits--vouchers {
            .fs-membership-benefit-icon {
              background-image: url("/images/profiles_2018/offers.svg");
              filter: invert(100%);
            }
          }
        }
      }
    }
  }
}
