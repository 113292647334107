// Example Markup:
// <div class="form-input">
//   <label class="form-input__label"></label>
//   <div class="form-input__wrap">
//     <input>
//     <label class="form-input__placeholder"></label>
//     <span class="form-input__error--icon"></span>
//   </div>
//   <div class="form-input__error"></div>
// </div>

$form-input-background: $white;
$form-input-color: $new-dark-grey;
$form-input-height: 47px;
$form-input-padding: 14px;
$form-input-margin: 8px;
$form-input-font-size: 15px;
$form-input-label-height: 22px;
$form-input-placeholder-color: $new-medium-grey;
$form-input-autocomplete-shadow: 0 0 0 $form-input-height $form-input-background
  inset;

$form-input-border-radius: 0;
$form-input-border-width: 1px;
$form-input-border-color: $new-light-grey;
$form-input-border-style: $form-input-border-width solid
  $form-input-border-color;

$form-input-focus-color: $new-darker-grey;
$form-input-focus-background: #fafafa;
$form-input-focus-border-color: $new-medium-grey;
$form-input-focus-shadow-color: rgba($new-darker-grey, 0.5);
$form-input-focus-shadow-blur: 10px;
$form-input-focus-shadow: 0 0 $form-input-focus-shadow-blur
  $form-input-focus-shadow-color;
$form-input-focus-autocomplete-shadow: 0 0 0 $form-input-height
  $form-input-focus-background inset;

$form-input-icon-size: 25px;
$form-input-icon-color: $form-input-focus-border-color;
$form-input-error-color: $new-error-color;

$form-input-tel-width: 80px;
$form-input-tel-spacing: $secondary-padding;

$form-input-link-color: $accent-color;

$form-input-icon-indent: 50px;

$form-input-switch-size: $form-input-label-height;
$form-input-switch-spacing: 2px;
$form-input-switch-knob-size: (
  $form-input-switch-size - ($form-input-switch-spacing * 3)
);
$form-input-switch-default-color: $new-medium-grey;
$form-input-switch-active-color: $accent-color;
$form-input-switch-transition: background 0.1s linear 0.1s, left 0.1s linear 0s;

$form-input-checkbox-size: 27px;
$form-input-check-active-color: $accent-color;
$form-input-check-wrapped-active-color: $new-medium-grey;
$form-input-check-disabled-color: $new-light-grey;

.form-input {
  clear: both;
  display: block;
  position: relative;
  margin: 0 auto $form-input-margin;

  line-height: $form-input-height;
  min-height: $form-input-height;
  letter-spacing: 0.05em;

  input,
  select,
  textarea {
    margin: 0;
    padding: 0 $form-input-padding;
    border: $form-input-border-style;
    line-height: $form-input-height;
    height: $form-input-height;

    font-size: $form-input-font-size;
    background-color: $form-input-background;
    color: $form-input-color;
    border-radius: $form-input-border-radius;
    text-indent: 0;

    // background image basics
    // e.g. credit card icon
    background-position: $form-input-padding 50%;
    background-repeat: no-repeat;

    &[disabled] {
      background-color: $new-lighter-grey;
      color: $form-input-color;
      // overrides for iOS
      -webkit-text-fill-color: $form-input-color;
      opacity: 1;
    }

    &:focus {
      background-color: $form-input-focus-background;
      color: $form-input-focus-color;

      background-position: $form-input-padding 50%;
      background-repeat: no-repeat;

      border-color: $form-input-focus-border-color;

      -webkit-box-shadow: $form-input-focus-shadow;
      box-shadow: $form-input-focus-shadow;
    }

    &::-webkit-input-placeholder {
      color: $form-input-placeholder-color;
    }
    &:-moz-placeholder {
      color: $form-input-placeholder-color;
    }
    &::-moz-placeholder {
      color: $form-input-placeholder-color;
    }
    &:-ms-input-placeholder {
      color: $form-input-placeholder-color;
    }
  }

  &.has-error {
    input,
    select,
    textarea,
    .selectize-input,
    .fs-select-mask {
      border-color: $form-input-error-color !important;
    }
  }

  &.has-icon {
    input {
      text-indent: $form-input-icon-indent;
    }
  }

  // dirty override to prevent password
  // managers from fighting for style dominance
  &.form-input--password input,
  input[type="password"],
  .password,
  .password-text {
    background-position: 200% 200% !important;
  }

  textarea {
    min-height: ($form-input-height * 3);
    max-width: 100%;
    height: auto;
    line-height: normal;
    padding-top: 12px;
  }

  // chrome autofill overrides
  :-webkit-autofill {
    -webkit-box-shadow: $form-input-autocomplete-shadow;
    -webkit-text-fill-color: $form-input-color;

    &:focus {
      -webkit-box-shadow: $form-input-focus-autocomplete-shadow,
        $form-input-focus-shadow;
      -webkit-text-fill-color: $form-input-focus-color;
    }

    // hide placeholder when autofilling
    & ~ .form-input__placeholder {
      display: none;
    }
  }

  // IE overrides
  ::-ms-clear,
  ::-ms-reveal {
    display: none;
  }

  // country tel number list
  .country-list {
    line-height: normal;
    max-width: 330px;
  }

  // autocomplete overrides
  .fs-autocomplete {
    .autocomplete-input-wrapper {
      .icon {
        line-height: inherit;
      }
      .autocomplete-input {
        height: $form-input-height;

        border-radius: $form-input-border-radius;
        border: $form-input-border-style;

        &:focus {
          border-color: $form-input-focus-border-color;
        }
      }
    }
    .autocomplete-list {
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;

      box-shadow: 0 $form-input-focus-shadow-blur $form-input-focus-shadow-blur
        $form-input-focus-shadow-color;
      border-color: $form-input-focus-border-color;

      z-index: $z_autocompleteList;

      ul {
        max-height: ($form-input-height * 5);
      }
    }
    &.is-open {
      .autocomplete-input {
        background-color: $form-input-focus-background;
        border-color: $form-input-focus-border-color;
        color: $form-input-focus-color;

        -webkit-box-shadow: $form-input-focus-shadow;
        box-shadow: $form-input-focus-shadow;
      }
    }
  }

  // dirty override for selectize
  .selectize-control {
    height: $form-input-height;

    &.single {
      .selectize-input.input-active {
        background-color: $form-input-focus-background;
        color: $form-input-focus-color;
      }

      // arrow override
      .selectize-input:after {
        @extend %form-input__arrow;
        @extend %form-input__arrow--down;
      }
      .selectize-input.dropdown-active:after {
        @extend %form-input__arrow--up;
        color: $form-input-focus-border-color;
      }
    }
  }

  .selectize-input {
    padding: 0 $form-input-padding;
    border: $form-input-border-style;

    line-height: $form-input-height;
    height: $form-input-height;

    font-size: $form-input-font-size;
    color: $form-input-color;

    border-radius: $form-input-border-radius;
    -webkit-box-shadow: none;
    box-shadow: none;

    input {
      font-size: $form-input-font-size;
      color: $form-input-color;

      &:-webkit-autofill {
        // arrrrrgh !important override...
        -webkit-box-shadow: $form-input-autocomplete-shadow !important;
      }
    }

    & > .item {
      display: block;
      padding-right: ($form-input-padding * 3);
      padding-left: $form-input-padding;
      width: 100%;

      position: absolute;
      left: $form-input-border-width;
      top: $form-input-border-width;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.focus {
      box-shadow: none;
    }

    &.input-active {
      background-color: $form-input-focus-background;
      border-color: $form-input-focus-border-color;
      color: $form-input-focus-color;

      -webkit-box-shadow: $form-input-focus-shadow;
      box-shadow: $form-input-focus-shadow;

      input {
        // arrrrrgh !important override...
        -webkit-box-shadow: $form-input-focus-autocomplete-shadow !important;
      }
    }

    &.dropdown-active {
      border-radius: $form-input-border-radius $form-input-border-radius 0 0;
    }
  }

  .selectize-dropdown {
    box-shadow: 0 $form-input-focus-shadow-blur $form-input-focus-shadow-blur
      $form-input-focus-shadow-color;
    border: $form-input-border-style;
    border-color: $form-input-focus-border-color;

    .active {
      background-color: $form-input-focus-background;
      color: $form-input-focus-color;
    }
  }

  .selectize-dropdown-content {
    [data-selectable],
    .optgroup-header {
      padding-right: $form-input-padding;
      padding-left: $form-input-padding;
    }
  }

  // dirty override for the select mask
  .fs-select-mask {
    border: $form-input-border-style;
    border-radius: $form-input-border-radius;

    select {
      border: none;
    }

    .icon {
      margin-right: ($form-input-padding - 3);
      margin-left: ($form-input-padding - 3);
    }
  }

  // change search override because ugh
  &.change-search {
    background: none;
  }

  // clearfix
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  // right-to-left language adjustments
  .rtl & {
    .selectize-input > .item {
      padding-left: ($form-input-padding * 3);
      padding-right: $form-input-padding;
      left: auto;
    }
  }
}

// apply input styling to outer wrapper
.form-input--input {
  padding: 0 $form-input-padding;
  border: $form-input-border-style;

  font-size: $form-input-font-size;
  background-color: $form-input-background;
  color: $form-input-color;
  border-radius: $form-input-border-radius;

  .form-input__wrap {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.de {
  .form-input--input {
    padding: 0 4px;
  }
}

.form-input--inline {
  display: inline-block;
  vertical-align: middle;
}

.form-input--multi-select {
  // each selectize control will probably need a set width
  .selectize-control {
    display: inline-block;
    vertical-align: middle;
  }
}

.form-input--cc {
  input {
    padding-left: ($form-input-padding * 4);
  }
}

.form-input--checkbox {
  input {
    display: none;
  }

  label {
    display: block;
    font-size: $form-input-font-size;
  }

  .form-input__wrap {
    padding-right: ($form-input-checkbox-size + $form-input-margin);
  }

  .rtl & {
    .form-input__wrap {
      padding-left: ($form-input-checkbox-size + $form-input-margin);
    }
  }
}

.form-input--checkbox--inline {
  display: inline-block;
  line-height: $form-input-checkbox-size;
  min-height: initial;
  height: auto;
  margin-bottom: 0;

  .form-input__wrap {
    height: auto;
  }
}

.form-input--checkbox__box {
  display: block;

  position: absolute;
  margin: auto;
  bottom: 0px;
  right: 0px;
  top: 0px;
  border: $form-input-border-style;
  width: $form-input-checkbox-size;
  height: $form-input-checkbox-size;
  line-height: ($form-input-checkbox-size - 2);

  background-color: $form-input-background;
  color: $form-input-check-active-color;
  border-radius: 3px;

  font-size: 31px;
  text-indent: -3px;

  &:after {
    content: "";
    font-family: "fs-rbf-font";
  }

  input:checked + & {
    border-color: $form-input-check-active-color;

    &:after {
      content: "3";
    }
  }

  // muted when wrapped
  // consider a refactor to separate variant
  .form-input--input &,
  .form-input--input input:checked + & {
    border-color: $form-input-border-color;
    color: $form-input-check-wrapped-active-color;
  }

  // disabled styles
  input[disabled] + & {
    border-color: $form-input-check-disabled-color !important;
    color: $form-input-check-disabled-color !important;
    cursor: not-allowed;
  }

  .rtl & {
    right: auto;
    left: 0;
  }
}

.form-input--tel {
  .form-input__placeholder {
    margin-left: ($form-input-tel-width + $form-input-tel-spacing);
    width: 59%;
  }

  .intl-tel-input {
    padding-left: ($form-input-tel-width + $form-input-tel-spacing);
    width: 100%;

    // override the override
    input[type="text"],
    input[type="tel"] {
      padding: 0 $form-input-padding;
    }

    .flag-dropdown:hover .selected-flag,
    .flag-container:hover .selected-flag {
      background: none;
    }

    .selected-flag .iti-arrow {
      @extend %form-input__arrow;

      &:before {
        @extend %form-input__arrow--down;
      }
    }
  }
  .flag-container {
    width: $form-input-tel-width;
    border: $form-input-border-style;
    background: $form-input-background;
    border-radius: $form-input-border-radius;
    left: 0;

    &:hover,
    &:active {
      background-color: $form-input-focus-background;
      border-color: $form-input-focus-border-color;
      color: $form-input-focus-color;

      -webkit-box-shadow: $form-input-focus-shadow;
      box-shadow: $form-input-focus-shadow;
    }
  }
  .selected-flag {
    width: $form-input-tel-width;
    padding-left: $form-input-padding;
  }
  .country-list {
    -webkit-box-shadow: $form-input-focus-shadow;
    box-shadow: $form-input-focus-shadow;
  }

  &.has-error {
    .flag-dropdown,
    .flag-container {
      border-color: $form-input-error-color;
    }
  }

  .rtl & {
    .form-input__placeholder {
      margin-right: ($form-input-tel-width + $form-input-tel-spacing);
      margin-left: 0;
    }
    .intl-tel-input {
      padding-right: ($form-input-tel-width + $form-input-tel-spacing);
      padding-left: 0;
    }
    .flag-dropdown,
    .flag-container {
      left: auto;
      right: 0;
    }
  }
}

.form-input--btn {
  display: block;

  margin-top: $form-input-margin;
  margin-left: auto;
  margin-right: auto;

  padding: 0 !important;
  font-size: 1em;

  line-height: $form-input-height;
  height: $form-input-height;

  .de {
    .form-input--btn {
      height: auto;
    }
  }
}
.fr {
  .form-input--btn {
    display: block;

    margin-top: $form-input-margin;
    margin-left: auto;
    margin-right: auto;

    padding: 0 !important;
    font-size: 1em;

    line-height: $form-input-height;
    height: auto;
  }
}

.form-input--spacer {
  padding: 0 0 ($form-input-height * 0.5);
  margin: 0;
  height: 0;
  outline: none;
  border: none;
}

%form-input--check {
  line-height: $form-input-switch-size;
  min-height: initial;
  height: auto;
}

.form-input--check--inline {
  @extend %form-input--check;

  .form-input__label {
    display: inline;
  }

  .form-input__switch {
    vertical-align: middle;
    top: -2px;
  }
}

.form-input--check--right {
  @extend %form-input--check;

  .form-input__label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .form-input__switch {
    margin-left: $form-input-margin;
    float: right;
  }

  .rtl & {
    .form-input__switch {
      margin-right: $form-input-margin;
      margin-left: auto;
      float: left;
    }
  }
}

// internal wrapper for manual placeholders etc
// it constrains the positioning context so that
// extra content above or below the form field
// (e.g. errors, labels) don't mess up the aligmment
.form-input__wrap {
  position: relative;
  height: $form-input-height;
}

.form-input__wrap--password {
  // second input field should be positioned
  // behind the first, to prevent rendering
  // jitter on show/hide
  input + input {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.form-input__label,
.form-input__label--mid {
  display: block;
  padding: 0 $form-input-border-width;
  line-height: $form-input-label-height;
}

.form-input__label--mid {
  text-align: center;
}

.form-input__error {
  display: none;
  padding: 0 $form-input-margin;
  line-height: $form-input-label-height;
  background-color: $form-input-error-color;
  color: $form-input-background;
  font-size: 0.9em;

  p {
    margin: 0;
    padding: 0;
    color: $white;
  }

  .has-error & {
    display: block;
  }
}

%form-input__icon {
  display: inline-block;
  position: absolute;
  text-align: center;

  width: $form-input-icon-size;
  height: $form-input-icon-size;
  line-height: $form-input-icon-size;

  right: $form-input-padding;
  top: (($form-input-height - $form-input-icon-size) * 0.5);

  vertical-align: middle;

  font-size: 18px;
  color: $form-input-icon-color;

  border-radius: 50%;

  .rtl & {
    left: $form-input-padding;
  }
}

// multiple icons
.form-input__icon-wrap {
  @extend %form-input__icon;
  display: block;
  width: auto;

  .form-input__icon,
  .form-input__icon--bg,
  .form-input__icon--password,
  .form-input__icon--error {
    position: static;
  }
}

.form-input__icon {
  @extend %form-input__icon;
}

.form-input__icon--bg {
  @extend %form-input__icon;

  background-color: $form-input-icon-color;
  color: $form-input-background;
}

.form-input__icon--password {
  @extend %form-input__icon;

  font-size: 32px;
}

.form-input__icon--error {
  @extend %form-input__icon;
  display: none;

  background-color: $form-input-error-color;
  color: $form-input-background;

  .has-error & {
    display: inline-block;
  }
}

%form-input__arrow {
  @extend %icon;
  @extend %form-input__icon;

  width: 1em;
  // margin reset
  margin: 0;
  // override border triangle
  border: none;
}
%form-input__arrow--down {
  @extend %icon-arrow-down;
  // minor alignment adjustment
  margin-top: 1px;
  // override border triangle
  border: none;
}
%form-input__arrow--up {
  @extend %icon-arrow-up;
  // margin reset
  margin-top: 0;
  // override border triangle
  border: none;
}

%form-input__placeholder {
  display: block;

  max-width: 92%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: $form-input-font-size;
  color: $form-input-placeholder-color;
}

.form-input__placeholder {
  @extend %form-input__placeholder;

  position: absolute;
  bottom: ($form-input-border-width * -1);
  left: ($form-input-padding + $form-input-border-width);
  pointer-events: none;

  // basic show/hide logic,
  // or feel free to roll-your-own
  .has-input & {
    display: none;
  }

  // leave space for the telephone dropdown
  .telephone-row & {
    left: 49px;
    z-index: 1;
  }

  .rtl & {
    right: ($form-input-padding + $form-input-border-width);
    left: auto;
  }

  .rtl .telephone-row & {
    right: 49px;
  }
}

// static replacement for input text
.form-input__static {
  @extend %form-input__placeholder;

  padding: $form-input-border-width;
  margin: 0 $form-input-padding;
}

.form-input__placeholder__alt {
  color: $new-medium-grey;
  font-size: 0.9em;

  text-transform: lowercase;
}

.form-input__placeholder--link {
  display: block;
  float: right;

  margin-right: $form-input-padding;
  padding: $form-input-border-width;

  font-size: $form-input-font-size;
  color: $form-input-link-color;

  text-decoration: none;

  .rtl & {
    margin-left: $form-input-border-width;
    margin-right: 0;
    float: left;
  }
}

// ideally a label that triggers a hidden input
.form-input__switch {
  position: relative;
  display: inline-block;
  height: $form-input-switch-size;
  width: ($form-input-switch-size * 2);
  border: $form-input-border-style;
  background: $form-input-background;

  input[type="checkbox"] {
    position: absolute;
    visibility: hidden;
  }

  &:active {
    border-color: $form-input-focus-border-color;
  }

  &.is-on {
    border-color: $form-input-switch-active-color;
  }

  .has-error & {
    border-color: $form-input-error-color;
  }
}

.form-input__switch__knob {
  -webkit-transition: $form-input-switch-transition;
  transition: $form-input-switch-transition;
  position: absolute;
  display: block;
  height: $form-input-switch-knob-size;
  width: $form-input-switch-knob-size;
  left: $form-input-switch-spacing;
  top: $form-input-switch-spacing;

  background-color: $form-input-switch-default-color;

  .is-on & {
    background-color: $form-input-switch-active-color;
    left: ($form-input-switch-knob-size + $form-input-switch-spacing * 4);
  }

  .has-error & {
    background-color: $form-input-error-color;
  }
}

.form-input__link {
  color: $form-input-link-color;
  text-decoration: none;
}

// mobile only
@media screen and (max-width: $screen-sm-max) {
}

// desktop
@media screen and (min-width: $screen-sm) {
  .form-input__label,
  .form-input__link {
    font-size: 0.9em;
  }

  .form-input__label {
    // no inception :)
    .form-input__link {
      font-size: 1;
    }
  }
}
