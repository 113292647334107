$intouch-background: $black;
$intouch-color: $white;
$intouch-border-color: $new-dark-grey;
$intouch-map-height: 194px;
$intouch-map-big-height: 512px;

.my-stay__intouch {
  padding: 100px 30px 30px;
  background-color: $intouch-background;
  height: 100%;

  h2 {
    font-size: 24px;
    letter-spacing: 4px;
  }

  p {
    color: $intouch-color;
  }

  a {
    color: $intouch-color;
  }

  .intouch__subheader {
    text-transform: uppercase;
    text-align: center;
    font-family: $my-stay-header-font;
    letter-spacing: 3px;
    font-size: 11px;
    line-height: 60px;
    color: $intouch-color;
  }

  h2.intouch__header {
    text-align: center;
    color: $intouch-color;
    padding-bottom: 30px;
  }

  .intouch__item {
    border-top: 1px $intouch-border-color solid;
    padding-top: 20px;
    padding-bottom: 20px;
    color: $intouch-color;
    text-transform: uppercase;
    font-size: 12px;

    dt.intouch__item-label {
      font-family: $my-stay-header-font;
      letter-spacing: 3px;
    }

    dd.intouch__item-text {
      margin-left: 0;
      a {
        color: $intouch-color;
        word-break: break-word;
      }
    }
  }

  .intouch__map {
    padding-top: 30px;
    .angular-google-map-container {
      height: $intouch-map-height;
      margin-bottom: 10px;
    }
  }
}

@media only screen and (min-width: $screen-md) {
  .intouch__item {
    margin-left: 140px;
    margin-right: 140px;

    .intouch__item-label {
      float: left;
    }
    .intouch__item-text {
      float: right;
    }
  }

  .intouch__map {
    .angular-google-map-container {
       height: $intouch-map-big-height !important;
    }
  }
}
