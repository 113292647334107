.new-modal--preferences-saved {
  .modal-dialog {
    max-width: 480px;
    width: 100%;
  }

  .modal-dialog__body {
    padding: 20px;

    p {
      margin: 0 0 30px;
    }
  }
}
