$timeline-item-background: $white;
$timeline-border-color: $new-light-grey;

.my-stay__timeline {
  a {
    @include block-link;
  }
  p a {
    @include override-block-link;
  }
}

.timeline__section-date {
  font-family: $my-stay-header-font;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  color: $black;
  margin-bottom: 30px;
}

.timeline_day {
  margin-bottom: 60px;
}

.timeline_day--depart {
  .timeline__item-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.timeline__item {
  padding-left: 30px;
  margin-left: 0;
  border-left: 1px solid $timeline-border-color;
}

.timeline__item-wrapper {
  background-color: $white;
  padding: 30px;
  margin-bottom: 30px;
}

.timeline__item-wrapper--pending {
  background: transparent;
  border: 1px solid $timeline-border-color;
}

.timeline__item-header {
  font-size: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 24px;
  color: $black;
  border-bottom: 1px solid $timeline-border-color;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.timeline__item-image {
  width: 100%;

  img {
    width: 100%;
    height: auto;
  }
}

.timeline__item-image-disclaimer {
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
  background-color: transparent;
  text-align: left;
  color: $black;
  margin-top: 20px;
  margin-bottom: 20px;
}


.timeline__item-content {
  overflow: hidden;

  .timeline__item-subheader {
    font-family: $my-stay-header-font;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $black;
  }
}

.timeline__item-content--no-image {
  .timeline__item-copy {
    width: 100%;
  }
}

.timeline__item-copy-details {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-left: 0 !important;
  p, li {
    @include my-stay-serif-paragraph;
  }
}

.timeline__item-activity {
  .timeline__item-content {
    border-bottom: 1px solid $timeline-border-color;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .timeline__item-special-instructions {
    border-bottom: 1px solid $timeline-border-color;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding-bottom: 20px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-bottom: 20px;
  }
}

.timeline__item-activity-details {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-left: 0;
  margin-bottom: 20px;
}

.timeline__item-copy-details--lowercase {
  text-transform: none;
  letter-spacing: 0;
  font-size: 14px;
}

.timeline__item-activity-details-status {
  font-weight: bold;
}

.timeline__item--transportation {
  .timeline__item-transportation-header {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    padding-left: 0 !important;
    padding-right: 5px;
    position: relative;
  }

  h3.timeline__item-transportation-header {
    font-size: 24px;
    color: $black;
    border-bottom: 1px solid #dadada;
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  .timeline__item--transportation-update {
    font-family: $my-stay-header-font;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .timeline__item-copy {
    width: 100%;
  }
}

.timeline__item--transportation-info {
  padding-bottom: 20px;
  border-bottom: 1px solid $timeline-border-color;
  p:last-of-type {
    margin-bottom: 0;
  }
  strong {
    font-family: $my-stay-header-font;
    margin-top: 12px;
    display: block;
  }
}

.timeline__item--transportation-info,
.timeline__item--transportation-main-info {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $timeline-border-color;
}

.timeline__upgrade-room-cta {
  margin: 30px 0;

  .timeline__upgrade-room-cta-title {
    font-family: $my-stay-header-font;
    font-size: 12px;
    letter-spacing: 2px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .timeline__upgrade-room-cta-cost {
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.activity__header {
  margin-bottom: 20px;
}

.activity__cta {
  font-family: $my-stay-header-font;
  text-decoration: underline;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  margin-right: 20px;
}

.timeline__item-reservation-details {
  padding: 20px 0 0;
  clear: both;
  border-top: 1px solid $timeline-border-color;

  .timeline__item-subheader {
    cursor: pointer;
  }
}

.timeline__item-reservation-cancellation {
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
  padding-top: 30px;
}

.my-stay__timeline p a.timeline__item-reservation-cancellation-cta {
  @include block-link;
  text-transform: uppercase;
  margin-bottom: 40px;
  text-align: center;
  margin: 0 auto;
}

.timeline__item-right-icon {
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
}

.timeline__item-toggle-carat {
  display: block;
  float: right;
  margin-top: 5px;
}

.timeline__item-toggle-carat--closed {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 5.5px 0 5.5px;
  border-color: #000000 transparent transparent transparent;
}

.timeline__item-toggle-carat--open {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5.5px 6px 5.5px;
  border-color: transparent transparent #000000 transparent;
}

.timeline__item-reservation-detail-list {
  padding: 0;
  margin-top: 20px;
  border-bottom: 1px solid $timeline-border-color;
}

.timeline__item-reservation-detail {
  position: relative;
  border-top: 1px solid $timeline-border-color;
  padding: 20px 0;
  list-style-type: none;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  overflow: hidden;
  color: $black;

  strong {
    float: right;
    font-family: $my-stay-header-font;
  }
}

.timeline__item-reservation-detail-link {
  float: right;
}

.timeline__categories {
  padding-bottom: 80px;
  .timeline__categories-title {
    padding-bottom: 25px;
  }

  button.timeline__categories-allservices {
    display: block;
    margin: 48px auto 0;
    width: auto;
  }
}

.view-my-stay .timeline__check-in-btn .btn--check-in {
  max-width: 140px;
  padding: 6px 0;
  font-size: 12px;
  min-width: auto;
}

@media only screen and (min-width: $screen-xs) {
  .timeline__item-right-icon {
    right: 0;
  }
}

@media only screen and (min-width: $screen-sm) {
  .timeline__item--transportation  .timeline__item-transportation-header {
    padding-right: 0;
  }
  .timeline__item--transportation-info strong {
    display: inline;
    margin-top: 0;
  }
  .timeline__item--transportation-main-info {
    padding-bottom: 0;
    border-bottom: 0;
  }
}

@media only screen and (min-width: $screen-md) {
  .timeline__item-image {
    width: 60%;
    float: left;

    img {
      padding-right: 30px;
    }
  }

  .timeline p {
    padding-left: 0px;
  }

  .timeline__item-copy-details--lowercase {
    margin-top: 20px;
  }

  .view-my-stay .timeline__item-copy {
    width: 40%;
    float: left;
  }

  .view-my-stay .timeline__item--transportation .timeline__item-copy {
    width: 100%;
    float: none;
  }

  .timeline__upgrade-room-cta {
    width: 40%;
    float: left;
  }

  .timeline__categories {
    padding-bottom: 100px;
    .timeline__categories-title {
      padding-bottom: 45px;
    }
  }
}

