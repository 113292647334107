.survey-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 3px #d8d8d8;
  padding: 20px;
  gap: 20px;
  margin: 0 0 20px;

  @media only screen and (min-width: 701px) {
    flex-direction: row;
  }

  &__heading {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &__stars {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__star {
    background: none !important;
    margin: 0 !important;
    padding: 0 !important;
    min-width: auto !important;
    border: none !important;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
