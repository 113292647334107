.fs-sister-properties {
  margin-top: 20px;

  text-align: center;

  p {
    margin: -10px 0 10px;
  }

  .fs-sister-properties-buttons {
    overflow: auto;
    margin-bottom: 20px;

    .btn {
      @include theme-button;
      @include theme-button-secondary;
      text-transform: uppercase;
    }

    .btn:before {
      display: none;
    }
  }
}

@media only screen and (min-width: $screen-xs) {
  .fs-sister-properties {
    margin: 0;
    .fs-sister-properties-buttons {
      .btn {
        display: inline-block;
        margin: 4px;
        width: auto;
      }
    }
  }
}
