.fs-profile-subscriptions {
  &__title {
    margin-bottom: 40px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 60px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      margin-bottom: 40px;
    }

    h3 {
      margin-bottom: 30px;

      @media screen and (min-width: $breakpoints-md) {
        margin-bottom: 0;
      }
    }

    .intro {
      color: #9b9b9b;
    }
  }

  &__checkboxes {
    margin: 0 0 50px;

    .fsp-input {
      padding-bottom: 30px;
      overflow: hidden;

      &__checkbox {
        float: left;
      }
    }

    &--local {
      &__block {
        overflow: hidden;
      }

      .fsp-input {
        float: left;
        margin: 0 30px 0 0;
      }
    }
  }

  &__yacht {
    margin-top: -40px;
    margin-bottom: 60px;

    p {
      margin-bottom: 30px;
    }
  }

  h4 {
    font-size: rem(10px, 16px);
    font-weight: 700;
    font-family: $hn;
    color: #7d7d7d;
    letter-spacing: 3px;
    line-height: 13px;
    margin: 0 0 30px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  a {
    color: #fff;
  }
}
