.view--itinerary {
  padding: 0;
  text-align: center;
  background: #fff;
  color: #000;

  &__header {
    border-bottom: solid 1px #d8d8d8;
    max-width: 1120px;
    margin: 0 auto 30px;
    width: 100%;
    padding: 46px 40px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (min-width: $breakpoints-md) {
      flex-direction: row;

      fs-select {
        height: 18px;
      }
    }

    .btn-back {
      background-image: url("/images/profiles_2018/back-arrow--black.svg");
      position: absolute;
      left: 20px;
      top: 46px;
    }

    &__title {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.31;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin: 0 0 24px;

      @media screen and (min-width: $breakpoints-md) {
        &::after {
          content: ": ";
        }
      }
    }

    .Dropdown {
      display: inline-block;
      margin: 0 auto 24px;
      padding: 0;
      max-width: 100%;
      overflow: hidden;

      font-family: $garamond-mt-std;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 1px;

      @media screen and (min-width: $breakpoints-md) {
        display: block;
        margin: 0 0 0 8px;
        height: 18px;
      }

      select,
      .mask {
        border: none;
        height: auto;
        background: #fff;
        color: #000;

        @media screen and (min-width: $breakpoints-md) {
          display: block !important;
          height: 18px;
          line-height: 18px;
        }
      }

      .mask {
        padding: 0 24px 0 4px;
        position: relative;
        white-space: normal;
        text-align: center;
      }

      select {
        display: inline;
        width: unset;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        font-family: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
      }

      &::after {
        top: 50%;
        margin-top: -2px;
        border-top-color: #000;
      }
    }
  }

  // &__main {}

  &__property {
    font-family: $hn-neue;
    font-style: normal;
    font-weight: 200;
    font-size: 32px;
    line-height: 1;
    letter-spacing: 5.33333px;
    text-transform: uppercase;
    margin: 0 0 16px;

    @media screen and (min-width: $breakpoints-md) {
      font-size: 48px;
      letter-spacing: 8px;
    }
  }

  &__dates {
    font-family: $hn-neue;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin: 0 0 32px;
  }

  &__chat-with-us-container {
    position: relative;
    padding: 0 20px;

    &:after {
      content: "";
      background-color: #f5f5f5;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 50%;
    }
  }

  &__chat-with-us {
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    margin: 0 auto;
    max-width: 930px;
    padding: 36px 24px;
    position: relative;
    z-index: 10;

    @media screen and (min-width: $breakpoints-md) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media screen and (min-width: $breakpoints-lg) {
      padding-left: 120px;
      padding-right: 120px;
    }

    p {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 200;
      font-size: 22px;
      line-height: 1.5;
      text-align: center;
      letter-spacing: 1px;
      margin: 0;
    }

    .CTA--secondary--dark {
      width: 100%;
      max-width: 264px;
      margin: 24px auto 0;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;

      font-family: $hn-neue;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 1.3;
      text-align: center;
      letter-spacing: 3px;
      text-transform: uppercase;
      overflow: hidden;

      svg {
        height: 40px;
        width: 40px;
        display: none;

        path {
          stroke: white;
          fill: transparent;
          transition: stroke 0.2s ease;
        }

        @media screen and (min-width: $breakpoints-md) {
          display: block;
        }
      }

      &:hover,
      &:focus {
        svg {
          path {
            stroke: #000;
          }
        }
      }
    }
  }
}

.itinerary {
  background: #f5f5f5;
  margin: 0 auto;
  padding: 40px 20px 50px;
  text-align: initial;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul > li:last-child {
    margin-bottom: 0;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    max-width: 670px;
    width: 100%;

    li {
      margin: 0 0 24px;
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;

      li {
        margin-bottom: 0;
        position: relative;
        padding: 0 0 0 20px;

        // VERTICAL LINE
        &:before {
          background: #d8d8d8;
          content: "";
          width: 2px;
          position: absolute;
          top: -20px;
          bottom: 0;
          left: 3px;
        }

        &:first-child:before {
          top: 8px;
        }

        // DOT
        &:after {
          background: #000;
          border-radius: 1000px;
          border: solid 2px #f5f5f5;
          content: "";
          width: 10px;
          height: 10px;
          position: absolute;
          top: 5px;
          left: -1px;
        }
      }
    }
  }

  &__date {
    font-family: $hn-neue;
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 1.5;
    letter-spacing: 4px;
    margin: 0 0 24px;
  }

  &__time {
    font-family: $hn-neue;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 4px;
    margin: 0 0 16px;
    position: relative;
  }

  &__status {
    font-family: $hn-neue;
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin: 0 0 16px;
    position: relative;
  }

  &__item {
    background: #fff;
    border: 1px solid #d8d8d8;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    color: #000;
    padding: 24px;
    width: 100%;
    text-align: initial;
    position: relative;

    &__header {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &__title {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.31;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin: 0;
    }

    &__status-row {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin: 0;
    }

    &__guests {
      margin: 0;
    }

    &__status {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 3px;
      text-transform: uppercase;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 6px;

      &:before {
        background-color: #000;
        border-radius: 1000px;
        content: "";
        display: block;
        height: 8px;
        width: 8px;
      }

      &--is-pending {
        color: #e75621;

        &:before {
          background-color: #e75621;
        }
      }

      &--is-confirmed {
        color: #008000;

        &:before {
          background-color: #008000;
        }
      }
    }

    &__content {
      margin: 12px 0 0;
      border-top: solid 1px #d8d8d8;
      padding: 12px 0 0 0;
      display: flex;
      flex-direction: column;
      gap: 8px;

      font-family: $hn-neue;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 3px;
      text-transform: uppercase;

      p {
        font-family: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        margin: 0;
      }
    }
  }

  &__block {
    margin-top: 20px;
    padding: 30px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    color: #000;
    width: 100%;
    text-align: initial;
    position: relative;

    &__title {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
    }

    &__cards {
      width: 100%;
      margin-top: 30px;
      display: flex;
      justify-content: center;
      gap: 20px;
    }
  }

  ul .itinerary__ancillary-cards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 50px;
    margin-left: -20px;
    margin-right: -20px;

    &__title {
      padding: 0 20px;
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 1.36364;
      letter-spacing: 0.846px;
    }
  }

  &__quick-link {
    width: 130px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    word-break: break-word;
    transition: box-shadow 0.1s ease;

    @media screen and (min-width: $breakpoints-md) {
      height: 120px;
    }

    &:hover,
    &:focus {
      box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.15);
    }
  }

  button.itinerary__item {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: initial;
    margin: 0;
    gap: 8px;
    transition: box-shadow 0.3s;

    &:hover,
    &:focus {
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }

    .itinerary__item {
      background: none;
      border: none;
      box-shadow: none;
      flex-grow: 1;
      flex-shrink: 1;
      width: auto;
      padding: 0;
      margin: 0;
    }

    .icon-container {
      flex-grow: 0;
      flex-shrink: 0;
      width: 15px;

      @media screen and (min-width: $breakpoints-md) {
        width: 20px;
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}

.get-in-touch {
  padding: 50px 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__inner {
    max-width: 670px;
    width: 100%;
  }

  &__title {
    font-family: $hn-neue;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.29;
    text-align: center;
    letter-spacing: 4px;
    text-transform: uppercase;
    margin: 0 0 40px;

    @media screen and (min-width: $breakpoints-md) {
      margin-bottom: 46px;
    }
  }

  dl {
    text-align: initial;
    font-family: $hn-neue;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.33;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  dt {
    border-top: solid 1px #000;
    padding: 20px 0 0;
    margin: 0 0 10px;

    @media screen and (min-width: $breakpoints-md) {
      float: left;
      margin-bottom: 40px;
    }
  }

  dd {
    margin: 0 0 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: normal;

    @media screen and (min-width: $breakpoints-md) {
      border-top: solid 1px #000;
      padding-top: 20px;
      text-align: right;
      margin-bottom: 40px;
    }
  }

  a {
    color: inherit;
  }
}

.view--itinerary.rtl {
  .view--itinerary__header {
    .btn-back {
      left: unset;
      right: 20px;
    }

    .Dropdown {
      @media screen and (min-width: $breakpoints-md) {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }

  .itinerary {
    ul {
      ul {
        li {
          padding-left: 0;
          padding-right: 20px;

          &:before {
            left: unset;
            right: 3px;
          }
        }
      }
    }
  }

  .itinerary__time:after {
    left: unset;
    right: -20px;
  }

  .get-in-touch {
    dt {
      @media screen and (min-width: $breakpoints-md) {
        float: right;
      }
    }

    dd {
      @media screen and (min-width: $breakpoints-md) {
        text-align: left;
      }
    }
  }
}

.fs-itineray-modal {
  background: #fff;
  width: 100%;
  max-width: 884px;
  margin: 0 auto;
  padding: 80px 20px 120px;
  color: #000;
  text-align: center;
  position: relative;
  min-height: 100%;
  position: relative;

  @media screen and (min-width: $breakpoints-md) {
    min-height: auto;
    padding-left: 75px;
    padding-right: 75px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0 0 30px;
    border-bottom: solid 1px #d8d8d8;
    margin: 0 0 30px;

    &.arrival {
      border-bottom: none;
      margin-bottom: 0;
    }

    &__title {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 200;
      font-size: 24px;
      line-height: 1.29;
      text-align: center;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin: 0;
    }

    &__vendor-name {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.31;
      letter-spacing: 4px;
      text-transform: uppercase;
      margin: 0;
    }
  }

  &__close-btn {
    background: none;
    border: none;
    position: absolute;
    top: 42px;
    right: 24px;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    font-size: 36px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  &__content {
    dl,
    dt,
    dd {
      margin: 0;
      padding: 0;
    }

    dl {
      text-align: initial;
    }

    dt {
      font-family: $hn-neue;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 1.3;
      letter-spacing: 3px;
      text-transform: uppercase;
      margin: 0 0 12px;
    }

    dd {
      font-family: $garamond-mt-std;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      margin: 0 0 27px;

      p {
        font-family: inherit;
        font-style: inherit;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        margin-top: 0;
      }
    }

    @media screen and (min-width: $breakpoints-md) {
      dl {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
      }

      *:nth-of-type(odd) {
        grid-column-start: 1;
        grid-column-end: 2;
      }

      *:nth-of-type(even) {
        grid-column-start: 2;
        grid-column-end: 3;
      }
    }
  }

  &__chat-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__chat {
    background: #f5f5f5;
    padding: 20px;
    text-align: initial;

    p {
      font-family: "Monotype Garamond W05", Garamond, serif;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.5;
      letter-spacing: 0.5px;
      margin: 0 0 18px;
    }
  }
}
