.layout--profile-auth {
  .view--register-reservation-details {
    h1 {
      margin-bottom: 30px;
    }

    p.intro {
      margin-bottom: 80px;
    }

    .fsp-input__checkbox {
      font-size: rem(15px);
    }

    .find-reservation-using-credit-card {
      text-align: left;
      margin-bottom: 50px;

      a {
        white-space: nowrap;
      }
    }

    .or {
      font-family: $garamond;
      font-style: italic;
      margin-right: 10px;
    }

    .view__actions {
      margin-top: 60px;

      @media screen and (min-width: $breakpoints-md) {
        margin-top: 80px;
      }
    }
  }
}
